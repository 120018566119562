import React from 'react';
import { Stack, Text, DefaultButton, PrimaryButton, Separator, Link } from '@fluentui/react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import withRouter from '../withRouter';

export interface HomeProps {
  // Fill in props here
}

const containerStyle = {
  root: { margin: 20, outerHeight: '100%' }
};

const images = [
  {
    original: 'images/screenshot-1.png',
    thumbnail: 'images/screenshot-1.png',
    originalAlt: 'Cloud Studio Diagram',
    thumbnailAlt: 'Cloud Studio Diagram',
    description: 'Cloud Studio Diagram'
  },
  {
    original: 'images/screenshot-2.png',
    thumbnail: 'images/screenshot-2.png',
    originalAlt: 'Cloud Studio Diagram',
    thumbnailAlt: 'Cloud Studio Diagram',
    description: 'Cloud Studio Diagram'
  }];

  class Home extends React.Component<any, any> {


    onGotoUrl = (url: string) => {
      this.props.navigate(url);
  }

    public render() {
  return (
    <Stack tokens={{ childrenGap: 20 }} styles={containerStyle} style={{ width: "calc(100vw - 260px)" }}>
      <Text variant="xLarge">
        Visualise your cloud with cloud/studio.
      </Text>
      <Stack tokens={{ childrenGap: 8 }} className="home-panel">
        {/* <Text>
          Simplify Cloud Management with the most Advanced Cloud Architecture Diagramming Tool - Improve collaboration, automation and compliance<br />
        </Text> */}
        <ul style={{lineHeight: '2.5em', fontSize: '1.1em'}}>
          <li>Create diagrams of your cloud, from your cloud</li>
          <li>Design from <Link onClick={() => this.onGotoUrl('/templates')}>hundreds of templates</Link> based on the Azure Architecture Center and Quickstart designs</li>
          <li>Apply sophisticated layouts quickly and easily</li>
          <li>Add links between elements and animations to create dynamic visualisations</li>
          <li>Use Markdown in shapes and labels </li>
          <li>Collaborate with your team in real-time</li>
        </ul>

        {/* <Stack style={{ padding: 0, height: '50vh' }}>
          <ImageGallery
            items={images}
            autoPlay={true}
            slideOnThumbnailOver={true}

          />
        </Stack> */}

        <Separator />

        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <DefaultButton text="Learn more" />
          <PrimaryButton text="Get started" onClick={() => this.onGotoUrl('/diagrams')}></PrimaryButton>
        </Stack>
        
      </Stack>
    </Stack>
  );
      }
}


export default withRouter(Home);