import * as React from 'react';
import { Text, Stack, DetailsList, DetailsListLayoutMode, SelectionMode, IColumn, Link, ConstrainMode, IRenderFunction, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps, TooltipHost, DetailsRow, ITooltipProps, ITooltipHostStyles, DirectionalHint, TooltipDelay, PrimaryButton, Spinner, SearchBox, IDetailsColumnProps, TagPicker, ITag, IBasePickerSuggestionsProps, ICommandBarItemProps, CommandBar, ICommandBarStyles, Separator } from '@fluentui/react';
import withRouter from '../withRouter';
import moment from 'moment';
import templates from "../services/templates-architecture-center";
import DiagramService from '../services/DiagramService';
import { StatusBarDetailsList } from '../page-components/StatusBarDetailsList';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { SignInButton } from '../auth/SignInButton';

// #region Definitions
export interface ITemplate {
  lastModified: string;
  title: string;
  summary: string;
  azureCategories: string[];
  products: string[];
  url: string;
  thumbnailUrl: string;
}

export interface ITemplatesProps {
  items: ITemplate[];
  navigate: any;
}

export interface ITemplatesState {
  columns: IColumn[];
  items: ITemplate[];
  searchTerm: string;
  isLoading: boolean;
  tags: ITag[];
  suggestedTags: ITag[];
  mostUsedTags: ITag[];
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
// #endregion

const tooltipHostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block', width: 'auto' } };

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested tags',
  noResultsFoundText: 'No tags found',
};

class Templates extends React.Component<ITemplatesProps, ITemplatesState> {
  private _allItems: ITemplate[];
  filterRef = React.createRef();
  diagramService: DiagramService = new DiagramService();

  constructor(props: ITemplatesProps) {
    super(props);

    this._allItems = props.items;

    // #region Column definitions
    const columns: IColumn[] = [
      {
        className: 'templates-cells',
        key: 'titleColumn',
        name: 'Title',
        fieldName: 'title',
        minWidth: 350,
        targetWidthProportion: 4,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: ITemplate) => (
          <React.Fragment>
            <TooltipHost
              tooltipProps={{
                onRenderContent: () => (
                  <a href={`https://learn.microsoft.com/en-us${item.thumbnailUrl}`} target="_blank">
                    <img alt="Thumbnail" src={`https://learn.microsoft.com/en-us${item.thumbnailUrl}`} style={{ width: '100%' }} />
                  </a>
                )
              }}
              delay={TooltipDelay.zero}
              directionalHint={DirectionalHint.rightTopEdge}
              styles={tooltipHostStyles}
            >
              <Link target='_new' href={`https://learn.microsoft.com/en-us${item.url}`}
              >{item.title}</Link>
            </TooltipHost>
            <br />
            <p style={{ marginBottom: 0 }}>
              {item.summary}
            </p>
            <div className='tag-container'>{
              item.products?.map((tag: string) => {
                return <div key={`${item.title}_${tag}`} className={`tag-item ${this.state.tags.map(x => x.name).includes(tag) ? 'active' : ''}`} title={tag} onClick={() => this.tagClicked(tag)}>{tag}</div>
              })
            }</div>
          </React.Fragment>
        ),
      },
      {
        className: 'templates-cells-centre',
        key: 'lastModifiedColumn',
        name: 'Last Modified',
        fieldName: 'last_modified',
        minWidth: 120,
        targetWidthProportion: 1,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        sortAscendingAriaLabel: 'Sorted Newest to Oldest',
        sortDescendingAriaLabel: 'Sorted Oldest to Newest',
        onColumnClick: this._onColumnClick,
        data: 'number',
        onRender: (item: ITemplate) => {
          return <span title={moment.utc(item.lastModified).format('LLL')}>{moment.utc(item.lastModified).fromNow()}</span>;
        },
        isPadded: true,
      },
      {
        className: 'templates-cells',
        key: 'categoriesColumn',
        name: 'Categories',
        fieldName: 'azure_categories',
        minWidth: 140,
        targetWidthProportion: 2,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: this._onColumnClick,
        onRender: (item: ITemplate) => {
          return <div className='tag-container'>{
            item.azureCategories?.map((tag: string) => {
              return <div key={`${item.title}_${tag}_category`} className="tag-item" title={tag}>{tag}</div>
            })
          }</div>;
        },
        isPadded: true,
      },
      {
        className: 'templates-cells',
        key: 'actionsColumn',
        name: '',
        fieldName: '',
        minWidth: 100,
        targetWidthProportion: 0.5,
        isRowHeader: false,
        isResizable: false,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: ITemplate) => (
          <React.Fragment>
            <PrimaryButton onClick={() => this.createFromTemplate(item)} text="Create" />
          </React.Fragment>
        ),
      },
    ];
    // #endregion

    const suggestedTags = templates.reduce((acc: ITag[], template: ITemplate) => {
      if (template.products) {
        template.products.forEach((tag: string) => {
          if (!acc.find((t: ITag) => t.name === tag)) {
            acc.push({ key: tag, name: tag });
          }
        });
      }
      return acc;
    }, []);

    const tags = templates.reduce((acc: string[], template: ITemplate) => {
      if (template.azureCategories) {
        template.azureCategories.forEach((tag: string) => {
          if (!acc.includes(tag)) {
            acc.push(tag);
          }
        });
      }
      return acc;
    }, []);

    const mostUsedTags = tags.reduce((acc: any[], tag: string) => {
      const count = templates.filter((template: ITemplate) => template.azureCategories?.includes(tag)).length;
      if (count > 1) {
        acc.push({ key: tag, name: tag, count: count });
      }
      return acc;
    }, []).sort((a: any, b: any) => (a.count > b.count ? -1 : 1));

    this.state = {
      items: templates.sort((a, b) => (a.lastModified > b.lastModified ? -1 : 1)),
      columns: columns,
      isLoading: false,
      searchTerm: '',
      tags: [],
      suggestedTags: suggestedTags,
      mostUsedTags: mostUsedTags,
    };

  }


  toolbarItems: ICommandBarItemProps[] = [
    {
      key: 'search',
      text: 'Search',
      iconProps: { iconName: 'Search' },
      onRender: (item: any) => {
        return <SearchBox
          id='search'
          styles={{ root: { marginTop: '6px', marginBottom: 0, height: '37px', width: '230px' }, field: { width: '230px' } }}
          placeholder="Search"
          underlined={true}
          defaultValue={this.state.searchTerm}
          onChange={(e, value) => this.onSearch(value, this.state.tags)}
        />
      }
    },
    {
      key: 'filter',
      iconProps: { iconName: 'Tag' },
      disabled: false,
      subMenuProps: {
        items: [
          {
            key: 'layoutDetails',
            onRender: () => (<Stack tokens={{ childrenGap: 10, padding: 10 }}>
              <TagPicker
                defaultSelectedItems={this.state.tags}
                onChange={this.tagsChanged}
                removeButtonAriaLabel="Remove"
                selectionAriaLabel="Selected tags"
                onResolveSuggestions={this.filterSuggestedTags}
                getTextFromItem={(item: ITag) => item.name}
                pickerSuggestionsProps={pickerSuggestionsProps}
                pickerCalloutProps={{ doNotLayer: false }}
                inputProps={{
                  id: 'tag-picker',
                  placeholder: "Filter by categories"
                }}
                styles={{ root: { margin: 0, height: 'auto', width: '360px', alignItems: 'start' }, text: { height: 'auto', alignSelf: 'flex-start' } }}
              />
                            <Stack horizontal tokens={{ childrenGap: 10, maxWidth: 360 }}>
                <div className='tag-container'>
                  {this.state.mostUsedTags.map((tag, index) => (!this.listContainsTagList(tag, this.state.tags)) &&
                    <div key={`_${tag.key}`} className={`tag-item ${this.state.tags.map(x => x.name).includes(tag.name) ? 'active' : ''}`} title={tag.name} onClick={() => this.tagClicked(tag.name)}>{tag.name}</div>
                  )}
                </div>
              </Stack>

            </Stack>),
          },
        ]
      }
    },
  ];

  tagClicked = (tag: string): void => {
    let tags = this.state.tags;
    if (tags.find((t: ITag) => t.name === tag)) {
      tags = tags.filter((t: ITag) => t.name !== tag);
      this.setState({ tags: tags.slice() });
    } else {
      tags.push({ key: tag, name: tag });
      this.setState({ tags: tags });
    }
    this.onSearch(this.state.searchTerm, tags);
  };

  tagsChanged = (items?: ITag[] | undefined) => {
    this.setState({ tags: items || [] });
    this.onSearch(this.state.searchTerm, items || []);
  };

  listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some(compareTag => compareTag.key === tag.key);
  };

  filterSuggestedTags = (filterText: string, tagList?: ITag[]): ITag[] => {
    if (!filterText) {
      return [];
    }
    let results = this.state.suggestedTags
      .filter((tag: ITag) => tag.name.toLowerCase().includes(filterText.toLowerCase()) && !this.listContainsTagList(tag, tagList))
      .sort((a: ITag, b: ITag) => a.name.localeCompare(b.name));
    results.push({ key: filterText, name: filterText });
    return results;
  };

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    if (ev.target instanceof HTMLInputElement) {
      return;
    }
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        // this.setState({
        //   announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? 'descending' : 'ascending'
        //     }`,
        // });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  // private onSearch = (text: string | undefined): void => {
  //   this.setState({
  //     searchTerm: text || '',
  //     items: templates.filter(x => x.title.toLowerCase().includes(text?.toLowerCase() || '')),
  //   });
  // };

  private onSearch = (text: string | undefined, tags: ITag[]): void => {
    const tagsList = tags.map(x => x.name);
    let results: any[] = templates
      .filter(
        x => (!text || x.title.toLowerCase().includes(text?.toLowerCase() || '') || x.summary.toLowerCase().includes(text?.toLowerCase() || ''))
          && (
            tagsList.length === 0
            || (x.azureCategories && tagsList.every(y => x.azureCategories.map(x => x.trim()).includes(y.toLowerCase())))
            || (x.products && tagsList.every(y => x.products.map(x => x.trim()).includes(y.toLowerCase())))
          )
      );

    this.setState({
      searchTerm: text || '',
      items: results.slice(),
    });

  };


  private createFromTemplate(item: ITemplate) {
    this.setState({ isLoading: true });
    this.diagramService.createFromArchitectureCentreTemplate(item, (id: string) => {
      this.props.navigate(`/diagram#${id}&fit`);
    }).then(() => {

    }).catch((ex: any) => {
      console.log(ex);
      this.setState({ isLoading: false });
    });
  }

  public render() {

    const { columns, items } = this.state;

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
      if (!props) {
        return null;
      }
      const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
      );
      return defaultRender!({
        ...props,
        onRenderColumnHeaderTooltip,
      });
    };

    const styles: Partial<ICommandBarStyles> = {
      root: {
        boxSizing: 'border-box',
        'border-bottom': '1px solid #eee',
        'background-color': '#fafcf7',
        padding: 0,
      },
    };

    return (
      <React.Fragment>
      <AuthenticatedTemplate>

      <Stack style={{ width: "calc(100vw - 220px)" }}>
        {this.state.isLoading && (
          <Stack horizontalAlign='center' verticalAlign='center' style={{ backgroundColor: 'rgba(240,240,240,0.5)', position: 'fixed', left: 220, top: 74, width: 'calc(100vw - 220px)', height: 'calc(100vh - 74px)', zIndex: 2000 }}>
            <Spinner label="Creating diagram" ariaLive="assertive" labelPosition="bottom" />
          </Stack>
        )}
        <CommandBar
          items={this.toolbarItems}
          ariaLabel="Use left and right arrow keys to navigate between commands"
          styles={styles}
        />
        <Stack style={{ width: "calc(100vw - 220px)", height: "calc(100vh - 160px)", overflowY: "scroll" }}>

        <DetailsList
          items={items}
          compact={false}
          columns={columns}
          selectionMode={SelectionMode.none}
          getKey={(item: any, index?: number) => { return `${item.url}-${index}`; }}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          onRenderDetailsHeader={onRenderDetailsHeader}
          constrainMode={ConstrainMode.unconstrained}
        />
        </Stack>
        <StatusBarDetailsList statusMessage={`Showing ${this.state.items.length} item${this.state.items.length !== 1 ? 's' : ''}`}></StatusBarDetailsList>
      </Stack>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>

      <Stack tokens={{ childrenGap: 20 }} styles={{ root: { margin: 20, outerHeight: '100%' } }}>
            <Text variant="xLarge">
              You'll need to have an account with us to view and use templates
            </Text>
            <Stack tokens={{ childrenGap: 8 }} className="home-panel">

              <ul style={{ lineHeight: '2.5em', fontSize: '1.1em', marginBottom: '10px' }}>
                <li>Whilst we're in alpha, cloud/studio is <strong>free</strong></li>
                <li>We'll be introducing a paid tier soon</li>
                <li>Sign up now to get going</li>
              </ul>

              <Separator />

              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <SignInButton></SignInButton>
              </Stack>

            </Stack>
          </Stack>

      </UnauthenticatedTemplate>

      </React.Fragment>
    );
  }
}

export default withRouter(Templates);