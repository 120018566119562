import { createTheme } from '@fluentui/react';

export const defaultTheme = createTheme({
    palette: {
      themePrimary: '#8dab60',
      themeLighterAlt: '#fafcf7',
      themeLighter: '#ebf2e1',
      themeLight: '#dae6c7',
      themeTertiary: '#b7cd97',
      themeSecondary: '#99b56f',
      themeDarkAlt: '#7f9a56',
      themeDark: '#6b8249',
      themeDarker: '#4f6036',
      neutralLighterAlt: '#eceeed',
      neutralLighter: '#e8eae9',
      neutralLight: '#dee1e0',
      neutralQuaternaryAlt: '#cfd1d0',
      neutralQuaternary: '#c6c8c7',
      neutralTertiaryAlt: '#bec0bf',
      neutralTertiary: '#bfc3c6',
      neutralSecondary: '#83888c',
      neutralSecondaryAlt: '#83888c',
      neutralPrimaryAlt: '#4e5357',
      neutralPrimary: '#3a3e41',
      neutralDark: '#2b2e30',
      black: '#202224',
      white: '#fdfffe',
    }
  });