import * as React from 'react';
import { ChoiceGroup, DefaultButton, IBasePickerSuggestionsProps, IChoiceGroupOption, IStackStyles, ITag, Label, Link, Panel, PrimaryButton, ScrollablePane, ScrollbarVisibility, Separator, Spinner, Stack, TagPicker, TextField } from '@fluentui/react';
import templates from "../services/templates-community";
import { getContainerStyleBasedOnResolution } from './wizard/helpers';
import { StepperNav } from './wizard/vertical-stepper';
import { IStepDescription } from './wizard/StepperNav';
import Editor from '../editor/Editor';
import { defaultTheme } from '../Theme';

type IDiagramCreateProps = {
    visible: boolean;
    tags: ITag[];
    onDismiss: () => void;
    onCreateDiagram: (details: any) => void;
}

type IDiagramCreateState = {
    visible: boolean;
    type: string;
    name: string;
    title: string;
    description: string;
    tags: ITag[];
    suggestedTags: ITag[];
    templateFilter: string | undefined;
    templates: any[];
    selectedTemplate: any;
    armTemplate: string;
    isLoading: boolean;
    steps: IStepDescription[];
    currentStep: IStepDescription;
    errorMessage: string;
}

const containerStyles: Partial<IStackStyles> = {
    root: {
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        padding: 0,
        margin: 0
    },
};

const options: IChoiceGroupOption[] = [
    { key: 'blank', text: 'Blank diagram' },
    { key: 'template', text: 'Diagram based on a template' },
    { key: 'code', text: 'Diagram from an ARM template ' }
];

export class DiagramCreate extends React.Component<any, IDiagramCreateState>  {

    constructor(props: IDiagramCreateProps) {
        super(props);

        this.handleArmTemplateChange = this.handleArmTemplateChange.bind(this);

        this.state = {
            type: 'blank',
            name: '',
            title: '',
            description: '',
            tags: [],
            suggestedTags: this.props.tags,
            selectedTemplate: undefined,
            armTemplate: '',
            templateFilter: '',
            templates: [],
            visible: false,
            isLoading: false,
            steps: this.blankDiagramSteps,
            currentStep: this.stepChoose,
            errorMessage: '',
        };
    }

    stepChoose = {
        stepContent: () => <div>Choose a diagram type</div>,
        stepStateColor: defaultTheme.palette.themePrimary,
        onClickHandler: () => this.setState({ currentStep: this.stepChoose }),
    };

    stepTemplate = {
        stepContent: () => <div>Select a template</div>,
        stepStateColor: defaultTheme.palette.themePrimary,
        onClickHandler: () => this.setState({ currentStep: this.stepTemplate }),
    };

    stepEnterCode = {
        stepContent: () => <div>Enter a template</div>,
        stepStateColor: defaultTheme.palette.themePrimary,
        onClickHandler: () => this.setState({ currentStep: this.stepEnterCode }),
    };

    stepConfigureDiagram = {
        stepContent: () => <div>Configure diagram</div>,
        stepStateColor: defaultTheme.palette.themePrimary,
        onClickHandler: () => this.setState({ currentStep: this.stepConfigureDiagram }),
    };

    stepReviewAndCreate = {
        stepContent: () => <div>Review & create</div>,
        stepStateColor: defaultTheme.palette.themePrimary,
        onClickHandler: () => this.setState({ currentStep: this.stepReviewAndCreate }),
    };

    blankDiagramSteps = [ this.stepChoose, this.stepConfigureDiagram, this.stepReviewAndCreate ];
    templateDiagramSteps = [ this.stepChoose, this.stepTemplate, this.stepConfigureDiagram, this.stepReviewAndCreate ];
    codeDiagramSteps = [ this.stepChoose, this.stepEnterCode, this.stepConfigureDiagram, this.stepReviewAndCreate ];
        
    componentDidUpdate(prevProps: any, prevState: IDiagramCreateState): void {
        if (prevProps.visible !== this.props.visible) {
            this.setState({ visible: this.props.visible });
        }
    }

    onOptionSelected = (event?: any, option?: IChoiceGroupOption) => {
        if (!option) return;
        const steps = option.key === 'template' ? this.templateDiagramSteps : option.key === 'code' ? this.codeDiagramSteps : this.blankDiagramSteps;
        this.setState({
            type: option?.key || this.state.type, steps: steps, currentStep: steps[0]
        });
    }

    onFinished = () => {
        this.setState({ isLoading: true });
        let details = {
            type: this.state.type,
            name: this.state.name,
            description: this.state.description,
            tags: this.state.tags.map((tag: ITag) => tag.key).join(', ') || '',
            title: this.state.name,
            selectedTemplate: this.state.selectedTemplate,
            armTemplate: this.state.armTemplate
        };
        this.props.onCreateDiagram(details);
    }

    onDismiss = () => {
        this.setState({ visible: false });
        this.props.onDismiss();
    }

    onPreviousStep = () => {
        const currentIndex = this.state.steps.findIndex((step) => step === this.state.currentStep);
        const previousStep = this.state.steps[currentIndex - 1];
        this.setState({ currentStep: previousStep });
    }

    onNextStep = () => {
        // First validate the current step
        switch (this.state.currentStep) {
            case this.stepChoose:
                if (this.state.type === '') {
                    this.setState({ errorMessage: 'Please select a diagram type'});
                    return;
                }
                break;
            case this.stepTemplate:
                if (this.state.templateFilter === '' || this.state.selectedTemplate === undefined) {
                    this.setState({ errorMessage: 'Please select a template'});
                    return;
                }
                break;
            case this.stepEnterCode:
                if (this.state.armTemplate === '') {
                    this.setState({ errorMessage: 'Please enter an ARM template'});
                    return;
                }
                break;
            case this.stepConfigureDiagram:
                if (this.state.name === '') {
                    this.setState({ errorMessage: 'Please enter a name'});
                    return;
                }
                break;
        }

        // Then move to the next step
        const currentIndex = this.state.steps.findIndex((step) => step === this.state.currentStep);
        if (currentIndex === this.state.steps.length - 1) {
            this.onFinished();
            return;
        }

        if (this.state.currentStep === this.stepTemplate) {
            if (this.state.name === undefined || this.state.name === '') {
                this.setState({ name: this.state.selectedTemplate.Title });
            }
            if (this.state.description === undefined || this.state.description === '') {
                this.setState({ description: this.state.selectedTemplate.Summary });
            }
            if (this.state.tags.length === 0) {
                this.setState({ tags: this.state.selectedTemplate.Products.map((tag: string) => { return { key: tag, name: tag } }) });
            }
        }

        const nextStep = this.state.steps[currentIndex + 1];
        this.setState({ currentStep: nextStep, errorMessage: '' });
    }

    stepper = () => {
        return (
            <Stack
                styles={{
                    root: {
                        minWidth: getContainerStyleBasedOnResolution().navWidth,
                        maxWidth: getContainerStyleBasedOnResolution().navWidth,
                    },
                }}
            >
                <StepperNav
                    steps={this.state.steps}
                    currentStep={this.state.currentStep} />
            </Stack>
        )
    }

    onRenderFooter = () => {
        if (this.state.isLoading) return (<Stack />)
        return (
            <footer style={{ marginLeft: getContainerStyleBasedOnResolution().navWidth }}>
                <Stack horizontal tokens={{ childrenGap: 12 }}>
                    <Stack.Item grow={0} >
                        <DefaultButton onClick={() => this.onPreviousStep()} disabled={this.state.currentStep === this.stepChoose}>Back</DefaultButton>
                    </Stack.Item>
                    <Stack.Item grow={0} >
                        <PrimaryButton onClick={() => this.onNextStep()} disabled={false}>
                            {this.state.currentStep !== this.stepReviewAndCreate ? 'Next' : 'Finish'}
                        </PrimaryButton>
                    </Stack.Item>
                    <Stack.Item grow={1} style={{ alignItems: 'flex-end' }}>
                        <DefaultButton onClick={this.onDismiss}>
                            Cancel
                        </DefaultButton>
                    </Stack.Item>
                </Stack>
            </footer>);
    }

    filterTemplates(filter: string | undefined) {
        const filtered = templates.filter(template => (template.Title.includes(filter || "") || template.Summary.includes(filter || "")) && template.Languages.includes('json'));
        this.setState({ templates: filtered, templateFilter: filter });
    }

    private handleArmTemplateChange(value?: string) {
        console.log('handleArmTemplateChange');
        this.setState({ type: 'code', name: '', selectedTemplate: null, armTemplate: value || '' });
    }


    tagsChanged = (items?: ITag[] | undefined) => {
        this.setState({ tags: items || [] });
    };

    listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false;
        }
        return tagList.some(compareTag => compareTag.key === tag.key);
    };

    filterSuggestedTags = (filterText: string, tagList?: ITag[]): ITag[] => {
        if (!filterText) {
            return [];
        }
        let results = this.props.tags.filter((tag: ITag) => tag.name.indexOf(filterText) === 0 && !this.listContainsTagList(tag, tagList));
        results.push({ key: filterText, name: filterText });
        return results;
    };

    public render() {

        const getTextFromItem = (item: ITag) => item.name;

        const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
            suggestionsHeaderText: 'Suggested tags',
            noResultsFoundText: 'No tags found',
        };

        return (
            <Panel
                headerText="Create a new Diagram"
                isBlocking={false}
                isOpen={this.state.visible}
                onDismiss={this.onDismiss}
                closeButtonAriaLabel="Close"
                onRenderFooterContent={this.onRenderFooter}
                isFooterAtBottom={true}
                styles={{ main: { minWidth: '60vw' } }}
            >
                {this.state.isLoading && (
                    <Stack horizontalAlign='center' verticalAlign='center' style={{ backgroundColor: 'rgba(240,240,240,0.5)', position: 'relative', left: 0, top: 0, width: '100%', height: '100%', zIndex: 2000 }}>
                        <Spinner label="Creating diagram" ariaLive="assertive" labelPosition="bottom" />
                    </Stack>
                )}

                {!this.state.isLoading && (
                    <Stack styles={containerStyles}>

                        <Stack tokens={{ maxHeight: "calc(100vh - 110px)" }}>
                            <Separator
                                styles={{
                                    root: [
                                        {
                                            selectors: { "::before": { top: "100%" } },
                                            height: 2,
                                            paddingTop: 0,
                                        },
                                    ],
                                }}
                            />
                            <Stack horizontal tokens={{ maxHeight: "inherit" }}>
                                <Stack.Item
                                    styles={{
                                        root: [
                                            {
                                                marginTop: 30,
                                                selectors: {
                                                    "::-webkit-scrollbar": { width: "4px", height: "6px" },
                                                    "::-webkit-scrollbar-track": {
                                                        borderRadius: "10px",
                                                        background: "rgba(0,0,0,0.05)",
                                                    },
                                                    "::-webkit-scrollbar-thumb": {
                                                        borderRadius: "10px",
                                                        background: "rgba(0,0,0,0.1)",
                                                    },
                                                    "::-webkit-scrollbar-thumb:hover": {
                                                        background: "rgba(0,0,0,0.2)",
                                                    },
                                                    "::-webkit-scrollbar-thumb:active": {
                                                        background: "rgba(0,0,0,0.3)",
                                                    },
                                                },
                                                height: "inherit",
                                                overflowY: "auto",
                                                width: getContainerStyleBasedOnResolution().navWidth,
                                            },
                                        ],
                                    }}
                                >
                                    <Stack>
                                        <StepperNav steps={this.state.steps} currentStep={this.state.currentStep} />
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <Separator vertical styles={{ root: { padding: 0 } }} />
                                </Stack.Item>
                                <Stack
                                    grow={1}
                                    styles={{
                                        root: {
                                            borderLeftStyle: "solid",
                                            borderLeftColor: "rgb(237, 235, 233)",
                                            borderLeftWidth: 1,
                                        },
                                    }}
                                >
                                    <Stack
                                        styles={{
                                            root: {
                                                height: "inherit",
                                                position: "relative",
                                                maxHeight: "calc(100vh - 110px)",
                                                minHeight: "80vh",
                                            },
                                        }}
                                    >
                                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>

                                            {this.state.currentStep === this.stepChoose && (
                                                <Stack verticalFill={true} tokens={{ childrenGap: 5 }} styles={{ root: { paddingLeft: '30px', paddingTop: '30px' } }}>
                                                    <ChoiceGroup
                                                        defaultSelectedKey={this.state.type}
                                                        options={options}
                                                        label="What would you like to create"
                                                        required={true}
                                                        onChange={(event?: any, option?: IChoiceGroupOption) => this.onOptionSelected(event, option)} />
                                                </Stack>
                                            )}

                                            {this.state.currentStep === this.stepTemplate && (
                                                <Stack verticalFill={true} tokens={{ childrenGap: 5 }} styles={{ root: { paddingLeft: 30, paddingTop: 20 } }}>
                                                    <TextField required errorMessage={this.state.errorMessage} label='Search for template' defaultValue={this.state.templateFilter} onChange={(event, newValue) => this.filterTemplates(newValue)}></TextField>
                                                    {this.state.templates && this.state.templates.length > 0 && <Label>Showing {this.state.templates.length} template(s)</Label>}
                                                    {this.state.templates && this.state.templates.length > 0 &&
                                                        <ScrollablePane key={`templates_${this.state.templates.length}`} scrollbarVisibility={ScrollbarVisibility.auto} styles={{ root: { marginLeft: 30, height: 'calc(100% - 120px)', top: 110 } }}>
                                                            {this.state.templates.map((template) => {
                                                                return <Stack key={`${template.Title}-stack`} style={{ padding: '10px 5px', borderBottom: '1px solid #eee', backgroundColor: this.state.selectedTemplate === template ? 'rgba(220, 220, 220, 1)' : 'transparent' }} >
                                                                    <Link key={`${template.Title}-title`} onClick={() => this.setState({ type: 'template', title: template.Title, selectedTemplate: template })}>{template.Title}</Link>
                                                                    <Label key={`${template.Title}-summary`} styles={{ root: { fontWeight: 400 } }}>{template.Summary}</Label>
                                                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                                                        <Link key={`${template.Title}-docs`} href={`https://learn.microsoft.com/en-us${template.Url}`} target="_blank">Details</Link>
                                                                        <Link key={`${template.Title}-arm`} href={`${template.TemplateUrl}`} target="_blank">ARM</Link>
                                                                        <Link key={`${template.Title}-armviz`} href={`http://armviz.io/#/?load=${template.TemplateUrl}`} target="_blank">ARMVIZ</Link>
                                                                    </Stack>
                                                                </Stack>
                                                            })
                                                            }
                                                        </ScrollablePane>}
                                                </Stack>
                                            )}

                                            {this.state.currentStep === this.stepEnterCode && (
                                                <Stack verticalFill={true} style={{ borderRight: '1px solid rgb(232, 234, 233)', borderBottom: '1px solid rgb(232, 234, 233)', borderTop: '1px solid rgb(232, 234, 233)' }}>
                                                    <Editor
                                                        mode="json"
                                                        language="json"
                                                        defaultLanguage='json'
                                                        value={this.state.armTemplate}
                                                        onChange={this.handleArmTemplateChange}
                                                    />
                                                </Stack>
                                            )}

                                            {this.state.currentStep === this.stepConfigureDiagram && (
                                                <Stack verticalFill={true} tokens={{ childrenGap: 5 }} styles={{ root: { paddingLeft: '30px', paddingTop: '30px' } }}>
                                                    <TextField errorMessage={this.state.errorMessage} required label='Name' defaultValue={this.state.name} onChange={(event, newValue) => this.setState({ name: newValue || 'New Diagram' })}></TextField>
                                                    <TextField multiline={true} label='Description' defaultValue={this.state.description} onChange={(event, newValue) => this.setState({ description: newValue || '' })}></TextField>

                                                    <div>
                                                        <label htmlFor='tag-picker' className='picker-label'>Tags</label>
                                                        <TagPicker
                                                            defaultSelectedItems={this.state.tags}
                                                            onChange={this.tagsChanged}
                                                            removeButtonAriaLabel="Remove"
                                                            selectionAriaLabel="Selected tags"
                                                            onResolveSuggestions={this.filterSuggestedTags}
                                                            getTextFromItem={getTextFromItem}
                                                            pickerSuggestionsProps={pickerSuggestionsProps}
                                                            pickerCalloutProps={{ doNotLayer: true }}
                                                            inputProps={{
                                                                id: 'tag-picker',
                                                            }}
                                                        />
                                                        <div
                                                            style={{ height: '10em' }}
                                                        />
                                                    </div>

                                                </Stack>
                                            )}

                                        </ScrollablePane>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>

                    </Stack>
                )}

            </Panel>
        );
    }

};
