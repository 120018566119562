import { AccountInfo, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { ITag } from "@fluentui/react";
import { msalConfig, silentRequest } from "../auth/authConfig.b2c";
import { v4 as uuidv4 } from 'uuid';

const DataService = {
    FunctionUrl: "https://api.cloudstudio.app/api/",

    userId: "",
    userName: "",
    accessToken: "",
    diagrams: [],

    //#region Authentication Methods

    getAccount(): AccountInfo {
        const msal = new PublicClientApplication(msalConfig);
        const currentAccounts = msal.getAllAccounts();
        // if (currentAccounts === null || currentAccounts.length === 0) {
        //     console.log("No accounts detected");
        //     return null;
        // }
        this.userId = currentAccounts[0].idTokenClaims?.oid || "";
        this.userName = currentAccounts[0].idTokenClaims?.name || "";
        return currentAccounts[0];
    },

    getAccessToken: async () => {
        const msal = new PublicClientApplication(msalConfig);
        await msal.initialize();
        const currentAccounts = msal.getAllAccounts();
        const redirectResponse = await msal.handleRedirectPromise();
        if (redirectResponse !== null) {
            return redirectResponse.accessToken;
        } else {
            try {
                const accessTokenResponse = await msal.acquireTokenSilent({ ...silentRequest, account: currentAccounts[0], });
                return accessTokenResponse.accessToken;
            } catch (error: any) {
                const errorCode = error.errorCode;
                if (errorCode === "login_required" || errorCode === "interaction_required" || errorCode === "consent_required") {
                    msal.acquireTokenRedirect(silentRequest);
                } else {
                    console.log(error);
                }
            }
        }
    },

    getFetchOptions: async (method: string = "GET", body: any = undefined) => {
        const accessToken = await DataService.getAccessToken();
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        const options = {
            method: method,
            headers: headers,
            body
        };
        return options;
    },

    //#endregion

    //#region Diagram Methods

    getDiagrams: async () => {
        const account = DataService.getAccount();
        const ownerId = account?.idTokenClaims?.oid;
        const options = await DataService.getFetchOptions();
        const response = await fetch(`${DataService.FunctionUrl}GetDiagrams?id=${ownerId}`, options);
        return response.json();
    },

    getDiagram: async (id: string) => {
        const account = DataService.getAccount();
        const ownerId = account?.idTokenClaims?.oid;
        const options = await DataService.getFetchOptions();
        const response = await fetch(`${DataService.FunctionUrl}GetDiagram?id=${id}`, options);
        console.log(response);
        return response.json();
    },

    createDiagram: async (diagram: any) => {
        const account = DataService.getAccount();
        diagram.ownerId = account?.idTokenClaims?.oid;
        diagram.ownerName = account?.idTokenClaims?.name;
        diagram.ownerEmail = account?.idTokenClaims?.emails ? account?.idTokenClaims?.emails[0] : '';
        const body = JSON.stringify(diagram);
        const options = await DataService.getFetchOptions("POST", body);
        const response = await fetch(`${DataService.FunctionUrl}CreateDiagram`, options);
        return response;
    },

    updateDiagram: async (id: string, title: string, description: string, tags: ITag[]) => {
        const tagList = tags.map((tag: any) => tag.name).join(", ");
        const account = DataService.getAccount();
        const update = {
            diagramId: id,
            ownerId: account?.idTokenClaims?.oid,
            title: title,
            description: description,
            tags: tagList
        };
        const body = JSON.stringify(update);
        const options = await DataService.getFetchOptions("POST", body);
        const response = await fetch(`${DataService.FunctionUrl}UpdateDiagram`, options);
        return response;
    },

    deleteDiagram: async (id: string) => {
        const account = DataService.getAccount();
        const update = {
            id: id,
            userId: account?.idTokenClaims?.oid,
            userName: account?.idTokenClaims?.name,
            userEmail: account?.idTokenClaims?.emails ? account?.idTokenClaims?.emails[0] : ''
        };
        const body = JSON.stringify(update);
        const options = await DataService.getFetchOptions("POST", body);
        const response = await fetch(`${DataService.FunctionUrl}DeleteDiagram`, options);
        return response;
    },

    diagramUpdated: async (id: string | undefined, png: string) => {
        if (!id) return Promise.resolve();

        console.log("diagramUpdated", id, png.length);

        const account = DataService.getAccount();
        const update = {
            id: id,
            userId: account?.idTokenClaims?.oid,
            userName: account?.idTokenClaims?.name,
            userEmail: account?.idTokenClaims?.emails ? account?.idTokenClaims?.emails[0] : '',
            thumbnail: png
        };
        const body = JSON.stringify(update);
        const options = await DataService.getFetchOptions("POST", body);
        const response = await fetch(`${DataService.FunctionUrl}DiagramUpdated`, options);
        return response;
    },

    createDiagramSnapshot: async (snapshot: any) => {
        const account = DataService.getAccount();
        snapshot.ownerId = account?.idTokenClaims?.oid;
        snapshot.ownerName = account?.idTokenClaims?.name;
        snapshot.ownerEmail = account?.idTokenClaims?.emails ? account?.idTokenClaims?.emails[0] : '';
        const body = JSON.stringify(snapshot);
        const options = await DataService.getFetchOptions("POST", body);
        const response = await fetch(`${DataService.FunctionUrl}CreateDiagramSnapshot`, options);
        return response;
    },

    getDiagramSnapshots: async (diagramId: string | undefined) => {
        if (!diagramId) return Promise.resolve([]);
        const options = await DataService.getFetchOptions("GET");
        const response = await fetch(`${DataService.FunctionUrl}GetDiagramSnapshots?id=${diagramId}`, options);
        return response.json();
    },

    getDiagramSnapshotJson: async (snapshot: any) => {
        const options = await DataService.getFetchOptions("GET");
        const response = await fetch(`${DataService.FunctionUrl}GetDiagramSnapshotJson?id=${snapshot.snapshotId}`, options);
        return response.text();
    },

    getChatMessages: async (diagramId: string | undefined) => {
        if (!diagramId) return Promise.resolve([]);
        const options = await DataService.getFetchOptions("GET");
        const response = await fetch(`${DataService.FunctionUrl}GetChatMessages?diagramId=${diagramId}`, options);
        return response.json();
    },

    //#endregion

    //#region Shape Methods

    getShapes: async () => {
        const account = DataService.getAccount();
        const ownerId = account?.idTokenClaims?.oid;
        const options = await DataService.getFetchOptions();
        const response = await fetch(`${DataService.FunctionUrl}GetShapes?id=${ownerId}`, options);
        return response.json();
    },

    createShape: async (shape: any) => {
        const account = DataService.getAccount();
        shape.ownerId = account?.idTokenClaims?.oid;
        shape.ownerName = account?.idTokenClaims?.name;
        shape.ownerEmail = account?.idTokenClaims?.emails ? account?.idTokenClaims?.emails[0] : '';
        shape.shapeId = uuidv4();
        const body = JSON.stringify(shape);
        const options = await DataService.getFetchOptions("POST", body);
        const response = await fetch(`${DataService.FunctionUrl}CreateShape`, options);
        return response;
    },

    updateShape: async (id: string, title: string, definition: string, preview: string) => {

        const account = DataService.getAccount();
        const update = {
            shapeId: id,
            ownerId: account?.idTokenClaims?.oid,
            title: title,
            definition: definition,
            preview: preview
        };
        const body = JSON.stringify(update);
        const options = await DataService.getFetchOptions("POST", body);
        const response = await fetch(`${DataService.FunctionUrl}UpdateShape`, options);
        return response;
    },

    deleteShape: async (id: string) => {
        const account = DataService.getAccount();
        const update = {
            shapeId: id,
            ownerId: account?.idTokenClaims?.oid,
            ownerName: account?.idTokenClaims?.name,
            ownerEmail: account?.idTokenClaims?.emails ? account?.idTokenClaims?.emails[0] : ''
        };
        const body = JSON.stringify(update);
        const options = await DataService.getFetchOptions("POST", body);
        const response = await fetch(`${DataService.FunctionUrl}DeleteShape`, options);
        return response;
    },

    //#endregion

    //#region Diagram Image Methods

    getDiagramImageTextFromUrl: async (url: string) => {
        const options = await DataService.getFetchOptions("POST");
        const response = await fetch(`${DataService.FunctionUrl}GetDiagramImageTextFromUrl?url=${encodeURIComponent(url)}`, options);
        return response.json();
    },

    getDiagramImageTextFromImageData: async (content: Blob) => {
        let formData = new FormData();
        formData.append("content", content);
        const options = await DataService.getFetchOptions("POST", content);
        options.headers.append("Content-Type", 'image/*');
        const response = await fetch(`${DataService.FunctionUrl}GetDiagramImageTextFromImageData`, options);
        return response.json();
    },

    //#endregion

}

export default DataService;
