import { Stack, Text } from '@fluentui/react';
  import React, { useEffect, useState } from 'react';

  export const Privacy = (props: any) => {
    const [content, setContent] = useState('');

    useEffect(() => {
      async function fetchData() {
        const response = await fetch('https://www.iubenda.com/api/privacy-policy/42078145');
        const result = await response.json();
        setContent(result.content);
      }
  
      fetchData();
    }, []);

    return (
        <Stack styles={{root: { margin: 20, outerHeight: '100%' }}} style={{ width: "calc(100vw - 260px)" }}>
        {/* <Text variant="xLarge">
          Privacy
        </Text> */}
        <Stack tokens={{ childrenGap: 8 }} className="content-panel">
            <div style={{ padding: 0 }} dangerouslySetInnerHTML={{__html: content}} />
        </Stack>
      </Stack>
    );

  }