import { Stack, Link, IStackStyles, IPersonaSharedProps, Persona, PersonaPresence, PersonaInitialsColor, ContextualMenuItemType, ContextualMenu, IContextualMenuItem, ILinkStyles, DirectionalHint } from '@fluentui/react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import React from 'react';
import { useEffect } from "react";
import { EventType } from "@azure/msal-browser";
import { silentRequest } from '../auth/authConfig.b2c';
import { SignInButton } from '../auth/SignInButton';
import { ProfilePanel } from './ProfilePanel';

const stackStyles: Partial<IStackStyles> = {
  root: {
    background: '#F4F6F5',
    boxSizing: 'border-box',
    boxShadow: '0px -1px 4px 1px rgba(0,0,0,0.5)',
    zIndex: '1',
    padding: 0,
    color: '#41502A',
    display: 'flex',
    alignItems: 'center',
    flexShrink: '0'
  },
};

const logoStyles: Partial<IStackStyles> = {
  root: {
    display: 'flex',
    'align-items': 'center',
    'flex-shrink': '0',
    height: 74
  },
};

const linkStyles: Partial<ILinkStyles> = {
  root: {
    'margin-left': 'auto !important'
  },
};

const signInStyles: Partial<IStackStyles> = {
  root: {
    marginLeft: 'auto !important',
    marginRight: 20
  },
};

export const Banner = () => {

  const { instance, accounts } = useMsal();
  const linkRef = React.useRef(null);
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const [showProfilePanel, setShowProfilePanel] = React.useState(false);
  const [signedInPersona, setSignedInPersona] = React.useState<IPersonaSharedProps|null>(null);
  const [profileData, setProfileData] = React.useState<any>(null);
  const onShowContextualMenu = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault();
    setShowContextualMenu(true);
  }, []);
  const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);

  useEffect(() => {
    // This will be run on component mount
    if (instance.getAllAccounts().length > 0) {
      let details: any = instance.getAllAccounts()[0].idTokenClaims;
      const persona: IPersonaSharedProps = {
        imageInitials: '',
        text: details?.name,
        secondaryText: details?.jobTitle || details?.emails[0],
      };
      console.log('details', details);
      setSignedInPersona(persona);
      setProfileData(details);
    }

    const callbackId = instance.addEventCallback((message: any) => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        let account = instance.getAllAccounts()[0];
        let details: any = account.idTokenClaims;
        const persona: IPersonaSharedProps = {
          imageInitials: '',
          text: details?.name,
          secondaryText: details?.jobTitle || details?.emails[0],
        };
        setSignedInPersona(persona);

        instance.acquireTokenSilent({ ...silentRequest, account: account, })
          .then((response) => {
            console.log('acquireTokenSilent', response);
            const expirationDate = new Date();
            // TODO: set expiration to 30 minutes & update on refresh
            expirationDate.setTime(expirationDate.getTime() + (30*60*60*1000));
            let expires = "expires="+ expirationDate.toUTCString();
            document.cookie = "accessToken=" + response.accessToken + ";" + expires + ";path=/;SameSite=Strict;";
            // getProfileData(token, account.localAccountId)
            //   .then((response) => { return response; })
            //   .then((details) => {
            //     getUserProfileImage(token, details.id).then((image) => setProfileData(details, image || undefined));
            //   });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    }

  }, [instance]);

  const menuItems: IContextualMenuItem[] = [
    { key: 'profile', text: 'Profile', onClick: () => setShowProfilePanel(true) },
    { key: 'subscription', text: 'Subscription' },
    { key: 'divider_1', itemType: ContextualMenuItemType.Divider },
    {
      key: 'logout', text: 'Sign Out', onClick: () => {
        instance.logoutRedirect({
          account: accounts[0],
        });
      }
    },
  ];


  return (
    <Stack horizontal verticalFill={true} tokens={{ childrenGap: 10, padding: '0 20px' }} styles={stackStyles} style={{ userSelect: 'none'}}>
      <Link href='/' className='banner-logo'>
        <Stack horizontal verticalFill={true} tokens={{ childrenGap: 10, padding: '10px 0 10px 10px' }} styles={logoStyles}>
          <img height={32} src='Favicon.svg' alt='cloud/studio logo' />
          <h2 style={{paddingBottom: 6, marginLeft: 8, fontSize: '24px', fontWeight: 400, color: 'rgb(58, 62, 65)'}}>cloud/<strong>studio</strong><span style={{fontSize: '9px', position:'relative', left: '-10px', top: '14px'}}>ALPHA</span></h2>
        </Stack>
      </Link>

      <AuthenticatedTemplate>

        <Link
          ref={linkRef}
          styles={linkStyles}
          className="persona-link"
          onClick={() => setShowProfilePanel(true)}
        >

          <Persona
            {...signedInPersona}
            hidePersonaDetails={false}
            presence={PersonaPresence.online}
            styles={{ root: { width: 180 } }}
            style={{ cursor: 'pointer', userSelect: 'none' }}
            imageAlt={signedInPersona?.text}
            initialsColor={PersonaInitialsColor.warmGray}
          />
          <ContextualMenu
            coverTarget={false}
            isBeakVisible={false}
            gapSpace={0}
            directionalHint={DirectionalHint.bottomLeftEdge}
            items={menuItems}
            hidden={!showContextualMenu}
            target={linkRef}
            onItemClick={onHideContextualMenu}
            onDismiss={onHideContextualMenu}
          />
        </Link>

        <ProfilePanel isOpen={showProfilePanel} onDismiss={() => setShowProfilePanel(false)} account={profileData} onSignOut={() => {
          instance.logoutRedirect({
          account: accounts[0],
        });}}></ProfilePanel>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Stack styles={signInStyles}>
          <SignInButton></SignInButton>
        </Stack>
      </UnauthenticatedTemplate>

    </Stack>
  );
}