import * as React from 'react';
import { TextField, DefaultButton, PrimaryButton, Panel, ITag, IBasePickerSuggestionsProps, TagPicker, PanelType } from '@fluentui/react';
import { IDiagram } from '../pages/Diagrams';
import DataService from '../services/DataService';

export interface DiagramPanelProps {
  diagram: IDiagram | null;
  isOpen: boolean;
  handleDiagramChanged: Function;
  handleDiagramPanelDismissed: Function;
  suggestedTags: ITag[];
}

const buttonStyles = { root: { marginRight: 8 } };

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested tags',
  noResultsFoundText: 'No tags found',
};


const getTextFromItem = (item: ITag) => item.name;

export class DiagramPanel extends React.Component<DiagramPanelProps, any> {

  state = {
    isOpen: false,
    name: this.props.diagram?.title || '',
    description: this.props.diagram?.description || '',
    tags: this.props.diagram?.tags.split(", ").map((tag: string) => { return { key: tag, name: tag } }),
    suggestedTags: this.props.suggestedTags,
  };

  saveChanges() {
    if (this.props.diagram != null) {
      DataService.updateDiagram(this.props.diagram.diagramId, this.state.name, this.state.description, this.state.tags || []);
    }
    this.props.handleDiagramChanged(this.state.name, this.state.description, this.state.tags?.map(m => m.name).join(", "));
    this.dismissPanel();
  }

  dismissPanel() {
    this.setState({ isOpen: false });
    this.props.handleDiagramPanelDismissed();
  }

  onHandleChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) {
    this.setState({ name: event.currentTarget.value });
  }

  public componentDidUpdate(previousProps: DiagramPanelProps, previousState: any) {

    if (this.props.diagram && previousProps.diagram !== this.props.diagram) {
      let name = this.props.diagram ? this.props.diagram.title : '';
      let description = this.props.diagram ? this.props.diagram.description : '';
      if (this.props.diagram && this.props.diagram.tags) {
        const tagList = this.props.diagram.tags.split(", ").map((tag: string) => { return { key: tag, name: tag } });
        this.setState({ name: name, description: description, tags: tagList, suggestedTags: this.props.suggestedTags });
      } else {
        this.setState({ name: name, description: description, tags: [], suggestedTags: this.props.suggestedTags });
      }
    }

    if (this.props.isOpen !== previousProps.isOpen) {
      this.setState({ isOpen: this.props.isOpen });
    }

  }

  onRenderFooterContent =
    () => (
      <div>
        <PrimaryButton onClick={this.saveChanges.bind(this)} styles={buttonStyles}>
          Save
        </PrimaryButton>
        <DefaultButton onClick={this.dismissPanel.bind(this)}>Cancel</DefaultButton>
      </div>
    );

    tagsChanged = (items?: ITag[] | undefined) => {
      this.setState({ tags: items || [] });
    };

    listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
      if (!tagList || !tagList.length || tagList.length === 0) {
        return false;
      }
      return tagList.some(compareTag => compareTag.key === tag.key);
    };
  
    filterSuggestedTags = (filterText: string, tagList?: ITag[]): ITag[] => {
      if (!filterText) {
        return [];
      }
      let results = this.state.suggestedTags.filter(tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !this.listContainsTagList(tag, tagList));
      results.push({ key: filterText, name: filterText });
      return results;
    };
  
  render() {
    return (
      <Panel
        type={PanelType.medium}
        isOpen={this.state.isOpen}
        onDismiss={this.dismissPanel.bind(this)}
        headerText="Diagram properties"
        closeButtonAriaLabel="Close"
        onRenderFooterContent={this.onRenderFooterContent}
        isFooterAtBottom={true}>
        <TextField label="Name" value={this.state.name} onChange={(event) => this.onHandleChange(event)} />
        <TextField multiline={true} label='Description' defaultValue={this.state.description} onChange={(event, newValue) => this.setState({ description: newValue || '' })}></TextField>

        <div>
          <label htmlFor='tag-picker' className='picker-label'>Tags</label>
          <TagPicker
            defaultSelectedItems={this.state.tags}
            onChange={this.tagsChanged}
            removeButtonAriaLabel="Remove"
            selectionAriaLabel="Selected tags"
            onResolveSuggestions={this.filterSuggestedTags}
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={pickerSuggestionsProps}
            pickerCalloutProps={{ doNotLayer: true }}
            inputProps={{
              id: 'tag-picker',
            }}
          />
          <div
            style={{ height: '10em' }}
          />
        </div>

      </Panel>
    );
  }

};
