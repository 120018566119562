import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig.b2c";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <PrimaryButton allowDisabledFocus onClick={() => handleLogin("redirect")}>
          Sign Up / Sign In
        </PrimaryButton>
    )
}