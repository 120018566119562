import { LogLevel } from '@azure/msal-browser';

export const tenantId = '31f49d59-c3a4-4118-90ad-fc1486f44540';

export const silentRequest = {
    scopes: ["openid", "profile", "https://cloudstudiob2c.onmicrosoft.com/c0c01898-bf9e-4072-9155-c773d40af9f3/doc:read", "https://cloudstudiob2c.onmicrosoft.com/c0c01898-bf9e-4072-9155-c773d40af9f3/doc:write", "https://cloudstudiob2c.onmicrosoft.com/c0c01898-bf9e-4072-9155-c773d40af9f3/summary:write"],
};

export const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1_SignUp_SignIn',
        forgotPassword: 'B2C_1_reset_v3',
        editProfile: 'B2C_1_edit_profile_v2',
    },
    authorities: {
        signUpSignIn: {
            authority: `https://login.cloudstudio.app/${tenantId}/B2C_1_SignUp_SignIn`,
        },
        forgotPassword: {
            authority: `https://login.cloudstudio.app/${tenantId}/B2C_1_reset_v3`,
        },
        editProfile: {
            authority: `https://login.cloudstudio.app/${tenantId}/B2C_1_edit_profile_v2`,
        },
    },
    authorityDomain: 'login.cloudstudio.app',
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
    auth: {
        clientId: 'c0c01898-bf9e-4072-9155-c773d40af9f3',
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: '/', 
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    // case LogLevel.Error:
                    //     console.error(message);
                    //     return;
                    // case LogLevel.Info:
                    //     console.info(message);
                    //     return;
                    // case LogLevel.Verbose:
                    //     console.debug(message);
                    //     return;
                    // case LogLevel.Warning:
                    //     console.warn(message);
                    //     return;
                    default:
                        return;
                }
            },
        },
    },
};


/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [
        "https://cloudstudiob2c.onmicrosoft.com/c0c01898-bf9e-4072-9155-c773d40af9f3/summary:write",
        "https://cloudstudiob2c.onmicrosoft.com/c0c01898-bf9e-4072-9155-c773d40af9f3/doc:write",
        "https://cloudstudiob2c.onmicrosoft.com/c0c01898-bf9e-4072-9155-c773d40af9f3/doc:read",
    ],
    prompt: "select_account",
};


/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphUsersEndpoint: "https://graph.microsoft.com/v1.0/users",
};
