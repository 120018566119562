import * as React from 'react';
import { Stack, Text, DetailsListLayoutMode, Selection, SelectionMode, IColumn, ContextualMenu, DirectionalHint, IconButton, IContextualMenuItem, IIconProps, Link, Spinner, ShimmeredDetailsList, IListProps, PrimaryButton, DefaultButton, Separator, ITag, SearchBox, IDetailsListStyles, ConstrainMode, DetailsList, Label, TextField, Announced, ICommandBarStyles, CommandBar, TagPicker, ICommandBarItemProps, IBasePickerSuggestionsProps, TooltipHost, IRenderFunction, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps, TooltipDelay, ITooltipHostStyles } from '@fluentui/react';
import diagramEventBus from "../EventBus";
import withRouter from '../withRouter';
import DiagramService from '../services/DiagramService';
import DataService from '../services/DataService';
import moment from 'moment';
import { DiagramCreate } from '../page-components/DiagramCreate';
import { DiagramPanel } from '../page-components/DiagramPanel';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { SignInButton } from '../auth/SignInButton';
import { StatusBarDetailsList } from '../page-components/StatusBarDetailsList';

// #region Definitions

export interface IDiagram {
  diagramId: string;
  timestamp: Date;
  title: string;
  description: string;
  ownerEmail: string;
  ownerId: string;
  ownerName: string;
  templateId: string;
  templateName: string;
  tags: string;
  thumbnail: string;
}

export interface IDiagramsProps {
  selectedDiagram: IDiagram | null;
  handleDiagramSelected: Function;
  diagrams: IDiagram[];
  navigate: any;
}

export interface IDiagramsState {
  columns: IColumn[];
  diagrams: IDiagram[];
  allDiagrams: IDiagram[];
  diagramsLoaded: boolean;
  selectionDetails: string;
  selectedDiagram: IDiagram | null;
  selectedPanelOpen: boolean;
  announcedMessage?: string;
  showContextualMenu: boolean;
  buttonRef: Element | null;
  showWizard: boolean;
  isLoading: boolean;
  suggestedTags: ITag[];
  mostUsedTags: ITag[];
  tags: ITag[];
  searchTerm: string;
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
// #endregion

// #region Styles

const containerStyle = {
  root: {
    margin: 20,
    outerHeight: '100%'
  }
};

const commandBarStyles: Partial<ICommandBarStyles> = {
  root: {
    boxSizing: 'border-box',
    'border-bottom': '1px solid #eee',
    padding: 0,
  },
};

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested tags',
  noResultsFoundText: 'No tags found',
};

// #endregion

class Diagrams extends React.Component<IDiagramsProps, IDiagramsState> {
  private _selection: Selection;
  filterRef = React.createRef();
  diagramService: DiagramService = new DiagramService();

  // #region Context menu items
  private _menuIcon: IIconProps = { iconName: 'MoreVertical' };
  private _menuItems: IContextualMenuItem[] = [
    // { key: 'move', text: 'Move to...', onClick: () => console.log('Move to'), iconProps: { iconName: 'MoveToFolder' } },
    // { key: 'copy', text: 'Copy to...', onClick: () => console.log('Copy to'), iconProps: { iconName: 'Copy' } },
    {
      key: 'edit', text: 'Edit properties...', onClick: (item: any) => {
        if (this.state.selectedDiagram != null) {
          this.handleDiagramEditProperties(this.state.selectedDiagram);
        }
      }, iconProps: { iconName: 'Edit' }
    },
    {
      key: 'delete', text: 'Delete', onClick: (event: any) => {
        this.onDeleteDiagram();
      }, iconProps: { iconName: 'Delete' }
    },
  ];
  // #endregion

  private onSearch = (text: string | undefined, tags: ITag[]): void => {
    let tagsList = tags.map(x => { return { ...x }.name; });
    tagsList = tagsList.map(x => x.toLowerCase().trim());

    let hasTags = (diagram: IDiagram): boolean => {
      const diagramTags = diagram.tags?.split(',')?.map(x => x.toLowerCase().trim()) || [];
      return diagramTags.some(x => tagsList.includes(x));
    };

    let results: IDiagram[] = this.state.allDiagrams
      .filter(
        x => (!text || x.title.toLowerCase().includes(text?.toLowerCase() || ''))
          && (
            tagsList.length === 0
            || hasTags(x)
          )
      );

    this.setState({
      searchTerm: text || '',
      diagrams: results,
    });

  };

  tagClicked = (tag: string): void => {
    let tags = this.state.tags;
    if (tags.find((t: ITag) => t.name === tag)) {
      tags = tags.filter((t: ITag) => t.name !== tag);
      this.setState({ tags: tags });
    } else {
      tags.push({ key: tag, name: tag });
      this.setState({ tags: tags });
    }
    this.onSearch(this.state.searchTerm, tags);
  };

  constructor(props: IDiagramsProps) {
    super(props);

    this.onShowContextualMenu = this.onShowContextualMenu.bind(this);
    this.onHideContextualMenu = this.onHideContextualMenu.bind(this);
    this._onItemInvoked = this._onItemInvoked.bind(this);
    this.handleDiagramEditProperties = this.handleDiagramEditProperties.bind(this);

    if (DataService.getAccount() !== null) {
      this.loadDiagrams();
    }

    const tooltipHostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block', width: 'auto' } };

    // #region Column definitions
    const columns: IColumn[] = [
      {
        className: 'diagrams-cells',
        key: 'titleColumn',
        name: 'Title',
        fieldName: 'title',
        targetWidthProportion: 4,
        minWidth: 210,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDiagram) => (<div>

          <TooltipHost
            tooltipProps={{
              onRenderContent: () => (
                <a href={`/diagram#${item.diagramId}`}>
                  <img alt="Thumbnail" src={`${item.thumbnail}`} style={{ width: '100%' }} />
                </a>
              )
            }}
            delay={TooltipDelay.zero}
            directionalHint={DirectionalHint.rightTopEdge}
            styles={tooltipHostStyles}
          >
            <Link href={`/diagram#${item.diagramId}`} onClick={
              (event?: React.MouseEvent<HTMLElement>) => {
                if (item) {
                  this.props.navigate(`/diagram#${item.diagramId}&fit`, { state: { selectedDiagram: item } });
                }
              }
            }>{item.title}</Link>
          </TooltipHost>

          <p style={{ marginBottom: 0 }}>
            {item.description}
          </p>

          {item.tags && item.tags.length > 0 && <div className='tag-container'>{
            item.tags?.split(", ").map((tag: string) => {
              return <div key={`${item.diagramId}_${tag}`} className={`tag-item ${this.state.tags.map(x => x.name).includes(tag) ? 'active' : ''}`} title={tag} onClick={() => this.tagClicked(tag)}>{tag}</div>
            })
          }</div>}
        </div>),
      },
      {
        className: 'diagrams-cells',
        key: 'contextMenu',
        name: '',
        minWidth: 44,
        maxWidth: 44,
        targetWidthProportion: 0,
        isResizable: false,
        isSorted: false,
        isPadded: true,
        onRender: (item: IDiagram) => {
          return (
            <IconButton iconProps={this._menuIcon} onClick={(event) => this.onShowContextualMenu(event, item)} />
          );
        },
      },
      {
        className: 'diagrams-cells',
        key: 'dateModifiedColumn',
        name: 'Date Modified',
        fieldName: 'timestamp',
        minWidth: 120,
        targetWidthProportion: 1,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        sortAscendingAriaLabel: 'Sorted Newest to Oldest',
        sortDescendingAriaLabel: 'Sorted Oldest to Newest',
        onColumnClick: this._onColumnClick,
        data: 'number',
        onRender: (item: IDiagram) => {
          return <span title={moment.utc(item.timestamp).format('LLL')}>{moment.utc(item.timestamp).fromNow()}</span>;
        },
        isPadded: true,
      },
      {
        className: 'diagrams-cells',
        key: 'modifiedByColumn',
        name: 'Modified By',
        fieldName: 'ownerName',
        minWidth: 220,
        targetWidthProportion: 2,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: this._onColumnClick,
        onRender: (item: IDiagram) => {
          return <span>{item.ownerName} ({item.ownerEmail})</span>;
        },
        isPadded: true,
      }
    ];
    // #endregion

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
          selectedDiagram: this._getFirstSelected()
        });
      },
    });

    this.state = {
      diagrams: [],
      allDiagrams: [],
      diagramsLoaded: false,
      columns: columns,
      selectionDetails: this._getSelectionDetails(),
      selectedDiagram: this._getFirstSelected(),
      selectedPanelOpen: false,
      announcedMessage: undefined,
      showContextualMenu: false,
      buttonRef: null,
      showWizard: false,
      isLoading: true,
      suggestedTags: [],
      mostUsedTags: [],
      searchTerm: '',
      tags: [],
    };

  }

  loadDiagrams() {
    DataService.getDiagrams().then((diagrams) => {
      DataService.diagrams = diagrams;
      const sorted = diagrams.sort((a: IDiagram, b: IDiagram) => (a.timestamp > b.timestamp ? -1 : 1));
      const tags = sorted.reduce((acc: string[], diagram: IDiagram) => {
        if (diagram.tags) {
          const tags = diagram.tags.split(', ');
          tags.forEach((tag: string) => {
            if (!acc.includes(tag)) {
              acc.push(tag);
            }
          });
        }
        return acc;
      }, []);
      const tagList = tags.map((m: string) => { return { key: m, name: m } }).sort((a: ITag, b: ITag) => (a.name > b.name ? 1 : -1));
      const mostUsedTags = tags.reduce((acc: any[], tag: string) => {
        const count = sorted.filter((diagram: IDiagram) => diagram.tags?.includes(tag)).length;
        if (count > 1) {
          acc.push({ key: tag, name: tag, count: count });
        }
        return acc;
      }, []).sort((a: any, b: any) => (a.count > b.count ? -1 : 1));

      this.setState({ diagrams: sorted, allDiagrams: diagrams.slice(), isLoading: false, suggestedTags: tagList, mostUsedTags: mostUsedTags.slice(0, 10), diagramsLoaded: true });
    });
  }

  componentWillUnmount() {
    diagramEventBus.remove("OnShowNewDiagramWizard", this);
    diagramEventBus.remove("OnDiagramSearch", this);
  }

  // #region Diagram Panel

  handleDiagramEditProperties = (selectedDiagram: IDiagram) => {
    this.setState({ selectedDiagram: selectedDiagram, selectedPanelOpen: true })
  };

  handleDiagramPanelDismissed = () => {
    this.setState({ selectedPanelOpen: false });
  }

  handleLeftNavClick = (isExpanded: boolean) => {
    console.log("handleLeftNavClick", isExpanded);
  }

  handleDiagramChanged = (name: string, description: string, tags: string) => {
    let newForm = this.state.selectedDiagram;
    if (newForm) {
      newForm.title = name;
      newForm.description = description;
      newForm.tags = tags;
    }
    this.setState({ diagrams: this.state.diagrams.slice(), selectedDiagram: newForm, selectedPanelOpen: false });
  };

  // #endregion


  // #region Context menu events

  onHideContextualMenu() {
    this.setState({ buttonRef: null, showContextualMenu: false });
  }

  onShowContextualMenu(event: any, item: IDiagram) {
    this.setState({ buttonRef: event.target, showContextualMenu: true, selectedDiagram: item });
  }

  // #endregion

  updateItem(item: IDiagram) {
    let { diagrams } = this.state;
    for (let element of diagrams) {
      if (element.diagramId === item.diagramId) {
        element.title = `${element.title} [updated at: ${new Date().toLocaleTimeString()}]`;
        break;
      }
    }
    this.setState({
      diagrams: [...diagrams]
    });
  }

  private _onItemInvoked(item: any): void {
    this.setState({ selectedDiagram: item });
    this.props.handleDiagramSelected(item);
  }

  private _getFirstSelected(): IDiagram | null {
    const selectionCount = this._selection.getSelectedCount();
    switch (selectionCount) {
      case 0:
        return null;
      default:
        return (this._selection.getSelection()[0] as IDiagram);
    }
  }

  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();
    switch (selectionCount) {
      case 0:
        return 'No items selected';
      case 1:
        return '1 item selected: ' + (this._selection.getSelection()[0] as IDiagram).title;
      default:
        return `${selectionCount} items selected`;
    }
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    if (ev.target instanceof HTMLInputElement) {
      return;
    }
    const { columns, diagrams } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? 'descending' : 'ascending'
            }`,
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const sorted = _copyAndSort(diagrams, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      diagrams: sorted,
    });
  };

  private async onDeleteDiagram() {
    let item = this.state.selectedDiagram;
    console.log(item?.title);
    if (item?.diagramId) {
      await DataService.deleteDiagram(item.diagramId);
      this.loadDiagrams();
      this._selection.setAllSelected(false);
    }
  }

  private async onCreateDiagram(details: any) {
    console.log('onCreateDiagram', details);
    this.setState({ showWizard: false, isLoading: true });
    if (details.type === 'blank') {
      await this.diagramService.createBlank(details, (id: string) => {
        this.setState({ isLoading: false });
        this.props.navigate(`/diagram#${id}`);
      });
    } else if (details.type === 'template') {
      const template = details.selectedTemplate;
      await this.diagramService.createFromTemplate(details, template, (id: string) => {
        this.setState({ isLoading: false });
        this.props.navigate(`/diagram#${id}&fit`);
      });
    } else if (details.type === 'code') {
      const code = details.armTemplate || '';
      await this.diagramService.createFromCode(details, code, (id: string) => {
        this.setState({ isLoading: false });
        this.props.navigate(`/diagram#${id}&fit`);
      });
    }
  }

  tagsChanged = (items?: ITag[] | undefined) => {
    this.setState({ tags: items || [] });
    this.onSearch(this.state.searchTerm, items || []);
  };

  listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some(compareTag => compareTag.key === tag.key);
  };

  filterSuggestedTags = (filterText: string, tagList?: ITag[]): ITag[] => {
    if (!filterText) {
      return [];
    }
    let results = this.state.suggestedTags.filter((tag: ITag) => tag.name.toLowerCase().includes(filterText.toLowerCase()) && !this.listContainsTagList(tag, tagList));
    results.push({ key: filterText, name: filterText });
    return results;
  };

  toolbarItems: ICommandBarItemProps[] = [
    {
      key: 'newItem',
      text: 'New',
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Add' },
      buttonStyles: { root: { backgroundColor: '#8dab60', color: 'rgb(253, 255, 254)', minWidth: 80 }, icon: { color: 'rgb(253, 255, 254)' }, label: { paddingLeft: 0 } },
      onClick: () => { this.setState({ showWizard: true }) },
    },
    // {
    //   key: 'upload',
    //   text: 'Upload',
    //   iconProps: { iconName: 'Upload' },
    //   href: 'https://developer.microsoft.com/en-us/fluentui',
    //   disabled: true,
    // },
    // {
    //   key: 'share',
    //   text: 'Share',
    //   iconProps: { iconName: 'Share' },
    //   onClick: () => console.log('Share'),
    //   disabled: true,
    // },
    // {
    //   key: 'download',
    //   text: 'Download',
    //   iconProps: { iconName: 'Download' },
    //   onClick: () => console.log('Download'),
    //   disabled: true,
    // },
    {
      key: 'search',
      text: 'Search',
      iconProps: { iconName: 'Search' },
      onRender: (item: any) => {
        return <SearchBox
          id='search'
          styles={{ root: { marginTop: '6px', marginBottom: 0, height: '37px', width: '230px' }, field: { width: '230px' } }}
          placeholder="Search"
          underlined={true}
          defaultValue={this.state.searchTerm}
          onChange={(e, value) => this.onSearch(value, this.state.tags)}
        />
      }
    },
    {
      key: 'filter',
      iconProps: { iconName: 'Tag' },
      disabled: false,
      subMenuProps: {
        items: [
          {
            key: 'tagsFilter',
            onRender: () => (<Stack tokens={{ childrenGap: 10, padding: 10 }}>
              <TagPicker
                defaultSelectedItems={this.state.tags}
                onChange={this.tagsChanged}
                removeButtonAriaLabel="Remove"
                selectionAriaLabel="Selected tags"
                onResolveSuggestions={this.filterSuggestedTags}
                getTextFromItem={(item: ITag) => item.name}
                pickerSuggestionsProps={pickerSuggestionsProps}
                pickerCalloutProps={{ doNotLayer: false }}
                inputProps={{
                  id: 'tag-picker',
                  placeholder: "Filter by tags"
                }}
                styles={{ root: { margin: 0, height: 'auto', width: '360px', alignItems: 'start' }, text: { height: 'auto', alignSelf: 'flex-start' } }}
              />
              <Stack>
                <Text variant="small">Most used tags:</Text>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 10, maxWidth: 360 }} style={{ padding: 0 }}>
                <div className='tag-container'>
                  {this.state.mostUsedTags.map((tag, index) => (!this.listContainsTagList(tag, this.state.tags)) &&
                    <div key={`_${tag.key}`} className={`tag-item ${this.state.tags.map(x => x.name).includes(tag.name) ? 'active' : ''}`} title={tag.name} onClick={() => this.tagClicked(tag.name)}>{tag.name}</div>
                  )}
                </div>
              </Stack>
            </Stack>),
          },
        ]
      }
    },
  ];


  public render() {

    const { columns, diagrams } = this.state;

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
      if (!props) {
        return null;
      }
      const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
      );
      return defaultRender!({
        ...props,
        onRenderColumnHeaderTooltip,
      });
    };


    return (
      <React.Fragment>
        {/* <Stack horizontal verticalAlign="center" tokens={{ padding: 10, childrenGap: 10 }} style={{ borderBottom: '1px solid rgb(232, 234, 233)' }}>
          <Announced message={`Number of items after filter applied: ${this.state.diagrams.length}.`} />
          <Announced message={selectionDetails} />
        </Stack> */}

        <AuthenticatedTemplate>

          <Stack style={{ width: "calc(100vw - 220px)" }}>

            <CommandBar
              items={this.toolbarItems}
              styles={commandBarStyles}
            />

            {this.state.isLoading && (
              <Stack horizontalAlign='center' verticalAlign='center' style={{ backgroundColor: 'rgba(240,240,240,0.5)', position: 'fixed', left: 220, top: 74, width: 'calc(100vw - 220px)', height: 'calc(100vh - 74px)', zIndex: 2000 }}>
                <Spinner label="Loading diagrams" ariaLive="assertive" labelPosition="bottom" />
              </Stack>
            )}
            {diagrams.length > 0 && <Stack style={{ width: "calc(100vw - 220px)", height: "calc(100vh - 160px)", overflowY: "scroll" }}>

              <DetailsList
                items={diagrams}
                compact={true}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={(item: any, index?: number) => { return `${item.title}-${index}`; }}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onRenderDetailsHeader={onRenderDetailsHeader}
                constrainMode={ConstrainMode.unconstrained}
                onItemInvoked={this._onItemInvoked}
              />
            </Stack>
            }

            <ContextualMenu
              items={this._menuItems}
              hidden={!this.state.showContextualMenu}
              target={this.state.buttonRef}
              onItemClick={this.onHideContextualMenu}
              onDismiss={this.onHideContextualMenu}
              isBeakVisible={false}
              directionalHint={DirectionalHint.bottomLeftEdge}
            />
            {(this.state.diagramsLoaded && diagrams.length > 0) && <StatusBarDetailsList statusMessage={`Showing ${this.state.diagrams.length} diagram${this.state.diagrams.length !== 1 ? 's' : ''}`}></StatusBarDetailsList>}

            {(this.state.diagramsLoaded && diagrams.length === 0 && !this.state.searchTerm) && <Stack tokens={{ childrenGap: 20 }} styles={containerStyle}>
              <Text variant="xLarge">
                Get started
              </Text>
              <Stack tokens={{ childrenGap: 8 }} className="home-panel">

                <ul style={{ lineHeight: '2.5em', fontSize: '1.1em', marginBottom: '10px' }}>
                  <li>Create a blank diagram</li>
                  <li>Create from a template</li>
                  <li>Create from an ARM template</li>
                </ul>

                <Separator />

                <Stack horizontal tokens={{ childrenGap: 8 }}>
                  <PrimaryButton text="Create diagram" onClick={() => this.setState({ showWizard: true })} />
                </Stack>

              </Stack>
            </Stack>
            }

          </Stack>

        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Stack tokens={{ childrenGap: 20 }} styles={containerStyle}>
            <Text variant="xLarge">
              You'll need to have an account with us to create and edit diagrams
            </Text>
            <Stack tokens={{ childrenGap: 8 }} className="home-panel">

              <ul style={{ lineHeight: '2.5em', fontSize: '1.1em', marginBottom: '10px' }}>
                <li>Whilst we're in alpha, cloud/studio is <strong>free</strong></li>
                <li>We'll be introducing a paid tier soon</li>
                <li>Sign up now to get going</li>
              </ul>

              <Separator />

              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <SignInButton></SignInButton>
              </Stack>

            </Stack>
          </Stack>
        </UnauthenticatedTemplate>

        <DiagramPanel
          isOpen={this.state.selectedPanelOpen}
          diagram={this.state.selectedDiagram}
          handleDiagramChanged={this.handleDiagramChanged}
          handleDiagramPanelDismissed={this.handleDiagramPanelDismissed}
          suggestedTags={this.state.suggestedTags}
        />

        <DiagramCreate
          tags={this.state.suggestedTags}
          visible={this.state.showWizard}
          onDismiss={() => this.setState({ showWizard: false })}
          onCreateDiagram={(details: any) => this.onCreateDiagram(details)}
        />

      </React.Fragment>
    );
  }
}

export default withRouter(Diagrams);