var templates = [
    {
      "summary": "Deploying Azure Kubernetes Service (AKS) apps with Azure Pipelines.",
      "azureCategories": [
        "containers",
        "devops",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-cicd-azure-pipelines-architecture.png",
      "title": "CI/CD for AKS apps with Azure Pipelines",
      "lastModified": "2024-05-18T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-registry",
        "azure-devops",
        "azure-kubernetes-service",
        "github"
      ],
      "url": "/azure/architecture/guide/aks/aks-cicd-azure-pipelines"
    },
    {
      "summary": "Learn how to install and run benchmark tests with TUFLOW HPC. See the results of running TUFLOW HPC on Azure virtual machines.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hpc-tuflow-hpc-architecture.png",
      "title": "Deploy TUFLOW HPC on an Azure virtual machine",
      "lastModified": "2024-02-05T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/tuflow-hpc"
    },
    {
      "summary": "Learn about the benefits and the test performance results of running BETA CAE EPILYSIS software on an Azure virtual machine.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hpc-epilysis-single.png",
      "title": "Deploy BETA CAE EPILYSIS on an Azure virtual machine",
      "lastModified": "2024-01-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/epilysis"
    },
    {
      "summary": "Find out how Barracuda Virtual Reactor computational fluid dynamics (CFD) software performs on a virtual machine.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/barracuda-virtual-reactor-thumb.png",
      "title": "Deploy Barracuda Virtual Reactor on a virtual machine",
      "lastModified": "2024-01-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/barracuda-virtual-reactor"
    },
    {
      "summary": "Learn about the guiding principles and considerations for the Gridwich continuous integration and continuous delivery (CD/CD) pipeline.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/request-response.png",
      "title": "Gridwich CI/CD pipeline",
      "lastModified": "2024-01-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-event-grid",
        "azure-functions",
        "azure-key-vault",
        "azure-pipelines"
      ],
      "url": "/azure/architecture/reference-architectures/media-services/gridwich-cicd"
    },
    {
      "summary": "Learn about Gridwich namespaces and project naming conventions and structure, based on components, capabilities and technologies.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/request-response.png",
      "title": "Gridwich project naming and namespaces",
      "lastModified": "2024-01-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-functions"
      ],
      "url": "/azure/architecture/reference-architectures/media-services/gridwich-project-names"
    },
    {
      "summary": "Understand the importance of logging and use of the ObjectLogger utility, context objects and logging levels.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/request-response.png",
      "title": "Logging in Gridwich",
      "lastModified": "2024-01-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/reference-architectures/media-services/gridwich-logging"
    },
    {
      "summary": "Understand the concepts and roles of sagas and saga participants in orchestrating Gridwich workflows.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/request-response.png",
      "title": "Gridwich saga orchestration",
      "lastModified": "2024-01-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-storage"
      ],
      "url": "/azure/architecture/reference-architectures/media-services/gridwich-saga-orchestration"
    },
    {
      "summary": "Learn about the components and libraries in the Gridwich clean monolith architecture and a microservices alternative.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/request-response.png",
      "title": "Gridwich clean monolith architecture",
      "lastModified": "2024-01-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-grid",
        "azure-functions"
      ],
      "url": "/azure/architecture/reference-architectures/media-services/gridwich-clean-monolith"
    },
    {
      "summary": "Learn about the characteristics of the Gridwich Azure Storage Service.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/request-response.png",
      "title": "Gridwich operations for Azure Storage",
      "lastModified": "2024-01-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-storage"
      ],
      "url": "/azure/architecture/reference-architectures/media-services/gridwich-storage-service"
    },
    {
      "summary": "Learn about a stateless action execution workflow that ingests, processes, and delivers media assets using Terraform Sandwiches and Event Grid Sandwiches.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/request-response.png",
      "title": "Gridwich cloud media system",
      "lastModified": "2024-01-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-event-grid",
        "azure-functions",
        "azure-logic-apps"
      ],
      "url": "/azure/architecture/reference-architectures/media-services/gridwich-architecture"
    },
    {
      "summary": "Learn about the specific Event Grid events that form the request-response sequence for different Gridwich operations.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/request-response.png",
      "title": "Gridwich request-response messages",
      "lastModified": "2024-01-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-logic-apps",
        "azure-storage"
      ],
      "url": "/azure/architecture/reference-architectures/media-services/gridwich-message-formats"
    },
    {
      "summary": "Learn how to set up an environment that's configured to run a workload on Azure Virtual Machines.",
      "azureCategories": [
        "compute",
        "migration",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/baseline-architecture-virtual-machines.png",
      "title": "Azure Virtual Machines baseline architecture",
      "lastModified": "2024-01-19T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-bastion",
        "azure-key-vault",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-vm-scalesets"
      ],
      "url": "/azure/architecture/virtual-machines/baseline"
    },
    {
      "summary": "Accelerate your Azure project with a fully functional sandbox environment that includes virtual networks, virtual machines, and databases.",
      "azureCategories": [
        "compute",
        "databases",
        "developer-tools",
        "networking",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/create-azure-sandbox.png",
      "title": "Azure Sandbox",
      "lastModified": "2024-01-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-bastion",
        "azure-database-mysql",
        "azure-sql-database",
        "azure-sql-virtual-machines",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/guide/azure-sandbox/azure-sandbox"
    },
    {
      "summary": "Learn about proven practices for building and deploying enterprise chat applications with OpenAI, Azure Machine Learning, and Azure App Services.",
      "azureCategories": [
        "ai-machine-learning",
        "web",
        "networking",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/openai-end-to-end.png",
      "title": "Baseline OpenAI end-to-end chat reference architecture",
      "lastModified": "2024-01-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-openai",
        "azure-machine-learning",
        "azure-app-service",
        "azure-key-vault",
        "azure-monitor"
      ],
      "url": "/azure/architecture/ai-ml/architecture/baseline-openai-e2e-chat"
    },
    {
      "summary": "Automate data flows with Apache NiFi on Azure. Use a scalable, highly available solution to move data into the cloud or storage and between cloud systems.",
      "azureCategories": [
        "integration",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-nifi-architecture-thumbnail.png",
      "title": "Apache NiFi on Azure",
      "lastModified": "2024-01-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway",
        "azure-log-analytics",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-vm-scalesets"
      ],
      "url": "/azure/architecture/example-scenario/data/azure-nifi"
    },
    {
      "summary": "Use Helm charts when you deploy NiFi on Azure Kubernetes Service (AKS). Helm streamlines the process of installing and managing Kubernetes applications.",
      "azureCategories": [
        "containers",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/helm-deployments-apache-nifi-architecture-thumbnail.png",
      "title": "Helm-based deployments for Apache NiFi",
      "lastModified": "2024-01-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/guide/data/helm-deployments-apache-nifi"
    },
    {
      "summary": "Learn how to use Private Link to expose apps running in one virtual network to consumers in another virtual network that has an overlapping IP address space.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/private-link-ip-constraints-thumb.png",
      "title": "Use Private Link to access applications on networks with overlapping address spaces",
      "lastModified": "2024-01-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-private-link",
        "azure-dns",
        "azure-expressroute"
      ],
      "url": "/azure/architecture/guide/networking/private-link-ip-constraints"
    },
    {
      "summary": "See how a logical data warehouse uses Azure Synapse serverless SQL pools to query data lake and online transactional data without requiring data movement.",
      "azureCategories": [
        "analytics",
        "databases",
        "integration",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/logical-data-warehouse-architecture-dataflow.png",
      "title": "Logical data warehouse with Azure Synapse serverless SQL pools",
      "lastModified": "2024-01-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-data-factory",
        "azure-data-lake",
        "azure-synapse-analytics",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/logical-data-warehouse"
    },
    {
      "summary": "Learn various ways to connect to an Azure Kubernetes Service (AKS) cluster's API server. Possibilities include Azure Bastion, ExpressRoute, and Cloud Shell.",
      "azureCategories": [
        "containers",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/access-azure-kubernetes-service-cluster-api-server-thumbnail.png",
      "title": "Access an Azure Kubernetes Service (AKS) API server",
      "lastModified": "2024-01-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-bastion",
        "azure-expressroute",
        "azure-kubernetes-service",
        "azure-private-link",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/guide/security/access-azure-kubernetes-service-cluster-api-server"
    },
    {
      "summary": "Learn about an enterprise-level cloud file sharing solution that uses Azure Files, Azure File Sync, Azure Private DNS, and Azure Private Endpoint.",
      "azureCategories": [
        "hybrid",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-files-private.png",
      "title": "Azure enterprise cloud file share",
      "lastModified": "2024-01-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-dns",
        "azure-files",
        "azure-private-link",
        "azure-storage",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/hybrid/azure-files-private"
    },
    {
      "summary": "Learn about partitioning in Kafka and Event Hubs with Kafka, see how many partitions to use in ingestion pipelines, and also see how to assign events to partitions.",
      "azureCategories": [
        "hybrid",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/event-processing-service-thumbnail.png",
      "title": "Partitioning in Azure Event Hubs and Kafka",
      "lastModified": "2024-01-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/reference-architectures/event-hubs/partitioning-in-event-hubs-and-kafka"
    },
    {
      "summary": "A serverless event-driven architecture running on Azure Kubernetes with KEDA scaler that ingests and processes a stream of data, then writes the results to a DB.",
      "azureCategories": [
        "analytics",
        "compute",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/serverless-event-processing-aks-card.png",
      "title": "Azure Kubernetes in event stream processing",
      "lastModified": "2024-01-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-iot-hub",
        "azure-event-hubs",
        "azure-functions",
        "azure-cosmos-db"
      ],
      "url": "/azure/architecture/solution-ideas/articles/serverless-event-processing-aks"
    },
    {
      "summary": "Learn about an architecture that provides a solution for the telecommunications industry that uses Azure Cloud Services to process large volumes of geospatial data.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/geospatial-analysis-telecommunications-industry.png",
      "title": "Geospatial analysis for the telecommunications industry",
      "lastModified": "2024-01-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-data-lake",
        "azure-databricks",
        "azure-machine-learning",
        "azure-maps"
      ],
      "url": "/azure/architecture/example-scenario/data/geospatial-analysis-telecommunications-industry"
    },
    {
      "summary": "Learn about options and best practices for using Azure Firewall and Azure Application Gateway security in virtual networks.",
      "azureCategories": [
        "networking",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/decision-tree.png",
      "title": "Firewall and Application Gateway for virtual networks",
      "lastModified": "2024-01-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway",
        "azure-firewall",
        "azure-front-door",
        "azure-virtual-network",
        "azure-web-application-firewall"
      ],
      "url": "/azure/architecture/example-scenario/gateway/firewall-application-gateway"
    },
    {
      "summary": "A serverless event-driven architecture using Azure Event Hubs and Azure Functions to ingest and filter a stream of data for database storage.",
      "azureCategories": [
        "analytics",
        "compute",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/serverless-event-processing-filtering-card.png",
      "title": "De-batch and filter serverless event processing with Event Hubs",
      "lastModified": "2024-01-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-functions",
        "azure-cosmos-db"
      ],
      "url": "/azure/architecture/solution-ideas/articles/serverless-event-processing-filtering"
    },
    {
      "summary": "A serverless event-driven architecture in a VNet with private endpoints that ingests and processes a stream of data, then writes the results to a database.",
      "azureCategories": [
        "analytics",
        "compute",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/serverless-event-processing-private-link-card.png",
      "title": "Serverless event stream processing in a VNet with private endpoints",
      "lastModified": "2024-01-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-private-link",
        "azure-event-hubs",
        "azure-functions",
        "azure-cosmos-db"
      ],
      "url": "/azure/architecture/solution-ideas/articles/serverless-event-processing-private-link"
    },
    {
      "summary": "How to use Azure Cosmos DB, change feed, and Azure Service Bus for reliable messaging and guaranteed delivery of domain events in distributed applications.",
      "azureCategories": [
        "databases",
        "integration",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/components.jpg",
      "title": "Transactional Outbox pattern with Azure Cosmos DB",
      "lastModified": "2024-01-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-service-bus",
        "azure-functions"
      ],
      "url": "/azure/architecture/databases/guide/transactional-outbox-cosmos"
    },
    {
      "summary": "Learn how to provide high-availability desktop access to file shares in an environment with many on-premises locations, and a workforce that works on-premises and remotely.",
      "azureCategories": [
        "hybrid",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-file-share-dr-remote-local-branch-workers.png",
      "title": "Hybrid file share with disaster recovery for remote and local branch workers",
      "lastModified": "2024-01-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-files",
        "azure-storage-accounts"
      ],
      "url": "/azure/architecture/example-scenario/hybrid/hybrid-file-share-dr-remote-local-branch-workers"
    },
    {
      "summary": "Learn how to preserve the original HTTP host name between a reverse proxy and its back-end web application in common Azure services.",
      "azureCategories": [
        "networking",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/issue-redirect-urls.png",
      "title": "Preserve the original HTTP host name between a reverse proxy and its back-end web application",
      "lastModified": "2024-01-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-api-management",
        "azure-app-service",
        "azure-application-gateway",
        "azure-front-door",
        "azure-spring-apps"
      ],
      "url": "/azure/architecture/best-practices/host-name-preservation"
    },
    {
      "summary": "Learn proven practices for running SAP HANA in a high availability environment on Azure Large Instances.",
      "azureCategories": [
        "databases",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sap-hana-large-instances.png",
      "title": "Run SAP HANA on Azure (Large Instances)",
      "lastModified": "2024-01-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/reference-architectures/sap/hana-large-instances"
    },
    {
      "summary": "Learn about architecture patterns for SDWAN integration with Azure hub-and-spoke network topologies, based on the Azure Well-Architected Framework's five pillars of architecture excellence.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sdwan-integration-hub-and-spoke.png",
      "title": "SDWAN integration with Azure hub-and-spoke network topologies",
      "lastModified": "2024-01-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-vpn-gateway",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/networking/guide/sdwan-integration-in-hub-and-spoke-network-topologies"
    },
    {
      "summary": "Learn how Ansys HFSS 3D electromagnetic simulation software performs on an Azure virtual machine (VM), and learn how to run HFSS on Azure.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hfss-architecture.png",
      "title": "Deploy Ansys HFSS on an Azure virtual machine",
      "lastModified": "2024-01-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-bastion"
      ],
      "url": "/azure/architecture/guide/hpc/ansys-hfss"
    },
    {
      "summary": "Learn how to install and run Devito on an Azure virtual machine, and see the performance results of running Devito on Azure.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hpc-devito-single.png",
      "title": "Deploy Devito on an Azure virtual machine",
      "lastModified": "2024-01-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-cyclecloud",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/devito-virtual-machine"
    },
    {
      "summary": "Learn how to build on the baseline architecture to address changes and expectations when you deploy it in an Azure landing zone.",
      "azureCategories": [
        "compute",
        "migration",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/baseline-landing-zone.png",
      "title": "Azure Virtual Machines baseline architecture in an Azure landing zone",
      "lastModified": "2024-01-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-bastion",
        "azure-firewall",
        "azure-log-analytics",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/virtual-machines/baseline-landing-zone"
    },
    {
      "summary": "Learn how Rock Flow Dynamics tNavigator software performs on an Azure virtual machine (VM), and learn how to run tNavigator on Azure.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/tnavigator-architecture.png",
      "title": "Deploy tNavigator on a virtual machine",
      "lastModified": "2024-01-05T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-cyclecloud"
      ],
      "url": "/azure/architecture/guide/hpc/tnavigator"
    },
    {
      "summary": "Best practices for an Azure NAT gateway, based on the Azure Well-Architected Framework's five pillars of architecture excellence.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/az-directions.png",
      "title": "Azure Well-Architected Framework review of an Azure NAT gateway",
      "lastModified": "2024-01-04T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway",
        "azure-virtual-network",
        "azure-private-link"
      ],
      "url": "/azure/architecture/networking/guide/well-architected-network-address-translation-gateway"
    },
    {
      "summary": "Understand how Kubernetes pods handle identity and access, and compare options in Amazon EKS and Azure Kubernetes Service (AKS).",
      "azureCategories": [
        "containers",
        "identity"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-ad-workload-identity.png",
      "title": "Kubernetes workload identity and access",
      "lastModified": "2024-01-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/aws-professional/eks-to-aks/workload-identity"
    },
    {
      "summary": "Consolidate your data estate across Microsoft Sentinel, Azure Monitor, and Azure Data Explorer to augment your security, observability, and analytics capabilities.",
      "azureCategories": [
        "analytics",
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/monitor-azure-data-explorer.png",
      "title": "Augment security, observability, and analytics by using Microsoft Sentinel, Azure Monitor, and Azure Data Explorer",
      "lastModified": "2023-12-19T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-explorer",
        "azure-monitor",
        "microsoft-sentinel"
      ],
      "url": "/azure/architecture/solution-ideas/articles/monitor-azure-data-explorer"
    },
    {
      "summary": "This article describes the considerations for mapping requests to tenants in a multitenant solution.",
      "azureCategories": [
        "management-and-governance",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/map-logical-physical.png",
      "title": "Map requests to tenants in a multitenant solution",
      "lastModified": "2023-12-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/guide/multitenant/considerations/map-requests"
    },
    {
      "summary": "Learn about the Deployment Stamps pattern, which deploys many independent copies (known as stamps, service units, or scale units) of application components.",
      "azureCategories": [
        "management-and-governance",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/deployment-stamp-traffic-routing.png",
      "title": "Deployment Stamps pattern",
      "lastModified": "2023-12-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-front-door"
      ],
      "url": "/azure/architecture/patterns/deployment-stamp"
    },
    {
      "summary": "Rehost IBM IMS DC and IMS DB on Azure by using Raincode IMSql. Rehost seamlessly without translating or changing your application.",
      "azureCategories": [
        "migration",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/imsql-thumb.png",
      "title": "Rehost IMS DC and IMS DB on Azure by using Raincode IMSql",
      "lastModified": "2023-12-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-vm-scalesets",
        "azure-logic-apps",
        "azure-sql-managed-instance",
        "azure-virtual-network",
        "azure-expressroute"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/rehost-ims-raincode-imsql"
    },
    {
      "summary": "Migrate a z/OS online transaction processing (OLTP) workload to an Azure application that is cost-effective, responsive, scalable, and adaptable.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ibm-zos-online-transaction-processing-on-azure.png",
      "title": "IBM z/OS online transaction processing on Azure",
      "lastModified": "2023-12-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-front-door",
        "azure-traffic-manager",
        "azure-kubernetes-service",
        "azure-spring-apps",
        "azure-cache-redis"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/ibm-zos-online-transaction-processing-azure"
    },
    {
      "summary": "See how Qlik Replication is a valuable tool for migrating mainframe and midrange systems to the cloud, or for extending such systems with cloud applications.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mainframe-midrange-data-replication-azure-qlik.png",
      "title": "Mainframe and midrange data replication to Azure using Qlik",
      "lastModified": "2023-12-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-data-lake",
        "azure-databricks"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/mainframe-midrange-data-replication-azure-qlik"
    },
    {
      "summary": "Learn how to migrate a Software AG Adabas & Natural mainframe system to Azure by using a rehost approach.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mainframe-software-ag-azure-rehost-after.png",
      "title": "Rehost Adabas & Natural applications in Azure",
      "lastModified": "2023-12-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-network",
        "azure-virtual-machines",
        "azure-expressroute"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/rehost-adabas-software-ag"
    },
    {
      "summary": "Migrate Unisys Dorado mainframe systems with Astadia and Micro Focus products. Move to Azure without rewriting code, switching data models, or updating screens.",
      "azureCategories": [
        "hybrid",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/migrate-unisys-dorado-mainframe-apps-architecture-diagram-thumbnail.png",
      "title": "Unisys Dorado mainframe migration to Azure with Astadia and Micro Focus",
      "lastModified": "2023-12-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-sql-database",
        "azure-storage",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/migrate-unisys-dorado-mainframe-apps-with-astadia-micro-focus"
    },
    {
      "summary": "Use proven customer engagement practices to design firewalls and proxies in Azure for traditional infrastructure workloads.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/standard-lb-inbound.png",
      "title": "Azure Network Virtual Appliances Firewall architecture overview",
      "lastModified": "2023-12-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-api-management",
        "azure-load-balancer",
        "azure-virtual-network",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/example-scenario/firewalls/"
    },
    {
      "summary": "Learn how the Red Hat JBoss Enterprise Application Platform (EAP) streamlines and simplifies the development and deployment of a diverse range of applications.",
      "azureCategories": [
        "developer-tools",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/jboss-deployment-red-hat.png",
      "title": "JBoss deployment with Red Hat on Azure",
      "lastModified": "2023-12-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-redhat-openshift",
        "azure-cosmos-db",
        "azure-monitor",
        "azure-container-registry",
        "azure-pipelines"
      ],
      "url": "/azure/architecture/solution-ideas/articles/jboss-deployment-red-hat"
    },
    {
      "summary": "Learn how to modernize mainframe computer systems that run Adabas & Natural and move them to the cloud.",
      "azureCategories": [
        "migration",
        "databases",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mainframe-software-ag-azure-refactor.png",
      "title": "Refactor mainframe computer systems that run Adabas & Natural",
      "lastModified": "2023-12-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-expressroute",
        "azure-managed-disks",
        "azure-netapp-files"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/refactor-adabas-aks"
    },
    {
      "summary": "The SUSE SAP automation solution speeds deployment of SAP infrastructure on Azure. Use it to bolster productivity and facilitate innovation.",
      "azureCategories": [
        "developer-tools",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sap-workload-automation-suse.png",
      "title": "SAP workload automation using SUSE on Azure",
      "lastModified": "2023-12-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-files",
        "azure-cloud-shell",
        "azure-load-balancer",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/solution-ideas/articles/sap-workload-automation-suse"
    },
    {
      "summary": "Use SMA Technologies OpCon in a Kubernetes configuration to automate Azure and on-premises workloads that run on various servers across an enterprise.",
      "azureCategories": [
        "containers",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sma-opcon-azure-thumbnail.png",
      "title": "SMA OpCon in Azure",
      "lastModified": "2023-12-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-private-link",
        "azure-sql-database",
        "azure-storage",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/solution-ideas/articles/sma-opcon-azure"
    },
    {
      "summary": "Improve efficiency and cut costs by running mainframe applications and workloads on Azure. Use UniKix software from NTT DATA to rehost legacy assets.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/rehost-mainframe-ntt-data-unikix-thumbnail.png",
      "title": "Rehost mainframe applications by using NTT DATA UniKix",
      "lastModified": "2023-12-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-load-balancer",
        "azure-site-recovery",
        "azure-sql-database",
        "azure-storage"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/rehost-mainframe-ntt-data-unikix"
    },
    {
      "summary": "Learn about options for using the Avanade Automated Migration Technology (AMT) Framework to migrate Unisys mainframe workloads to Azure.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/unisys-components.png",
      "title": "Unisys mainframe migration with Avanade AMT",
      "lastModified": "2023-12-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-bastion",
        "azure-expressroute",
        "azure-sql-database",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/reference-architectures/migration/unisys-mainframe-migration"
    },
    {
      "summary": "Find out how WRF performs on an Azure virtual machine and get a brief description of the steps for running the software on Azure.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/weather-research-forecasting-thumb.png",
      "title": "Deploy WRF on a virtual machine",
      "lastModified": "2023-11-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/weather-research-forecasting"
    },
    {
      "summary": "Find out how GROMACS molecular dynamics simulation software performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/gromacs-thumb.png",
      "title": "Deploy GROMACS on a virtual machine",
      "lastModified": "2023-11-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/gromacs"
    },
    {
      "summary": "See how Autodesk Maya performs on an Azure virtual machine. Maya provides 3D animation, modeling, simulation, and rendering capabilities.",
      "azureCategories": [
        "compute",
        "developer-tools",
        "media"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/autodesk-maya-thumbnail.png",
      "title": "Deploy Autodesk Maya on a virtual machine",
      "lastModified": "2023-11-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/autodesk-maya"
    },
    {
      "summary": "Learn how to keep Azure Synapse Analytics data pools synchronized with operational data changes in MongoDB Atlas.",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-synapse-analytics-mongodb.png",
      "title": "Enable real-time sync of MongoDB Atlas data changes to Azure Synapse Analytics",
      "lastModified": "2023-11-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/example-scenario/analytics/sync-mongodb-atlas-azure-synapse-analytics"
    },
    {
      "summary": "Find out how Quantum ESPRESSO materials modeling software performs on an Azure virtual machine.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/quantum-espresso-thumb.png",
      "title": "Deploy Quantum ESPRESSO on an Azure virtual machine",
      "lastModified": "2023-11-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/quantum-espresso"
    },
    {
      "summary": "Learn about guidance for creating a proof of concept, which helps you migrate a workload from a mainframe to the cloud.",
      "azureCategories": [
        "databases",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mainframe-proof.png",
      "title": "Mainframe workload migration proof of concept",
      "lastModified": "2023-11-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-expressroute"
      ],
      "url": "/azure/architecture/mainframe/mainframe-proof-of-concept"
    },
    {
      "summary": "Learn how the Altair AcuSolve computational fluid dynamics (CFD) solver performs on an Azure virtual machine.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/acusolve-cluster-thumb.png",
      "title": "Deploy Altair AcuSolve on an Azure virtual machine",
      "lastModified": "2023-11-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-cyclecloud"
      ],
      "url": "/azure/architecture/guide/hpc/hpc-altair-acusolve"
    },
    {
      "summary": "Learn how the Altair nanoFluidX particle-based (SPH) fluid dynamics simulation tool performs on an Azure virtual machine.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/nanofluidx-thumb.png",
      "title": "Deploy Altair nanoFluidX on an Azure virtual machine",
      "lastModified": "2023-11-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/nanofluidx"
    },
    {
      "summary": "Learn how to run FSI workloads on Azure using Azure Batch.",
      "azureCategories": [
        "compute",
        "networking",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/secured-azure-batch.png",
      "title": "Use Azure Batch to run Financial Service Industry (FSI)  workloads",
      "lastModified": "2023-11-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-batch",
        "azure-container-registry",
        "azure-key-vault",
        "azure-storage",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/batch/fsi-workloads-using-batch"
    },
    {
      "summary": "Learn how to deploy ADS CFD Code Leo for high performance computing (HPC) on an Azure virtual machine.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hps-code-leo-thumb.png",
      "title": "Deploy ADS CFD Code Leo for HPC on a virtual machine",
      "lastModified": "2023-11-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/hpc-ads-cfd"
    },
    {
      "summary": "Find out how Tecnomatix plant simulation modeling software performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/tecnomatix-thumb.png",
      "title": "Deploy Siemens Tecnomatix on a virtual machine",
      "lastModified": "2023-11-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/siemens-tecnomatix"
    },
    {
      "summary": "Find out how the Altair ultraFluidX Lattice Boltzmann method (LBM) solver performs on an Azure virtual machine.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hpc-ultrafluidx-thumb.png",
      "title": "Deploy ultraFluidX on a virtual machine",
      "lastModified": "2023-11-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/ultrafluidx"
    },
    {
      "summary": "Use virtualization from Unisys and Microsoft Azure to migrate from a Unisys ClearPath Forward Libra mainframe, which allows an accelerated move into Azure.",
      "azureCategories": [
        "compute",
        "hybrid",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/unisys-clearpath-forward-mainframe-rehost.png",
      "title": "Unisys ClearPath MCP virtualization on Azure",
      "lastModified": "2023-11-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-storage",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/unisys-clearpath-forward-mainframe-rehost"
    },
    {
      "summary": "Learn about Apache HBase and about ways to migrate an HBase implementation to Azure by using Azure services like HDInsight and Cosmos DB.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/flowchart-hbase-azure-landing-targets-thumb.png",
      "title": "Apache HBase migration to Azure",
      "lastModified": "2023-11-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-hdinsight",
        "azure-cosmos-db",
        "azure-data-lake-storage",
        "azure-synapse-analytics",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/guide/hadoop/apache-hbase-migration"
    },
    {
      "summary": "Learn about Apache Kafka and about ways to migrate a Kafka implementation to Azure by using Azure services like HDInsight and Event Hubs.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/flowchart-kafka-azure-landing-targets-thumb.png",
      "title": "Apache Kafka migration to Azure",
      "lastModified": "2023-11-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-hdinsight",
        "azure-cosmos-db",
        "azure-data-lake-storage",
        "azure-synapse-analytics",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/guide/hadoop/apache-kafka-migration"
    },
    {
      "summary": "Learn how M-Star computational fluid dynamics software performs on an Azure virtual machine (VM). Learn the steps for running M-Star on an Azure VM.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/m-star-architecture.png",
      "title": "Deploy M-Star on a virtual machine",
      "lastModified": "2023-11-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/m-star"
    },
    {
      "summary": "Learn about Apache Sqoop and about ways to migrate a Sqoop implementation to Azure by using Azure services like VMs, HDInsight and Data Factory.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/flowchart-sqoop-azure-landing-targets-thumb.png",
      "title": "Apache Sqoop migration to Azure",
      "lastModified": "2023-11-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-hdinsight",
        "azure-cosmos-db",
        "azure-data-lake-storage",
        "azure-synapse-analytics",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/guide/hadoop/apache-sqoop-migration"
    },
    {
      "summary": "Learn how to create an infrastructure and workflow to ensure a valid digital evidence chain of custody (CoC) for computer forensics in Azure.",
      "azureCategories": [
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/chain-of-custody.png",
      "title": "Computer forensics chain of custody in Azure",
      "lastModified": "2023-11-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-automation",
        "azure-disk-encryption",
        "azure-key-vault",
        "azure-storage-accounts"
      ],
      "url": "/azure/architecture/example-scenario/forensics/"
    },
    {
      "summary": "Learn about an architecture for transitioning to SDVs in which the in-vehicle software stack undergoes simulation and testing in cloud-based environments.",
      "azureCategories": [
        "devops",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/software-defined-vehicle-toolchain.png",
      "title": "Software-defined vehicle DevOps toolchain",
      "lastModified": "2023-11-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-grid",
        "azure-kubernetes-service",
        "azure-virtual-machines",
        "azure-container-registry",
        "azure-expressroute"
      ],
      "url": "/azure/architecture/industries/automotive/software-defined-vehicle-reference-architecture"
    },
    {
      "summary": "Learn about architectural recommendations and code assets from the accompanying reference implementation.",
      "azureCategories": [
        "containers",
        "networking",
        "identity",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-pci.png",
      "title": "Architecture of an AKS regulated cluster for PCI-DSS 3.2.1 (Part 2 of 9)",
      "lastModified": "2023-11-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-firewall",
        "azure-application-gateway",
        "microsoft-defender",
        "defender-for-cloud",
        "azure-monitor"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks-pci/aks-pci-ra-code-assets"
    },
    {
      "summary": "Find out how CP2K quantum chemistry and solid-state physics software performs on an Azure virtual machine.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cp2k-thumb.png",
      "title": "Deploy CP2K on a virtual machine",
      "lastModified": "2023-11-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/cp2k"
    },
    {
      "summary": "Learn how NAMD, a parallel molecular dynamics application designed for high-performance simulation of large biomolecular systems, performs on an Azure VM.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hpc-namd.png",
      "title": "Deploy NAMD on a virtual machine",
      "lastModified": "2023-11-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/hpc-namd"
    },
    {
      "summary": "Learn how to use Azure Service Bus and Azure Data Explorer to enhance an existing message broker architecture with near real-time analytics.",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/analytics-service-bus.png",
      "title": "Real-time analytics on data with Azure Service Bus and Azure Data Explorer",
      "lastModified": "2023-10-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-service-bus",
        "azure-data-explorer",
        "azure-app-service",
        "azure-sql-database",
        "azure-cosmos-db"
      ],
      "url": "/azure/architecture/solution-ideas/articles/analytics-service-bus"
    },
    {
      "summary": "Learn about SWIFT Alliance Connect Virtual components that can be deployed on Azure.",
      "azureCategories": [
        "integration",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/swift-alliance-connect-virtual.png",
      "title": "SWIFT Alliance Connect Virtual on Azure",
      "lastModified": "2023-10-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-resource-manager",
        "azure-policy",
        "azure-logic-apps",
        "azure-monitor",
        "entra",
        "entra-id"
      ],
      "url": "/azure/architecture/example-scenario/finance/swift-on-azure-vsrx"
    },
    {
      "summary": "Learn how to use virtualization technologies from Microsoft partner Unisys with an existing Unisys ClearPath Forward (CPF) Dorado enterprise server.",
      "azureCategories": [
        "databases",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/unisys-clearpath-forward-OS-2200-mainframe-rehost-diagram-postmigration.png",
      "title": "Unisys ClearPath Forward OS 2200 enterprise server virtualization on Azure",
      "lastModified": "2023-10-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-expressroute"
      ],
      "url": "/azure/architecture/mainframe/virtualization-of-unisys-clearpath-forward-os-2200-enterprise-server-on-azure"
    },
    {
      "summary": "Learn how to integrate Chef Infra, Chef InSpec, Test Kitchen, Terraform, Terraform Cloud, and GitHub Actions to automate and create data analytics environments.",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/faa-swim.png",
      "title": "Ingest FAA SWIM content to analyze flight data",
      "lastModified": "2023-10-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-databricks",
        "azure-data-lake-storage",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/example-scenario/analytics/ingest-faa-swim-analyze-flight-data"
    },
    {
      "summary": "Find out how OpenRadioss finite element analysis (FEA) software performs on an Azure virtual machine.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/openradioss-thumb.png",
      "title": "Deploy OpenRadioss on a virtual machine",
      "lastModified": "2023-10-18T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/openradioss"
    },
    {
      "summary": "Learn how Windows 365 delivers highly optimized and personalized computing instances that are purpose built for each user's requirements.",
      "azureCategories": [
        "azure-virtual-desktop",
        "windows-virtual-desktop"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/windows-365-azure-network-connection.png",
      "title": "Windows 365 Azure network connection",
      "lastModified": "2023-10-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-virtual-desktop",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/virtual-desktop/windows-365-azure-network-connection"
    },
    {
      "summary": "Learn how the connected fleets reference architecture helps you build solutions to manage connected fleets, generate insights, and integrate fleet solutions.",
      "azureCategories": [
        "analytics",
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/automotive-connected-fleets.png",
      "title": "Automotive connected fleets",
      "lastModified": "2023-10-05T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-data-explorer",
        "azure-event-hubs",
        "azure-functions",
        "azure-iot-hub"
      ],
      "url": "/azure/architecture/industries/automotive/automotive-connected-fleets"
    },
    {
      "summary": "Learn how to use the Messaging Bridge pattern to integrate disparate systems built on top of different messaging infrastructures.",
      "azureCategories": [
        "integration",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/messaging-bridge.png",
      "title": "Messaging Bridge pattern",
      "lastModified": "2023-10-04T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-service-bus"
      ],
      "url": "/azure/architecture/patterns/messaging-bridge"
    },
    {
      "summary": "Learn how the digital transformation architecture extends mainframe applications to Azure without disruptions or modifications to the existing applications.",
      "azureCategories": [
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/extend-mainframes-to-digital-channels-by-using-standards-based-rest-apis.png",
      "title": "Extend mainframes to digital channels by using standards-based REST APIs",
      "lastModified": "2023-09-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-expressroute",
        "azure-monitor",
        "azure-redhat-openshift",
        "power-platform",
        "power-apps"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/extend-mainframes-to-digital-channels-by-using-standards-based-rest-apis"
    },
    {
      "summary": "Automate the transcription of healthcare-specific audio data and the process of serving that data to end users.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics",
        "popular"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/analyze-recordings-text-analytics-azure-openai-thumbnail.png",
      "title": "Analyze call center recordings using text analytics for health and Azure OpenAI Service",
      "lastModified": "2023-09-19T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "language-service",
        "azure-synapse-analytics",
        "ai-services",
        "azure-blob-storage"
      ],
      "url": "/azure/architecture/example-scenario/healthcare/analyze-recordings-text-analytics-azure-openai"
    },
    {
      "summary": "Perform cross-cluster scaling of on-premises workloads by using Azure Arc, Traffic Manager, or Front Door and then deploy by using Azure Pipelines.",
      "azureCategories": [
        "hybrid",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-cross-cluster-scaling-with-azure-arc.png",
      "title": "Hybrid Cross-Cluster Scaling with Azure Arc for the workloads deployed on Azure Stack HCI",
      "lastModified": "2023-09-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-expressroute",
        "azure-files",
        "azure-storage-accounts"
      ],
      "url": "/azure/architecture/hybrid/hybrid-cross-cluster-scaling"
    },
    {
      "summary": "Learn how to protect APIs with API Management and Application Gateway.",
      "azureCategories": [
        "web",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/protect-apis.png",
      "title": "Protect APIs with Application Gateway and API Management",
      "lastModified": "2023-09-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-api-management",
        "azure-application-gateway"
      ],
      "url": "/azure/architecture/web-apps/api-management/architectures/protect-apis"
    },
    {
      "summary": "Learn how to use Azure OpenAI and Azure Cognitive Search to search documents in your enterprise data and retrieve results.",
      "azureCategories": [
        "analytics",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/openai-embedding.png",
      "title": "Search and query an enterprise knowledge base by using Azure OpenAI or Azure Cognitive Search",
      "lastModified": "2023-08-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-cache-redis",
        "azure-cognitive-search",
        "ai-services",
        "document-intelligence"
      ],
      "url": "/azure/architecture/ai-ml/openai/idea/search-and-query-using-openai-service"
    },
    {
      "summary": "Deploy the Magento e-commerce platform to Azure Kubernetes Service (AKS), and learn about considerations for hosting Magento on Azure.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/magento-architecture-thumbnail.png",
      "title": "Magento e-commerce platform in Azure Kubernetes Service",
      "lastModified": "2023-08-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-container-registry",
        "azure-database-mysql",
        "azure-files",
        "azure-kubernetes-service",
        "azure-monitor"
      ],
      "url": "/azure/architecture/example-scenario/magento/magento-azure"
    },
    {
      "summary": "Learn about the responsibilities of control planes in multitenant solutions and how to design a control plane that meets your needs.",
      "azureCategories": [
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/control-planes-thumb.png",
      "title": "Considerations for multitenant control planes",
      "lastModified": "2023-08-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/guide/multitenant/considerations/control-planes"
    },
    {
      "summary": "Deploy Azure Health Data Services on Azure to improve your ability to safeguard your organization's health data.",
      "azureCategories": [
        "analytics",
        "databases",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-health-data-services.png",
      "title": "Azure Health Data Services architecture guide",
      "lastModified": "2023-08-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-health-data-services",
        "azure-api-management",
        "azure-application-gateway",
        "azure-synapse-analytics",
        "azure-firewall"
      ],
      "url": "/azure/architecture/guide/data/azure-health-data-services"
    },
    {
      "summary": "Learn about the Azure availability zones construct, a software and networking solution for high availability (HA) that protects against datacenter failures.",
      "azureCategories": [
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/high-availability-availability-zone.png",
      "title": "Build solutions for high availability using availability zones",
      "lastModified": "2023-08-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/high-availability/building-solutions-for-high-availability"
    },
    {
      "summary": "Learn how to to use a mobile robot with a live streaming camera and object detection to implement various use cases.",
      "azureCategories": [
        "ai-machine-learning",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/video-ingestion.png",
      "title": "Video ingestion and object detection on the edge and in the cloud",
      "lastModified": "2023-08-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-stack-edge",
        "azure-kubernetes-service",
        "azure-sql-edge",
        "azure-container-registry"
      ],
      "url": "/azure/architecture/ai-ml/idea/video-ingestion-object-detection-edge-cloud"
    },
    {
      "summary": "Learn how to extract insights from customer conversations at a call center by using Azure AI services and Azure OpenAI Service.",
      "azureCategories": [
        "analytics",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/call-center-analytics.png",
      "title": "Extract and analyze call center data",
      "lastModified": "2023-08-04T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-speech",
        "ai-services",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/ai-ml/openai/architecture/call-center-openai-analytics"
    },
    {
      "summary": "Learn about the recommended architecture for deploying an enterprise application by using App Services Environment version 3 in Azure.",
      "azureCategories": [
        "management-and-governance",
        "featured"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/app-service-environment.png",
      "title": "Enterprise deployment using Azure App Service Environment",
      "lastModified": "2023-08-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-application-gateway",
        "azure-app-service",
        "azure-firewall",
        "azure-virtual-network",
        "azure-private-link"
      ],
      "url": "/azure/architecture/web-apps/app-service-environment/architectures/ase-standard-deployment"
    },
    {
      "summary": "Recommended architecture for deploying an enterprise application using App Services Environment in multiple availability zones.",
      "azureCategories": [
        "management-and-governance",
        "featured"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/app-service-environment-high-availability.png",
      "title": "High availability enterprise deployment using App Service Environment",
      "lastModified": "2023-08-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-application-gateway",
        "azure-firewall",
        "azure-virtual-network",
        "azure-app-service"
      ],
      "url": "/azure/architecture/web-apps/app-service-environment/architectures/ase-high-availability-deployment"
    },
    {
      "summary": "Learn how to deploy mission-critical web applications by using the Web Apps feature of Azure App Service.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/scale-unit-architecture-thumbnail.png",
      "title": "Mission-critical baseline with App Service",
      "lastModified": "2023-08-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-front-door",
        "azure-cache-redis",
        "azure-app-configuration",
        "azure-monitor"
      ],
      "url": "/azure/architecture/guide/networking/global-web-applications/mission-critical-app-service"
    },
    {
      "summary": "Proven practices for running SAP HANA in a high-availability, scale-up environment that supports disaster recovery on Azure.",
      "azureCategories": [
        "databases",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sap-hana-scale-up-thumbnail.png",
      "title": "Run SAP HANA for Linux virtual machines in a scale-up architecture on Azure",
      "lastModified": "2023-07-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/reference-architectures/sap/run-sap-hana-for-linux-virtual-machines"
    },
    {
      "summary": "Learn proven practices for running SAP NetWeaver in a Windows environment on Azure, with high availability.",
      "azureCategories": [
        "databases",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sap-netweaver-windows.png",
      "title": "Run SAP NetWeaver in Windows on Azure",
      "lastModified": "2023-07-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-sap",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-netapp-files"
      ],
      "url": "/azure/architecture/guide/sap/sap-netweaver"
    },
    {
      "summary": "Create a data store to search and filter medical data by using Azure Data Factory pipelines with preingestion and postingestion validation pipelines.",
      "azureCategories": [
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/data-factory-pipeline.png",
      "title": "Ingest and store medical data in Azure",
      "lastModified": "2023-07-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-data-explorer",
        "azure-queue-storage",
        "azure-blob-storage",
        "azure-files"
      ],
      "url": "/azure/architecture/example-scenario/data/medical-data-ingestion"
    },
    {
      "summary": "Review proven practices for running SAP S/4HANA in a Linux environment on Azure, with high availability.",
      "azureCategories": [
        "databases",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sap-s4hana-thumb.png",
      "title": "SAP S/4HANA in Linux on Azure",
      "lastModified": "2023-07-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-sap",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-netapp-files"
      ],
      "url": "/azure/architecture/guide/sap/sap-s4hana"
    },
    {
      "summary": "Learn proven practices for running SAP on Oracle in Azure, with high availability.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sap-oracle-architecture.png",
      "title": "SAP deployment on Azure using an Oracle database",
      "lastModified": "2023-07-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-sap",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-netapp-files"
      ],
      "url": "/azure/architecture/example-scenario/apps/sap-production"
    },
    {
      "summary": "Learn about the SAP BW/4HANA application tier and how it's suitable for a high availability, small-scale production environment of SAP BW/4HANA on Azure.",
      "azureCategories": [
        "databases",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sap-bw4hana.png",
      "title": "Run SAP BW/4HANA with Linux virtual machines on Azure",
      "lastModified": "2023-07-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-bastion",
        "azure-managed-disks",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-sap"
      ],
      "url": "/azure/architecture/reference-architectures/sap/run-sap-bw4hana-with-linux-virtual-machines"
    },
    {
      "summary": "Minimize private address space consumption when you build large networks in Azure. This article presents two methods to create more IPv4 address space.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ipv4-hub-spoke.png",
      "title": "Prevent IPv4 exhaustion in Azure",
      "lastModified": "2023-07-19T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-firewall",
        "azure-virtual-network",
        "azure-private-link"
      ],
      "url": "/azure/architecture/guide/networking/ipv4-exhaustion"
    },
    {
      "summary": "Plan the implementation of the reliable web app pattern for .NET. Find essential design guidance for web apps moving to the cloud. Learn how to update (re-platform) web apps to be successful in the cloud.",
      "azureCategories": [
        "migration",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/reliable-web-app-dotnet.png",
      "title": "Reliable web app pattern for .NET - Plan the implementation",
      "lastModified": "2023-07-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-front-door",
        "azure-cache-redis",
        "dotnet"
      ],
      "url": "/azure/architecture/web-apps/guides/reliable-web-app/dotnet/plan-implementation"
    },
    {
      "summary": "Apply the reliable web app pattern for .NET to your web app. Find essential implementation guidance for web apps moving to the cloud. Learn how to update (re-platform) web apps to be successful in the cloud.",
      "azureCategories": [
        "migration",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/reliable-web-app-dotnet.png",
      "title": "Reliable web app pattern for .NET - Apply the pattern",
      "lastModified": "2023-07-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-front-door",
        "azure-cache-redis",
        "dotnet"
      ],
      "url": "/azure/architecture/web-apps/guides/reliable-web-app/dotnet/apply-pattern"
    },
    {
      "summary": "Plan the implementation of the reliable web app pattern for Java. Find essential design guidance for web apps moving to the cloud. Learn how to update (re-platform) web apps to be successful in the cloud.",
      "azureCategories": [
        "migration",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/reliable-web-app-java.png",
      "title": "Reliable web app pattern for Java - Plan the implementation",
      "lastModified": "2023-07-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-cache-redis",
        "azure-database-postgresql",
        "microsoft-authentication-library",
        "msal-java"
      ],
      "url": "/azure/architecture/web-apps/guides/reliable-web-app/java/plan-implementation"
    },
    {
      "summary": "Apply the reliable web app pattern for Java to your web app. Find essential implementation guidance for web apps moving to the cloud. Learn how to update (re-platform) web apps to be successful in the cloud.",
      "azureCategories": [
        "migration",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/reliable-web-app-java.png",
      "title": "Reliable web app pattern for Java - Apply the pattern",
      "lastModified": "2023-07-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-front-door",
        "azure-cache-redis",
        "microsoft-authentication-library",
        "msal-java"
      ],
      "url": "/azure/architecture/web-apps/guides/reliable-web-app/java/apply-pattern"
    },
    {
      "summary": "Best practices and recommendations for architecting an entire SAP landscape on Azure.",
      "azureCategories": [
        "databases",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sap-whole-landscape.png",
      "title": "SAP landscape architecture",
      "lastModified": "2023-07-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-files",
        "azure-load-balancer"
      ],
      "url": "/azure/architecture/guide/sap/sap-whole-landscape"
    },
    {
      "summary": "Learn proven practices for enabling improved-security inbound and outbound internet connections for your SAP on Azure infrastructure.",
      "azureCategories": [
        "databases",
        "compute",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sap-internet-inbound-outbound-content-thumbnail.png",
      "title": "Inbound and outbound internet connections for SAP on Azure",
      "lastModified": "2023-07-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-application-gateway",
        "azure-load-balancer"
      ],
      "url": "/azure/architecture/guide/sap/sap-internet-inbound-outbound"
    },
    {
      "summary": "Use Azure Front Door, Azure Virtual Machine Scale Sets, and other Azure services to deploy a highly scalable and secure installation of WordPress.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/wordpress-vm.png",
      "title": "WordPress on virtual machines",
      "lastModified": "2023-06-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-front-door",
        "azure-key-vault",
        "azure-load-balancer",
        "azure-virtual-network",
        "azure-vm-scalesets"
      ],
      "url": "/azure/architecture/example-scenario/infrastructure/wordpress-iaas"
    },
    {
      "summary": "Learn about multiple options for hosting WordPress on Azure. See guidance and tips for deploying a secure, scalable, and cost-effective WordPress installation.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-wordpress.png",
      "title": "WordPress on Azure",
      "lastModified": "2023-06-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-front-door",
        "azure-kubernetes-service",
        "azure-web-application-firewall",
        "azure-private-link"
      ],
      "url": "/azure/architecture/guide/infrastructure/wordpress-overview"
    },
    {
      "summary": "Use Azure Front Door, Azure App Service, and other Azure services to deploy a highly scalable and secure installation of WordPress.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/wordpress-app-service.png",
      "title": "WordPress on App Service",
      "lastModified": "2023-06-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-front-door",
        "azure-load-balancer",
        "azure-virtual-network",
        "azure-app-service",
        "azure-database-mysql"
      ],
      "url": "/azure/architecture/example-scenario/infrastructure/wordpress-app-service"
    },
    {
      "summary": "Use Azure Kubernetes Service (AKS) to deploy a highly scalable and secure installation of WordPress that's a good fit for large, storage-intensive applications.",
      "azureCategories": [
        "web",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/wordpress-aks-azure-netapp-files.png",
      "title": "WordPress on Azure Kubernetes Service",
      "lastModified": "2023-06-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cache-redis",
        "azure-front-door",
        "azure-kubernetes-service",
        "azure-load-balancer",
        "azure-netapp-files"
      ],
      "url": "/azure/architecture/example-scenario/infrastructure/wordpress-container"
    },
    {
      "summary": "Run an application on Azure Red Hat OpenShift in a zone-redundant configuration to achieve high availability.",
      "azureCategories": [
        "containers",
        "web",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/openshift-architecture.png",
      "title": "Deploy web apps by using zone-redundant Azure Red Hat OpenShift",
      "lastModified": "2023-06-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-redhat-openshift",
        "azure-cosmos-db",
        "azure-front-door"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aro/ha-zr-aro"
    },
    {
      "summary": "Find out how Samadii SCIV (Statistical Contact in Vacuum) performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/samadii-sciv-thumbnail.png",
      "title": "Deploy Samadii SCIV on a virtual machine",
      "lastModified": "2023-06-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/samadii-sciv"
    },
    {
      "summary": "Learn how Altair EDEM discrete element method (DEM) software performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/altair-edem-thumb.png",
      "title": "Deploy Altair EDEM on a virtual machine",
      "lastModified": "2023-06-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/altair-edem"
    },
    {
      "summary": "Find out how the Samadii Plasma particle-based application performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/plasma-thumbnail.png",
      "title": "Deploy Samadii Plasma on a virtual machine",
      "lastModified": "2023-06-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/plasma"
    },
    {
      "summary": "Find out how Samadii DEM, a discrete element method solver, performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/samadii-dem-architecture.png",
      "title": "Deploy Samadii DEM on a virtual machine",
      "lastModified": "2023-06-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/samadii-dem"
    },
    {
      "summary": "Learn how to deploy Azure Spring Apps instances in multiple regions for increased availability, resilience, and performance.",
      "azureCategories": [
        "networking",
        "security",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/spring-apps-reference-architecture-multi-region.svg",
      "title": "Deploy Azure Spring Apps to multiple regions",
      "lastModified": "2023-06-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway",
        "azure-front-door",
        "azure-key-vault",
        "azure-spring-apps"
      ],
      "url": "/azure/architecture/web-apps/spring-apps/architectures/spring-apps-multi-region"
    },
    {
      "summary": "Learn how to deploy Azure Spring Apps instances in multiple zones for increased availability, resilience, and performance.",
      "azureCategories": [
        "networking",
        "security",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/deploy-spring-web-applications.png",
      "title": "Azure Spring Apps baseline architecture",
      "lastModified": "2023-06-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway",
        "azure-key-vault",
        "azure-spring-apps",
        "azure-database-mysql"
      ],
      "url": "/azure/architecture/web-apps/spring-apps/architectures/spring-apps-multi-zone"
    },
    {
      "summary": "Learn about a high-availability blue/green deployment solution for applications on Azure Spring Apps.",
      "azureCategories": [
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/blue-green-deployment-thumbnail.png",
      "title": "Blue/green deployments for applications on Azure Spring Apps",
      "lastModified": "2023-06-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-spring-apps",
        "github",
        "azure-devops"
      ],
      "url": "/azure/architecture/web-apps/spring-apps/guides/blue-green-spring"
    },
    {
      "summary": "Learn how to expose Azure Spring Apps with reverse proxy services like Application Gateway, Azure Front Door, and Spring Cloud Gateway.",
      "azureCategories": [
        "web",
        "networking",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-front-door-application-gateway-reverse-proxy.png",
      "title": "Expose Azure Spring Apps through a reverse proxy",
      "lastModified": "2023-06-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-spring-apps",
        "azure-application-gateway",
        "azure-front-door"
      ],
      "url": "/azure/architecture/web-apps/spring-apps/guides/spring-cloud-reverse-proxy"
    },
    {
      "summary": "Learn how to deploy a reference architecture for Spring Boot apps in Azure Spring Apps with landing zones so workloads can share resources via centralized management.",
      "azureCategories": [
        "networking",
        "security",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/spring-apps-reference-architecture-landing-zone.svg",
      "title": "Azure Spring Apps integrated with landing zones",
      "lastModified": "2023-06-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway",
        "azure-key-vault",
        "azure-spring-apps"
      ],
      "url": "/azure/architecture/web-apps/spring-apps/architectures/spring-apps-landing-zone"
    },
    {
      "summary": "Use Azure Cognitive Services AI tools to automate the offline dubbing process and ensure a high-quality dubbed version of original input.",
      "azureCategories": [
        "ai-machine-learning",
        "media"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/audio-dubbing-architecture.png",
      "title": "Offline audio dubbing",
      "lastModified": "2023-06-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "ai-services",
        "azure-speech"
      ],
      "url": "/azure/architecture/guide/media/offline-audio-dubbing"
    },
    {
      "summary": "Learn how to monitor an end-to-end distributed system by using OpenCensus for Python and Application Insights.",
      "azureCategories": [
        "security",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/monitor-with-opencensus-application-insights.png",
      "title": "Monitor a distributed system by using Application Insights and OpenCensus",
      "lastModified": "2023-06-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-functions",
        "azure-service-bus",
        "azure-monitor"
      ],
      "url": "/azure/architecture/guide/devops/monitor-with-opencensus-application-insights"
    },
    {
      "summary": "Learn how LAMMPS classical molecular dynamics software performs on single-node and multi-node Azure virtual machine configurations.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/multi-node-lammps.png",
      "title": "Deploy LAMMPS on an Azure virtual machine",
      "lastModified": "2023-06-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/hpc-lammps"
    },
    {
      "summary": "Implement Azure DevOps for z/OS mainframe applications that use IBM Z and Cloud Modernization Stack.",
      "azureCategories": [
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/devops-mainframe-ibm-stack.png",
      "title": "Implement Azure DevOps for mainframe applications that use IBM Z and Cloud Modernization Stack",
      "lastModified": "2023-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-devops",
        "azure-repos",
        "azure-pipelines",
        "azure-boards",
        "azure-test-plans"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/devops-mainframe-ibm-z"
    },
    {
      "summary": "Learn how to use an AI/ML pipeline, LangChain, and language models to create an analysis of how your product compares to competitor's similar products.",
      "azureCategories": [
        "ai-machine-learning",
        "compute",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/language-model-architecture.png",
      "title": "Build language model pipelines with memory",
      "lastModified": "2023-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "bing",
        "azure-bing-web",
        "azure",
        "azure-cache-redis",
        "azure-pipelines"
      ],
      "url": "/azure/architecture/ai-ml/openai/guide/language-model-pipelines"
    },
    {
      "summary": "Discover how a split 5G/LTE core architecture is suited for large enterprises that have multiple sites.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/private-5g-network.png",
      "title": "Create a 5G split core shop floor network",
      "lastModified": "2023-06-12T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-analysis-services",
        "azure-arc",
        "azure-sql-database",
        "azure-stack-edge",
        "azure-storage"
      ],
      "url": "/azure/architecture/solution-ideas/articles/private-network-split-core-manufacture"
    },
    {
      "summary": "Learn about the Siemens Teamcenter product lifecycle management (PLM) solution architecture on Azure.",
      "azureCategories": [
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/teamcenter-baseline-architecture.png",
      "title": "Siemens Teamcenter baseline architecture on Azure",
      "lastModified": "2023-06-12T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/example-scenario/manufacturing/teamcenter-baseline"
    },
    {
      "summary": "Learn how to modernize IBM mainframe and midrange data. Use a data-first approach to migrate the data to Azure.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/modernize-mainframe-data-with-azure.png",
      "title": "Modernize mainframe and midrange data",
      "lastModified": "2023-06-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-data-lake",
        "azure-sql-database",
        "azure-sql-managed-instance",
        "azure-storage"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/modernize-mainframe-data-to-azure"
    },
    {
      "summary": "Implement Trusted Internet Connection (TIC) 3.0 compliance for your internet-facing Azure applications and services, and improve the user experience.",
      "azureCategories": [
        "networking",
        "security",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/trusted-internet-connections.png",
      "title": "Implement TIC 3.0 compliance",
      "lastModified": "2023-06-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-firewall",
        "azure-application-gateway",
        "azure-front-door",
        "azure-log-analytics",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/example-scenario/security/trusted-internet-connections"
    },
    {
      "summary": "Use Azure services to build an offline data operations and data management (DataOps) solution for an automated driving system.",
      "azureCategories": [
        "analytics",
        "databases",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/autonomous-vehicle-operations-dataops-thumbnail.png",
      "title": "Data operations for autonomous vehicle operations",
      "lastModified": "2023-06-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-batch",
        "azure-cosmos-db",
        "azure-data-factory",
        "azure-data-lake-storage",
        "azure-data-share"
      ],
      "url": "/azure/architecture/example-scenario/automotive/autonomous-vehicle-operations-dataops"
    },
    {
      "summary": "Learn how to use Azure NetApp Files as a storage solution for Siemens Teamcenter product lifecycle management (PLM).",
      "azureCategories": [
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/teamcenter-plm-netapp-files.png",
      "title": "Use Teamcenter PLM with Azure NetApp Files",
      "lastModified": "2023-06-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-netapp-files",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/example-scenario/manufacturing/teamcenter-plm-netapp-files"
    },
    {
      "summary": "Discover infrastructure considerations for an Azure Kubernetes Service cluster on a dual-stack network using both IPv4 and IPv6 addresses.",
      "azureCategories": [
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/dual-stack.png",
      "title": "Enable dual-stack network traffic on Azure Kubernetes Service",
      "lastModified": "2023-06-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-load-balancer",
        "azure-virtual-network",
        "azure-container-registry"
      ],
      "url": "/azure/architecture/guide/aks/aks-dual-stack"
    },
    {
      "summary": "Learn how to use the Gatekeeper pattern to protect applications and services by using a dedicated host instance as a broker to validate requests and data.",
      "azureCategories": [
        "web",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/gatekeeper-endpoint.png",
      "title": "Gatekeeper pattern",
      "lastModified": "2023-05-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-dedicated-host"
      ],
      "url": "/azure/architecture/patterns/gatekeeper"
    },
    {
      "summary": "This article describes how to organize your Azure resources in a multitenant solution.",
      "azureCategories": [
        "management-and-governance",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/isolation-resource-group.png",
      "title": "Azure resource organization in multitenant solutions",
      "lastModified": "2023-05-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "entra",
        "entra-id"
      ],
      "url": "/azure/architecture/guide/multitenant/approaches/resource-organization"
    },
    {
      "summary": "A starting point for building software as a service (SaaS) on Azure. This architecture includes common SaaS components such as identity, onboarding, and tenant management.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/saas-starter-web-app-thumbnail.svg",
      "title": "Starter web app for SaaS development",
      "lastModified": "2023-05-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "entra",
        "entra-external-id",
        "azure-sql-database",
        "azure-logic-apps",
        "azure-resource-manager"
      ],
      "url": "/azure/architecture/example-scenario/apps/saas-starter-web-app"
    },
    {
      "summary": "Learn how to contextualize data by looking up context that's stored in a graph database in SQL Database.",
      "azureCategories": [
        "analytics",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/data-contextualization.png",
      "title": "Contextualize data by using graph in SQL Database",
      "lastModified": "2023-05-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-sql-database",
        "azure-databricks",
        "azure-data-lake-storage"
      ],
      "url": "/azure/architecture/example-scenario/data/data-contextualization-based-on-azure-sql-graph"
    },
    {
      "summary": "Use Bicep and a deployment script to pause a deployment in order to wait for a resource property to return a specific value.",
      "azureCategories": [
        "devops",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/deployment-scripts-property-check.png",
      "title": "Use deployment scripts to check resource properties",
      "lastModified": "2023-05-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-resource-manager",
        "azure-virtual-network",
        "azure-virtual-wan"
      ],
      "url": "/azure/architecture/guide/devops/deployment-scripts-property-check"
    },
    {
      "summary": "Learn how Autodesk Revit building information modeling software performs on an Azure virtual machine.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hpc-revit.png",
      "title": "Deploy Revit on an Azure virtual machine",
      "lastModified": "2023-05-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/hpc-autodesk-revit"
    },
    {
      "summary": "A baseline reference architecture for a network secured, highly available, and zone-redundant App Service web application.",
      "azureCategories": [
        "web",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/baseline-app-service-architecture.png",
      "title": "Baseline highly available zone-redundant web application",
      "lastModified": "2023-05-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-application-gateway",
        "azure-storage",
        "azure-sql-database",
        "azure-private-link",
        "azure-virtual-network",
        "azure-monitor"
      ],
      "url": "/azure/architecture/web-apps/app-service/architectures/baseline-zone-redundant"
    },
    {
      "summary": "Use Azure Machine Learning, Azure Synapse Analytics, App Service, and Power BI to model loan credit risk and default probability.",
      "azureCategories": [
        "ai-machine-learning",
        "databases",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/loan-credit-model.png",
      "title": "Model loan credit risk and default probability",
      "lastModified": "2023-05-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning",
        "azure-synapse-analytics",
        "azure-app-service",
        "azure-data-lake-storage",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/example-scenario/ai/loan-credit-risk-analyzer-default-modeling"
    },
    {
      "summary": "Use Model9 Manager to send mainframe data directly to Azure Blob Storage as part of a mainframe modernization migration.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/model9-mainframe-midrange-data-archive-azure.png",
      "title": "Modernize mainframe workloads by using Model9",
      "lastModified": "2023-05-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-expressroute",
        "azure-vpn-gateway",
        "azure-synapse-analytics",
        "azure-monitor"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/mainframe-modernization-model9"
    },
    {
      "summary": "Build an app deployment pipeline for containerized apps on AKS hybrid that are running on Azure Stack HCI or Windows Server.",
      "azureCategories": [
        "containers",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-stack-hci.png",
      "title": "Deploy and operate apps with AKS hybrid on Azure Stack HCI or Windows Server",
      "lastModified": "2023-05-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-stack-hci",
        "azure-arc",
        "github",
        "azure-pipelines"
      ],
      "url": "/azure/architecture/example-scenario/hybrid/aks-hybrid-stack-hci"
    },
    {
      "summary": "Learn how to generate summaries of customer–agent interactions by using the Azure OpenAI GPT-3 model.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/conversation-summarization-overview.png",
      "title": "Conversation summarization",
      "lastModified": "2023-05-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "ai-services"
      ],
      "url": "/azure/architecture/ai-ml/guide/conversation-summarization"
    },
    {
      "summary": "Restrict inbound traffic to a web app or function app. Use private endpoints in Azure to give consumer tenants secure access to provider tenant apps.",
      "azureCategories": [
        "networking",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cross-tenant-secure-access-private-endpoints-thumbnail.png",
      "title": "Cross-tenant secure access to Azure web apps and function apps with private endpoints",
      "lastModified": "2023-05-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-dns",
        "azure-functions",
        "azure-private-link"
      ],
      "url": "/azure/architecture/guide/networking/cross-tenant-secure-access-private-endpoints"
    },
    {
      "summary": "Learn how to use Teradata VantageCloud Enterprise together with Azure Data Factory to develop data integration pipelines.",
      "azureCategories": [
        "integration",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/teradata-azure-data-factory-vnet-peering.png",
      "title": "Integrate Teradata VantageCloud with Azure Data Factory",
      "lastModified": "2023-05-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-blob-storage",
        "azure-private-link",
        "azure-virtual-wan"
      ],
      "url": "/azure/architecture/databases/guide/teradata-vantage-data-factory"
    },
    {
      "summary": "Learn about the architecture for a massive-scale Azure Virtual WAN deployment that has multiple hubs per region.",
      "azureCategories": [
        "networking",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/massive-scale-architecture-design.png",
      "title": "Massive-scale VWAN architecture design",
      "lastModified": "2023-05-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-wan",
        "azure-vm-scalesets",
        "azure-expressroute"
      ],
      "url": "/azure/architecture/example-scenario/networking/massive-scale-azure-architecture"
    },
    {
      "summary": "Learn how to manage Microsoft 365 tenant configuration by using Microsoft365DSC and Azure DevOps.",
      "azureCategories": [
        "management-and-governance",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/manage-microsoft-365-tenant-configuration-microsoft365dsc-azure-devops.png",
      "title": "Manage Microsoft 365 tenant configuration by using Microsoft365DSC and Azure DevOps",
      "lastModified": "2023-05-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-devops",
        "azure-key-vault",
        "azure-virtual-machines-windows",
        "m365"
      ],
      "url": "/azure/architecture/example-scenario/devops/manage-microsoft-365-tenant-configuration-microsoft365dsc-devops"
    },
    {
      "summary": "Learn how to use Azure Machine Learning and Microsoft Power Platform to quickly create a machine learning proof of concept and a production version.",
      "azureCategories": [
        "ai-machine-learning",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/citizen-ai-power-platform-thumbnail.png",
      "title": "Citizen AI with Power Platform",
      "lastModified": "2023-05-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning",
        "power-platform",
        "power-apps",
        "power-automate",
        "power-bi"
      ],
      "url": "/azure/architecture/example-scenario/ai/citizen-ai-power-platform"
    },
    {
      "summary": "Learn how to use tcVISION, a mainframe data replication solution, to migrate data to and from multiple Azure Data Platform services.",
      "azureCategories": [
        "migration",
        "integration",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mainframe-realtime-batch-data-replication-azure-tcvision.png",
      "title": "Mainframe and midrange data replication to Azure using tcVISION",
      "lastModified": "2023-05-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-database-migration",
        "azure-functions",
        "azure-logic-apps",
        "azure-sql-database",
        "azure-storage"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/mainframe-data-replication-azure-tcvision"
    },
    {
      "summary": "Learn how to quickly build an architecture with real-time inference by using Azure Machine Learning and low-code UI with Microsoft Power Platform.",
      "azureCategories": [
        "ai-machine-learning",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/deploy-real-time-machine-learning-model-application-ui.png",
      "title": "Build an architecture with real-time machine learning inference and low-code web application UI on Azure",
      "lastModified": "2023-05-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning",
        "power-platform",
        "power-apps",
        "power-automate",
        "power-bi"
      ],
      "url": "/azure/architecture/example-scenario/ai/deploy-real-time-machine-learning-model-application-ui"
    },
    {
      "summary": "Implement comprehensive logging and monitoring and enhanced security for Azure OpenAI Service large language models.",
      "azureCategories": [
        "ai-machine-learning",
        "management-and-governance",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/openai-monitor-log.png",
      "title": "Implement logging and monitoring for Azure OpenAI models",
      "lastModified": "2023-05-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "ai-services",
        "azure-api-management",
        "azure-monitor",
        "entra",
        "entra-id"
      ],
      "url": "/azure/architecture/ai-ml/openai/architecture/log-monitor-azure-openai"
    },
    {
      "summary": "Learn how to use Precisely Connect to migrate legacy mainframe and midrange systems to the Azure platform.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mainframe-precisely-connect-thumb.png",
      "title": "Replicate mainframe data by using Precisely Connect",
      "lastModified": "2023-05-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-sql-database",
        "azure-sql-managed-instance",
        "azure-synapse-analytics",
        "azure-databricks",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/mainframe-replication-precisely-connect"
    },
    {
      "summary": "Use AI Builder and Azure Form Recognizer in a Power Automate workflow to extract text from images, for indexing and retrieval.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-extract-object-text-thumbnail.png",
      "title": "Extract text from objects using Power Automate and AI Builder",
      "lastModified": "2023-05-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "power-platform",
        "ai-builder",
        "azure",
        "document-intelligence",
        "power-automate",
        "azure-functions"
      ],
      "url": "/azure/architecture/example-scenario/ai/extract-object-text"
    },
    {
      "summary": "Learn about a custom solution that provides real-time presence information by using Microsoft Cloud services.",
      "azureCategories": [
        "web",
        "identity"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/real-time-presence.png",
      "title": "Real-time presence with Microsoft 365, Azure, and Power Platform",
      "lastModified": "2023-05-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-functions",
        "ms-graph",
        "power-platform",
        "azure-signalr-service",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/solution-ideas/articles/presence-microsoft-365-power-platform"
    },
    {
      "summary": "Use Azure Arc to deploy an Azure Arc-enabled SQL managed instance across two sites that are outside of Azure in a highly available architecture.",
      "azureCategories": [
        "hybrid",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-arc-sql-managed-instance-dr-thumb.png",
      "title": "Deploy an Azure Arc-enabled SQL managed instance for disaster recovery",
      "lastModified": "2023-04-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-arc",
        "azure-sql-managed-instance"
      ],
      "url": "/azure/architecture/hybrid/arc-sql-managed-instance-disaster-recovery"
    },
    {
      "summary": "Build a CTFd service with Azure PaaS so you can manage your games while Azure takes care of the infrastructure.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-ctfd.png",
      "title": "Capture-the-Flag with CTFd on Azure PaaS",
      "lastModified": "2023-04-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-database-mariadb",
        "azure-key-vault",
        "azure-log-analytics",
        "azure-private-link",
        "azure-container-registry"
      ],
      "url": "/azure/architecture/example-scenario/apps/capture-the-flag-platform-on-azure-paas"
    },
    {
      "summary": "This article provides guidance on implementing DNS to support private endpoints in a Virtual WAN network in a single region where the virtual hub has a Firewall with DNS Proxy enabled. The solution includes implementing a virtual WAN hub extension for DNS.",
      "azureCategories": [
        "networking",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/dns-private-endpoints-virtual-wan-scenario-single-region-works.png",
      "title": "Single region scenario - Private Link and DNS in Azure Virtual WAN",
      "lastModified": "2023-04-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-private-link",
        "azure-dns",
        "azure-firewall",
        "azure-virtual-wan"
      ],
      "url": "/azure/architecture/guide/networking/private-link-virtual-wan-dns-single-region-workload"
    },
    {
      "summary": "Establish enhanced-security bidirectional connections to edge devices without making significant changes to the firewall or network configuration on the edge.",
      "azureCategories": [
        "iot",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/secure-tunneling-azure-relay.png",
      "title": "Secure tunneling with Azure Relay",
      "lastModified": "2023-04-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-relay",
        "azure-iot-hub",
        "azure-functions",
        "azure-container-instances",
        "azure-container-registry"
      ],
      "url": "/azure/architecture/example-scenario/networking/secure-tunneling-azure-relay"
    },
    {
      "summary": "Learn how to implement DNS to support private endpoints in a Virtual WAN network. In these scenarios, every Virtual WAN hub has a firewall that has DNS proxy enabled.",
      "azureCategories": [
        "networking",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/dns-private-endpoints-virtual-wan-baseline-architecture.png",
      "title": "Guide to Private Link and DNS in Azure Virtual WAN",
      "lastModified": "2023-04-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-private-link",
        "azure-dns",
        "azure-firewall",
        "azure-virtual-wan"
      ],
      "url": "/azure/architecture/guide/networking/private-link-virtual-wan-dns-guide"
    },
    {
      "summary": "Learn about automated scheduling and dispatching for semiconductor manufacturing workloads on Azure.",
      "azureCategories": [
        "ai-machine-learning",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/fab-scheduling.png",
      "title": "Automate scheduling and dispatching for semiconductor manufacturing",
      "lastModified": "2023-04-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-virtual-network",
        "azure-files",
        "azure-container-registry"
      ],
      "url": "/azure/architecture/example-scenario/manufacturing/fab-scheduling-azure"
    },
    {
      "summary": "This article describes a pattern for exposing shared services, such as DNS, in a hub-and-spoke topology that uses Azure Virtual WAN.",
      "azureCategories": [
        "networking",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/dns-private-endpoints-virtual-wan-baseline-architecture.png",
      "title": "Virtual hub extension pattern",
      "lastModified": "2023-04-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-private-link",
        "azure-dns",
        "azure-firewall",
        "azure-virtual-wan"
      ],
      "url": "/azure/architecture/guide/networking/private-link-virtual-wan-dns-virtual-hub-extension-pattern"
    },
    {
      "summary": "Learn which options and scenarios are possible to design and implement an effective multi-region BCDR strategy for Azure Virtual Desktop.",
      "azureCategories": [
        "compute",
        "hybrid",
        "windows-virtual-desktop"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-virtual-desktop-bcdr-pooled-host-pool.png",
      "title": "Multiregion Business Continuity and Disaster Recovery (BCDR) for Azure Virtual Desktop",
      "lastModified": "2023-04-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-desktop"
      ],
      "url": "/azure/architecture/example-scenario/wvd/azure-virtual-desktop-multi-region-bcdr"
    },
    {
      "summary": "Use Azure Digital Twins to create models of smart places from IoT device data. View and control products, systems, environments, and experiences.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/smart-places-diagram-thumbnail-new.png",
      "title": "Create smart places by using Azure Digital Twins",
      "lastModified": "2023-04-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-explorer",
        "azure-data-factory",
        "azure-digital-twins",
        "azure-functions",
        "azure-iot-hub"
      ],
      "url": "/azure/architecture/example-scenario/iot/smart-places"
    },
    {
      "summary": "Deploy and operate a solution that uses OHDSI and the OMAP CDM to analyze observational patient data.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ohdsi-azure.png",
      "title": "Analyze observational patient data by using OHDSI with the OMOP CDM",
      "lastModified": "2023-04-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-pipelines",
        "azure-sql-database",
        "azure-vm-scalesets",
        "azure-blob-storage",
        "azure-container-registry"
      ],
      "url": "/azure/architecture/example-scenario/digital-health/patient-data-ohdsi-omop-cdm"
    },
    {
      "summary": "Learn how to use a lift-and-shift approach to migrate an HP-UX workload to Azure. The architecture uses Charon-PAR emulator software.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/charon-par-hp-ux.png",
      "title": "Run HP-UX workloads in Azure with Stromasys Charon-PAR",
      "lastModified": "2023-04-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-expressroute",
        "azure-storage",
        "azure-files"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/hp-ux-stromasys-charon-par"
    },
    {
      "summary": "Read about high availability for multitier application deployment in AKS clusters, including a checklist to identify and eliminate points of failure.",
      "azureCategories": [
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/replicas.png",
      "title": "High availability for multitier AKS applications",
      "lastModified": "2023-04-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-disk-storage",
        "azure-files",
        "azure-kubernetes-service",
        "azure-load-balancer"
      ],
      "url": "/azure/architecture/guide/aks/aks-high-availability"
    },
    {
      "summary": "Use Profisee to migrate from SQL Server Master Data Services to a full-featured master data management (MDM) platform in the Azure cloud.",
      "azureCategories": [
        "databases",
        "management-and-governance",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/profisee-master-data-management-microsoft-purview.png",
      "title": "Use Profisee MDM to migrate SQL Server Master Data Services to Azure",
      "lastModified": "2023-04-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-sql-database",
        "azure-synapse-analytics",
        "microsoft-purview"
      ],
      "url": "/azure/architecture/databases/architecture/profisee-mds-migration-utility"
    },
    {
      "summary": "Learn how to implement a compliance risk analysis solution that's based on real-world experience. It uses Azure Cognitive Search and Azure Cognitive Services.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/compliance-risk-analysis-diagram-solution-architecture-thumb.png",
      "title": "Compliance risk analysis by using Azure Cognitive Search",
      "lastModified": "2023-04-18T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cognitive-search",
        "ai-services",
        "ms-graph"
      ],
      "url": "/azure/architecture/guide/ai/compliance-risk-analysis"
    },
    {
      "summary": "Reduce repetition, model best practices, and have consistent standard deployments by modularizing your Azure Resource Manager templates.",
      "azureCategories": [
        "containers",
        "devops",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/enterprise-infrastructure-bicep-container-registry-thumbnail.png",
      "title": "Enterprise infrastructure as code using Bicep and Azure Container Registry",
      "lastModified": "2023-04-18T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-registry",
        "azure-devops",
        "azure-kubernetes-service",
        "azure-resource-manager",
        "azure-policy"
      ],
      "url": "/azure/architecture/guide/azure-resource-manager/advanced-templates/enterprise-infrastructure-bicep-container-registry"
    },
    {
      "summary": "A discussion of how best to configure your environment for Windows Server Update Services (WSUS).",
      "azureCategories": [
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/wsus-vnet.png",
      "title": "Plan deployment for updating Windows VMs in Azure",
      "lastModified": "2023-04-18T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-firewall",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/wsus/"
    },
    {
      "summary": "Learn how to use DevSecOps for IaC to securely deploy cloud infrastructure into a new Azure landing zone.",
      "azureCategories": [
        "devops",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/devsecops-for-iac-diagram.png",
      "title": "DevSecOps for infrastructure as code (IaC)",
      "lastModified": "2023-04-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "microsoft-sentinel",
        "azure-monitor",
        "github"
      ],
      "url": "/azure/architecture/solution-ideas/articles/devsecops-infrastructure-as-code"
    },
    {
      "summary": "Learn about options and best practices for spoke-to-spoke communication in Azure Virtual Network designs.",
      "azureCategories": [
        "networking",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/virtual-network-manager-connectivity-options.png",
      "title": "Spoke-to-spoke networking",
      "lastModified": "2023-04-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-firewall",
        "azure-virtual-network",
        "azure-virtual-wan",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/networking/spoke-to-spoke-networking"
    },
    {
      "summary": "Deploy high-quality speech-aware applications that use AI. This article provides details about how to deploy the solution described in part one of this guide.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/custom-speech-text-deploy-thumb.png",
      "title": "Deploy a custom speech-to-text solution",
      "lastModified": "2023-04-12T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "ai-services",
        "azure-speech",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/ai-ml/guide/custom-speech-text-deploy"
    },
    {
      "summary": "See how to achieve enterprise-grade logging with a common logging method that enables end-to-end traceability across different applications.",
      "azureCategories": [
        "databases",
        "developer-tools",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/enterprise-grade-logging.png",
      "title": "Enterprise-grade logging on Azure",
      "lastModified": "2023-04-12T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-explorer",
        "azure-event-hubs",
        "azure-functions"
      ],
      "url": "/azure/architecture/reference-architectures/logging/enterprise-grade-logging"
    },
    {
      "summary": "A hub-spoke reference architecture.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hub-spoke-vwan-architecture-001.png",
      "title": "Hub-spoke network topology with Azure Virtual WAN",
      "lastModified": "2023-04-12T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-wan"
      ],
      "url": "/azure/architecture/networking/hub-spoke-vwan-architecture"
    },
    {
      "summary": "Find out how OpenFOAM computational fluid dynamics (CFD) software performs on an Azure virtual machine.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/openfoam-thumbnail.png",
      "title": "Deploy OpenFOAM on a virtual machine",
      "lastModified": "2023-04-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/openfoam"
    },
    {
      "summary": "See an architecture that protects backend APIs by using API Management and Azure AD B2C to validate bearer tokens. The APIs can be in Azure or other environments.",
      "azureCategories": [
        "security",
        "identity",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/protect-backend-apis-azure-management.png",
      "title": "Protect backend APIs by using Azure API Management and Azure AD B2C",
      "lastModified": "2023-04-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-api-management",
        "entra",
        "entra-external-id",
        "azure-front-door"
      ],
      "url": "/azure/architecture/solution-ideas/articles/protect-backend-apis-azure-management"
    },
    {
      "summary": "Elastic Enterprise Search streamlines search for all work documents and data. Learn how to deploy it and optimize productivity.",
      "azureCategories": [
        "developer-tools",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/elastic-workplace-search.png",
      "title": "Elastic Enterprise Search on Azure",
      "lastModified": "2023-04-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-storage",
        "azure-virtual-machines",
        "azure-app-service",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/solution-ideas/articles/elastic-workplace-search"
    },
    {
      "summary": "Learn how to use AI and machine learning to forecast a customer's future order quantity for a specific SKU.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/order-forecasting.png",
      "title": "Use AI to forecast customer orders",
      "lastModified": "2023-04-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning",
        "azure-synapse-analytics",
        "azure-data-lake",
        "azure-sql-database",
        "power-platform",
        "power-apps"
      ],
      "url": "/azure/architecture/solution-ideas/articles/next-order-forecasting"
    },
    {
      "summary": "Enable machine learning inference on an IoT Edge device. Scenarios include image classification, object detection, and body, face, and gesture analysis.",
      "azureCategories": [
        "iot",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/inference-iot-edge-thumb.png",
      "title": "Enable machine learning inference on an Azure IoT Edge device",
      "lastModified": "2023-04-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot-edge",
        "azure-iot-hub"
      ],
      "url": "/azure/architecture/guide/iot/machine-learning-inference-iot-edge"
    },
    {
      "summary": "Integrate the Profisee master data management platform with Microsoft Purview to govern and manage data. Produce and deliver high-quality, trusted data.",
      "azureCategories": [
        "databases",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/profisee-master-data-management-microsoft-purview.png",
      "title": "Data governance with Profisee and Microsoft Purview",
      "lastModified": "2023-04-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-kubernetes-service",
        "azure-synapse-analytics",
        "microsoft-purview",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/databases/architecture/profisee-master-data-management-purview"
    },
    {
      "summary": "Learn about approaches for implementing high-quality speech-aware applications that use AI. This article describes the problem space and decision-making process.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/speech-text-thumb.png",
      "title": "Implement custom speech-to-text",
      "lastModified": "2023-04-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "ai-services",
        "azure-speech",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/ai-ml/guide/custom-speech-text"
    },
    {
      "summary": "Use Azure SignalR Service to update cloud applications. This service sends real-time IoT data to clients like web pages and mobile apps.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/real-time-iot-updates-cloud-apps-thumbnail.png",
      "title": "Real-time IoT updates",
      "lastModified": "2023-04-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-functions",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-maps",
        "azure-signalr-service"
      ],
      "url": "/azure/architecture/example-scenario/iot/real-time-iot-updates-cloud-apps"
    },
    {
      "summary": "Learn about deploying secure applications using the Azure App Service Environment, the Azure Application Gateway service, and Web Application Firewall.",
      "azureCategories": [
        "security",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/fully-managed-secure-apps-thumb.png",
      "title": "Securely managed web applications",
      "lastModified": "2023-03-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-application-gateway",
        "azure-sql-database",
        "azure-vpn-gateway",
        "azure-web-application-firewall"
      ],
      "url": "/azure/architecture/example-scenario/apps/fully-managed-secure-apps"
    },
    {
      "summary": "Collect, process, and store geospatial data by using managed Azure services. Make the data available through web apps. Visualize, explore, and analyze the data.",
      "azureCategories": [
        "analytics",
        "databases",
        "integration",
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/geospatial-data-processing-analytics-azure-architecture-thumbnail-new.png",
      "title": "Geospatial data processing and analytics",
      "lastModified": "2023-03-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-data-lake-storage",
        "azure-database-postgresql",
        "azure-databricks",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/example-scenario/data/geospatial-data-processing-analytics-azure"
    },
    {
      "summary": "Use Azure services such as Azure Form Recognizer, Azure Logic Apps, and Azure Functions to implement cost-effective and flexible automated PDF processing.",
      "azureCategories": [
        "media"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/automate-pdf-forms-processing.png",
      "title": "Automate PDF forms processing",
      "lastModified": "2023-03-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "document-intelligence",
        "ai-services",
        "azure-logic-apps",
        "azure-functions"
      ],
      "url": "/azure/architecture/ai-ml/architecture/automate-pdf-forms-processing"
    },
    {
      "summary": "Learn about a highly scalable architecture for ingesting metadata from external catalogs into Microsoft Purview.",
      "azureCategories": [
        "databases",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/metadata-ingestion-thumb.png",
      "title": "Ingest metadata from external catalogs to Microsoft Purview",
      "lastModified": "2023-03-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "microsoft-purview",
        "azure",
        "azure-functions",
        "azure-event-hubs",
        "azure-table-storage",
        "azure-monitor"
      ],
      "url": "/azure/architecture/solution-ideas/articles/sync-framework-metadata-ingestion"
    },
    {
      "summary": "This example architecture shows an end-to-end approach for an automotive original equipment manufacturer (OEM) and includes a reference architecture and several published supporting open-source libraries that can be reused.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/automated-guided-vehicles-fleet-control-01.png",
      "title": "Automated guided vehicles fleet control",
      "lastModified": "2023-03-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-container-apps",
        "azure-container-registry",
        "azure-iot-hub",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/iot/automated-guided-vehicles-fleet-control"
    },
    {
      "summary": "Create a highly available SQL Managed Instance design by using customer-managed keys stored in Azure Key Vault.",
      "azureCategories": [
        "security",
        "databases",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sql-managed-instance-cmk.png",
      "title": "SQL Managed Instance with customer-managed keys",
      "lastModified": "2023-03-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-sql-managed-instance",
        "azure-key-vault",
        "azure-private-link"
      ],
      "url": "/azure/architecture/databases/architecture/sql-managed-instance-cmk"
    },
    {
      "summary": "This article describes approaches to consider for cost management and cost allocation in a multitenant solution.",
      "azureCategories": [
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/tags.png",
      "title": "Architectural approaches for cost management and allocation in a multitenant solution",
      "lastModified": "2023-03-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cost-management",
        "azure-resource-manager",
        "azure-monitor"
      ],
      "url": "/azure/architecture/guide/multitenant/approaches/cost-management-allocation"
    },
    {
      "summary": "This article describes approaches to consider when deploying and configuring a multitenant solution.",
      "azureCategories": [
        "management-and-governance",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/example-data-pipeline.png",
      "title": "Architectural approaches for the deployment and configuration of multitenant solutions",
      "lastModified": "2023-03-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-devops",
        "azure-pipelines",
        "ms-website",
        "azure-marketplace",
        "github"
      ],
      "url": "/azure/architecture/guide/multitenant/approaches/deployment-configuration"
    },
    {
      "summary": "Deploy SWIFT Alliance Lite2 on Azure. Migrate an existing deployment from on-premises or create a new deployment.",
      "azureCategories": [
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/diagrams-swift-alliance-lite2-azure.png",
      "title": "SWIFT Alliance Lite2 on Azure",
      "lastModified": "2023-03-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-network",
        "azure-firewall",
        "azure-policy",
        "azure-bastion",
        "azure-expressroute"
      ],
      "url": "/azure/architecture/example-scenario/finance/swift-alliance-lite2-on-azure"
    },
    {
      "summary": "Run SWIFT Alliance Messaging Hub (AMH) on Azure. Use this messaging solution with the Alliance Connect Virtual networking solution, which also runs on Azure.",
      "azureCategories": [
        "integration",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/alliance-messaging-hub-alliance-connect-virtual.png",
      "title": "SWIFT Alliance Messaging Hub (AMH) with Alliance Connect Virtual",
      "lastModified": "2023-03-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-managed-disks",
        "azure-load-balancer",
        "azure-firewall"
      ],
      "url": "/azure/architecture/example-scenario/finance/swift-alliance-messaging-hub-vsrx"
    },
    {
      "summary": "Deploy Azure infrastructure for SWIFT Alliance Cloud.",
      "azureCategories": [
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/swift-alliance-cloud-azure.png",
      "title": "SWIFT Alliance Cloud on Azure",
      "lastModified": "2023-03-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-network",
        "azure-firewall",
        "azure-policy",
        "azure-bastion",
        "azure-expressroute"
      ],
      "url": "/azure/architecture/example-scenario/finance/swift-alliance-cloud-on-azure"
    },
    {
      "summary": "Use Durable Functions, a feature of Azure Functions, as an automated document processing pipeline to classify documents by type.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/automate-document-classification-durable-functions-thumb.png",
      "title": "Automate document identification, classification, and search by using Durable Functions",
      "lastModified": "2023-03-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-functions",
        "azure-app-service",
        "ai-services",
        "azure-cognitive-search",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/ai-ml/architecture/automate-document-classification-durable-functions"
    },
    {
      "summary": "Learn how to deploy line-of-business applications with an internal App Service Environment v3, while considering networking boundaries, DevOps, management, and more.",
      "azureCategories": [
        "compute",
        "security",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-line-of-business-internal-app-service-environment-v3.png",
      "title": "Deploy a line-of-business application using Azure App Service Environment v3",
      "lastModified": "2023-03-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-dns",
        "azure-monitor",
        "azure-log-analytics",
        "azure-key-vault"
      ],
      "url": "/azure/architecture/example-scenario/apps/line-of-business-internal-app-service-environment-v3"
    },
    {
      "summary": "Deploy and run SWIFT Alliance Access with Alliance Connect Virtual on Azure.",
      "azureCategories": [
        "integration",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/alliance-access-with-alliance-connect-virtual.png",
      "title": "SWIFT Alliance Access with Alliance Connect Virtual on Azure",
      "lastModified": "2023-03-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-managed-disks",
        "azure-load-balancer",
        "azure-firewall"
      ],
      "url": "/azure/architecture/example-scenario/finance/swift-alliance-access-vsrx-on-azure"
    },
    {
      "summary": "See an example solution for reliable and resilient Docker image deployment over limited, intermittent, or low bandwidth.",
      "azureCategories": [
        "devops",
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/efficient-docker-image-deployment-01.png",
      "title": "Efficient Docker image deployment for low-bandwidth connectivity",
      "lastModified": "2023-03-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-registry",
        "azure-functions",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-pipelines"
      ],
      "url": "/azure/architecture/example-scenario/iot/efficient-docker-image-deployment"
    },
    {
      "summary": "Build an Azure Cognitive Search index by using file content from Azure Blob Storage and metadata from Azure Table Storage.",
      "azureCategories": [
        "databases",
        "ai-machine-learning",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/search-blob-metadata-thumb.png",
      "title": "Index file content and metadata by using Azure Cognitive Search",
      "lastModified": "2023-03-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cognitive-search",
        "azure-blob-storage",
        "azure-table-storage"
      ],
      "url": "/azure/architecture/ai-ml/architecture/search-blob-metadata"
    },
    {
      "summary": "Learn how you can use Azure Lab Services to set up identical VMs from templates, for use in training, customer demos, and software development.",
      "azureCategories": [
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/teacher-provisioned-virtual-labs-azure.png",
      "title": "Teacher-provisioned virtual labs in Azure",
      "lastModified": "2023-03-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-lab-services",
        "azure-virtual-machines",
        "entra",
        "entra-id"
      ],
      "url": "/azure/architecture/example-scenario/devops/teacher-provisioned-virtual-labs-azure"
    },
    {
      "summary": "This example scenario shows how using a dedicated search service can dramatically increase the relevance of search results for your e-commerce customers.",
      "azureCategories": [
        "web",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-ecommerce-search.png",
      "title": "Intelligent product search engine for e-commerce",
      "lastModified": "2023-03-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-bot-service",
        "azure-cognitive-search",
        "ai-services",
        "azure-sql-database",
        "azure-app-service"
      ],
      "url": "/azure/architecture/example-scenario/apps/ecommerce-search"
    },
    {
      "summary": "Use Luminex products to transfer mainframe file and tape data to and from Azure to meet backup, archival, and other business needs.",
      "azureCategories": [
        "migration",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/luminex-mainframe-file-tape-transfer-thumbnail.png",
      "title": "Mainframe file and tape backup to Azure using Luminex",
      "lastModified": "2023-03-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-expressroute",
        "azure-sql-database",
        "azure-storage",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/luminex-mainframe-file-tape-transfer"
    },
    {
      "summary": "Subscription vending implementation guidance to standardize the process for automating subscription creation and deploying workloads faster.",
      "azureCategories": [
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sample-subscription-vending-architecture.png",
      "title": "Subscription vending implementation guidance",
      "lastModified": "2023-03-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/landing-zones/subscription-vending"
    },
    {
      "summary": "Implement a hierarchy service so business stakeholders can centrally define the organization of production assets like machines within factories.",
      "azureCategories": [
        "compute",
        "iot",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/connected-factory-hierarchy-service-01.png",
      "title": "Connected factory hierarchy service",
      "lastModified": "2023-03-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-data-explorer",
        "azure-digital-twins",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/solution-ideas/articles/connected-factory-hierarchy-service"
    },
    {
      "summary": "View a reference architecture to enable geospatial workloads on Azure by using Azure Synapse.",
      "azureCategories": [
        "analytics",
        "ai-machine-learning",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/geospatial-processing-analytics-architecture.png",
      "title": "Spaceborne data analysis with Azure Synapse Analytics",
      "lastModified": "2023-03-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-synapse-analytics",
        "ai-services",
        "azure-computer-vision",
        "azure-batch",
        "azure-storage"
      ],
      "url": "/azure/architecture/industries/aerospace/geospatial-processing-analytics"
    },
    {
      "summary": "Learn how to build, deploy, and monitor a machine learning model for employee attrition that can be integrated with external applications using Databricks and Kubernetes.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/employee-retention-databricks-kubernetes.png",
      "title": "Employee retention with Databricks and Kubernetes",
      "lastModified": "2023-03-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-databricks",
        "azure-kubernetes-service",
        "azure-container-registry",
        "azure-storage",
        "azure-monitor"
      ],
      "url": "/azure/architecture/example-scenario/ai/employee-retention-databricks-kubernetes"
    },
    {
      "summary": "Learn how to use Azure Quantum to implement hybrid quantum applications",
      "azureCategories": [
        "compute",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cicd-for-quantum-computing-jobs.png",
      "title": "Quantum computing integration with classical apps",
      "lastModified": "2023-03-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-quantum",
        "azure-key-vault",
        "entra",
        "entra-id"
      ],
      "url": "/azure/architecture/example-scenario/quantum/quantum-computing-integration-with-classical-apps"
    },
    {
      "summary": "Use confidential computing on Kubernetes to run big data analytics with Apache Spark inside confidential containers that are protected by Intel Software Guard Extensions with data from Azure Data Lake and Azure SQL Database.",
      "azureCategories": [
        "analytics",
        "databases",
        "containers",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/data-analytics-containers-spark-kubernetes-azure-sql.png",
      "title": "Big data analytics on confidential computing with Apache Spark on Kubernetes",
      "lastModified": "2023-03-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-sql-database",
        "azure-data-lake"
      ],
      "url": "/azure/architecture/example-scenario/confidential/data-analytics-containers-spark-kubernetes-azure-sql"
    },
    {
      "summary": "Learn how to use a high-performance computing (HPC) environment on Azure to run CAE simulations for manufacturing.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/run-cae-workloads-on-azure.png",
      "title": "Run CAE simulations for manufacturing on Azure",
      "lastModified": "2023-03-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/hpc/hpc-run-cae-simulations"
    },
    {
      "summary": "Build an example workload architecture that uses Azure services to process and maintain data used by sports analytics solutions.",
      "azureCategories": [
        "analytics",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sports-analytics-azure-reference-architecture.png",
      "title": "Build a sports analytics architecture on Azure",
      "lastModified": "2023-03-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-data-lake-storage",
        "azure-databricks",
        "azure-event-hubs",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/example-scenario/analytics/sports-analytics-architecture-azure"
    },
    {
      "summary": "Learn how to build a telehealth system, using the Azure cloud platform, that connects a professional healthcare organization to its remote patients.",
      "azureCategories": [
        "containers",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-telehealth-system-thumbnail.png",
      "title": "Build a telehealth system on Azure",
      "lastModified": "2023-03-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-database-postgresql",
        "azure-functions",
        "azure-kubernetes-service",
        "azure-storage",
        "azure-traffic-manager"
      ],
      "url": "/azure/architecture/example-scenario/apps/telehealth-system"
    },
    {
      "summary": "Use edge devices in your IoT implementation to provide quick response, high availability, and high bandwidth.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/data-storage-edge-thumbnail.png",
      "title": "IoT Edge data storage and processing",
      "lastModified": "2023-03-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-sql-edge"
      ],
      "url": "/azure/architecture/solution-ideas/articles/data-storage-edge"
    },
    {
      "summary": "Learn how the activity of one tenant can impact the performance of other tenants in a multitenant system.",
      "azureCategories": [
        "management-and-governance",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/noisy-neighbor-single.png",
      "title": "Noisy Neighbor antipattern",
      "lastModified": "2023-03-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/antipatterns/noisy-neighbor/noisy-neighbor"
    },
    {
      "summary": "Use Azure network security capabilities, such as virtual networks, network peering, Private Link, and private DNS zones to protect MLOps solutions.",
      "azureCategories": [
        "ai-machine-learning",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/network-security-mlops-thumbnail.svg",
      "title": "Secure MLOps solutions with Azure network security",
      "lastModified": "2023-03-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-devops",
        "azure-dns",
        "azure-machine-learning",
        "azure-private-link",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/ai-ml/guide/network-security-mlops"
    },
    {
      "summary": "This article describes the features of Azure App Service and Azure Functions that are useful when working with multitenanted systems, and links to guidance and examples for how to use Azure App Service and Azure Functions in a multitenant solution.",
      "azureCategories": [
        "web",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/host-front-door.png",
      "title": "Azure App Service and Azure Functions considerations for multitenancy",
      "lastModified": "2023-03-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-functions"
      ],
      "url": "/azure/architecture/guide/multitenant/service/app-service"
    },
    {
      "summary": "Learn about an architecture that you can use to build automated driving solution by using Azure services.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics",
        "compute",
        "databases",
        "containers",
        "devops",
        "security",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/avops-architecture-thumb.png",
      "title": "Create an Autonomous Vehicle Operations (AVOps) solution",
      "lastModified": "2023-03-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning",
        "azure-data-factory",
        "azure-data-share",
        "azure-batch",
        "github"
      ],
      "url": "/azure/architecture/solution-ideas/articles/avops-architecture"
    },
    {
      "summary": "This article describes approaches to support multitenancy for the storage and data components of your solution.",
      "azureCategories": [
        "storage",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/shared-database.png",
      "title": "Architectural approaches for storage and data in multitenant solutions",
      "lastModified": "2023-03-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-sql-database",
        "azure-storage"
      ],
      "url": "/azure/architecture/guide/multitenant/approaches/storage-data"
    },
    {
      "summary": "Learn techniques for using GitOps principles to operate and manage an Azure Kubernetes Services (AKS) cluster. The solutions use Flux v2 and Argo CD.",
      "azureCategories": [
        "containers",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/gitops-flux.png",
      "title": "GitOps for Azure Kubernetes Service",
      "lastModified": "2023-03-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "github"
      ],
      "url": "/azure/architecture/example-scenario/gitops-aks/gitops-blueprint-aks"
    },
    {
      "summary": "Implement a No Token in the Browser architecture for a JavaScript single-page application using API Management.",
      "azureCategories": [
        "identity",
        "security",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/no-token.png",
      "title": "Protect access tokens in a single-page application by using Azure API Management",
      "lastModified": "2023-03-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-api-management",
        "entra",
        "entra-id",
        "static-web-apps"
      ],
      "url": "/azure/architecture/web-apps/guides/security/secure-single-page-application-authorization"
    },
    {
      "summary": "Learn how Autodesk Civil 3D for civil infrastructure design performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hpc-civil-3d-thumb.png",
      "title": "Deploy Autodesk Civil 3D on an Azure virtual machine",
      "lastModified": "2023-03-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/civil-3d"
    },
    {
      "summary": "Learn how DevSecOps helps you incorporate security best practices from the start of software development.",
      "azureCategories": [
        "devops",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/devsecops-azure-aks.png",
      "title": "DevSecOps on Azure Kubernetes Service (AKS)",
      "lastModified": "2023-03-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-boards",
        "azure-devops",
        "azure-monitor",
        "azure-pipelines",
        "azure-policy"
      ],
      "url": "/azure/architecture/guide/devsecops/devsecops-on-aks"
    },
    {
      "summary": "Learn about how to perform data scrambling for SAP applications using Delphix and Azure Data Factory or Azure Synapse Analytics.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/delphix-continuous-compliance-architecture-thumb.png",
      "title": "Data scrambling for SAP applications using Delphix and Azure Data Factory",
      "lastModified": "2023-03-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/databases/guide/data-scrambling-for-sap-using-delphix-and-azure-data-factory"
    },
    {
      "summary": "Use this chart and other information to decide which compute service, or hosting model for computing resources, best suits your application.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/compute-choices.png",
      "title": "Choose an Azure compute service",
      "lastModified": "2023-03-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/guide/technology-choices/compute-decision-tree"
    },
    {
      "summary": "Create content-based recommendation systems that use Azure Machine Learning and Databricks to deliver personalized recommendations.",
      "azureCategories": [
        "ai-machine-learning",
        "featured"
      ],
      "thumbnailUrl": "/azure/architecture/solution-ideas/media/build-content-based-recommendation-system-using-recommender-thumbnail.svg",
      "title": "Build a content-based recommendation system",
      "lastModified": "2023-03-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-databricks",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/solution-ideas/articles/build-content-based-recommendation-system-using-recommender"
    },
    {
      "summary": "Find out how mLogica LIBER*IRIS provides a proven solution for bulk data migration from a mainframe to Azure for enterprise workloads.",
      "azureCategories": [
        "databases",
        "devops",
        "migration",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mlogica-mainframe-data-migration-architecture-thumbnail.png",
      "title": "Migrate mainframe data tier to Azure with mLogica LIBER*IRIS",
      "lastModified": "2023-03-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-database-mysql",
        "azure-database-postgresql",
        "azure-cosmos-db",
        "azure-sql-database",
        "azure-storage"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/mainframe-data-replication-azure-data-platform"
    },
    {
      "summary": "Learn how to implement real-time monitoring and observability of systems and end-user device telemetry data.",
      "azureCategories": [
        "media",
        "ai-machine-learning",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/monitoring-media-thumb.png",
      "title": "Build real-time monitoring and observable systems for media",
      "lastModified": "2023-03-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-explorer",
        "azure-functions",
        "azure-metrics-advisor",
        "azure-blob-storage",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/example-scenario/monitoring/monitoring-observable-systems-media"
    },
    {
      "summary": "Learn about how to perform data obfuscation using Delphix in Azure Data Factory.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/delphix-continuous-compliance-architecture-thumb.png",
      "title": "Data obfuscation on Azure with Delphix and Azure Data Factory",
      "lastModified": "2023-03-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/databases/guide/data-obfuscation-with-delphix-in-azure-data-factory"
    },
    {
      "summary": "This article introduces the approaches you can consider when planning a multitenant architecture.",
      "azureCategories": [
        "management-and-governance",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/deployment-stamps.png",
      "title": "Architectural approaches for a multitenant solution",
      "lastModified": "2023-02-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/guide/multitenant/approaches/overview"
    },
    {
      "summary": "This article describes the considerations you need to give to domain names when building multitenant web applications.",
      "azureCategories": [
        "management-and-governance",
        "networking",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/subdomains-multiple-stem.png",
      "title": "Considerations when using domain names in a multitenant solution",
      "lastModified": "2023-02-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/guide/multitenant/considerations/domain-names"
    },
    {
      "summary": "See a connected factory signal processing pipeline with a common configuration interface to connect brownfield and modern IoT devices through an OPC UA gateway.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/connected-factory-signal-pipeline-01.png",
      "title": "Connected factory signal processing pipeline",
      "lastModified": "2023-02-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-kubernetes-service",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/example-scenario/iot/connected-factory-signal-pipeline"
    },
    {
      "summary": "This article introduces the key considerations when planning a multitenant architecture.",
      "azureCategories": [
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/deployment-stamps.png",
      "title": "Architectural considerations for a multitenant solution",
      "lastModified": "2023-02-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/guide/multitenant/considerations/overview"
    },
    {
      "summary": "Learn how to use the Application Gateway Ingress Controller (AGIC) with your Azure Kubernetes Service (AKS) cluster to expose microservice-based applications to the internet.",
      "azureCategories": [
        "containers",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-agic.png",
      "title": "Use Application Gateway Ingress Controller (AGIC) with a multitenant Azure Kubernetes Service",
      "lastModified": "2023-02-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway",
        "azure-key-vault",
        "azure-container-registry",
        "azure-kubernetes-service",
        "azure-bastion"
      ],
      "url": "/azure/architecture/example-scenario/aks-agic/aks-agic"
    },
    {
      "summary": "Learn how to implement resilient NetApp file shares. Failure of the primary Azure region causes automatic failover to the secondary Azure region.",
      "azureCategories": [
        "networking",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/enterprise-file-shares-disaster-recovery.png",
      "title": "Enterprise file shares with disaster recovery",
      "lastModified": "2023-02-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-netapp-files",
        "entra",
        "windows",
        "windows-server"
      ],
      "url": "/azure/architecture/example-scenario/file-storage/enterprise-file-shares-disaster-recovery"
    },
    {
      "summary": "Learn about a solution that uses behavioral profiles and machine learning to detect mobile bank fraud.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/bank-fraud-solution-high-level-thumb.png",
      "title": "Detect mobile bank fraud",
      "lastModified": "2023-02-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning",
        "azure-synapse-analytics",
        "azure-event-hubs",
        "azure-key-vault",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/guide/ai/bank-fraud-solution"
    },
    {
      "summary": "Learn about various multitenancy models and about how to choose the best one for your scenario.",
      "azureCategories": [
        "management-and-governance",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/map-logical-physical.png",
      "title": "Tenancy models for a multitenant solution",
      "lastModified": "2023-02-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/guide/multitenant/considerations/tenancy-models"
    },
    {
      "summary": "By orchestrating the deployment of containers using Azure Kubernetes Service (AKS), you can achieve replicable, manageable clusters of containers.",
      "azureCategories": [
        "devops",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/container-cicd-using-jenkins-and-kubernetes-on-azure-container-service.png",
      "title": "Container CI/CD using Jenkins and Kubernetes on Azure Kubernetes Service (AKS)",
      "lastModified": "2023-02-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-registry",
        "azure-cosmos-db",
        "azure-kubernetes-service",
        "azure-monitor"
      ],
      "url": "/azure/architecture/solution-ideas/articles/container-cicd-using-jenkins-and-kubernetes-on-azure-container-service"
    },
    {
      "summary": "Learn how the AVL FIRE M computational fluid dynamics (CFD) simulation application performs on an Azure virtual machine.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/fire-m-cluster-architecture.png",
      "title": "Deploy AVL FIRE M on an Azure virtual machine",
      "lastModified": "2023-02-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-cyclecloud"
      ],
      "url": "/azure/architecture/guide/hpc/hpc-avl-fire-m"
    },
    {
      "summary": "Learn how business users and the operations team can create and maintain data pipelines by using configuration files that define the pipelines.",
      "azureCategories": [
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/configuration-driven-data-pipeline-architecture.png",
      "title": "Configuration-driven data pipeline",
      "lastModified": "2023-02-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-pipelines",
        "azure-event-hubs",
        "azure-data-factory",
        "azure-databricks",
        "azure-data-lake-storage"
      ],
      "url": "/azure/architecture/solution-ideas/articles/configuration-driven-data-pipeline"
    },
    {
      "summary": "Learn about running SAS Grid 9.4 on Azure, using Azure NetApp Files for SASDATA storage, and about storage options for SASWORK.",
      "azureCategories": [
        "analytics",
        "compute",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sas-azure-netapp-files-thumb.png",
      "title": "Deploy SAS Grid 9.4 on Azure NetApp Files",
      "lastModified": "2023-02-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-netapp-files",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/guide/hpc/netapp-files-sas"
    },
    {
      "summary": "Learn how Engys ELEMENTS computational fluid dynamics (CFD) software performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/engys-elements-thumb.png",
      "title": "Deploy Engys ELEMENTS on an Azure virtual machine",
      "lastModified": "2023-02-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-cyclecloud"
      ],
      "url": "/azure/architecture/guide/hpc/engys-elements"
    },
    {
      "summary": "Run IBM Sterling Order Management Software (OMS) on Azure. Design and implement cloud solutions for Sterling OMS that use Azure.",
      "azureCategories": [
        "compute",
        "containers",
        "storage",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/deploy-ibm-sterling-oms-thumbnail.png",
      "title": "Deploy IBM Sterling Order Management on Azure",
      "lastModified": "2023-02-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-database-postgresql",
        "azure-files",
        "azure-redhat-openshift",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/reference-architectures/ibm/deploy-ibm-sterling-oms"
    },
    {
      "summary": "Learn how Sandi HiFUN computational fluid dynamics (CFD) software performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hpc-sandi-hifun-thumb.png",
      "title": "Deploy Sandi HiFUN on an Azure virtual machine",
      "lastModified": "2023-02-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/hpc-sandi-hifun"
    },
    {
      "summary": "Build a scalable solution for batch scoring models on a schedule in parallel using Azure Machine Learning.",
      "azureCategories": [
        "ai-machine-learning",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/batch-scoring-python-thumbnail.png",
      "title": "Batch scoring of Python models on Azure",
      "lastModified": "2023-02-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-registry",
        "azure-event-hubs",
        "azure-machine-learning",
        "azure-sql-database",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/ai-ml/architecture/batch-scoring-python"
    },
    {
      "summary": "Give mainframe applications access to Azure data without changing code. Use Microsoft Service for DRDA to run Db2 SQL statements on a SQL Server database.",
      "azureCategories": [
        "databases",
        "integration",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mainframe-access-azure-databases-architecture-thumbnail.png",
      "title": "Mainframe and midrange Db2 applications accessing Azure SQL databases",
      "lastModified": "2023-02-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-sql-database",
        "azure-virtual-machines",
        "sql-server"
      ],
      "url": "/azure/architecture/solution-ideas/articles/mainframe-access-azure-databases"
    },
    {
      "summary": "Learn how to deploy AKS clusters using a blue-green strategy.",
      "azureCategories": [
        "containers",
        "devops",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/blue-green-aks-deployment-diagram-public-architecture-thumb.png",
      "title": "Blue-green deployment of AKS clusters",
      "lastModified": "2023-02-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-application-gateway",
        "azure-container-registry",
        "azure-front-door",
        "azure-dns"
      ],
      "url": "/azure/architecture/guide/aks/blue-green-deployment-for-aks"
    },
    {
      "summary": "Learn how to create an Azure CI/CD pipeline to manage your Power Platform application lifecycle.",
      "azureCategories": [
        "devops",
        "web",
        "featured"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/devops-cicd-for-power-apps.png",
      "title": "Build CI/CD with Azure for Microsoft Power Platform",
      "lastModified": "2023-02-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-monitor",
        "azure-devops",
        "azure-app-service",
        "power-platform",
        "power-apps",
        "azure-repos"
      ],
      "url": "/azure/architecture/solution-ideas/articles/azure-devops-continuous-integration-for-power-platform"
    },
    {
      "summary": "Learn about a custom collaboration solution that uses libraries and Azure services.",
      "azureCategories": [
        "identity",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/real-time-data-collaboration.png",
      "title": "Real-time collaboration with Azure and Microsoft 365",
      "lastModified": "2023-01-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "ms-graph",
        "azure",
        "azure-app-service",
        "azure-fluid-relay",
        "m365"
      ],
      "url": "/azure/architecture/solution-ideas/articles/collaboration-microsoft-365"
    },
    {
      "summary": "Build an end-to-end Customer 360 solution by using Azure Synapse Analytics and Dynamics 360 Customer Insights.",
      "azureCategories": [
        "analytics",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/customer-360-architecture.png",
      "title": "Customer 360 with Azure Synapse and Dynamics 365 Customer Insights",
      "lastModified": "2023-01-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "dynamics-365",
        "customer-insights-data",
        "azure",
        "azure-synapse-analytics",
        "azure-machine-learning",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/example-scenario/analytics/synapse-customer-insights"
    },
    {
      "summary": "Learn how Engys HELYX computational fluid dynamics (CFD) software performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/engys-helyx-thumb.png",
      "title": "Deploy Engys HELYX on an Azure virtual machine",
      "lastModified": "2023-01-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-cyclecloud"
      ],
      "url": "/azure/architecture/guide/hpc/engys-helyx"
    },
    {
      "summary": "Build and deploy a social medial analytics solution that uses Azure Synapse Analytics, Azure Machine Learning, and Azure Cognitive Services with external data.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics",
        "media"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/build-deploy-social-media-analytics-solution-thumbnail.png",
      "title": "Build and deploy a social media analytics solution",
      "lastModified": "2023-01-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "ai-services",
        "azure-synapse-analytics",
        "azure-machine-learning",
        "azure-data-lake",
        "power-platform",
        "azure-powerbi-embedded"
      ],
      "url": "/azure/architecture/ai-ml/architecture/build-deploy-social-media-analytics-solution"
    },
    {
      "summary": "Use Azure Machine Learning with the Ray-on-AML library to run and train donor-patient cross match models over FHIR and health data.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/donor-patient-match.png",
      "title": "Donor-patient matching on Azure Machine Learning",
      "lastModified": "2023-01-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-health-data-services",
        "azure-kubernetes-service",
        "azure-machine-learning",
        "power-platform",
        "power-apps"
      ],
      "url": "/azure/architecture/example-scenario/machine-learning/donor-patient-cross-match"
    },
    {
      "summary": "Use Azure Data Factory to extract data from source systems and update data in Azure Digital Twins by using batch pipelines.",
      "azureCategories": [
        "analytics",
        "integration",
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/batch-integration-azure-data-factory-digital-twins-thumbnail.png",
      "title": "Batch integration with Azure Data Factory for Azure Digital Twins",
      "lastModified": "2023-01-18T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-batch",
        "azure-data-factory",
        "azure-digital-twins",
        "azure-storage"
      ],
      "url": "/azure/architecture/example-scenario/iot/batch-integration-azure-data-factory-digital-twins"
    },
    {
      "summary": "Architecture for integrating a WAF-enabled web app into a hardened network environment that forces all inbound and outbound flows through a firewall appliance.",
      "azureCategories": [
        "networking",
        "security",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hardened-webapp-architecture-v2.png",
      "title": "Network-hardened web application with private connectivity to PaaS datastores",
      "lastModified": "2023-01-18T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-front-door",
        "azure-private-link",
        "azure-sql-database",
        "azure-firewall"
      ],
      "url": "/azure/architecture/example-scenario/security/hardened-web-app"
    },
    {
      "summary": "Use Azure Event Hubs, Azure Synapse Analytics, and Azure Data Lake Storage to create an end-to-end, near real-time data lakehouse data processing solution.",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/real-time-lakehouse-data-processing.png",
      "title": "Near real-time lakehouse data processing",
      "lastModified": "2023-01-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cognitive-search",
        "azure-cosmos-db",
        "azure-data-lake",
        "azure-event-hubs",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/example-scenario/data/real-time-lakehouse-data-processing"
    },
    {
      "summary": "Learn how to design and implement a baseline architecture for Microsoft Azure Kubernetes Service (AKS) running on Azure Stack HCI (AKS hybrid).",
      "azureCategories": [
        "hybrid",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-azure-stack-hci-baseline-v8.png",
      "title": "Baseline architecture for AKS on Azure Stack HCI",
      "lastModified": "2023-01-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-stack",
        "windows",
        "windows-server"
      ],
      "url": "/azure/architecture/example-scenario/hybrid/aks-baseline"
    },
    {
      "summary": "Learn how to design and implement network concepts for deploying Azure Kubernetes Service (AKS) nodes on AKS hybrid clusters.",
      "azureCategories": [
        "hybrid",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-network.png",
      "title": "Network architecture for AKS on Azure Stack HCI",
      "lastModified": "2023-01-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-stack",
        "windows",
        "windows-server"
      ],
      "url": "/azure/architecture/example-scenario/hybrid/aks-network"
    },
    {
      "summary": "Learn how to build image processing into your applications by using Azure services such as the Computer Vision API and Azure Functions.",
      "azureCategories": [
        "ai-machine-learning",
        "media"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-intelligent-apps-image-processing.png",
      "title": "Image classification on Azure",
      "lastModified": "2023-01-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-computer-vision",
        "azure-cosmos-db",
        "azure-event-grid",
        "azure-functions"
      ],
      "url": "/azure/architecture/ai-ml/architecture/intelligent-apps-image-processing"
    },
    {
      "summary": "Overview of SAP guidance and resources",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sap-overview-architecture-diagram.png",
      "title": "SAP on Azure architecture center",
      "lastModified": "2023-01-12T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/reference-architectures/sap/sap-overview"
    },
    {
      "summary": "Build scalable cloud applications by using performance modeling and other principles and practices of site reliability engineering (SRE).",
      "azureCategories": [
        "web",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/scalable-apps-performance-modeling-site-reliability.png",
      "title": "Scalable cloud applications and site reliability engineering (SRE)",
      "lastModified": "2023-01-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-front-door",
        "azure-api-management",
        "azure-kubernetes-service",
        "azure-application-gateway",
        "dynamics-365"
      ],
      "url": "/azure/architecture/example-scenario/apps/scalable-apps-performance-modeling-site-reliability"
    },
    {
      "summary": "Learn about a reference architecture that shows how to move data from mainframe and midrange systems to Azure.",
      "azureCategories": [
        "migration",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mainframe-export-archive-data.png",
      "title": "Move archive data from mainframe systems to Azure",
      "lastModified": "2023-01-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-storage",
        "azure-files",
        "azure-blob-storage",
        "azure-data-box-family"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/move-archive-data-mainframes"
    },
    {
      "summary": "View a reference architecture that shows how to deploy ArcGIS Pro in Azure Virtual Desktop to support the hyperscale of Azure.",
      "azureCategories": [
        "analytics",
        "azure-virtual-desktop",
        "databases",
        "identity"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/virtual-desktop-gis-azure-network.png",
      "title": "Deploy Esri ArcGIS Pro in Azure Virtual Desktop",
      "lastModified": "2023-01-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-desktop",
        "azure-netapp-files",
        "azure-monitor",
        "azure-policy",
        "entra",
        "entra-id"
      ],
      "url": "/azure/architecture/example-scenario/data/esri-arcgis-azure-virtual-desktop"
    },
    {
      "summary": "Use Azure Private Link to access PaaS services from a hub-and-spoke network. Connect to these services by using a private IP address from your virtual network.",
      "azureCategories": [
        "networking",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/private-link-hub-spoke-network-private-link-thumbnail.png",
      "title": "Azure Private Link in a hub-and-spoke network",
      "lastModified": "2023-01-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-private-link",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/networking/private-link-hub-spoke-network"
    },
    {
      "summary": "Protect web apps by implementing zero-trust security in virtual networks. Use Azure Firewall and Azure Application Gateway in networks to maximize protection.",
      "azureCategories": [
        "networking",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/application-gateway-before-azure-firewall-architecture-thumbnail.png",
      "title": "Zero-trust network for web applications with Azure Firewall and Application Gateway",
      "lastModified": "2023-01-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway",
        "azure-firewall",
        "azure-virtual-network",
        "azure-virtual-wan",
        "azure-web-application-firewall"
      ],
      "url": "/azure/architecture/example-scenario/gateway/application-gateway-before-azure-firewall"
    },
    {
      "summary": "This article describes how to use MSIX to enable CI/CD for building, deploying, and updating Windows desktop applications.",
      "azureCategories": [
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/devops-windows-architecture.png",
      "title": "CI/CD for Windows desktop apps",
      "lastModified": "2023-01-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-pipelines",
        "azure-storage",
        "azure-key-vault",
        "azure-devops",
        "github"
      ],
      "url": "/azure/architecture/solution-ideas/articles/azure-devops-ci-cd-for-desktop-apps"
    },
    {
      "summary": "Learn about using rules to allow for fine-grained routing of events in an IoT solution.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/event-routing.svg",
      "title": "IoT event routing",
      "lastModified": "2023-01-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot",
        "azure-iot-hub"
      ],
      "url": "/azure/architecture/example-scenario/iot/event-routing"
    },
    {
      "summary": "Learn what to avoid doing when moving from a test environment to a production environment.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/late-binding-dps.png",
      "title": "Move an IoT solution from test to production",
      "lastModified": "2023-01-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot-hub"
      ],
      "url": "/azure/architecture/example-scenario/iot/iot-move-to-production"
    },
    {
      "summary": "Learn how to use Azure IoT for environment monitoring and supply chain optimization.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/environment-monitor-supply-chain-thumbnail.png",
      "title": "Environment monitoring and supply chain optimization with IoT",
      "lastModified": "2023-01-05T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-functions",
        "azure-iot",
        "azure-storage",
        "azure-machine-learning",
        "azure-databricks",
        "azure-monitor"
      ],
      "url": "/azure/architecture/solution-ideas/articles/environment-monitoring-and-supply-chain-optimization"
    },
    {
      "summary": "Get started with Azure VMware Solution foundation block capacity planning. Understand how to size and plan for capacity needed to run workloads on Azure VMware Solution.",
      "azureCategories": [
        "hybrid",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-vmware-solution-sizing.png",
      "title": "Capacity planning considerations for Azure VMware Solution",
      "lastModified": "2023-01-05T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-vmware-solution",
        "azure-migrate"
      ],
      "url": "/azure/architecture/solution-ideas/articles/azure-vmware-solution-foundation-capacity"
    },
    {
      "summary": "Get started with Azure VMware Solution foundation block networking. Understand what networking solution options exist and how to choose between them.",
      "azureCategories": [
        "hybrid",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-vmware-solution-networking.png",
      "title": "Networking considerations for Azure VMware Solution",
      "lastModified": "2023-01-05T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-vmware-solution",
        "azure-expressroute",
        "azure-traffic-manager",
        "azure-application-gateway"
      ],
      "url": "/azure/architecture/solution-ideas/articles/azure-vmware-solution-foundation-networking"
    },
    {
      "summary": "Get started with Azure VMware Solution foundation block landing zone. Understand how Azure VMware Solution fits into a larger landing zone implementation.",
      "azureCategories": [
        "hybrid",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-vmware-solution-landing-zone.png",
      "title": "Landing zone considerations for Azure VMware Solution",
      "lastModified": "2023-01-05T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-vmware-solution"
      ],
      "url": "/azure/architecture/solution-ideas/articles/azure-vmware-solution-foundation-landing-zone"
    },
    {
      "summary": "Create great experiences for developers, support community engagement & contribution, and monetize your API offerings.",
      "azureCategories": [
        "web",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/design-api-developer-experiences-management-github.png",
      "title": "Design great API developer experiences using API Management and GitHub",
      "lastModified": "2023-01-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-private-link",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/web/design-api-developer-experiences-management-github"
    },
    {
      "summary": "Supplementary article to the AKS Baseline reference architecture providing guidance on running Windows containers on AKS",
      "azureCategories": [
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/secure-baseline-architecture.png",
      "title": "Running Windows containers on AKS",
      "lastModified": "2022-12-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway",
        "azure-container-registry",
        "azure-firewall",
        "azure-kubernetes-service",
        "azure-rbac"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks/windows-containers-on-aks"
    },
    {
      "summary": "Understand monitoring and logging for an Azure Kubernetes Service (AKS) cluster and workloads, and compare Amazon EKS and AKS monitoring and logging.",
      "azureCategories": [
        "containers",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/monitor-containers-architecture.png",
      "title": "Kubernetes monitoring and logging",
      "lastModified": "2022-12-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-log-analytics",
        "azure-monitor"
      ],
      "url": "/azure/architecture/aws-professional/eks-to-aks/monitoring"
    },
    {
      "summary": "Understand Kubernetes cluster and workload costs, see how to optimize and govern costs, and compare Azure Kubernetes Service (AKS) and Amazon EKS options.",
      "azureCategories": [
        "containers",
        "storage",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/control-plane-and-nodes.png",
      "title": "Cost management for Kubernetes",
      "lastModified": "2022-12-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cost-management",
        "azure-kubernetes-service",
        "azure-managed-disks",
        "azure-storage",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/aws-professional/eks-to-aks/cost-management"
    },
    {
      "summary": "Understand Kubernetes nodes and node pools, how to handle Azure Kubernetes Service (AKS) nodes and node pools, and node pool options for Amazon EKS and AKS.",
      "azureCategories": [
        "containers",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-node-resource-interactions.png",
      "title": "Kubernetes node and node pool management",
      "lastModified": "2022-12-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/aws-professional/eks-to-aks/node-pools"
    },
    {
      "summary": "Learn how to build reliable workloads on spot virtual machines.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/spot-vm-arch.png",
      "title": "How to build workloads on spot virtual machines",
      "lastModified": "2022-12-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/guide/spot/spot-eviction"
    },
    {
      "summary": "Understand networking options to securely access the Kubernetes API server, and compare options in Amazon EKS and Azure Kubernetes Service (AKS).",
      "azureCategories": [
        "containers",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/private-aks-cluster.png",
      "title": "Secure network access to Kubernetes",
      "lastModified": "2022-12-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-bastion",
        "azure-dns",
        "azure-kubernetes-service",
        "azure-private-link",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/aws-professional/eks-to-aks/private-clusters"
    },
    {
      "summary": "Migrate existing web apps to containers deployed in App Service, which allows you to focus on composing containers not worrying about managing container orchestration.",
      "azureCategories": [
        "migration",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/migrate-existing-applications-to-container-apps.png",
      "title": "Lift and shift to containers with Azure App Service",
      "lastModified": "2022-12-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-container-registry",
        "azure-app-service"
      ],
      "url": "/azure/architecture/web-apps/idea/migrate-existing-applications-to-container-apps"
    },
    {
      "summary": "This article describes the features of Azure Kubernetes Service (AKS) that are useful when working with multitenanted systems. It links to guidance and examples for how to use AKS in a multitenant solution.",
      "azureCategories": [
        "web",
        "compute",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-firewall.png",
      "title": "Azure Kubernetes Service (AKS) considerations for multitenancy",
      "lastModified": "2022-12-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/guide/multitenant/service/aks"
    },
    {
      "summary": "Learn how to implement a pipeline that moves data from an on-premises data warehouse into Azure Synapse and transforms the data for analysis.",
      "azureCategories": [
        "integration",
        "analytics",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/enterprise-bi-scoped-architecture.png",
      "title": "Enterprise business intelligence",
      "lastModified": "2022-12-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "power-platform",
        "power-bi",
        "azure",
        "azure-synapse-analytics",
        "azure-data-factory",
        "entra",
        "entra-id",
        "azure-blob-storage"
      ],
      "url": "/azure/architecture/example-scenario/analytics/enterprise-bi-synapse"
    },
    {
      "summary": "Learn how to define and implement a cross-tenant workload migration strategy to address business transformations like acquisitions or divestiture.",
      "azureCategories": [
        "migration",
        "management-and-governance",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/migrate-across-security-tenants-thumb.png",
      "title": "Migrate cloud workloads across security tenants",
      "lastModified": "2022-12-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-devops",
        "azure-resource-manager",
        "azure-backup"
      ],
      "url": "/azure/architecture/solution-ideas/articles/migrate-cloud-workloads-across-security-tenants"
    },
    {
      "summary": "Learn how to deploy a Distributed File System (DFS) Namespaces failover cluster that uses Azure virtual machines.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/dfs-failover-cluster-thumb.png",
      "title": "Deploy a DFS Namespaces failover cluster",
      "lastModified": "2022-12-22T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-disk-storage",
        "azure-virtual-network",
        "azure-load-balancer"
      ],
      "url": "/azure/architecture/solution-ideas/articles/dfs-failover-cluster-vms"
    },
    {
      "summary": "Implement a secure hybrid network architecture with Active Directory Federation Service authorization in Azure.",
      "azureCategories": [
        "identity",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/adfs.png",
      "title": "Extend on-premises AD FS to Azure",
      "lastModified": "2022-12-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-load-balancer"
      ],
      "url": "/azure/architecture/reference-architectures/identity/adfs"
    },
    {
      "summary": "Learn how to connect an on-premises network to an Azure virtual network (VNet) using ExpressRoute with a virtual private network (VPN) gateway failover.",
      "azureCategories": [
        "hybrid",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/expressroute-vpn-failover.png",
      "title": "Connect an on-premises network to Azure using ExpressRoute",
      "lastModified": "2022-12-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-virtual-network",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/reference-architectures/hybrid-networking/expressroute-vpn-failover"
    },
    {
      "summary": "Prepare Azure landing zone subscriptions for a scalable, enhanced-security deployment of Azure Synapse Analytics.",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/synapse-analytics-landing-zone-thumb.png",
      "title": "Azure Synapse Analytics for landing zones",
      "lastModified": "2022-12-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-synapse-analytics",
        "azure-private-link",
        "azure-data-lake-storage",
        "azure-key-vault"
      ],
      "url": "/azure/architecture/example-scenario/analytics/synapse-analytics-landing-zone"
    },
    {
      "summary": "Learn how to use on-premises data to train machine learning models by using Azure and Azure Stack Hub.",
      "azureCategories": [
        "ai-machine-learning",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/train-machine-learning-on-premises-data-at-edge-thumbnail.png",
      "title": "Train machine learning models at the edge",
      "lastModified": "2022-12-19T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-registry",
        "azure-machine-learning",
        "azure-stack-hub",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/example-scenario/hybrid/train-machine-learning-models-on-premises-data"
    },
    {
      "summary": "Deploy Moodle with Azure NetApp Files for a resilient solution that offers high-throughput, low-latency access to scalable shared storage.",
      "azureCategories": [
        "databases",
        "networking",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/moodle-azure-netapp-files-architecture-thumbnail.png",
      "title": "Moodle deployment with Azure NetApp Files",
      "lastModified": "2022-12-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway",
        "azure-cache-redis",
        "azure-database-mysql",
        "azure-netapp-files",
        "azure-vm-scalesets"
      ],
      "url": "/azure/architecture/example-scenario/file-storage/moodle-azure-netapp-files"
    },
    {
      "summary": "See how buildings and campuses can securely and reliably connect, and scale their on-premises Internet of Things (IoT) devices to the cloud.",
      "azureCategories": [
        "hybrid",
        "iot",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/healthcare-architecture-thumbnail.png",
      "title": "IoT device connectivity for healthcare facilities",
      "lastModified": "2022-12-12T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-arc",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-sphere",
        "azure-stack-edge"
      ],
      "url": "/azure/architecture/solution-ideas/articles/healthcare-network"
    },
    {
      "summary": "Set up improved-security private connectivity to a multitenant web app or function app from an on-premises network or from within an Azure virtual network.",
      "azureCategories": [
        "security",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/multitenant-web-apps-thumb.png",
      "title": "Improved-security access to multitenant web apps from an on-premises network",
      "lastModified": "2022-12-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-virtual-network",
        "azure-private-link",
        "azure-key-vault",
        "azure-storage-accounts"
      ],
      "url": "/azure/architecture/web-apps/guides/networking/access-multitenant-web-app-from-on-premises"
    },
    {
      "summary": "Learn how to integrate Azure Kubernetes Service (AKS) and Azure API Management with mutual TLS in an architecture that provides end-to-end encryption.",
      "azureCategories": [
        "containers",
        "security",
        "identity",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mutual-tls-deploy-aks-api-management-thumb.png",
      "title": "Deploy AKS and API Management with mTLS",
      "lastModified": "2022-12-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-kubernetes-service",
        "azure-api-management",
        "azure-container-registry",
        "microsoft-defender",
        "defender-for-cloud"
      ],
      "url": "/azure/architecture/solution-ideas/articles/mutual-tls-deploy-aks-api-management"
    },
    {
      "summary": "Manage virtual machine compliance without impairing DevOps practices. Use Azure VM Image Builder and Azure Compute Gallery to minimize risk from system images.",
      "azureCategories": [
        "compute",
        "devops",
        "management-and-governance",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/virtual-machine-compliance-architecture-thumbnail.png",
      "title": "Manage virtual machine compliance",
      "lastModified": "2022-12-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-policy"
      ],
      "url": "/azure/architecture/example-scenario/security/virtual-machine-compliance"
    },
    {
      "summary": "An example of a carrier-grade solution design that focuses on highly reliability using Azure services.",
      "azureCategories": [
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/recommendation-architecture-thumbnail.png",
      "title": "Carrier-grade voicemail solution on Azure",
      "lastModified": "2022-12-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-traffic-manager",
        "azure-cosmos-db"
      ],
      "url": "/azure/architecture/industries/telecommunications/carrier-grade"
    },
    {
      "summary": "Extend your mainframe applications to Azure by using the Verastream Host Integrator (VHI). VHI simplifies mainframe and host-based application functionality into a web service.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/extend-mainframe-applications-architecture-thumb.png",
      "title": "Extend mainframe applications to Azure by using Verastream Host Integrator",
      "lastModified": "2022-12-05T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "power-platform",
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-kubernetes-service",
        "azure-monitor"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/extend-mainframe-applications"
    },
    {
      "summary": "Deploy an AKS cluster in a hub-and-spoke network topology by using Terraform and Azure DevOps. Help protect inbound and outbound traffic by using Azure Firewall.",
      "azureCategories": [
        "containers",
        "security",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-firewall.png",
      "title": "Use Azure Firewall to help protect an Azure Kubernetes Service (AKS) cluster",
      "lastModified": "2022-12-05T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-firewall",
        "azure-kubernetes-service",
        "azure-private-link",
        "azure-virtual-network",
        "azure-devops"
      ],
      "url": "/azure/architecture/guide/aks/aks-firewall"
    },
    {
      "summary": "In this scenario, an e-commerce company in the travel industry uses Azure API Management to migrate a legacy web application.",
      "azureCategories": [
        "web",
        "migration",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-apim-api-scenario.png",
      "title": "Migrate a web app by using Azure API Management",
      "lastModified": "2022-12-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-api-management",
        "azure-monitor",
        "azure-app-service"
      ],
      "url": "/azure/architecture/example-scenario/apps/apim-api-scenario"
    },
    {
      "summary": "Learn how to use Azure Stack Hub to create a high-availability Kubernetes cluster solution on Azure.",
      "azureCategories": [
        "hybrid",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/high-availability-kubernetes-thumb.png",
      "title": "Build a high-availability Kubernetes cluster",
      "lastModified": "2022-12-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-stack-hub",
        "azure-kubernetes-service",
        "azure-virtual-network",
        "azure-container-registry",
        "azure-pipelines"
      ],
      "url": "/azure/architecture/example-scenario/hybrid/high-availability-kubernetes"
    },
    {
      "summary": "See how manufacturers can use Azure Network Function Manager with high-performance, low-latency, on-premises 5G Standalone networks for industrial automation and productivity.",
      "azureCategories": [
        "hybrid",
        "iot",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/low-latency-network-thumbnail.png",
      "title": "Low-latency network connections for industry",
      "lastModified": "2022-11-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-stack-edge"
      ],
      "url": "/azure/architecture/solution-ideas/articles/low-latency-network"
    },
    {
      "summary": "Reference architecture for a mission-critical workload that takes a dependency on Azure landing zones for connectivity resources, which allows integration with other applications and shared services of an enterprise.",
      "azureCategories": [
        "containers",
        "networking",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mission-critical-architecture-landing-zone.svg",
      "title": "Mission-critical baseline architecture in an Azure landing zone",
      "lastModified": "2022-11-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-front-door",
        "azure-container-registry",
        "azure-kubernetes-service",
        "azure-rbac"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks-mission-critical/mission-critical-landing-zone"
    },
    {
      "summary": "Harness the power of edge AI when disconnected from the internet and move your AI models to the edge with a solution architecture that includes Azure Stack Hub.",
      "azureCategories": [
        "ai-machine-learning",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ai-at-the-edge-disconnected-thumbnail.png",
      "title": "Disconnected AI at the edge with Azure Stack Hub",
      "lastModified": "2022-11-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-registry",
        "azure-hdinsight",
        "azure-kubernetes-service",
        "azure-machine-learning",
        "azure-stack-hub"
      ],
      "url": "/azure/architecture/ai-ml/idea/ai-at-the-edge-disconnected"
    },
    {
      "summary": "This solution provides an Azure-based smart solution, applying external open-source tools, to determine the optimal energy unit commitments from various energy resources for an energy grid.",
      "azureCategories": [
        "ai-machine-learning",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/energy-supply-optimization-thumbnail.png",
      "title": "Energy supply optimization",
      "lastModified": "2022-11-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-batch",
        "azure-blob-storage",
        "azure-data-science-vm",
        "azure-queue-storage",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/solution-ideas/articles/energy-supply-optimization"
    },
    {
      "summary": "Forecast spikes in demand for energy products and services with Microsoft Azure Machine Learning and give your company a competitive advantage.",
      "azureCategories": [
        "ai-machine-learning",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/forecast-energy-power-demand-thumbnail.png",
      "title": "Forecast energy and power demand with machine learning",
      "lastModified": "2022-11-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning",
        "azure-data-factory",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/forecast-energy-power-demand"
    },
    {
      "summary": "Use knowledge mining to help customer support teams quickly find answers to customer questions or assess customer sentiment at scale.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/knowledge-mining-customer-feedback-and-analytics-thumbnail.png",
      "title": "Knowledge mining for customer support and feedback analysis",
      "lastModified": "2022-11-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cognitive-search",
        "language-service",
        "azure-translator"
      ],
      "url": "/azure/architecture/ai-ml/idea/customer-feedback-and-analytics"
    },
    {
      "summary": "Predict customer churn and find patterns in existing data associated with the predicted churn rate using Azure AI Platform.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/customer-churn-prediction-thumbnail.png",
      "title": "Customer churn prediction using real-time analytics",
      "lastModified": "2022-11-22T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/ai-ml/idea/customer-churn-prediction"
    },
    {
      "summary": "Create a sustainability model based on available proxies that scores the carbon impact of an application.",
      "azureCategories": [
        "ai-machine-learning",
        "databases",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/measure-app-sci-score.png",
      "title": "Measure Azure app sustainability by using the SCI score",
      "lastModified": "2022-11-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-monitor",
        "azure-automation",
        "azure-logic-apps",
        "azure-table-storage",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/example-scenario/apps/measure-azure-app-sustainability-sci-score"
    },
    {
      "summary": "This reference architecture illustrates how developers can create, manage, and monitor deployed containers in the public cloud, across multiple clouds, and on-premises.",
      "azureCategories": [
        "hybrid",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-containers.png",
      "title": "Run containers in a hybrid environment",
      "lastModified": "2022-11-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-registry",
        "azure-arc",
        "azure-container-instances",
        "azure-cosmos-db",
        "azure-kubernetes-service",
        "azure-monitor"
      ],
      "url": "/azure/architecture/hybrid/hybrid-containers"
    },
    {
      "summary": "Use Azure File Sync and Azure Files to extend file services hosting capabilities across cloud and on-premises file share resources.",
      "azureCategories": [
        "hybrid",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-file-services.png",
      "title": "Hybrid file services",
      "lastModified": "2022-11-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-expressroute",
        "azure-files",
        "azure-storage-accounts"
      ],
      "url": "/azure/architecture/hybrid/hybrid-file-services"
    },
    {
      "summary": "This solution delivers an optimized approach to backing up and restoring files and applications of VM-based user workloads hosted on Azure Stack Hub.",
      "azureCategories": [
        "hybrid",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-stack-backup.png",
      "title": "Back up files and applications on Azure Stack Hub",
      "lastModified": "2022-11-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-backup",
        "azure-expressroute",
        "azure-stack-hub",
        "azure-storage"
      ],
      "url": "/azure/architecture/hybrid/azure-stack-backup"
    },
    {
      "summary": "Use knowledge mining technologies such as key phrase extraction and entity recognition to quickly review dense technical material.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/knowledge-mining-content-research-thumbnail.png",
      "title": "Knowledge mining for content research",
      "lastModified": "2022-11-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cognitive-search",
        "document-intelligence",
        "language-service",
        "azure-translator"
      ],
      "url": "/azure/architecture/solution-ideas/articles/content-research"
    },
    {
      "summary": "Learn how Azure Arc extends Kubernetes cluster management and configuration across customer data centers, edge locations, and multiple cloud environments.",
      "azureCategories": [
        "hybrid",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/arc-hybrid-kubernetes.png",
      "title": "Azure Arc hybrid management and deployment for Kubernetes clusters",
      "lastModified": "2022-11-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-arc",
        "azure-kubernetes-service",
        "azure-monitor",
        "azure-policy",
        "azure-rbac"
      ],
      "url": "/azure/architecture/hybrid/arc-hybrid-kubernetes"
    },
    {
      "summary": "This example architecture integrates enterprise backend systems, using message broker and events to decouple services for greater scalability and reliability.",
      "azureCategories": [
        "integration",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/enterprise-integration-message-broker-events.png",
      "title": "Enterprise integration using message broker and events",
      "lastModified": "2022-11-18T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-grid",
        "azure-service-bus"
      ],
      "url": "/azure/architecture/example-scenario/integration/queues-events"
    },
    {
      "summary": "Use Azure Synapse Analytics, SQL Database, and Data Lake Storage to modernize SMB legacy and on-premises data. Easily integrate fused data with other services.",
      "azureCategories": [
        "analytics",
        "databases",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/small-medium-data-warehouse.png",
      "title": "Modern data warehouse for small and medium business",
      "lastModified": "2022-11-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-lake",
        "azure-sql-database",
        "azure-synapse-analytics",
        "dynamics-365",
        "power-platform"
      ],
      "url": "/azure/architecture/example-scenario/data/small-medium-data-warehouse"
    },
    {
      "summary": "Create a modern analytics architecture with Azure Databricks, Data Lake Storage, and other Azure services. Unify data, analytics, and AI workloads at any scale.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-databricks-modern-analytics-architecture.png",
      "title": "Modern analytics architecture with Azure Databricks",
      "lastModified": "2022-11-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-data-lake-storage",
        "azure-databricks",
        "azure-synapse-analytics",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/azure-databricks-modern-analytics-architecture"
    },
    {
      "summary": "This example demonstrates a data pipeline that integrates large amounts of data from multiple sources into a unified analytics platform in Azure.",
      "azureCategories": [
        "analytics",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-data-warehousing.png",
      "title": "Data warehousing and analytics",
      "lastModified": "2022-11-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-lake-storage",
        "azure-cosmos-db",
        "azure-data-factory",
        "azure-sql-database",
        "azure-table-storage"
      ],
      "url": "/azure/architecture/example-scenario/data/data-warehouse"
    },
    {
      "summary": "Use a Zero Trust approach to help ensure that your applications, and the data they contain, are protected.",
      "azureCategories": [
        "identity"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/functions-api-management-thumb.png",
      "title": "Apply the verify explicitly Zero Trust principle to your API implementation",
      "lastModified": "2022-11-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-api-management",
        "entra",
        "entra-id",
        "azure-functions",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/example-scenario/security/apps-zero-trust-identity"
    },
    {
      "summary": "Understand the topological relationship between IoT devices, platform, and applications and learn about IoT gateways, communications protocols, and provisioning.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/devices-platform-application.svg",
      "title": "IoT solution architecture",
      "lastModified": "2022-11-15T18:31:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot",
        "azure-event-hubs",
        "azure-iot-hub"
      ],
      "url": "/azure/architecture/example-scenario/iot/devices-platform-application"
    },
    {
      "summary": "Use Microsoft AI to improve website content tagging accuracy by combining deep learning and natural language processing (NLP) with data on site-specific search terms.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/website-content-tag-suggestion-with-deep-learning-and-nlp-thumbnail.png",
      "title": "Suggest content tags with NLP using deep learning",
      "lastModified": "2022-11-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-registry",
        "azure-cognitive-search",
        "azure-kubernetes-service",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/ai-ml/idea/website-content-tag-suggestion-with-deep-learning-and-nlp"
    },
    {
      "summary": "Use Microsoft Entra ID named locations application context to control access to financial services industry (FSI) data and applications.",
      "azureCategories": [
        "identity",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/authentication-context.png",
      "title": "Location-based access control for FSI applications",
      "lastModified": "2022-11-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "microsoft-authenticator"
      ],
      "url": "/azure/architecture/example-scenario/financial/location-based-access"
    },
    {
      "summary": "Bring your trained AI model to the edge with Azure Stack Hub and integrate it with your applications for low-latency intelligence.",
      "azureCategories": [
        "ai-machine-learning",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ai-at-the-edge-thumbnail.png",
      "title": "AI at the edge with Azure Stack Hub",
      "lastModified": "2022-11-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-registry",
        "azure-kubernetes-service",
        "azure-machine-learning",
        "azure-stack-hub"
      ],
      "url": "/azure/architecture/ai-ml/idea/ai-at-the-edge"
    },
    {
      "summary": "Build a scalable solution for batch scoring an Apache Spark classification model on a schedule using Azure Databricks.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/batch-scoring-spark-models-databricks-thumbnail.png",
      "title": "Batch scoring of Spark models on Azure Databricks",
      "lastModified": "2022-11-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-databricks",
        "azure-data-factory",
        "azure-blob-storage"
      ],
      "url": "/azure/architecture/ai-ml/architecture/batch-scoring-databricks"
    },
    {
      "summary": "Replicate data while modernizing mainframe and midrange systems. Sync on-premises data with Azure data during modernization.",
      "azureCategories": [
        "migration",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sync-mainframe-data-with-azure-thumbnail.png",
      "title": "Replicate and sync mainframe data in Azure",
      "lastModified": "2022-11-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-databricks"
      ],
      "url": "/azure/architecture/reference-architectures/migration/sync-mainframe-data-with-azure"
    },
    {
      "summary": "Learn how to run an Azure App Service application in multiple regions to achieve high availability, by following this example architecture.",
      "azureCategories": [
        "web",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/multi-region-web-app-diagram.png",
      "title": "Highly available multi-region web application",
      "lastModified": "2022-11-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-cosmos-db",
        "azure-front-door",
        "azure-storage",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/web-apps/app-service/architectures/multi-region"
    },
    {
      "summary": "Many machine learning (ML) problems are too complex for a single ML model to solve. Learn about many models machine learning at scale with Azure Machine Learning.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/many-models-machine-learning-azure-thumbnail.png",
      "title": "Many models machine learning (ML) at scale with Azure Machine Learning",
      "lastModified": "2022-11-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-data-lake",
        "azure-databricks",
        "azure-machine-learning",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/ai-ml/idea/many-models-machine-learning-azure-machine-learning"
    },
    {
      "summary": "Find out about migrating your IoT solutions to Azure, including devices, gateways, ingestion, communications, event processing, and analytics.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-iot-reference-architecture.png",
      "title": "Migrate your IoT solutions to Azure",
      "lastModified": "2022-11-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot",
        "azure-iot-hub",
        "azure-iot-sdk"
      ],
      "url": "/azure/architecture/guide/iot/migrate-iot-solution-azure"
    },
    {
      "summary": "Handle dependent data in a resilient way in Power Apps.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/solution-thumbnail.png",
      "title": "Eventual consistency between multiple Power Apps instances",
      "lastModified": "2022-11-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "power-platform",
        "dataverse",
        "azure",
        "azure-logic-apps"
      ],
      "url": "/azure/architecture/guide/power-platform/eventual-consistency"
    },
    {
      "summary": "Learn how to monitor an Azure Functions topology that uses Event Hubs.",
      "azureCategories": [
        "compute",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/observability-application-map.png",
      "title": "Monitor Azure Functions and Event Hubs",
      "lastModified": "2022-11-04T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-functions",
        "azure-monitor"
      ],
      "url": "/azure/architecture/serverless/event-hubs-functions/observability"
    },
    {
      "summary": "Find out how to automate video analysis. Use Computer Vision and Azure Machine Learning to analyze your content.",
      "azureCategories": [
        "ai-machine-learning",
        "media"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/analyze-video-thumb.png",
      "title": "Analyze video content with Computer Vision and Azure Machine Learning",
      "lastModified": "2022-11-04T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning",
        "ai-services",
        "azure-logic-apps",
        "azure-synapse-analytics",
        "azure-data-lake-storage"
      ],
      "url": "/azure/architecture/ai-ml/architecture/analyze-video-computer-vision-machine-learning"
    },
    {
      "summary": "Learn about how applications can use cloud-to-device messaging or direct methods to send commands to IoT devices.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cloud-to-device-message.png",
      "title": "IoT application-to-device commands",
      "lastModified": "2022-11-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot-hub"
      ],
      "url": "/azure/architecture/example-scenario/iot/cloud-to-device"
    },
    {
      "summary": "Explore recommended strategies for hosting Java applications to Azure, including platform types and supportability.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/java-application-tree.png",
      "title": "Compare Java application hosting options on Azure",
      "lastModified": "2022-11-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-spring-apps",
        "azure-app-service",
        "azure-kubernetes-service",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/guide/technology-choices/service-for-java-comparison"
    },
    {
      "summary": "Build a quality assurance system that collects data and improves productivity by identifying potential problems in a manufacturing pipeline before they occur.",
      "azureCategories": [
        "ai-machine-learning",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/quality-assurance-thumbnail.png",
      "title": "Quality assurance",
      "lastModified": "2022-11-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-machine-learning",
        "azure-stream-analytics",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/quality-assurance"
    },
    {
      "summary": "Learn how to use IMSql to rehost IMS DB and IMS TM systems on .NET and SQL Server by using virtual machines.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/imsql-rehost-ims-thumb.png",
      "title": "Rehost IMS workloads to virtual machines by using IMSql",
      "lastModified": "2022-11-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-vm-scalesets",
        "azure-sql-managed-instance"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/imsql-rehost-ims"
    },
    {
      "summary": "See automated and user-managed methods to achieve business continuity and disaster recovery (BCDR) for Data Factory and Azure Synapse pipelines.",
      "azureCategories": [
        "analytics",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/pipelines-disaster-recovery.png",
      "title": "BCDR for Azure Data Factory and Azure Synapse Analytics pipelines",
      "lastModified": "2022-10-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-repos",
        "azure-synapse-analytics",
        "github"
      ],
      "url": "/azure/architecture/example-scenario/analytics/pipelines-disaster-recovery"
    },
    {
      "summary": "Use an Azure Stack Edge or Azure IoT Edge device together with network cameras to determine if retail shelves have out-of-stock items.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/stock-detection-thumb.png",
      "title": "Implement out-of-stock detection at the edge",
      "lastModified": "2022-10-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-stack-edge",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-time-series-insights",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/example-scenario/hybrid/stock-detection-edge"
    },
    {
      "summary": "Analyze data in real time to detect fraudulent transactions or other anomalous activity using Azure Event Hubs and Stream Analytics.",
      "azureCategories": [
        "security",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-fraud-detection-thumbnail-new.png",
      "title": "Real-time fraud detection",
      "lastModified": "2022-10-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-event-hubs",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/example-scenario/data/fraud-detection"
    },
    {
      "summary": "Often machine learning (ML) problems are too complex for a single ML model to solve. Learn about many models machine learning at scale in Azure with Spark.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/many-models-machine-learning-azure-spark-thumbnail.png",
      "title": "Many models machine learning (ML) at scale in Azure with Spark",
      "lastModified": "2022-10-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-data-lake",
        "azure-databricks",
        "azure-machine-learning",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/ai-ml/idea/many-models-machine-learning-azure-spark"
    },
    {
      "summary": "Overview for the high-power compute need in the manufacturing industry.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-batch-ai.png",
      "title": "High-performance computing (HPC) for manufacturing",
      "lastModified": "2022-10-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-batch",
        "azure-files",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/industries/manufacturing/compute-manufacturing-overview"
    },
    {
      "summary": "Retail industry assortment optimization. SKU optimization through insights from AI and ML.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-sku-optimization.png",
      "title": "SKU optimization for consumer brands",
      "lastModified": "2022-10-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-machine-learning",
        "azure-machine-learning-studio",
        "azure-data-science-vm"
      ],
      "url": "/azure/architecture/industries/retail/sku-optimization-solution-guide"
    },
    {
      "summary": "Extract insights from social media for your big data marketing campaigns by using Azure Synapse Analytics, Azure Machine Learning, and Azure Cognitive Services.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/optimize-marketing-with-machine-learning.png",
      "title": "Optimize marketing with machine learning",
      "lastModified": "2022-10-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "ai-services",
        "azure-synapse-analytics",
        "azure-machine-learning",
        "azure-data-lake",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/ai-ml/idea/optimize-marketing-with-machine-learning"
    },
    {
      "summary": "Refactor your mainframe architecture to Azure by using the CloudFrame Renovate code migration tool. Renovate migrates COBOL to Java.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cloudframe-renovate-thumb.png",
      "title": "Refactor mainframe architecture by using CloudFrame Renovate",
      "lastModified": "2022-10-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-kubernetes-service",
        "azure-virtual-network",
        "azure-sql-database",
        "azure-site-recovery"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/cloudframe-renovate-mainframe-refactor"
    },
    {
      "summary": "Learn how to create a jump server on Azure by using Apache Guacamole, an open-source tool that provides functionality similar to that of Azure Bastion.",
      "azureCategories": [
        "compute",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-architecture-guacamole.png",
      "title": "Implement an open-source jump server solution on Azure",
      "lastModified": "2022-10-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-load-balancer",
        "azure-virtual-network",
        "azure-database-mysql"
      ],
      "url": "/azure/architecture/example-scenario/infrastructure/apache-guacamole"
    },
    {
      "summary": "Find out how Visiopharm AI-driven pathology software performs on an Azure VM and get a brief description of the steps for running the software on Azure.",
      "azureCategories": [
        "compute",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/visiopharm-thumb.png",
      "title": "Deploy Visiopharm on a virtual machine",
      "lastModified": "2022-10-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/visiopharm"
    },
    {
      "summary": "Predict capacity and patient flow for your hospital or healthcare facility so that you can enhance the quality of care and improve operational efficiency.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics",
        "databases",
        "integration",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/predict-length-of-stay-thumbnail.png",
      "title": "Predict patient length of stay and flow",
      "lastModified": "2022-10-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-data-lake-storage",
        "azure-machine-learning",
        "azure-synapse-analytics",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/example-scenario/digital-health/predict-patient-length-of-stay"
    },
    {
      "summary": "Provide a high level of preventative medical care with remote patient monitoring from Azure. Analyze large amounts of medical data in a secure environment.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics",
        "databases",
        "integration",
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/remote-patient-monitoring.png",
      "title": "Remote patient monitoring",
      "lastModified": "2022-10-19T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-lake-storage",
        "azure-databricks",
        "azure-event-hubs",
        "azure-machine-learning",
        "azure-synapse-analytics",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/example-scenario/digital-health/remote-patient-monitoring"
    },
    {
      "summary": "Find out how Turbostream, simulation software that's based on a CFD solver, performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/turbostream-thumbnail.png",
      "title": "Deploy Turbostream on a virtual machine",
      "lastModified": "2022-10-18T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/turbostream"
    },
    {
      "summary": "Find out how the Remcom XFdtd electromagnetic simulation tool performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/xfdtd-thumb.png",
      "title": "Deploy Remcom XFdtd on a virtual machine",
      "lastModified": "2022-10-18T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/remcom-xfdtd"
    },
    {
      "summary": "Find out how Ansys Rocky DEM particle simulation software performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/rocky-thumbnail.png",
      "title": "Deploy Ansys Rocky on a virtual machine",
      "lastModified": "2022-10-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/ansys-rocky"
    },
    {
      "summary": "Find out how the Ansys LS-DYNA multiphysics solver application performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ls-dyna-thumbnail.png",
      "title": "Deploy Ansys LS-DYNA on a virtual machine",
      "lastModified": "2022-10-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/ls-dyna"
    },
    {
      "summary": "Reference architecture for a baseline infrastructure that deploys an Azure Kubernetes Service (AKS) cluster.",
      "azureCategories": [
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-baseline-architecture.png",
      "title": "Baseline architecture for an Azure Kubernetes Service (AKS) cluster",
      "lastModified": "2022-10-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway",
        "azure-container-registry",
        "azure-firewall",
        "azure-kubernetes-service",
        "azure-rbac"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks/baseline-aks"
    },
    {
      "summary": "Build a pipeline to transcribe and analyze audio conversations by using the Azure Cognitive Services Speech and Language APIs.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/speech-to-text-analytics-nlp-pipeline-thumb.png",
      "title": "Use a speech-to-text transcription pipeline to analyze recorded conversations",
      "lastModified": "2022-10-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-speech",
        "language-service",
        "ai-services",
        "azure-synapse-analytics",
        "azure-logic-apps"
      ],
      "url": "/azure/architecture/ai-ml/architecture/speech-to-text-transcription-analytics"
    },
    {
      "summary": "Find out how the Samadii EM, software that analyzes electromagnetic fields, performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/samadii-em-thumbnail.png",
      "title": "Deploy Samadii EM on a virtual machine",
      "lastModified": "2022-10-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/samadii-em"
    },
    {
      "summary": "Learn about several common connectivity patterns to build, deploy, and manage Industrial IoT (IIoT) solutions.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/iiot-connectivity-patterns-scale-factories.png",
      "title": "Industrial IoT connectivity patterns",
      "lastModified": "2022-10-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-data-explorer"
      ],
      "url": "/azure/architecture/guide/iiot-patterns/iiot-connectivity-patterns"
    },
    {
      "summary": "Review the differences between virtual network peering and VPN gateways, which are two ways to connect virtual networks in Azure.",
      "azureCategories": [
        "networking",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/default.png",
      "title": "Choose between virtual network peering and VPN gateways",
      "lastModified": "2022-10-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-virtual-network",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/reference-architectures/hybrid-networking/vnet-peering"
    },
    {
      "summary": "Learn about several common prediction patterns to build, deploy and manage Industrial IoT (IIoT) solutions.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/machine-learning-realtime.png",
      "title": "Industrial IoT prediction patterns",
      "lastModified": "2022-10-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-data-explorer",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/guide/iiot-patterns/iiot-prediction-patterns"
    },
    {
      "summary": "Learn about common visibility patterns to build, deploy, and manage Industrial IoT (IIoT) solutions.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/anomaly-detection-thumbnail-new.png",
      "title": "Industrial IoT visibility patterns",
      "lastModified": "2022-10-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-data-explorer",
        "ai-services"
      ],
      "url": "/azure/architecture/guide/iiot-patterns/iiot-visibility-patterns"
    },
    {
      "summary": "Secure your IT environment by using Azure security services and Azure Security Benchmark. This article is part of a series.",
      "azureCategories": [
        "integration",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-security-build-first-layer-defense-thumbnail.png",
      "title": "Build the first layer of defense with Azure Security services",
      "lastModified": "2022-10-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "entra",
        "entra-id"
      ],
      "url": "/azure/architecture/solution-ideas/articles/azure-security-build-first-layer-defense"
    },
    {
      "summary": "Read an overview about the common patterns used to build, deploy, and manage Industrial IoT (IIoT) solutions.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/iiot-maturity.png",
      "title": "Industrial IoT patterns",
      "lastModified": "2022-10-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-data-explorer"
      ],
      "url": "/azure/architecture/guide/iiot-patterns/iiot-patterns-overview"
    },
    {
      "summary": "Learn about a common transparency pattern to build, deploy, and manage Industrial IoT (IIoT) solutions.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/overall-equipment-effectiveness.png",
      "title": "Industrial IoT transparency patterns",
      "lastModified": "2022-10-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-data-explorer"
      ],
      "url": "/azure/architecture/guide/iiot-patterns/iiot-transparency-patterns"
    },
    {
      "summary": "Integrate Azure monitoring services and Azure security services to provide defense in depth to your IT environment. This article introduces a series of articles.",
      "azureCategories": [
        "security",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-monitor-integrate-security-components-thumbnail.png",
      "title": "Use Azure monitoring to integrate security components",
      "lastModified": "2022-10-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-monitor",
        "office-365",
        "microsoft-defender",
        "defender-office365"
      ],
      "url": "/azure/architecture/guide/security/azure-monitor-integrate-security-components"
    },
    {
      "summary": "Use Microsoft Sentinel playbooks to generate automated responses and deliver intelligent security analytics for enterprises.",
      "azureCategories": [
        "migration",
        "identity",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/microsoft-sentinel-automated-response-architecture.png",
      "title": "Microsoft Sentinel automated responses",
      "lastModified": "2022-10-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "microsoft-sentinel",
        "entra",
        "entra-id",
        "azure-logic-apps"
      ],
      "url": "/azure/architecture/solution-ideas/articles/microsoft-sentinel-automated-response"
    },
    {
      "summary": "Diagram the IT environment of your organization and develop a threat map to plan and build your defensive layer of security. This article is part of a series.",
      "azureCategories": [
        "integration",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/map-threats-it-environment-thumbnail.png",
      "title": "Map threats to your IT environment",
      "lastModified": "2022-10-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "office-365"
      ],
      "url": "/azure/architecture/solution-ideas/articles/map-threats-it-environment"
    },
    {
      "summary": "Integrate security solutions from Azure and Microsoft 365 to create robust security for your hybrid and cloud IT environments. This article is part of a series.",
      "azureCategories": [
        "integration",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/microsoft-365-defender-security-integrate-azure-thumbnail.png",
      "title": "Integrate Azure and Microsoft Defender XDR security services",
      "lastModified": "2022-10-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "microsoft-sentinel",
        "azure-monitor",
        "microsoft-defender",
        "defender-for-cloud",
        "azure-log-analytics",
        "azure-network-watcher"
      ],
      "url": "/azure/architecture/solution-ideas/articles/microsoft-365-defender-security-integrate-azure"
    },
    {
      "summary": "Learn about several options for moving, converting, transforming, and storing mainframe and midrange file system data on-premises and in Azure.",
      "azureCategories": [
        "migration",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mainframe-azure-file-replication.png",
      "title": "Mainframe file replication and sync on Azure",
      "lastModified": "2022-10-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-data-lake",
        "azure-sql-database",
        "azure-storage",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/solution-ideas/articles/mainframe-azure-file-replication"
    },
    {
      "summary": "Second article in a series covering disaster recovery for an Azure data platform",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-analytics-end-to-end.png",
      "title": "DR for Azure Data Platform - Architecture",
      "lastModified": "2022-10-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-synapse-analytics",
        "azure-machine-learning",
        "azure-cosmos-db",
        "azure-data-lake",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/data-guide/disaster-recovery/dr-for-azure-data-platform-architecture"
    },
    {
      "summary": "Sixth article in a series covering disaster recovery for an Azure data platform",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-analytics-end-to-end.png",
      "title": "DR for Azure Data Platform - Summary",
      "lastModified": "2022-10-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-synapse-analytics",
        "azure-machine-learning",
        "azure-cosmos-db",
        "azure-data-lake",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/data-guide/disaster-recovery/dr-for-azure-data-platform-summary"
    },
    {
      "summary": "Fifth article in a series covering disaster recovery for an Azure data platform",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-analytics-end-to-end.png",
      "title": "DR for Azure Data Platform - Deploy this scenario",
      "lastModified": "2022-10-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-synapse-analytics",
        "azure-machine-learning",
        "azure-cosmos-db",
        "azure-data-lake",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/data-guide/disaster-recovery/dr-for-azure-data-platform-deploy-this-scenario"
    },
    {
      "summary": "Third article in a series covering disaster recovery for an Azure data platform",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-analytics-end-to-end.png",
      "title": "DR for Azure Data Platform - Scenario details",
      "lastModified": "2022-10-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-synapse-analytics",
        "azure-machine-learning",
        "azure-cosmos-db",
        "azure-data-lake",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/data-guide/disaster-recovery/dr-for-azure-data-platform-scenario-details"
    },
    {
      "summary": "Fourth article in a series covering disaster recovery for an Azure data platform",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-analytics-end-to-end.png",
      "title": "DR for Azure Data Platform - Recommendations",
      "lastModified": "2022-10-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-synapse-analytics",
        "azure-machine-learning",
        "azure-cosmos-db",
        "azure-data-lake",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/data-guide/disaster-recovery/dr-for-azure-data-platform-recommendations"
    },
    {
      "summary": "First article in a series covering disaster recovery for an Azure data platform",
      "azureCategories": [
        "databases",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-analytics-end-to-end.png",
      "title": "DR for Azure Data Platform - Overview",
      "lastModified": "2022-10-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-synapse-analytics",
        "azure-machine-learning",
        "azure-cosmos-db",
        "azure-data-lake",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/data-guide/disaster-recovery/dr-for-azure-data-platform-overview"
    },
    {
      "summary": "Integrate Profisee master data management with Azure Data Factory to deliver high-quality, trusted data for Azure Synapse and all analytic applications.",
      "azureCategories": [
        "databases",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/profisee-master-data-management-data-factory.png",
      "title": "Master data management with Profisee and Azure Data Factory",
      "lastModified": "2022-10-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-databricks",
        "azure-data-lake"
      ],
      "url": "/azure/architecture/databases/architecture/profisee-master-data-management-data-factory"
    },
    {
      "summary": "Find out how Luxion KeyShot real-time 3D rendering software performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/keyshot-thumb.png",
      "title": "Deploy Luxion KeyShot on a virtual machine",
      "lastModified": "2022-10-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/luxion-keyshot"
    },
    {
      "summary": "Find out how the Indica Labs HALO AI deep learning classifier performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/halo-ai-thumb.png",
      "title": "Deploy HALO AI on a virtual machine",
      "lastModified": "2022-10-05T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/indica-labs-halo-ai"
    },
    {
      "summary": "Learn how to build and deploy container applications using Azure Kubernetes Service, DevOps, and GitOps.",
      "azureCategories": [
        "containers",
        "devops",
        "featured",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ci-cd-gitops-github-actions-aks-thumb.png",
      "title": "CI/CD for AKS apps with GitHub Actions and GitFlow",
      "lastModified": "2022-10-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-registry",
        "azure-kubernetes-service",
        "azure-monitor",
        "azure-pipelines"
      ],
      "url": "/azure/architecture/guide/aks/aks-cicd-github-actions-and-gitops"
    },
    {
      "summary": "See how retailers like grocery stores can use 5G video networks and IoT Edge devices to monitor storefront events and take immediate actions to improve customer experience.",
      "azureCategories": [
        "hybrid",
        "iot",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/video-analytics-architecture-thumbnail.png",
      "title": "Video capture and analytics for retail",
      "lastModified": "2022-10-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-media-services",
        "azure-stack-edge",
        "azure-app-service"
      ],
      "url": "/azure/architecture/solution-ideas/articles/video-analytics"
    },
    {
      "summary": "This article describes an architecture that uses Microsoft Cloud for Healthcare to gather insights from clinical and medical data.",
      "azureCategories": [
        "web",
        "mobile"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/clinical-insights-solution-thumbnail.png",
      "title": "Clinical insights with Microsoft Cloud for Healthcare",
      "lastModified": "2022-09-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "industry-solutions",
        "mscloud-healthcare",
        "azure",
        "azure-synapse-analytics",
        "azure-machine-learning",
        "power-platform",
        "power-automate",
        "dynamics-365"
      ],
      "url": "/azure/architecture/example-scenario/mch-health/medical-data-insights"
    },
    {
      "summary": "Use Azure Database for MySQL to develop sophisticated machine learning and visualization apps that provide analytics and information that you can act on.",
      "azureCategories": [
        "databases",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/intelligent-apps-using-azure-database-for-mysql.png",
      "title": "Intelligent apps using Azure Database for MySQL",
      "lastModified": "2022-09-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "ai-services",
        "azure-database-mysql",
        "azure-machine-learning",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/databases/idea/intelligent-apps-using-azure-database-for-mysql"
    },
    {
      "summary": "Upload, encode, and distribute video files with Azure services. Stream content to any video-capable endpoint device, mobile application, or desktop browser.",
      "azureCategories": [
        "media",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/digital-media-video.png",
      "title": "Video-on-demand digital media",
      "lastModified": "2022-09-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-cdn",
        "azure-media-player"
      ],
      "url": "/azure/architecture/solution-ideas/articles/digital-media-video"
    },
    {
      "summary": "Find out how the Siemens NX design and simulation application performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/siemens-nx-thumb.png",
      "title": "Deploy Siemens NX on a virtual machine",
      "lastModified": "2022-09-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/siemens-nx"
    },
    {
      "summary": "Perform batch scoring with R models using Azure Batch and a data set based on retail store sales forecasting.",
      "azureCategories": [
        "ai-machine-learning",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/batch-scoring-r-models-thumbnail.png",
      "title": "Batch scoring with R models to forecast sales",
      "lastModified": "2022-09-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-batch",
        "azure-blob-storage",
        "azure-container-instances",
        "azure-logic-apps",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/ai-ml/architecture/batch-scoring-r-models"
    },
    {
      "summary": "Apply neural style transfer to a video using a convolutional neural network (deep learning) in an Azure Machine Learning pipeline with batch scoring.",
      "azureCategories": [
        "ai-machine-learning",
        "media"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-machine-learning-deep-learning-scoring-pipeline-thumbnail.png",
      "title": "Batch scoring for deep learning models using Azure Machine Learning pipelines",
      "lastModified": "2022-09-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-logic-apps",
        "azure-machine-learning",
        "azure-rbac",
        "azure-storage"
      ],
      "url": "/azure/architecture/ai-ml/architecture/batch-scoring-deep-learning"
    },
    {
      "summary": "Deploy Ansys Fluent on an Azure virtual machine (VM), and find out how Ansys Fluent performs on Azure.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/fluent-thumb.png",
      "title": "Deploy Ansys Fluent on a virtual machine",
      "lastModified": "2022-09-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/ansys-fluent"
    },
    {
      "summary": "Learn about Apache HDFS and about ways to migrate an HDFS implementation to Azure by using the ABFS driver and Azure Data Lake Storage.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hdfs-1-data-migration-planning-thumb.png",
      "title": "Apache Hadoop Distributed File System (HDFS) migration to Azure",
      "lastModified": "2022-09-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-hdinsight",
        "azure-cosmos-db",
        "azure-data-lake-storage",
        "azure-synapse-analytics",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/guide/hadoop/apache-hdfs-migration"
    },
    {
      "summary": "Learn how to use Azure IoT to enhance a retail solution for stores implementing buy online, pick up in store scenarios.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/bopis.png",
      "title": "Buy online, pick up in store (retail)",
      "lastModified": "2022-09-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-event-hubs",
        "azure-functions",
        "azure-iot",
        "azure-storage"
      ],
      "url": "/azure/architecture/example-scenario/iot/vertical-buy-online-pickup-in-store"
    },
    {
      "summary": "Implement an Azure architecture for Murex workloads. Murex is a global software provider for trading, risk management, and processing operations for capital markets.",
      "azureCategories": [
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/murex-azure-reference-architecture.png",
      "title": "Host a Murex MX.3 workload on Azure",
      "lastModified": "2022-09-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-firewall",
        "azure-expressroute",
        "azure-key-vault",
        "azure-storage",
        "azure-monitor"
      ],
      "url": "/azure/architecture/example-scenario/finance/murex-mx3-azure"
    },
    {
      "summary": "Improve uptime and operations in hospitality, manufacturing, and retail. Use mixed reality, IoT, and real-time data to digitally replicate your physical space.",
      "azureCategories": [
        "mixed-reality",
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/facilities-management-powered-by-mixed-reality-and-iot.png",
      "title": "Facilities management powered by mixed reality and IoT",
      "lastModified": "2022-09-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-digital-twins",
        "azure-event-hubs",
        "azure-functions",
        "azure-spatial-anchors"
      ],
      "url": "/azure/architecture/solution-ideas/articles/facilities-management-powered-by-mixed-reality-and-iot"
    },
    {
      "summary": "Learn how to store OAuth 2.0 On-Behalf-Of (OBO) refresh tokens securely using Azure Key Vault and Azure Functions managed identity for key rotation and token refresh.",
      "azureCategories": [
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/refresh-diagram.png",
      "title": "Secure OAuth 2.0 On-Behalf-Of refresh tokens for web services",
      "lastModified": "2022-09-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-clis",
        "azure-devops",
        "azure-functions",
        "azure-key-vault",
        "azure-pipelines"
      ],
      "url": "/azure/architecture/example-scenario/secrets/secure-refresh-tokens"
    },
    {
      "summary": "Use Quorum Blockchain Service to track and trace physical assets, along with their metadata.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/quorum-blockchain-service.png",
      "title": "Supply chain management with Quorum Blockchain Service",
      "lastModified": "2022-09-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-monitor",
        "azure-container-instances",
        "azure-managed-applications"
      ],
      "url": "/azure/architecture/guide/blockchain/quorum-blockchain-service"
    },
    {
      "summary": "Derive insights from siloed data. Connect operational data in MongoDB Atlas to Azure Synapse Analytics, and run real-time analytics on the data.",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-synapse-analytics-integrate-mongdb-atlas-thumbnail.png",
      "title": "Analyze operational data on MongoDB Atlas using Azure Synapse Analytics",
      "lastModified": "2022-09-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-data-lake-storage",
        "azure-event-grid",
        "azure-synapse-analytics",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/example-scenario/analytics/azure-synapse-analytics-integrate-mongodb-atlas"
    },
    {
      "summary": "Build an automated document processing pipeline. Use Azure Form Recognizer to extract data and natural language processing models to enrich the data.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/automate-document-processing-form-recognizer.png",
      "title": "Automate document processing by using Azure Form Recognizer",
      "lastModified": "2022-09-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cognitive-search",
        "ai-services",
        "azure-cosmos-db",
        "document-intelligence",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/ai-ml/architecture/automate-document-processing-azure-form-recognizer"
    },
    {
      "summary": "Find out how Ansys CFX computational fluid dynamics software performs on an Azure virtual machine (VM).",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cfx-thumb.png",
      "title": "Deploy Ansys CFX on a virtual machine",
      "lastModified": "2022-09-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/ansys-cfx"
    },
    {
      "summary": "Establish a trusted environment for communication and information sharing without resorting to a centralized database.",
      "azureCategories": [
        "networking",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-decentralized-trust.png",
      "title": "Decentralized trust between banks",
      "lastModified": "2022-09-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-key-vault",
        "azure-load-balancer",
        "azure-monitor",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/example-scenario/apps/decentralized-trust"
    },
    {
      "summary": "Use Azure Service Fabric as a platform for decomposing an unwieldy monolithic application into multiple, manageable microservices.",
      "azureCategories": [
        "migration",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-service-fabric-complete.png",
      "title": "Using Service Fabric to decompose applications",
      "lastModified": "2022-09-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-service-fabric",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/example-scenario/infrastructure/service-fabric-microservices"
    },
    {
      "summary": "Learn about scaling up and managing IoT device populations with deployment stamps, and see how to move devices and applications between stamps.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/scale-iot-deployment-stamps-thumbnail.png",
      "title": "Scale and manage IoT solutions with deployment stamps",
      "lastModified": "2022-09-22T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-iot-hub",
        "azure-traffic-manager"
      ],
      "url": "/azure/architecture/example-scenario/iot/application-stamps"
    },
    {
      "summary": "Learn how to create a general mainframe rehosting architecture on Azure. Rehosting is a cost-effective method to maintain old mainframe hardware.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-mainframe-rehost-postmigration-diagram.png",
      "title": "Rehost a general mainframe on Azure",
      "lastModified": "2022-09-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-container-registry",
        "azure-kubernetes-service",
        "azure-site-recovery"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/mainframe-rehost-architecture-azure"
    },
    {
      "summary": "Learn how to build a hybrid app that spans Azure and Azure Stack Hub. The solution uses a single on-premises data source.",
      "azureCategories": [
        "compute",
        "storage",
        "hybrid",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-cross-cloud-scale-on-premises-data.png",
      "title": "Cross-cloud scaling - on-premises data",
      "lastModified": "2022-09-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-stack-hub",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-vpn-gateway",
        "azure-app-service"
      ],
      "url": "/azure/architecture/example-scenario/hybrid/hybrid-cross-cloud-scale-on-premises-data"
    },
    {
      "summary": "With identity-based authentication, you can control access to Azure file shares by using Active Directory Directory Services users and groups.",
      "azureCategories": [
        "hybrid",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-file-share.png",
      "title": "Use Azure file shares in a hybrid environment",
      "lastModified": "2022-09-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-files"
      ],
      "url": "/azure/architecture/hybrid/azure-file-share"
    },
    {
      "summary": "Build intelligent marketing systems that provide customer-tailored content by using machine learning models that analyze data from multiple sources.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/personalized-offers.png",
      "title": "Personalized offers",
      "lastModified": "2022-09-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-functions",
        "azure-machine-learning",
        "azure-storage",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/ai-ml/idea/personalized-offers"
    },
    {
      "summary": "Learn how to use Azure Traffic Manager to direct traffic to specific cloud and on-premises endpoints based on various metrics.",
      "azureCategories": [
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-geo-distributed.png",
      "title": "Hybrid geo-distributed architecture",
      "lastModified": "2022-09-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-stack-hub",
        "azure-traffic-manager"
      ],
      "url": "/azure/architecture/example-scenario/hybrid/hybrid-geo-distributed"
    },
    {
      "summary": "See how Microsoft Defender for Cloud Apps and Microsoft Sentinel can help secure and protect Amazon Web Services (AWS) account access and environments.",
      "azureCategories": [
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aws-azure-security-solutions-thumbnail.png",
      "title": "Azure security solutions for AWS",
      "lastModified": "2022-09-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "microsoft-sentinel",
        "microsoft-defender",
        "defender-for-cloud-apps",
        "defender-for-cloud"
      ],
      "url": "/azure/architecture/guide/aws/aws-azure-security-solutions"
    },
    {
      "summary": "Find out how the Altair Radioss analysis solution for nonlinear problems performs on an Azure virtual machine.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/altair-radioss-thumb.png",
      "title": "Deploy Altair Radioss on an Azure virtual machine",
      "lastModified": "2022-09-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-cyclecloud"
      ],
      "url": "/azure/architecture/guide/hpc/altair-radioss"
    },
    {
      "summary": "Use Azure Database for PostgreSQL to securely store critical data and provide users with high-value analytics and insights over aggregated data.",
      "azureCategories": [
        "databases",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/finance-management-apps-using-azure-database-for-postgresql.png",
      "title": "Finance management apps using Azure Database for PostgreSQL",
      "lastModified": "2022-09-15T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-database-postgresql",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/finance-management-apps-using-azure-database-for-postgresql"
    },
    {
      "summary": "Learn how the Autodesk Inventor application for mechanical design performs on an Azure virtual machine.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hpc-inventor-thumb.png",
      "title": "Deploy Autodesk Inventor on a virtual machine",
      "lastModified": "2022-09-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/hpc/autodesk-inventor"
    },
    {
      "summary": "Learn about Azure hybrid solutions, including alternatives to deploy and host hybrid services on-premises, at the edge, in Azure, and in other clouds.",
      "azureCategories": [
        "hybrid",
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-decision-tree.png",
      "title": "Azure hybrid options",
      "lastModified": "2022-09-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-arc",
        "azure-iot-edge",
        "azure-stack-edge",
        "azure-stack-hci",
        "azure-stack-hub"
      ],
      "url": "/azure/architecture/guide/technology-choices/hybrid-considerations"
    },
    {
      "summary": "Learn how to deploy a secure hybrid network that extends an on-premises network to Azure by implementing a perimeter network called a DMZ.",
      "azureCategories": [
        "networking",
        "security",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/dmz-private.png",
      "title": "Implement a secure hybrid network",
      "lastModified": "2022-09-12T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-firewall",
        "azure-load-balancer",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/reference-architectures/dmz/secure-vnet-dmz"
    },
    {
      "summary": "Learn how to build an Azure system that serves web and non-web workloads with resilient multi-tier applications in multiple Azure regions.",
      "azureCategories": [
        "networking",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/high-availability-multi-region-v-10.png",
      "title": "Multi-region load balancing with Traffic Manager, Azure Firewall, and Application Gateway",
      "lastModified": "2022-09-12T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-firewall",
        "azure-application-gateway",
        "azure-bastion",
        "azure-load-balancer",
        "azure-traffic-manager"
      ],
      "url": "/azure/architecture/high-availability/reference-architecture-traffic-manager-application-gateway"
    },
    {
      "summary": "This example demonstrates how you can deploy portals that automate manual or paper-based processes and surface rich user experience.",
      "azureCategories": [
        "mobile"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/custom-business-processes.png",
      "title": "Custom business processes",
      "lastModified": "2022-09-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-api-management",
        "azure-bot-service",
        "azure-functions",
        "power-platform",
        "power-apps",
        "power-bi",
        "power-automate"
      ],
      "url": "/azure/architecture/solution-ideas/articles/custom-business-processes"
    },
    {
      "summary": "Learn about the options for connecting an on-premises network to an Azure Virtual Network (VNet) by comparing reference architectures for each option.",
      "azureCategories": [
        "hybrid",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/default.png",
      "title": "Connect an on-premises network to Azure",
      "lastModified": "2022-09-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-virtual-network",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/reference-architectures/hybrid-networking/"
    },
    {
      "summary": "Learn about building a continuous integration and continuous delivery (CI/CD) pipeline for deploying microservices to Azure Kubernetes Service (AKS) using Azure DevOps and Helm.",
      "azureCategories": [
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-ci-cd.png",
      "title": "Build a CI/CD pipeline for microservices on Kubernetes with Azure DevOps and Helm",
      "lastModified": "2022-09-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-container-registry",
        "azure-devops"
      ],
      "url": "/azure/architecture/microservices/ci-cd-kubernetes"
    },
    {
      "summary": "Use APIOps with an Azure API Management instance to build and deploy APIs. This solution provides self-service tools, auditing, policy enforcement, and early feedback.",
      "azureCategories": [
        "devops",
        "developer-tools",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/automated-api-deployments-architecture-diagram-thumb.png",
      "title": "Automate API deployments with APIOps",
      "lastModified": "2022-09-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-api-management",
        "azure-devops",
        "azure-pipelines"
      ],
      "url": "/azure/architecture/example-scenario/devops/automated-api-deployments-apiops"
    },
    {
      "summary": "Use transaction history data to develop a pricing strategy with a machine learning model accounting for confounding and data sparsity.",
      "azureCategories": [
        "analytics",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/interactive-price-analytics.png",
      "title": "Interactive price analytics using transaction history data",
      "lastModified": "2022-09-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-machine-learning",
        "m365",
        "office-excel",
        "azure-blob-storage",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/solution-ideas/articles/interactive-price-analytics"
    },
    {
      "summary": "Use the Gateway Routing design pattern to route requests to multiple services using a single endpoint.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/gateway-routing.png",
      "title": "Gateway Routing pattern",
      "lastModified": "2022-08-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway"
      ],
      "url": "/azure/architecture/patterns/gateway-routing"
    },
    {
      "summary": "Learn how to run SAS analytics products on Azure. See guidelines for designing and implementing cloud solutions for SAS Viya and SAS Grid that use Azure.",
      "azureCategories": [
        "compute",
        "storage",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sas-azure-guide-architecture-diagram-thumbnail.png",
      "title": "SAS on Azure architecture",
      "lastModified": "2022-08-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/guide/sas/sas-overview"
    },
    {
      "summary": "Get an overview of Azure security technologies, guidance offerings, solution ideas, and reference architectures.",
      "azureCategories": [
        "security",
        "identity",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/security-overview.png",
      "title": "Security architecture design",
      "lastModified": "2022-08-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-firewall",
        "azure-front-door",
        "azure-key-vault",
        "azure-private-link"
      ],
      "url": "/azure/architecture/guide/security/security-start-here"
    },
    {
      "summary": "Learn how to use Azure Data Services to build a modern analytics platform capable of handling the most common data challenges in an organization.",
      "azureCategories": [
        "databases",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-analytics-end-to-end.png",
      "title": "Analytics end-to-end with Azure Synapse",
      "lastModified": "2022-08-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-synapse-analytics",
        "azure-cosmos-db",
        "azure-data-factory",
        "azure-databricks",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/example-scenario/dataplate2e/data-platform-end-to-end"
    },
    {
      "summary": "Use Azure Monitor to monitor performance and availability for OS workloads running on-premises, in third-party cloud providers, and in Microsoft Azure.",
      "azureCategories": [
        "hybrid",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-perf-monitoring.png",
      "title": "Hybrid availability and performance monitoring",
      "lastModified": "2022-08-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-log-analytics",
        "azure-monitor",
        "azure-storage",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/hybrid/hybrid-perf-monitoring"
    },
    {
      "summary": "Build a data consortium to share healthcare data among partners. Collect data from multiple sources and share it with members in a secure and efficient way.",
      "azureCategories": [
        "databases",
        "hybrid",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-health-data-consortium-architecture-thumbnail.png",
      "title": "Health data consortium on Azure",
      "lastModified": "2022-08-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-data-lake-storage",
        "azure-data-share",
        "azure-databricks",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/example-scenario/data/azure-health-data-consortium"
    },
    {
      "summary": "See an enterprise monitoring solution that uses Azure Monitor to collect and manage data from cloud, on-premises, and hybrid resources.",
      "azureCategories": [
        "devops",
        "hybrid",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/enterprise-monitoring.png",
      "title": "Enterprise monitoring with Azure Monitor",
      "lastModified": "2022-08-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-arc",
        "azure-automation",
        "azure-logic-apps",
        "azure-monitor",
        "microsoft-sentinel"
      ],
      "url": "/azure/architecture/example-scenario/monitoring/enterprise-monitoring"
    },
    {
      "summary": "Learn how to implement a secure hybrid network architecture that integrates on-premises Active Directory domains with Microsoft Entra ID.",
      "azureCategories": [
        "identity",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-ad.png",
      "title": "Integrate on-premises Active Directory domains with Microsoft Entra ID",
      "lastModified": "2022-08-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-virtual-network",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/reference-architectures/identity/azure-ad"
    },
    {
      "summary": "Big Data Analytics with Azure Data Explorer demonstrates Azure Data Explorer's abilities to cater to volume, velocity, and variety of data.",
      "azureCategories": [
        "analytics",
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/big-data-azure-data-explorer.png",
      "title": "Big data analytics with Azure Data Explorer",
      "lastModified": "2022-08-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-explorer",
        "azure-event-hubs",
        "azure-iot-hub",
        "azure-storage",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/solution-ideas/articles/big-data-azure-data-explorer"
    },
    {
      "summary": "Learn about an approach to machine learning operations (MLOps) that uses Azure Databricks to run model training and batch scoring.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/orchestrate-mlops-azure-databricks-01-thumb.png",
      "title": "Orchestrate MLOps by using Azure Databricks",
      "lastModified": "2022-08-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-databricks"
      ],
      "url": "/azure/architecture/ai-ml/idea/orchestrate-mlops-azure-databricks"
    },
    {
      "summary": "This article describes an architecture to develop a virtual health solution using Microsoft Cloud for Healthcare.",
      "azureCategories": [
        "web",
        "mobile"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/virtual-health-solution.png",
      "title": "Virtual health on Microsoft Cloud for Healthcare",
      "lastModified": "2022-08-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/example-scenario/mch-health/virtual-health-mch"
    },
    {
      "summary": "See an example data analytics solution that can democratize data access and provide R&D engineers with near real-time insights into test drive diagnostic data.",
      "azureCategories": [
        "analytics",
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/analytics-dataflow.png",
      "title": "Data analytics for automotive test fleets",
      "lastModified": "2022-08-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-data-explorer",
        "azure-event-hubs",
        "azure-functions",
        "azure-iot-hub"
      ],
      "url": "/azure/architecture/industries/automotive/automotive-telemetry-analytics"
    },
    {
      "summary": "Learn about proven practices for deploying web applications that use Azure App Service and Azure SQL Database.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/basic-web-app.png",
      "title": "Basic web application",
      "lastModified": "2022-08-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-key-vault",
        "azure-monitor",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/web-apps/app-service/architectures/basic-web-app"
    },
    {
      "summary": "Learn how to use Azure Content Delivery Network and other Azure services to deploy a highly scalable and secure installation of WordPress.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/secure-scalable-wordpress.png",
      "title": "Scalable and secure WordPress on Azure",
      "lastModified": "2022-08-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cdn",
        "azure-key-vault",
        "azure-load-balancer",
        "azure-virtual-network",
        "azure-vm-scalesets"
      ],
      "url": "/azure/architecture/web-apps/hosting-applications/wordpress"
    },
    {
      "summary": "Learn how security operations teams can use Microsoft Entra identity and access features in an adaptive, integrated zero-trust security architecture.",
      "azureCategories": [
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture.png",
      "title": "Microsoft Entra IDaaS in security operations",
      "lastModified": "2022-08-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "microsoft-sentinel"
      ],
      "url": "/azure/architecture/example-scenario/aadsec/azure-ad-security"
    },
    {
      "summary": "Explore an Azure architecture for a consumer health portal in a digital health solution.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/consumer-health-portal-thumbnail.png",
      "title": "Consumer health portal on Azure",
      "lastModified": "2022-08-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-functions",
        "azure-web-application-firewall"
      ],
      "url": "/azure/architecture/example-scenario/digital-health/health-portal"
    },
    {
      "summary": "Learn how Project Bonsai builds and deploys autonomous systems using machine teaching, deep reinforcement learning, and simulations.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/machine-teaching-1-2.png",
      "title": "Machine teaching with Project Bonsai autonomous systems",
      "lastModified": "2022-08-22T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-instances",
        "azure-container-registry",
        "azure-storage"
      ],
      "url": "/azure/architecture/solution-ideas/articles/autonomous-systems"
    },
    {
      "summary": "Use Azure Batch to manage existing Windows or Linux applications, including Autodesk Maya and Blender, to run large-scale 3D video render jobs.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-video-rendering.png",
      "title": "3D video rendering",
      "lastModified": "2022-08-22T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-batch",
        "azure-storage",
        "azure-virtual-network",
        "azure-vm-scalesets"
      ],
      "url": "/azure/architecture/example-scenario/infrastructure/video-rendering"
    },
    {
      "summary": "To assess autonomous driving (AD), function engineers need to simulate the behavior of vehicles with AD capabilities.",
      "azureCategories": [
        "compute",
        "containers",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/building-blocks-autonomous-driving-simulation-environments.png",
      "title": "Building blocks for autonomous-driving simulation environments",
      "lastModified": "2022-08-22T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-instances",
        "entra",
        "entra-id",
        "azure-virtual-network",
        "azure-virtual-machines",
        "azure-pipelines"
      ],
      "url": "/azure/architecture/industries/automotive/building-blocks-autonomous-driving-simulation-environments"
    },
    {
      "summary": "Learn about an end-to-end solution for health records management that uses Azure Functions microservices integrated with other services via a virtual network.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/virtual-network-microservices-thumbnail.png",
      "title": "Virtual network integrated serverless microservices",
      "lastModified": "2022-08-22T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-api-management",
        "azure-cosmos-db",
        "azure-functions",
        "azure-key-vault",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/integrated-multiservices/virtual-network-integration"
    },
    {
      "summary": "Learn how to perform image-based modeling on Azure infrastructure-as-a-service (IaaS) by following the architecture and design guidance in an example scenario.",
      "azureCategories": [
        "compute",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-image-modeling.png",
      "title": "Digital image-based modeling on Azure",
      "lastModified": "2022-08-22T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-key-vault",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/example-scenario/infrastructure/image-modeling"
    },
    {
      "summary": "Help secure the connection to a Microsoft Teams channel bot’s web app by using Azure Private Link and Azure Private Endpoint.",
      "azureCategories": [
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/securing-bot-image-001-thumbnail.png",
      "title": "Help secure your Microsoft Teams channel bot and web app behind a firewall",
      "lastModified": "2022-08-22T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-web-application-firewall"
      ],
      "url": "/azure/architecture/example-scenario/teams/securing-bot-teams-channel"
    },
    {
      "summary": "Learn about best practices for monitoring a microservices application that runs on Azure Kubernetes Service, by collecting telemetry from the application.",
      "azureCategories": [
        "management-and-governance",
        "developer-tools",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/implement-drone-delivery.png",
      "title": "Monitor a microservices application in AKS",
      "lastModified": "2022-08-19T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-monitor",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/microservices/logging-monitoring"
    },
    {
      "summary": "Architecture for a highly reliable workload that is accessed over a public endpoint and doesn't require private network connectivity to other company resources.",
      "azureCategories": [
        "containers",
        "networking",
        "security",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mission-critical-intro-thumb.png",
      "title": "Mission-critical baseline architecture on Azure",
      "lastModified": "2022-08-18T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-front-door",
        "azure-container-registry",
        "azure-kubernetes-service",
        "azure-rbac"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks-mission-critical/mission-critical-intro"
    },
    {
      "summary": "Learn about scaling Azure AI and machine learning environments that must comply with extensive security policies.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/scale-ai-and-machine-learning-in-regulated-industries.png",
      "title": "Scale AI and machine learning initiatives in regulated industries",
      "lastModified": "2022-08-18T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning",
        "azure-synapse-analytics",
        "azure-databricks"
      ],
      "url": "/azure/architecture/example-scenario/ai/scale-ai-and-machine-learning-in-regulated-industries"
    },
    {
      "summary": "Learn how to connect to edge resources or devices that are protected by firewalls. This architecture uses Azure Relay in a hybrid scenario.",
      "azureCategories": [
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-relay-connection.png",
      "title": "Hybrid relay connection in Azure and Azure Stack Hub",
      "lastModified": "2022-08-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-stack-hub",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/solution-ideas/articles/hybrid-relay-connection"
    },
    {
      "summary": "Implement a custom natural language processing (NLP) solution in Azure. Use Spark NLP for tasks like topic and sentiment detection and analysis.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/large-scale-custom-natural-language-processing-thumbnail.png",
      "title": "Large-scale custom natural language processing",
      "lastModified": "2022-08-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-computer-vision",
        "azure-data-lake-storage",
        "azure-databricks",
        "azure-hdinsight",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/ai-ml/idea/large-scale-custom-natural-language-processing"
    },
    {
      "summary": "The article provides an overview for implementing an AI-based footfall detection solution, which analyzes visitor traffic in retail stores. The solution generates insights from real-world actions, by using Azure, Azure Stack Hub, and the Custom Vision AI Dev Kit.",
      "azureCategories": [
        "compute",
        "storage",
        "hybrid",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-footfall-detection-pattern.png",
      "title": "AI-based footfall detection",
      "lastModified": "2022-08-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-stack-hub",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/solution-ideas/articles/hybrid-footfall-detection"
    },
    {
      "summary": "Learn how to architect a solution that processes changes to underlying data within a web view, without the need for a page refresh, by using real-time services.",
      "azureCategories": [
        "devops",
        "integration",
        "developer-tools",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/archdiagram-thumbnail.png",
      "title": "Share a location in real time by using low-cost serverless Azure services",
      "lastModified": "2022-08-12T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-front-door",
        "azure-functions",
        "azure-service-bus"
      ],
      "url": "/azure/architecture/example-scenario/signalr/"
    },
    {
      "summary": "Architecture for a highly reliable workload with enhanced network controls such as private endpoints.",
      "azureCategories": [
        "containers",
        "networking",
        "security",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mission-critical-architecture-network.svg",
      "title": "Mission-critical baseline architecture with network controls",
      "lastModified": "2022-08-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-front-door",
        "azure-firewall",
        "azure-virtual-network",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks-mission-critical/mission-critical-network-architecture"
    },
    {
      "summary": "Transform unstructured image and text data into full text searchable content with Azure Cognitive Search pre-built and custom skills.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cognitive-search-for-ai-enrichment.png",
      "title": "AI enrichment with image and text processing",
      "lastModified": "2022-08-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-blob-storage",
        "azure-cognitive-search",
        "azure-functions"
      ],
      "url": "/azure/architecture/solution-ideas/articles/cognitive-search-with-skillsets"
    },
    {
      "summary": "Analytics solutions turn volumes of data into useful business intelligence (BI), such as reports and visualizations, and inventive artificial intelligence (AI), such as forecasts based on machine learning.",
      "azureCategories": [
        "analytics",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/analytics-get-started-diagram-thumb.png",
      "title": "Analytics architecture design",
      "lastModified": "2022-08-05T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-synapse-analytics",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/analytics-start-here"
    },
    {
      "summary": "An opinionated accelerator to deploy a secure enterprise-ready API management solution and CI/CD pipeline.",
      "azureCategories": [
        "developer-tools",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/app-gateway-internal-api-management-function.png",
      "title": "Azure API Management landing zone accelerator",
      "lastModified": "2022-08-04T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-api-management",
        "azure-application-gateway",
        "azure-functions",
        "dotnet"
      ],
      "url": "/azure/architecture/example-scenario/integration/app-gateway-internal-api-management-function"
    },
    {
      "summary": "Run high performance computing (HPC) applications in Azure with a low-maintenance solution that includes the head node, compute nodes, and storage nodes.",
      "azureCategories": [
        "compute",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hpc-cluster.png",
      "title": "HPC cluster deployed in the cloud",
      "lastModified": "2022-08-04T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-pipelines",
        "azure-blob-storage",
        "azure-batch",
        "azure-monitor"
      ],
      "url": "/azure/architecture/solution-ideas/articles/hpc-cluster"
    },
    {
      "summary": "Explore Azure Virtual Desktop and learn how to build virtual desktop infrastructure solutions at enterprise scale.",
      "azureCategories": [
        "windows-virtual-desktop"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/windows-virtual-desktop.png",
      "title": "Azure Virtual Desktop for the enterprise",
      "lastModified": "2022-08-04T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-virtual-network",
        "azure-virtual-desktop"
      ],
      "url": "/azure/architecture/example-scenario/wvd/windows-virtual-desktop"
    },
    {
      "summary": "Optimize inventory and forecast demand by using Azure Machine Learning, Ray, and Power BI. Enable users to configure and run simulations in Power BI.",
      "azureCategories": [
        "ai-machine-learning",
        "integration",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/optimize-inventory-forecast-demand-thumbnail.svg",
      "title": "Optimize inventory and forecast demand with Power Platform and Azure",
      "lastModified": "2022-07-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-machine-learning",
        "azure-data-lake-storage",
        "azure-synapse-analytics",
        "power-platform"
      ],
      "url": "/azure/architecture/example-scenario/analytics/optimize-inventory-forecast-demand"
    },
    {
      "summary": "Automate data integration tasks by using Azure Logic Apps. Configure API calls to trigger tasks like storing data in an on-premises SQL Server database.",
      "azureCategories": [
        "integration",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/logic-apps-data-integration-thumbnail.png",
      "title": "Data integration with Logic Apps and SQL Server",
      "lastModified": "2022-07-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-api-management",
        "azure-logic-apps",
        "sql-server"
      ],
      "url": "/azure/architecture/example-scenario/integration/logic-apps-data-integration"
    },
    {
      "summary": "See how to use a hub-and-spoke architectural model to deploy Power Automate parent and child flows.",
      "azureCategories": [
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/power-automate.png",
      "title": "Power Automate deployment at scale",
      "lastModified": "2022-07-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-logic-apps",
        "m365",
        "office-sp",
        "power-platform",
        "power-automate"
      ],
      "url": "/azure/architecture/example-scenario/power-automate/power-automate"
    },
    {
      "summary": "Use the Saga design pattern to ensure data consistency on distributed transactions in microservices architectures.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/saga-overview.png",
      "title": "Saga distributed transactions pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/reference-architectures/saga/saga"
    },
    {
      "summary": "Learn about the Index Table pattern. Create indexes over the fields in data stores that are frequently referenced by queries.",
      "azureCategories": [
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/index-table.png",
      "title": "Index Table pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-storage"
      ],
      "url": "/azure/architecture/patterns/index-table"
    },
    {
      "summary": "Prioritize requests sent to services so that requests with a higher priority are received and processed more quickly than those with a lower priority.",
      "azureCategories": [
        "compute",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/priority-queue-separate.png",
      "title": "Priority Queue pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-service-bus"
      ],
      "url": "/azure/architecture/patterns/priority-queue"
    },
    {
      "summary": "Learn tips for how to troubleshoot a VPN gateway connection between an on-premises network and Azure.",
      "azureCategories": [
        "hybrid",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/default.png",
      "title": "Troubleshoot a hybrid VPN connection",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-network",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/reference-architectures/hybrid-networking/troubleshoot-vpn"
    },
    {
      "summary": "Find the best available option for your use case when configuring Kubernetes clusters at the edge.",
      "azureCategories": [
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/choose-bare-metal-kubernetes.png",
      "title": "Choose a bare-metal Kubernetes at the edge platform option",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/operator-guides/aks/choose-bare-metal-kubernetes"
    },
    {
      "summary": "Learn about the infrastructure and DevOps considerations of deploying and running a microservices architecture on Azure Kubernetes Service (AKS).",
      "azureCategories": [
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks.png",
      "title": "Microservices architecture on Azure Kubernetes Service",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-container-registry",
        "azure-kubernetes-service",
        "azure-load-balancer",
        "azure-pipelines",
        "azure-monitor"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks-microservices/aks-microservices"
    },
    {
      "summary": "This reference architecture shows proven practices for deploying a highly available SharePoint Server 2016 farm on Azure, using MinRole topology and SQL Server Always On availability groups.",
      "azureCategories": [
        "management-and-governance",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sharepoint-ha.png",
      "title": "Run a highly available SharePoint Server 2016 farm in Azure",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-managed-disks",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/reference-architectures/sharepoint/"
    },
    {
      "summary": "Learn the best practices for running a Windows virtual machine on Azure, which requires some additional components, including networking and storage resources.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/single-vm-diagram.png",
      "title": "Run a Windows VM on Azure",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-backup",
        "azure-blob-storage",
        "azure-resource-manager",
        "azure-storage",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/reference-architectures/n-tier/windows-vm"
    },
    {
      "summary": "Learn about the monitoring services you can use on Azure by following a reference architecture that uses a dataflow model for use with multiple data sources.",
      "azureCategories": [
        "web",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-diagram-app-monitoring-thumbnail.png",
      "title": "Web application monitoring on Azure",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-log-analytics",
        "azure-monitor"
      ],
      "url": "/azure/architecture/web-apps/guides/monitoring/app-monitoring"
    },
    {
      "summary": "Learn how to use the Retry pattern to enable an application to handle anticipated, temporary failures when the app tries to connect to a service or network resource.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/retry-pattern.png",
      "title": "Retry pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/patterns/retry"
    },
    {
      "summary": "Learn how use the Leader Election pattern to coordinate the actions performed by a collection of collaborating task instances in a distributed application.",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/leader-election.png",
      "title": "Leader Election pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-hdinsight"
      ],
      "url": "/azure/architecture/patterns/leader-election"
    },
    {
      "summary": "Learn how to create a four stage end-to-end stream processing pipeline in Azure using Azure Databricks.",
      "azureCategories": [
        "analytics",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/stream-processing-databricks-thumbnail-new.png",
      "title": "Stream processing with Azure Databricks",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-databricks",
        "azure-event-hubs",
        "azure-log-analytics",
        "azure-monitor"
      ],
      "url": "/azure/architecture/reference-architectures/data/stream-processing-databricks"
    },
    {
      "summary": "Explore the Scheduler Agent Supervisor pattern, which coordinates a set of actions across a distributed set of services and other remote resources.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/scheduler-agent-supervisor-solution.png",
      "title": "Scheduler Agent Supervisor pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/patterns/scheduler-agent-supervisor"
    },
    {
      "summary": "Use Dynamics 365 Customer Insights to create an enhanced customer dataset and make it available in Azure Data Lake for consumption by Azure Synapse Analytics.",
      "azureCategories": [
        "databases",
        "analytics",
        "storage",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/customer-insights-synapse.png",
      "title": "Enhanced customer dimension with Dynamics 365 Customer Insights",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-lake-storage",
        "azure-synapse-analytics",
        "azure-data-factory",
        "dynamics-365",
        "customer-insights-data"
      ],
      "url": "/azure/architecture/solution-ideas/articles/customer-insights-synapse"
    },
    {
      "summary": "Understand how to create a separate Active Directory domain in Azure that's trusted by domains in your on-premises AD forest.",
      "azureCategories": [
        "identity",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/adds-forest.png",
      "title": "Create an AD DS resource forest in Azure",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-expressroute",
        "azure-virtual-network",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/reference-architectures/identity/adds-forest"
    },
    {
      "summary": "Learn how to automate an extract, load, and transform (ELT) workflow in Azure using Azure Data Factory with Azure Synapse Analytics.",
      "azureCategories": [
        "analytics",
        "databases",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/enterprise-bi-adf.png",
      "title": "Automated enterprise BI",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-analysis-services",
        "azure-blob-storage",
        "azure-data-factory",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/reference-architectures/data/enterprise-bi-adf"
    },
    {
      "summary": "Explore the Competing Consumers pattern. This pattern enables many concurrent consumers to process messages that are received on the same messaging channel.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/competing-consumers-diagram.png",
      "title": "Competing Consumers pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-functions",
        "azure-service-bus"
      ],
      "url": "/azure/architecture/patterns/competing-consumers"
    },
    {
      "summary": "Generate prepopulated views over the data in one or more data stores when the data isn't ideally formatted for required query operations.",
      "azureCategories": [
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/materialized-view-summary.png",
      "title": "Materialized View pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-storage"
      ],
      "url": "/azure/architecture/patterns/materialized-view"
    },
    {
      "summary": "Use load testing to tune the performance of an application with multiple backend services. Increase resource units, run parallel queries, and optimize the query.",
      "azureCategories": [
        "containers",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/gateway-aggregation.png",
      "title": "Performance tuning - Multiple backend services",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-cosmos-db"
      ],
      "url": "/azure/architecture/performance/backend-services"
    },
    {
      "summary": "Use the Compensating Transaction pattern to undo work when a step of an eventually consistent operation fails.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/compensating-transaction-diagram.png",
      "title": "Compensating Transaction pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/patterns/compensating-transaction"
    },
    {
      "summary": "Use the Compute Resource Consolidation design pattern to consolidate multiple tasks or operations into a single computational unit.",
      "azureCategories": [
        "web",
        "compute",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/compute-resource-consolidation-diagram.png",
      "title": "Compute Resource Consolidation pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/patterns/compute-resource-consolidation"
    },
    {
      "summary": "Implement the functional checks of the Health Endpoint Monitoring pattern. Use external tools to check apps at regular intervals through exposed endpoints.",
      "azureCategories": [
        "developer-tools",
        "devops",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/health-endpoint-monitoring-pattern.png",
      "title": "Health Endpoint Monitoring pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-front-door",
        "azure-monitor",
        "azure-traffic-manager"
      ],
      "url": "/azure/architecture/patterns/health-endpoint-monitoring"
    },
    {
      "summary": "Use machine learning to automate recommendations. Use Azure Databricks and Azure Data Science Virtual Machines (DSVM) to train the models.",
      "azureCategories": [
        "ai-machine-learning",
        "compute",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/recommenders-architecture.png",
      "title": "Build a real-time recommendation API on Azure",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-databricks",
        "azure-kubernetes-service",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/ai-ml/architecture/real-time-recommendation"
    },
    {
      "summary": "Learn how Azure services and components can provide scale-out performance comparable to IBM z/OS mainframe CF and Parallel Sysplex capabilities.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/refactor-zos-coupling-facility.png",
      "title": "Refactor IBM z/OS mainframe coupling facility (CF) to Azure",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/reference-architectures/zos/refactor-zos-coupling-facility"
    },
    {
      "summary": "Handle faults that might take a variable amount of time to fix when connecting to a remote service or resource.",
      "azureCategories": [
        "networking",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/circuit-breaker-diagram.png",
      "title": "Circuit Breaker pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/patterns/circuit-breaker"
    },
    {
      "summary": "Control the consumption of resources used by an instance of an application, an individual tenant, or an entire service.",
      "azureCategories": [
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/throttling-multi-tenant.png",
      "title": "Throttling pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/patterns/throttling"
    },
    {
      "summary": "Explore the Backends for Frontends pattern, which creates separate backend services to be consumed by specific frontend applications or interfaces.",
      "azureCategories": [
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/backend-for-frontend-example.png",
      "title": "Backends for Frontends pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/patterns/backends-for-frontends"
    },
    {
      "summary": "Learn the best practices for running a Linux virtual machine on Azure, which requires some additional components, including networking and storage resources.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/single-vm-diagram.png",
      "title": "Run a Linux VM on Azure",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-backup",
        "azure-blob-storage",
        "azure-storage",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/reference-architectures/n-tier/linux-vm"
    },
    {
      "summary": "Use the Gateway Offloading design pattern to offload shared or specialized service functionality to a gateway proxy.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/gateway-offload.png",
      "title": "Gateway Offloading pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway"
      ],
      "url": "/azure/architecture/patterns/gateway-offloading"
    },
    {
      "summary": "Use a queue that acts as a buffer between a task and a service that it invokes in order to smooth intermittent heavy loads.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/queue-based-load-leveling-function.png",
      "title": "Queue-Based Load Leveling pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-functions",
        "azure-service-bus"
      ],
      "url": "/azure/architecture/patterns/queue-based-load-leveling"
    },
    {
      "summary": "Learn about the architectural decisions and recommended practices for migrating an application from Azure Cloud Services to Azure Service Fabric.",
      "azureCategories": [
        "migration",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/move-service-fabric.png",
      "title": "Migrate an Azure Cloud Services application to Azure Service Fabric",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-service-fabric"
      ],
      "url": "/azure/architecture/service-fabric/migrate-from-cloud-services"
    },
    {
      "summary": "See how solar-powered IoT devices combine with Azure services to deliver clean, low-cost power, light, and internet services to remote locations.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/iot-power-architecture-thumbnail.png",
      "title": "IoT connected light, power, and internet",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-databricks",
        "azure-iot-hub",
        "azure-machine-learning",
        "azure-synapse-analytics",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/iot-power-management"
    },
    {
      "summary": "Deploy static content to a cloud-based storage service that can deliver them directly to the client.",
      "azureCategories": [
        "containers",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/static-content-hosting-pattern.png",
      "title": "Static Content Hosting pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-storage"
      ],
      "url": "/azure/architecture/patterns/static-content-hosting"
    },
    {
      "summary": "Learn how to load data on demand into a cache, which can improve performance and maintain consistency between cached data and the underlying data store.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cache-aside-diagram.png",
      "title": "Cache-Aside pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cache-redis"
      ],
      "url": "/azure/architecture/patterns/cache-aside"
    },
    {
      "summary": "Deploy Linux virtual machines and a virtual network configured for an N-tier architecture with Apache Cassandra in Microsoft Azure.",
      "azureCategories": [
        "databases",
        "web",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/n-tier-cassandra.png",
      "title": "N-tier application with Apache Cassandra",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-dns",
        "azure-load-balancer",
        "azure-monitor",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/databases/architecture/n-tier-cassandra"
    },
    {
      "summary": "You can use a rate limiting pattern to help you avoid or minimize throttling errors.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/rate-limiting-pattern.png",
      "title": "Rate Limiting pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-service-bus",
        "azure-queue-storage",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/patterns/rate-limiting-pattern"
    },
    {
      "summary": "Use load testing to tune the performance of a distributed business transaction. Tests include increasing the cache size, parallelism, and lock duration.",
      "azureCategories": [
        "containers",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/drone-delivery-impl.png",
      "title": "Distributed business transaction performance tuning",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-cache-redis"
      ],
      "url": "/azure/architecture/performance/distributed-transaction"
    },
    {
      "summary": "Allow decoupling of backend processing from a frontend host, where backend processing needs to be asynchronous, but the frontend still needs a clear response.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/async-request-fn.png",
      "title": "Asynchronous Request-Reply pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-logic-apps"
      ],
      "url": "/azure/architecture/patterns/async-request-reply"
    },
    {
      "summary": "Learn about VM security, encryption, NSGs, DMZs, access control, and other security considerations for highly sensitive IaaS and hybrid apps.",
      "azureCategories": [
        "security",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/dedicated-hosts.png",
      "title": "Security considerations for highly sensitive IaaS apps in Azure",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-disk-encryption",
        "azure-firewall",
        "azure-key-vault",
        "microsoft-defender",
        "defender-for-cloud",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/reference-architectures/n-tier/high-security-iaas"
    },
    {
      "summary": "Incrementally migrate a legacy system by gradually replacing specific pieces of functionality with new applications and services.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/strangler.png",
      "title": "Strangler Fig pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-migrate"
      ],
      "url": "/azure/architecture/patterns/strangler-fig"
    },
    {
      "summary": "Implement a secure site-to-site network architecture that spans an Azure virtual network and an on-premises network connected using Azure ExpressRoute.",
      "azureCategories": [
        "hybrid",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/expressroute.png",
      "title": "Extend an on-premises network using ExpressRoute",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-virtual-network",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/reference-architectures/hybrid-networking/expressroute"
    },
    {
      "summary": "Learn about the Ambassador pattern, which creates helper services that send network requests on behalf of a consumer service or application.",
      "azureCategories": [
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ambassador.png",
      "title": "Ambassador pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/patterns/ambassador"
    },
    {
      "summary": "Use the Sharding design pattern to divide a data store into a set of horizontal partitions or shards.",
      "azureCategories": [
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sharding-data-hash.png",
      "title": "Sharding pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-disk-storage"
      ],
      "url": "/azure/architecture/patterns/sharding"
    },
    {
      "summary": "Learn about a scalable, secure AKS microservices architecture that builds on recommended AKS microservices baseline architectures and implementations.",
      "azureCategories": [
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-production-deployment.png",
      "title": "Advanced Azure Kubernetes Service (AKS) microservices architecture",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway",
        "azure-container-registry",
        "azure-kubernetes-service",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks-microservices/aks-microservices-advanced"
    },
    {
      "summary": "Learn how to extend an on-premises Active Directory domain to Azure in order to provide distributed authentication services.",
      "azureCategories": [
        "identity",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ad-ds-extend-domain.png",
      "title": "Deploy AD DS in an Azure virtual network",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "azure",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/identity/adds-extend-domain"
    },
    {
      "summary": "Examine the Anti-corruption Layer pattern. Implement a façade or adapter layer between a modern application and a legacy system.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/anti-corruption-layer.png",
      "title": "Anti-corruption Layer pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-logic-apps"
      ],
      "url": "/azure/architecture/patterns/anti-corruption-layer"
    },
    {
      "summary": "Run Azure Kubernetes Service (AKS) cluster in multiple regions to achieve high availability.",
      "azureCategories": [
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-multi-cluster.png",
      "title": "AKS baseline for multiregion clusters",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-front-door",
        "azure-application-gateway",
        "azure-container-registry",
        "azure-firewall"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks-multi-region/aks-multi-cluster"
    },
    {
      "summary": "Learn how Microsoft Entra ID can help secure and protect Amazon Web Services (AWS) identity management and account access. Discover Microsoft security solutions.",
      "azureCategories": [
        "identity"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-aws-integration.png",
      "title": "Microsoft Entra identity management and access management for AWS",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "entra",
        "entra-id"
      ],
      "url": "/azure/architecture/reference-architectures/aws/aws-azure-ad-security"
    },
    {
      "summary": "Learn how to segregate operations that read data from those that update data, using the CQRS (Command and Query Responsibility Segregation) pattern.",
      "azureCategories": [
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/command-query-responsibility-segregation-separate-stores.png",
      "title": "CQRS pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-storage"
      ],
      "url": "/azure/architecture/patterns/cqrs"
    },
    {
      "summary": "Deploy components of an application into a separate process or container to provide isolation and encapsulation.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sidecar.png",
      "title": "Sidecar pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/patterns/sidecar"
    },
    {
      "summary": "Break down a task that performs complex processing into a series of separate elements that can be reused.",
      "azureCategories": [
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/pipes-filters-solution.png",
      "title": "Pipes and Filters pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-devops"
      ],
      "url": "/azure/architecture/patterns/pipes-and-filters"
    },
    {
      "summary": "Learn about the Gateway Aggregation pattern, which uses a gateway to aggregate many individual requests into a single request.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/gateway-aggregation-services.png",
      "title": "Gateway Aggregation pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-traffic-manager"
      ],
      "url": "/azure/architecture/patterns/gateway-aggregation"
    },
    {
      "summary": "Use load testing to tune the performance of an event streaming application. Fix partitioning issues and do parallel writes.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/event-streaming.png",
      "title": "Performance tuning - Event streaming",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-functions",
        "azure-iot-hub",
        "azure-cosmos-db"
      ],
      "url": "/azure/architecture/performance/event-streaming"
    },
    {
      "summary": "This article presents a reference architecture for serverless event ingestion and processing using Azure Functions.",
      "azureCategories": [
        "developer-tools",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/serverless-event-processing.png",
      "title": "Serverless event processing",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-functions",
        "azure-monitor",
        "azure-pipelines",
        "azure-storage"
      ],
      "url": "/azure/architecture/reference-architectures/serverless/event-processing"
    },
    {
      "summary": "Isolate elements of an application into pools so that if one fails, the others will continue to function.",
      "azureCategories": [
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/bulkhead-1.png",
      "title": "Bulkhead pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/patterns/bulkhead"
    },
    {
      "summary": "Use a token or key that provides clients with restricted direct access to a specific resource or service.",
      "azureCategories": [
        "compute",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/valet-key-pattern.png",
      "title": "Valet Key pattern",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-storage"
      ],
      "url": "/azure/architecture/patterns/valet-key"
    },
    {
      "summary": "This reference architecture shows an end-to-end stream processing pipeline, which ingests data, correlates records, and calculates a rolling average.",
      "azureCategories": [
        "analytics",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/stream-processing-asa.png",
      "title": "Stream processing with Azure Stream Analytics",
      "lastModified": "2022-07-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-event-hubs",
        "azure-monitor",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/reference-architectures/data/stream-processing-stream-analytics"
    },
    {
      "summary": "Visual assistant identifies image content and delivers related information. It can read business cards and barcodes and recognize people, places, and objects.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/visual-assistant.png",
      "title": "Visual assistant",
      "lastModified": "2022-07-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-bot-service",
        "ai-services"
      ],
      "url": "/azure/architecture/ai-ml/idea/visual-assistant"
    },
    {
      "summary": "Use Azure services with a bot assistant to improve interactions with customers and provide suggestions based on visual information.",
      "azureCategories": [
        "ai-machine-learning",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/bot-assistant-architecture.png",
      "title": "Retail assistant with visual capabilities",
      "lastModified": "2022-07-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "bing",
        "azure-bing-custom",
        "azure-bing-visual",
        "azure-bot-service",
        "ai-services"
      ],
      "url": "/azure/architecture/ai-ml/idea/retail-assistant-or-vacation-planner-with-visual-capabilities"
    },
    {
      "summary": "Learn how designs that use microservices can increase the velocity of application releases by deploying small, autonomous services independently.",
      "azureCategories": [
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/microservices-patterns.png",
      "title": "Design patterns for microservices",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cloud-services"
      ],
      "url": "/azure/architecture/microservices/design/patterns"
    },
    {
      "summary": "How to apply DevOps principles to data pipelines built according to the modern data warehouse (MDW) architectural pattern with Microsoft Azure.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/street-parking-availability.png",
      "title": "DataOps for the modern data warehouse",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-databricks",
        "azure-devops",
        "azure-key-vault",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/databases/architecture/dataops-mdw"
    },
    {
      "summary": "Use Azure IoT Central or individual Azure platform-as-a-service (PaaS) components to build, deploy, and manage internet-of-things (IoT) solutions.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/iotc-arch.png",
      "title": "Choose an Internet of Things (IoT) solution in Azure",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot-hub",
        "azure-iot-central"
      ],
      "url": "/azure/architecture/example-scenario/iot/iot-central-iot-hub-cheat-sheet"
    },
    {
      "summary": "This reference architecture illustrates a logic app that&apos;s running in Microsoft Azure, which is triggered by Azure Spring Apps.",
      "azureCategories": [
        "hybrid",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/gateway-logic-apps.png",
      "title": "On-premises data gateway for Azure Logic Apps",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-logic-apps",
        "azure-resource-manager",
        "azure-spring-apps"
      ],
      "url": "/azure/architecture/hybrid/gateway-logic-apps"
    },
    {
      "summary": "Details about the patterns and implementations used when the commercial software engineer team created the banking system cloud transformation solution.",
      "azureCategories": [
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/orchestration-based-saga-serverless-arch.png",
      "title": "Patterns and implementations for a banking cloud transformation",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-event-hubs",
        "azure-functions",
        "azure-kubernetes-service",
        "azure-pipelines"
      ],
      "url": "/azure/architecture/example-scenario/banking/patterns-and-implementations"
    },
    {
      "summary": "Explore the benefits, challenges, and best practices for Big Data architectures on Azure. Examine IoT architecture, a specialized subset of big data solutions.",
      "azureCategories": [
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/iot.png",
      "title": "Big data architecture style",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-lake-analytics",
        "azure-iot"
      ],
      "url": "/azure/architecture/guide/architecture-styles/big-data"
    },
    {
      "summary": "Learn how to establish non-production development and test environments for SAP NetWeaver in a Windows or Linux environment on Azure.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-sap-dev-test.png",
      "title": "Development and test environments for SAP workloads on Azure",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-resource-manager"
      ],
      "url": "/azure/architecture/example-scenario/apps/sap-dev-test"
    },
    {
      "summary": "Run OPM Flow reservoir simulation software and OPM ResInsight visualization software on an Azure HPC compute cluster and visualization VM.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-hpc-reservoir-simulation.png",
      "title": "Run reservoir simulation software on Azure",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cyclecloud",
        "azure-key-vault",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/example-scenario/infrastructure/reservoir-simulation"
    },
    {
      "summary": "This article explains the phases of migrating ecommerce infrastructure from on premises to Azure.",
      "azureCategories": [
        "databases",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cosmos-db-data-model.png",
      "title": "Visual search in retail with Azure Cosmos DB",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-cosmos-db",
        "azure-kubernetes-service",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/industries/retail/visual-search-use-case-overview"
    },
    {
      "summary": "Learn how to start from a carefully designed domain model to determine the right size for a microservice.",
      "azureCategories": [
        "integration",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/drone-delivery.png",
      "title": "Identify microservice boundaries",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-devops"
      ],
      "url": "/azure/architecture/microservices/model/microservice-boundaries"
    },
    {
      "summary": "Use domain-driven design in a microservices architecture to identify the entity and aggregate patterns, which help identify natural boundaries for the services.",
      "azureCategories": [
        "migration",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/drone-domain-driven-design.png",
      "title": "Using tactical DDD to design microservices",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-migrate"
      ],
      "url": "/azure/architecture/microservices/model/tactical-ddd"
    },
    {
      "summary": "Learn how to design and build a microservices architecture on Azure by following a reference implementation that illustrates best practices.",
      "azureCategories": [
        "developer-tools",
        "featured"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/drone-delivery-implementation.png",
      "title": "Design a microservices architecture",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/microservices/design/"
    },
    {
      "summary": "Learn about microservices on Azure, an architectural style for applications that are resilient, highly scalable, and independently deployable.",
      "azureCategories": [
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/microservices-logical.png",
      "title": "Microservices architecture design",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-devops"
      ],
      "url": "/azure/architecture/microservices/"
    },
    {
      "summary": "Conceptualize, architect, build, and deploy an application that uses projects from the CNCF after deployment of AKS.",
      "azureCategories": [
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cncf-architecture.png",
      "title": "Build CNCF projects by using Azure Kubernetes Service",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/example-scenario/apps/build-cncf-incubated-graduated-projects-aks"
    },
    {
      "summary": "Explore the benefits, challenges, and best practices for event-driven and IoT architectures on Azure.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/event-driven.svg",
      "title": "Event-driven architecture style",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/guide/architecture-styles/event-driven"
    },
    {
      "summary": "Learn how to design a hybrid Domain Name System (DNS) solution to resolve names for workloads that are hosted on-premises and in Microsoft Azure.",
      "azureCategories": [
        "hybrid",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-dns-infra.png",
      "title": "Design a hybrid Domain Name System solution with Azure",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-bastion",
        "azure-dns",
        "azure-expressroute",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/hybrid/hybrid-dns-infra"
    },
    {
      "summary": "Learn how to leverage Azure Arc for management, maintenance, and monitoring of SQL Server instances in on-premises and multi-cloud environments.",
      "azureCategories": [
        "hybrid",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/administer-sql-server-azure-arc.png",
      "title": "Optimize administration of SQL Server instances in on-premises and multi-cloud environments by using Azure Arc",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-arc",
        "azure-kubernetes-service",
        "azure-resource-manager",
        "azure-sql-managed-instance"
      ],
      "url": "/azure/architecture/hybrid/azure-arc-sql-server"
    },
    {
      "summary": "Learn about managing data in a microservices architecture. Data integrity and data consistency are critical challenges for microservices.",
      "azureCategories": [
        "databases",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/data-considerations.png",
      "title": "Data considerations for microservices",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-devops"
      ],
      "url": "/azure/architecture/microservices/design/data-considerations"
    },
    {
      "summary": "Bring workflows together and automate processes by connecting apps, data, services, and devices. See resources with integration guidelines and recommendations.",
      "azureCategories": [
        "hybrid",
        "integration",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/integration-basic-architecture-thumbnail.png",
      "title": "Integration architecture design",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-api-management",
        "azure-data-factory",
        "azure-event-grid",
        "azure-functions",
        "azure-logic-apps"
      ],
      "url": "/azure/architecture/integration/integration-start-here"
    },
    {
      "summary": "Overview of how to develop predictive maintenance (PdM) for manufacturing customers on Azure.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/data-science-diagram.png",
      "title": "Introduction to predictive maintenance in manufacturing",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-lake-storage",
        "azure-event-hubs",
        "azure-iot-hub",
        "azure-iot-edge",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/industries/manufacturing/predictive-maintenance-overview"
    },
    {
      "summary": "Identify speech in static video files and manage it as standard content with this speech-to-text solution. Jump to video points that contain specific phrases.",
      "azureCategories": [
        "ai-machine-learning",
        "media"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/digital-media-speech-text.png",
      "title": "Keyword search and speech-to-text",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cdn",
        "azure-cognitive-search",
        "azure-media-player",
        "azure-video-indexer",
        "azure-app-service"
      ],
      "url": "/azure/architecture/ai-ml/idea/digital-media-speech-text"
    },
    {
      "summary": "Learn about continuous integration and continuous delivery for microservices, including challenges and recommended approaches.",
      "azureCategories": [
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ci-cd-monolith.png",
      "title": "CI/CD for microservices architectures",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/microservices/ci-cd"
    },
    {
      "summary": "Learn about good API design in a microservices architecture. APIs should be efficient and have well-defined semantics and versioning schemes.",
      "azureCategories": [
        "web",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/api-design.png",
      "title": "Design APIs for microservices",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-devops"
      ],
      "url": "/azure/architecture/microservices/design/api-design"
    },
    {
      "summary": "Understand the benefits, challenges, and best practices of the Big Compute architecture style on Azure.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/big-compute-hybrid.png",
      "title": "Big compute architecture style",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-batch"
      ],
      "url": "/azure/architecture/guide/architecture-styles/big-compute"
    },
    {
      "summary": "An API gateway sits between clients and services and acts as a reverse proxy. Learn how to choose an API gateway technology for a microservice.",
      "azureCategories": [
        "web",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/gateway-thumbnail.png",
      "title": "Use API gateways in microservices",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway",
        "azure-api-management"
      ],
      "url": "/azure/architecture/microservices/design/gateway"
    },
    {
      "summary": "Deploy machine learning models to Azure Kubernetes Service (AKS) for high-scale production inference.",
      "azureCategories": [
        "ai-machine-learning",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/machine-learning-model-deployment-to-aks.png",
      "title": "Deploy machine learning models to AKS with Kubeflow",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-container-registry",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/ai-ml/idea/machine-learning-model-deployment-aks"
    },
    {
      "summary": "This solution delivers an optimized approach to disaster recovery of virtual machine (VM)-based user workloads hosted on Azure Stack Hub.",
      "azureCategories": [
        "hybrid",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-stack-vm-dr.png",
      "title": "Disaster recovery for Azure Stack Hub virtual machines",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-site-recovery",
        "azure-stack",
        "azure-stack-hub",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/hybrid/azure-stack-vm-disaster-recovery"
    },
    {
      "summary": "Use Microsoft Defender for Cloud and Microsoft Sentinel to monitor the security configuration and telemetry of on-premises and Azure operating system workloads.",
      "azureCategories": [
        "hybrid",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-security-monitoring.png",
      "title": "Monitor hybrid security using Microsoft Defender for Cloud and Microsoft Sentinel",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-log-analytics",
        "azure-monitor",
        "microsoft-defender",
        "defender-for-cloud",
        "microsoft-sentinel",
        "azure-stack"
      ],
      "url": "/azure/architecture/hybrid/hybrid-security-monitoring"
    },
    {
      "summary": "Learn about the benefits, challenges, and best practices for Web-Queue-Worker architectures on Azure.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/web-queue-worker-physical.png",
      "title": "Web-Queue-Worker architecture style",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service"
      ],
      "url": "/azure/architecture/guide/architecture-styles/web-queue-worker"
    },
    {
      "summary": "This scenario explains the architecture and considerations to take into account when installing and configuring Jenkins.",
      "azureCategories": [
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-jenkins.png",
      "title": "Run a Jenkins server on Azure",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-blob-storage",
        "azure-key-vault",
        "azure-monitor",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/example-scenario/apps/jenkins"
    },
    {
      "summary": "This reference architecture illustrates how to design and implement disaster recovery of Azure Stack HCI by using stretched clustering.",
      "azureCategories": [
        "hybrid",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure_stack_hci_dr.png",
      "title": "Use Azure Stack HCI stretched clusters for disaster recovery",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-backup",
        "azure-monitor",
        "azure-stack-hci"
      ],
      "url": "/azure/architecture/hybrid/azure-stack-hci-dr"
    },
    {
      "summary": "Learn how to extend rapid machine learning inference from the cloud to on-premises and edge locations with Azure Stack Edge.",
      "azureCategories": [
        "hybrid",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/deploy-ai-ml-azure-stack-edge.png",
      "title": "Deploy AI and machine learning computing on-premises and to the edge",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-registry",
        "azure-iot-edge",
        "azure-machine-learning",
        "azure-stack-edge"
      ],
      "url": "/azure/architecture/ai-ml/idea/deploy-ai-ml-azure-stack-edge"
    },
    {
      "summary": "Import threat indicators, view logs, and create rules to generate security alerts and incidents, and visualize threat intelligence data with Microsoft Sentinel.",
      "azureCategories": [
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sentinel-data-flow.png",
      "title": "Threat indicators for cyber threat intelligence in Microsoft Sentinel",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-logic-apps",
        "azure-monitor",
        "microsoft-sentinel"
      ],
      "url": "/azure/architecture/example-scenario/data/sentinel-threat-intelligence"
    },
    {
      "summary": "Learn about the tradeoffs between asynchronous messaging versus synchronous APIs for communication between microservices and some challenges in communication.",
      "azureCategories": [
        "integration",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/supervisor.png",
      "title": "Design interservice communication for microservices",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-devops"
      ],
      "url": "/azure/architecture/microservices/design/interservice-communication"
    },
    {
      "summary": "Learn how to design infrastructure for highly available virtualized and containerized workloads in remote office/branch office (ROBO) scenarios.",
      "azureCategories": [
        "hybrid",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-stack-robo.png",
      "title": "Use Azure Stack HCI switchless interconnect and lightweight quorum for remote office or branch office",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-arc",
        "azure-monitor",
        "azure-policy",
        "microsoft-defender",
        "defender-for-cloud",
        "azure-stack-hci"
      ],
      "url": "/azure/architecture/hybrid/azure-stack-robo"
    },
    {
      "summary": "Read about the benefits, challenges, and best practices of the microservices architecture style on Azure.",
      "azureCategories": [
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/microservices-logical.png",
      "title": "Microservice architecture style",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure"
      ],
      "url": "/azure/architecture/guide/architecture-styles/microservices"
    },
    {
      "summary": "Know the benefits, challenges, and best practices for N-tier architectures on Azure. An N-tier architecture splits an app into logical layers and physical tiers.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/n-tier-physical-bastion.png",
      "title": "N-tier architecture style",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-storage",
        "azure-cloud-services",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/guide/architecture-styles/n-tier"
    },
    {
      "summary": "How an actuarial developer can move an existing solution plus supporting infrastructure to Azure.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/process-files.png",
      "title": "Actuarial risk analysis and financial modeling",
      "lastModified": "2022-07-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-data-factory",
        "azure-cosmos-db",
        "azure-hdinsight",
        "azure-databricks"
      ],
      "url": "/azure/architecture/industries/finance/actuarial-risk-analysis-financial-model"
    },
    {
      "summary": "Learn about big data architectures that handle the ingestion, processing, and analysis of data too large or complex for traditional database systems.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/big-data-pipeline.png",
      "title": "Big data architectures",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-lake-analytics",
        "azure-iot-hub",
        "azure-machine-learning",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/databases/guide/big-data-architectures"
    },
    {
      "summary": "Guidance on using Content Delivery Networks (CDNs) to deliver high-bandwidth content hosted in Azure.",
      "azureCategories": [
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/caching-thumbnail.png",
      "title": "CDN guidance",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-storage",
        "azure-blob-storage"
      ],
      "url": "/azure/architecture/best-practices/cdn"
    },
    {
      "summary": "Use Azure Automation State Configuration to configure Windows and Linux systems.",
      "azureCategories": [
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/state-config.png",
      "title": "Azure Automation State Configuration",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-automation",
        "azure-monitor",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/example-scenario/state-configuration/state-configuration"
    },
    {
      "summary": "A detailed explanation of the MLOps maturity model stages which lists defining characteristics of each stage.",
      "azureCategories": [
        "developer-tools",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/data-science-initial-methodology.png",
      "title": "Machine Learning operations maturity model",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/ai-ml/guide/mlops-maturity-model"
    },
    {
      "summary": "Build a multitenant SaaS solution on Azure, designed for high availability, scalability, data security and isolation using the App Service and Azure Kubernetes Service.",
      "azureCategories": [
        "identity"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/multitenant-saas.png",
      "title": "Multitenant SaaS on Azure",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-app-service",
        "azure-dns",
        "azure-front-door",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/example-scenario/multi-saas/multitenant-saas"
    },
    {
      "summary": "Learn how caching can improve the performance and scalability of a system by copying frequently accessed data to fast storage close to the application.",
      "azureCategories": [
        "databases",
        "identity",
        "web",
        "storage",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/caching-thumbnail.png",
      "title": "Caching guidance",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cache-redis"
      ],
      "url": "/azure/architecture/best-practices/caching"
    },
    {
      "summary": "Develop and deploy serverless microservices across cloud providers with the open-source Serverless Framework and Serverless Multicloud Library.",
      "azureCategories": [
        "developer-tools",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/multi-cloud-serverless-architecture.png",
      "title": "Multicloud solutions with the Serverless Framework",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-functions"
      ],
      "url": "/azure/architecture/example-scenario/serverless/serverless-multicloud"
    },
    {
      "summary": "Use this chart and other information to decide which multiparty computing services to use for your solution.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/multiparty-compute-options.png",
      "title": "Choose an Azure multiparty computing service",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-kubernetes-service",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/guide/technology-choices/multiparty-computing-service"
    },
    {
      "summary": "Use Azure Route Server to manage the dynamic routing between NVAs and virtual networks. Simplify NVA maintenance, and avoid manually updating route tables.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/manage-routing-azure-route-server-architecture-thumbnail.png",
      "title": "Update route tables by using Azure Route Server",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-storage",
        "azure-virtual-network",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/example-scenario/networking/manage-routing-azure-route-server"
    },
    {
      "summary": "View guidance for how to separate data partitions to be managed and accessed separately. Understand horizontal, vertical, and functional partitioning strategies.",
      "azureCategories": [
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/data-partitioning-03.png",
      "title": "Data partitioning guidance",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage"
      ],
      "url": "/azure/architecture/best-practices/data-partitioning"
    },
    {
      "summary": "Learn about non-relational databases that store data as key/value pairs, graphs, time series, objects, and other storage models, based on data requirements.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/graph.png",
      "title": "Non-relational data and NoSQL",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-blob-storage",
        "azure-data-lake"
      ],
      "url": "/azure/architecture/data-guide/big-data/non-relational-data"
    },
    {
      "summary": "View guidance on separating data partitions to be managed and accessed separately in different services, such as Azure Search, Azure storage queues, and more.",
      "azureCategories": [
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/point-shardlet.png",
      "title": "Data partitioning strategies",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-table-storage"
      ],
      "url": "/azure/architecture/best-practices/data-partitioning-strategies"
    },
    {
      "summary": "Learn about what needs to be done in each SDK for devices to achieve third-party token-server authentication.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-iot-client-sdk-support.png",
      "title": "Azure IoT client SDK support for third-party token servers",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot",
        "azure-iot-hub"
      ],
      "url": "/azure/architecture/guide/iot/azure-iot-client-sdk-support"
    },
    {
      "summary": "Create a pipeline for ingesting and analyzing RSS news feed data using Azure Cosmos DB, Azure Cognitive Services, and other Azure services.",
      "azureCategories": [
        "analytics",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mass-ingestion-newsfeeds-architecture.png",
      "title": "Analyze news feeds with near real-time analytics using image and natural language processing",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-functions",
        "azure-service-bus",
        "azure-translator-text",
        "azure-face"
      ],
      "url": "/azure/architecture/example-scenario/ai/news-feed-ingestion-and-near-real-time-analysis"
    },
    {
      "summary": "Learn how to apply the machine learning operations (MLOps) maturity model to implement a machine learning solution for predicting product shipping levels.",
      "azureCategories": [
        "developer-tools",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/data-sciene-lifecycle-model-flow.png",
      "title": "Machine learning operations (MLOps) framework to upscale machine learning lifecycle with Azure Machine Learning",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/ai-ml/guide/mlops-technical-paper"
    },
    {
      "summary": "Learn how to track how users use your distributed applications and services, trace resource utilization, and monitor the health and performance.",
      "azureCategories": [
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/pull-model.png",
      "title": "Monitoring and diagnostics guidance",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-monitor"
      ],
      "url": "/azure/architecture/best-practices/monitoring"
    },
    {
      "summary": "Learn about Azure load-balancing services and how you can use them to distribute your workloads across multiple computing resources.",
      "azureCategories": [
        "web",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/load-balancing-decision-tree.png",
      "title": "Load-balancing options",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-load-balancer",
        "azure-front-door",
        "azure-application-gateway",
        "azure-traffic-manager"
      ],
      "url": "/azure/architecture/guide/technology-choices/load-balancing-overview"
    },
    {
      "summary": "Learn about online analytical processing (OLAP) solutions to organize large databases and support complex analysis without affecting transactional systems.",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/semantic-modeling.png",
      "title": "Online analytical processing (OLAP)",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-analysis-services"
      ],
      "url": "/azure/architecture/data-guide/relational-data/online-analytical-processing"
    },
    {
      "summary": "This article provides an overview of the Azure database solutions described in Azure Architecture Center.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-design-types.png",
      "title": "Databases architecture design",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-database-mariadb",
        "azure-database-migration",
        "azure-database-mysql",
        "azure-database-postgresql"
      ],
      "url": "/azure/architecture/databases/"
    },
    {
      "summary": "Use Azure Kubernetes Service (AKS) and Azure Service Bus to implement high-volume batch transaction processing.",
      "azureCategories": [
        "compute",
        "hybrid",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/process-batch-transactions.png",
      "title": "High-volume batch transaction processing",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-service-bus",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/process-batch-transactions"
    },
    {
      "summary": "Learn how to add communications features to your applications by using Azure Communication Services.",
      "azureCategories": [
        "identity"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-identity.png",
      "title": "Azure Communication Services architecture design",
      "lastModified": "2022-07-25T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-communication-services",
        "entra",
        "entra-id",
        "azure-functions"
      ],
      "url": "/azure/architecture/guide/mobile/azure-communication-services-architecture"
    },
    {
      "summary": "This architecture provides an example delta lake for travel booking, where large amounts of raw documents are generated at a high frequency.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/build-data-lake-support-adhoc-queries-online-02-thumb.png",
      "title": "Build a delta lake to support ad hoc queries in online leisure and travel booking",
      "lastModified": "2022-07-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-data-lake-storage",
        "azure-databricks",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/solution-ideas/articles/build-data-lake-support-adhoc-queries-online"
    },
    {
      "summary": "Use Azure Functions and Azure Cosmos DB to build globally distributed, scalable, serverless applications.",
      "azureCategories": [
        "databases",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/serverless-apps-using-cosmos-db.png",
      "title": "Serverless apps using Azure Cosmos DB",
      "lastModified": "2022-07-22T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-functions"
      ],
      "url": "/azure/architecture/databases/idea/serverless-apps-using-cosmos-db"
    },
    {
      "summary": "Use convolutional neural networks (CNNs) to classify large volumes of images efficiently. Take advantage of CNN layers to identify elements in images.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/image-classification-with-convolutional-neural-networks-thumbnail.png",
      "title": "Image classification with convolutional neural networks (CNNs)",
      "lastModified": "2022-07-22T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-container-registry",
        "azure-data-science-vm",
        "azure-kubernetes-service",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/ai-ml/idea/image-classification-with-convolutional-neural-networks"
    },
    {
      "summary": "Use Azure DNS Private Resolver to simplify hybrid recursive domain name system (DNS) resolution for on-premises and Azure workloads.",
      "azureCategories": [
        "hybrid",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-dns-private-resolver-thumbnail.png",
      "title": "Azure DNS Private Resolver",
      "lastModified": "2022-07-19T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-dns",
        "azure-expressroute",
        "azure-firewall",
        "azure-virtual-network",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/example-scenario/networking/azure-dns-private-resolver"
    },
    {
      "summary": "BrainLit's UVEN system uses IoT and Azure Sphere to provide smart, safe, secure virus disinfection and healthy, human-optimized lighting.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/uven-system-thumbnail.png",
      "title": "UVEN smart and secure disinfection and lighting",
      "lastModified": "2022-07-19T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-sphere"
      ],
      "url": "/azure/architecture/solution-ideas/articles/uven-disinfection"
    },
    {
      "summary": "Enable a risk stratification model for surgeries.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/surgery-risk-models.png",
      "title": "Implement risk prediction for surgeries",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning",
        "azure-synapse-analytics",
        "azure-data-factory",
        "azure-data-lake",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/example-scenario/ai/risk-stratification-surgery"
    },
    {
      "summary": "Learn about Anomaly Detector and see how anomaly detection models are selected with time-series data.",
      "azureCategories": [
        "analytics",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/anomaly-detector-process.png",
      "title": "Anomaly detector process",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-databricks",
        "azure-service-bus",
        "azure-storage-accounts"
      ],
      "url": "/azure/architecture/solution-ideas/articles/anomaly-detector-process"
    },
    {
      "summary": "With this mixed reality scenario, clients, designers, and onsite engineers can easily share and review designs as 3D holograms in the context of their environment.",
      "azureCategories": [
        "mixed-reality"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/collaborative-design-review-powered-by-mixed-reality.png",
      "title": "Design review powered by mixed reality",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-blob-storage",
        "azure-cosmos-db",
        "azure-spatial-anchors"
      ],
      "url": "/azure/architecture/solution-ideas/articles/collaborative-design-review-powered-by-mixed-reality"
    },
    {
      "summary": "Optimize the media rendering process with a step-by-step HPC solution architecture from Azure that combines Azure CycleCloud and HPC Cache.",
      "azureCategories": [
        "storage",
        "compute",
        "media"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-batch-rendering.png",
      "title": "HPC media rendering",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-batch",
        "azure-cyclecloud",
        "azure-fxt-edge-filer",
        "azure-vm-scalesets"
      ],
      "url": "/azure/architecture/solution-ideas/articles/azure-batch-rendering"
    },
    {
      "summary": "Use this architecture to back up on-premises applications and data with Azure Backup and Blob storage applications.",
      "azureCategories": [
        "storage",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/backup-archive-cloud-application.png",
      "title": "Back up on-premises applications and data to the cloud",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-backup",
        "azure-blob-storage",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/solution-ideas/articles/backup-archive-on-premises-applications"
    },
    {
      "summary": "In this architecture, archive your on-premises data to the cloud using StorSimple and Azure Blob Storage.",
      "azureCategories": [
        "storage",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/backup-archive-cloud-application.png",
      "title": "Archive on-premises data to the cloud",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-storsimple",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/solution-ideas/articles/backup-archive-on-premises"
    },
    {
      "summary": "Understand how to improve cross-cloud scalability with solution architecture that includes Azure Stack.",
      "azureCategories": [
        "hybrid",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cross-cloud-scaling.png",
      "title": "Cross-cloud scaling with Azure Functions",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-functions",
        "azure-stack",
        "azure-traffic-manager"
      ],
      "url": "/azure/architecture/solution-ideas/articles/cross-cloud-scaling"
    },
    {
      "summary": "This solution consolidates data from multiple business systems and then surfaces the data through web and mobile front ends. This consolidation helps improve employee productivity and helps speed up decision making.",
      "azureCategories": [
        "migration",
        "mobile"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/adding-a-modern-web-and-mobile-frontend-to-a-legacy-claims-processing-application.png",
      "title": "Add a mobile front end to a legacy app",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "entra-external-id",
        "azure",
        "azure-sql-database",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/solution-ideas/articles/adding-a-modern-web-and-mobile-frontend-to-a-legacy-claims-processing-application"
    },
    {
      "summary": "Use this architecture to accelerate development of reliable, high-performing chat applications.",
      "azureCategories": [
        "hybrid",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cross-platform-chat.png",
      "title": "Cross-platform chat",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-signalr-service"
      ],
      "url": "/azure/architecture/solution-ideas/articles/cross-platform-chat"
    },
    {
      "summary": "Learn how to build high availability of services into your business continuity and disaster recovery strategy.",
      "azureCategories": [
        "management-and-governance",
        "networking",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/build-high-availability-into-your-bcdr-strategy.png",
      "title": "Build high availability into your BCDR strategy",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-load-balancer",
        "azure-sql-database",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-app-service"
      ],
      "url": "/azure/architecture/solution-ideas/articles/build-high-availability-into-your-bcdr-strategy"
    },
    {
      "summary": "This example demonstrates how manufacturers can connect their assets to the cloud using OPC UA and the Industrial Components.",
      "azureCategories": [
        "iot",
        "analytics",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/condition-monitoring-thumbnail-new.png",
      "title": "Condition monitoring for industrial IoT",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-lake",
        "azure-event-hubs",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-monitor"
      ],
      "url": "/azure/architecture/solution-ideas/articles/condition-monitoring"
    },
    {
      "summary": "Learn how to integrate your app with Azure Event Grid and take advantage of its reliable delivery, advanced routing, and direct integration.",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/application-integration-using-event-grid.png",
      "title": "Application integration using Azure Event Grid",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-grid",
        "azure-functions",
        "azure-logic-apps"
      ],
      "url": "/azure/architecture/solution-ideas/articles/application-integration-using-event-grid"
    },
    {
      "summary": "Learn how to orchestrate CI/CD of containers using Kubernetes in Azure Kubernetes Service, so you can achieve replicable, manageable clusters of containers.",
      "azureCategories": [
        "devops",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cicd-for-containers.png",
      "title": "CI/CD for containers",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-monitor",
        "azure-container-registry",
        "azure-devops",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/solution-ideas/articles/cicd-for-containers"
    },
    {
      "summary": "Demonstrates how to combine real-time aircraft data with analytics to create a solution for predictive aircraft engine monitoring and health.",
      "azureCategories": [
        "analytics",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aircraft-engine-monitoring-for-predictive-maintenance-in-aerospace.png",
      "title": "Predictive aircraft engine monitoring",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-event-hubs",
        "azure-hdinsight",
        "azure-machine-learning",
        "azure-stream-analytics",
        "azure-monitor"
      ],
      "url": "/azure/architecture/solution-ideas/articles/aircraft-engine-monitoring-for-predictive-maintenance-in-aerospace"
    },
    {
      "summary": "Create solutions with touch-free interfaces and other intelligent and perceptive IoT devices. The solutions are backed by the storage, compute, AI, and machine learning capabilities of the Azure cloud.",
      "azureCategories": [
        "iot",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/avanade-contactless-interface-iot-edge-thumbnail.png",
      "title": "Contactless IoT interfaces with Azure intelligent edge",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "ai-services",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-storage",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/solution-ideas/articles/contactless-interfaces"
    },
    {
      "summary": "This architecture allows you to combine any data at any scale with custom machine learning and get near real-time data analytics on streaming services.",
      "azureCategories": [
        "analytics",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/advanced-analytics-on-big-data.png",
      "title": "Advanced analytics architecture",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-analysis-services",
        "azure-blob-storage",
        "azure-cosmos-db",
        "azure-synapse-analytics",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/advanced-analytics-on-big-data"
    },
    {
      "summary": "Build a real-time data ingestion/processing pipeline to ingest and process messages from IoT devices into a big data analytic platform in Azure.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-realtime-analytics-vehicle-data-1.png",
      "title": "Process real-time vehicle data using IoT",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-iot-edge",
        "azure-sphere",
        "azure-stream-analytics",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/example-scenario/data/realtime-analytics-vehicle-iot"
    },
    {
      "summary": "Improve operations by using Azure Databricks, Delta Lake, and MLflow for data science and machine learning. Develop, train, and deploy machine learning models.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-databricks-data-science-machine-learning-architecture-thumbnail.png",
      "title": "Data science and machine learning with Azure Databricks",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-databricks",
        "azure-data-lake-storage",
        "azure-kubernetes-service",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/solution-ideas/articles/azure-databricks-data-science-machine-learning"
    },
    {
      "summary": "Create a COVID-19 safe environment that monitors social distance, mask/PPE use, and occupancy requirements with CCTVs and Azure IoT Edge, Stream Analytics, and Machine Learning.",
      "azureCategories": [
        "iot",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/bosch-cctv-iot-edge-covid-19-safe-environment-mask-detection.png",
      "title": "COVID-19 safe environments with IoT Edge monitoring and alerting",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-digital-twins",
        "azure-iot-edge",
        "azure-kubernetes-service",
        "azure-service-bus",
        "azure-stream-analytics",
        "azure-monitor"
      ],
      "url": "/azure/architecture/solution-ideas/articles/cctv-iot-edge-for-covid-19-safe-environment-and-mask-detection"
    },
    {
      "summary": "Explore big-compute solutions with Azure Batch, and use HPC cloud systems for cloud-native application and batch processing.",
      "azureCategories": [
        "compute",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/big-compute-with-azure-batch.png",
      "title": "HPC system and big-compute solutions",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-batch",
        "azure-storage-accounts",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/solution-ideas/articles/big-compute-with-azure-batch"
    },
    {
      "summary": "Use TimeXtender to build a modern data estate that is ready for cloud scale analytics using a drag-and-drop user interface, with definitions stored in a metadata repository.",
      "azureCategories": [
        "analytics",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cloud-scale-analytics-with-discovery-hub.png",
      "title": "TimeXtender with cloud scale analytics",
      "lastModified": "2022-07-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-analysis-services",
        "azure-data-lake-storage",
        "azure-databricks",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/solution-ideas/articles/cloud-scale-analytics-with-discovery-hub"
    },
    {
      "summary": "Migrate an Oracle database and its applications to Azure. Use Oracle Active Data Guard for the database, and use Azure Load Balancer for the application tier.",
      "azureCategories": [
        "databases",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/reference-architecture-for-oracle-database-migration-to-azure.png",
      "title": "Oracle database migration to Azure",
      "lastModified": "2022-07-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-load-balancer",
        "azure-expressroute",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/databases/idea/reference-architecture-for-oracle-database-migration-to-azure"
    },
    {
      "summary": "Deploy existing microservice applications with Azure Container Apps.",
      "azureCategories": [
        "developer-tools",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/microservices-with-container-apps-runtime-diagram.png",
      "title": "Deploy microservices with Azure Container Apps",
      "lastModified": "2022-07-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-apps",
        "azure-cosmos-db",
        "azure-service-bus"
      ],
      "url": "/azure/architecture/example-scenario/serverless/microservices-with-container-apps"
    },
    {
      "summary": "Use simulated and actual applications and existing workloads to monitor the reaction of the solution infrastructure for scalability and performance.",
      "azureCategories": [
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/banking-system-solution-arch.png",
      "title": "Banking system cloud transformation on Azure",
      "lastModified": "2022-07-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-kubernetes-service",
        "azure-monitor",
        "azure-pipelines",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/example-scenario/banking/banking-system-cloud-transformation"
    },
    {
      "summary": "Create ETL pipelines for batch and streaming data with Azure Databricks to simplify data lake ingestion at any scale.",
      "azureCategories": [
        "analytics",
        "iot",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ingest-etl-stream-with-adb.png",
      "title": "Ingestion, ETL, and stream processing pipelines with Azure Databricks and Delta Lake",
      "lastModified": "2022-07-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-databricks",
        "azure-data-lake-storage",
        "azure-iot-hub",
        "azure-data-factory",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/solution-ideas/articles/ingest-etl-stream-with-adb"
    },
    {
      "summary": "Build solutions that integrate data from many IoT devices into a comprehensive data analysis architecture to improve and automate decision making.",
      "azureCategories": [
        "iot",
        "analytics",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-big-data-with-iot.png",
      "title": "IoT and data analytics",
      "lastModified": "2022-07-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-iot-hub",
        "azure-sql-database",
        "azure-table-storage"
      ],
      "url": "/azure/architecture/example-scenario/data/big-data-with-iot"
    },
    {
      "summary": "Create personalized marketing offers and improve customer response using big-data insights based on Azure Machine Learning and Stream Analytics.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/personalized-marketing.png",
      "title": "Create personalized marketing solutions in near real time",
      "lastModified": "2022-07-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-event-hubs",
        "azure-functions",
        "azure-machine-learning",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/ai-ml/idea/personalized-marketing"
    },
    {
      "summary": "View a reference architecture that describes how to enable real-time anomaly detection for conveyor belts.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/real-time-anomaly-detection.png",
      "title": "Implement real-time anomaly detection for conveyor belts",
      "lastModified": "2022-07-12T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning",
        "azure-iot-hub",
        "azure-data-factory",
        "azure-databricks",
        "azure-data-lake"
      ],
      "url": "/azure/architecture/example-scenario/ai/real-time-anomaly-detection-conveyor-belt"
    },
    {
      "summary": "Control IoT devices by using Azure Speech Service, Language Understanding Service (LUIS), and Azure Bot Service to create natural conversational interfaces.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/controlling-iot-devices-using-voice-thumbnail-new.png",
      "title": "Control IoT devices with a voice assistant app",
      "lastModified": "2022-07-12T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-bot-service",
        "azure-iot-hub",
        "ai-services",
        "azure-speech"
      ],
      "url": "/azure/architecture/solution-ideas/articles/iot-controlling-devices-with-voice-assistant"
    },
    {
      "summary": "Use Internet of Things technologies with the Project 15 Open Platform to accelerate innovation in species tracking, ecosystem monitoring, and other areas.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/project-15-ref-architecture-thumbnail.png",
      "title": "Project 15 Open Platform IoT sustainability",
      "lastModified": "2022-07-12T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-grid",
        "azure-event-hubs",
        "azure-functions",
        "azure-iot-hub",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/solution-ideas/articles/project-15-iot-sustainability"
    },
    {
      "summary": "Learn how a web app can use Azure Digital Twins and Autodesk Forge API to accelerate the process of creating digital twins from Build Information Modeling (BIM) models.",
      "azureCategories": [
        "integration",
        "iot",
        "web",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-digital-twins-builder-thumbnail-new.png",
      "title": "Azure digital twins builder",
      "lastModified": "2022-07-12T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-digital-twins",
        "azure-functions",
        "static-web-apps"
      ],
      "url": "/azure/architecture/solution-ideas/articles/azure-digital-twins-builder"
    },
    {
      "summary": "Learn about high-availability (HA) and disaster recovery (DR) options when deploying multitier infrastructure-as-a-service (IaaS) apps to Azure.",
      "azureCategories": [
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ha-decision-tree.png",
      "title": "High availability and disaster recovery scenarios for IaaS apps",
      "lastModified": "2022-07-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-site-recovery",
        "azure-virtual-machines",
        "azure-disk-storage"
      ],
      "url": "/azure/architecture/example-scenario/infrastructure/iaas-high-availability-disaster-recovery"
    },
    {
      "summary": "Learn how to run IBM Maximo Application Suite (MAS) on Azure. See guidance for designing and implementing cloud solutions with MAS 8.5 and up that use Azure.",
      "azureCategories": [
        "compute",
        "containers",
        "storage",
        "analytics",
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/deploy-ibm-maximo-application-suite-thumbnail.png",
      "title": "Deploy IBM Maximo Application Suite on Azure",
      "lastModified": "2022-07-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-files",
        "azure-load-balancer",
        "azure-redhat-openshift",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/apps/deploy-ibm-maximo-application-suite"
    },
    {
      "summary": "This example scenario implements an e-commerce front end using Azure platform as a service tools, which allow you to handle increases in transactions.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-ecommerce-scenario.png",
      "title": "E-commerce front end",
      "lastModified": "2022-07-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-external-id",
        "azure",
        "azure-cdn",
        "ai-services",
        "azure-traffic-manager",
        "azure-app-service"
      ],
      "url": "/azure/architecture/example-scenario/apps/ecommerce-scenario"
    },
    {
      "summary": "Keep sensitive user and payment data from an e-commerce website secure by using the Microsoft Azure App Service Environment.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ecommerce-website-running-in-secured-ase.png",
      "title": "E-commerce website running in secured App Service Environment",
      "lastModified": "2022-07-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-app-service",
        "azure-cache-redis",
        "azure-cdn",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/web-apps/idea/ecommerce-website-running-in-secured-ase"
    },
    {
      "summary": "This reference architecture shows a serverless web application, which serves static content from Azure Blob Storage and implements an API using Azure Functions.",
      "azureCategories": [
        "web",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/serverless-web-app.png",
      "title": "Serverless web application",
      "lastModified": "2022-07-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-api-management",
        "azure-blob-storage",
        "azure-cdn",
        "azure-functions",
        "azure-monitor"
      ],
      "url": "/azure/architecture/web-apps/serverless/architectures/web-app"
    },
    {
      "summary": "Build and implement a mobile client app architecture that offers authentication abilities and social image sharing with a companion web app, even while offline.",
      "azureCategories": [
        "mobile",
        "web",
        "identity"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/social-mobile-and-web-app-with-authentication.png",
      "title": "Social app for mobile and web with authentication",
      "lastModified": "2022-07-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-functions",
        "azure-traffic-manager",
        "vs",
        "dotnet",
        "xamarin"
      ],
      "url": "/azure/architecture/solution-ideas/articles/social-mobile-and-web-app-with-authentication"
    },
    {
      "summary": "Build scalable e-commerce web apps with Azure Functions and Web Apps so you can create personalized experiences while Azure takes care of the infrastructure.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/scalable-ecommerce-web-app.png",
      "title": "Architect scalable e-commerce web app",
      "lastModified": "2022-07-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-functions",
        "azure-sql-database",
        "azure-app-service"
      ],
      "url": "/azure/architecture/web-apps/idea/scalable-ecommerce-web-app"
    },
    {
      "summary": "Learn how to create a resilient multitier web application built for high availability and disaster recovery on Azure.",
      "azureCategories": [
        "web",
        "featured"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-disaster-recovery-multi-tier-app.png",
      "title": "Multi-tier web application built for HA/DR",
      "lastModified": "2022-07-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-arc",
        "sql-server",
        "windows"
      ],
      "url": "/azure/architecture/example-scenario/infrastructure/multi-tier-app-disaster-recovery"
    },
    {
      "summary": "See Azure options for orchestrating, storing, building, deploying, and using custom document processing models.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/build-deploy-custom-models-thumb.png",
      "title": "Custom document processing models on Azure",
      "lastModified": "2022-07-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "document-intelligence",
        "ai-services",
        "azure-logic-apps",
        "azure-machine-learning-studio",
        "azure-storage"
      ],
      "url": "/azure/architecture/ai-ml/architecture/build-deploy-custom-models"
    },
    {
      "summary": "Use machine learning and responsible AI to predict student attrition. Understand factors that affect education. Provide unbiased support for student success.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/student-attrition-prediction-thumbnail.png",
      "title": "Predict student attrition",
      "lastModified": "2022-07-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-machine-learning",
        "azure-sql-database",
        "azure-synapse-analytics",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/example-scenario/ai/student-attrition-prediction"
    },
    {
      "summary": "Learn how to design a hybrid update management solution to manage updates on both Microsoft Azure and on-premises Windows and Linux computers.",
      "azureCategories": [
        "hybrid",
        "management-and-governance",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-update-mgmt.png",
      "title": "Azure Automation update management",
      "lastModified": "2022-07-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-automation",
        "azure-log-analytics",
        "azure-monitor",
        "azure-resource-manager",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/hybrid/azure-update-mgmt"
    },
    {
      "summary": "Learn how subject matter expertise and deep reinforcement learning are used in machine teaching to turn automated control systems into autonomous systems.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/machine-teaching-1-4.png",
      "title": "Use subject matter expertise in machine teaching and reinforcement learning",
      "lastModified": "2022-07-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning",
        "azure-container-instances",
        "azure-storage",
        "azure-iot"
      ],
      "url": "/azure/architecture/solution-ideas/articles/machine-teaching"
    },
    {
      "summary": "Deploy Cognizant Safe Buildings, IoT, and Azure services to protect buildings from COVID-19 outbreaks.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/safe-building-arch-design-thumbnail.png",
      "title": "Cognizant Safe Buildings with IoT and Azure",
      "lastModified": "2022-07-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-kubernetes-service",
        "azure-stream-analytics",
        "azure-databricks"
      ],
      "url": "/azure/architecture/solution-ideas/articles/safe-buildings"
    },
    {
      "summary": "Use CluedIn eventual connectivity data integration to blend data from many siloed data sources and prepare it for analytics and business operations.",
      "azureCategories": [
        "databases",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cluedin-architecture-thumb.png",
      "title": "Master data management with Azure and CluedIn",
      "lastModified": "2022-07-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-sql-database",
        "azure-synapse-analytics",
        "azure-monitor"
      ],
      "url": "/azure/architecture/databases/architecture/cluedin"
    },
    {
      "summary": "This article describes an example workload of creating multiple Active Directory forests by using Azure Virtual Desktop.",
      "azureCategories": [
        "windows-virtual-desktop"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-virtual-desktop-multi-forest-adds.png",
      "title": "Multiple forests with AD DS and Microsoft Entra ID",
      "lastModified": "2022-07-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-desktop",
        "entra",
        "entra-id",
        "azure-expressroute",
        "azure-storage"
      ],
      "url": "/azure/architecture/example-scenario/wvd/multi-forest"
    },
    {
      "summary": "This architecture shows how the Raincode COBOL compiler modernizes mainframe legacy applications.",
      "azureCategories": [
        "integration",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/raincode-reference-architecture-02.png",
      "title": "Rehost mainframe applications to Azure with Raincode compilers",
      "lastModified": "2022-07-05T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-kubernetes-service",
        "azure-files",
        "azure-expressroute",
        "azure-load-balancer"
      ],
      "url": "/azure/architecture/reference-architectures/app-modernization/raincode-reference-architecture"
    },
    {
      "summary": "Use CluedIn to migrate your master data services solution to Azure, by using CluedIn and Azure Purview.",
      "azureCategories": [
        "databases",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/migrate-mds-to-azure-with-cluedin.png",
      "title": "Migrate master data services to Azure with CluedIn and Azure Purview",
      "lastModified": "2022-07-05T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "microsoft-purview",
        "azure",
        "azure-data-factory",
        "azure-sql-database",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/databases/architecture/migrate-master-data-services-with-cluedin"
    },
    {
      "summary": "Best practices for implementing access controls as per Requirement 7, 8, and 9 of PCI-DSS 3.2.1.",
      "azureCategories": [
        "containers",
        "identity"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-pci.png",
      "title": "Access management of an AKS baseline cluster for a PCI-DSS 3.2.1 (Part 6 of 9)",
      "lastModified": "2022-07-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "entra",
        "entra-id"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks-pci/aks-pci-identity"
    },
    {
      "summary": "Migrate an on-premises IBM AIX system and web application to a highly available, secure RedHat Enterprise Linux solution in Azure.",
      "azureCategories": [
        "migration",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/rhel-azure-system.png",
      "title": "AIX UNIX on-premises to Azure Linux migration",
      "lastModified": "2022-07-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-netapp-files",
        "azure-site-recovery",
        "azure-sql-database",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/unix-migration/migrate-aix-azure-linux"
    },
    {
      "summary": "Restrict communications between two Azure App Service backend services on both the application and network layers.",
      "azureCategories": [
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/service-to-service-architecture.png",
      "title": "Restrict interservice communications",
      "lastModified": "2022-07-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "entra",
        "entra-id",
        "azure-app-service"
      ],
      "url": "/azure/architecture/example-scenario/service-to-service/restrict-communications"
    },
    {
      "summary": "Refactor your Oracle database with Azure Database Migration Service and move it to PostgreSQL.",
      "azureCategories": [
        "databases",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/refactor.png",
      "title": "Oracle database migration: Refactor",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-database-postgresql",
        "azure-database-migration",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/oracle-migrate/oracle-migration-refactor"
    },
    {
      "summary": "This example illustrates a migration of AIX logical partitions (LPARs) to Skytap on Azure.",
      "azureCategories": [
        "hybrid",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/migrate-aix-workloads-to-azure-with-skytap.png",
      "title": "Migrate AIX workloads to Azure with Skytap",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-network",
        "azure-private-link",
        "azure-expressroute",
        "azure-virtual-machines",
        "azure-data-box-family"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/migrate-aix-workloads-to-azure-with-skytap"
    },
    {
      "summary": "This article provides an overview of an implementation for a scalable cloud load testing pipeline.",
      "azureCategories": [
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/load-testing-pipeline-jmeter.png",
      "title": "JMeter implementation for a load testing pipeline",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-instances",
        "azure-pipelines",
        "azure-container-registry"
      ],
      "url": "/azure/architecture/example-scenario/banking/jmeter-load-testing-pipeline-implementation-reference"
    },
    {
      "summary": "This example architecture shows how to use the native IBM i backup and recovery services with Microsoft Azure components.",
      "azureCategories": [
        "hybrid",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/migrate-ibm-i-series-to-azure-with-skytap.png",
      "title": "Migrate IBM i series to Azure with Skytap",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-network",
        "azure-private-link",
        "azure-expressroute",
        "azure-virtual-machines",
        "azure-data-box-family"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/migrate-ibm-i-series-to-azure-with-skytap"
    },
    {
      "summary": "Learn about Oracle database migration paths and the methods you use to migrate your schema to SQL or PostgreSQL.",
      "azureCategories": [
        "databases",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/oracle-migration-process-to-sql-pg.png",
      "title": "Oracle database migration decision process",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-database-migration",
        "azure-managed-applications",
        "azure-sql-database",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/example-scenario/oracle-migrate/oracle-migration-overview"
    },
    {
      "summary": "Use Azure services to re-engineer mainframe batch applications. This architecture change can reduce costs and improve scalability.",
      "azureCategories": [
        "developer-tools",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-reengineered-mainframe-batch-application-thumb.png",
      "title": "Re-engineer mainframe batch applications on Azure",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-databricks",
        "azure-kubernetes-service",
        "azure-sql-database",
        "azure-storage"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/reengineer-mainframe-batch-apps-azure"
    },
    {
      "summary": "This example describes a data-first approach to middleware integration that enables IBM message queues (MQs).",
      "azureCategories": [
        "hybrid",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/integrate-ibm-message-queues-azure.png",
      "title": "Integrate IBM mainframe and midrange message queues with Azure",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-logic-apps",
        "azure-sql-database",
        "azure-sql-managed-instance",
        "azure-sql-virtual-machines",
        "azure-database-postgresql"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/integrate-ibm-message-queues-azure"
    },
    {
      "summary": "Learn how to use the Automated COBOL Refactoring solution from Advanced to modernize your mainframe COBOL applications, run them on Azure, and reduce costs.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/refactor-mainframe-applications-advanced-post.png",
      "title": "Refactor mainframe applications with Advanced",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-files",
        "azure-load-balancer",
        "azure-sql-database",
        "azure-storage",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/refactor-mainframe-applications-advanced"
    },
    {
      "summary": "See an architecture that uses Astadia to migrate COBOL applications from an on-premises environment to the cloud.",
      "azureCategories": [
        "migration",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/refactor-mainframe-applications-astadia-post.png",
      "title": "Refactor mainframe applications with Astadia",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-bastion",
        "azure-load-balancer",
        "azure-private-link",
        "azure-site-recovery"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/refactor-mainframe-applications-astadia"
    },
    {
      "summary": "Learn how an IoT monitor and manage loop is a supervisory system that continually monitors a physical system controlled by a set of networked IoT devices.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/monitor-and-manage.png",
      "title": "IoT monitor and manage loops",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot",
        "azure-monitor"
      ],
      "url": "/azure/architecture/example-scenario/iot/monitor-manage-loop"
    },
    {
      "summary": "See how to use the Avanade Automated Migration Technology (AMT) framework to migrate IBM z/OS mainframe workloads to Azure.",
      "azureCategories": [
        "hybrid",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/asysco-zos-migration.png",
      "title": "IBM z/OS mainframe migration with Avanade AMT",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-load-balancer",
        "azure-sql-database",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/asysco-zos-migration"
    },
    {
      "summary": "Create a connection between your existing Oracle Database and your Azure applications.",
      "azureCategories": [
        "databases",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cross-cloud-connectivity.png",
      "title": "Oracle database migration: Cross-cloud connectivity",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/example-scenario/oracle-migrate/oracle-migration-cross-cloud"
    },
    {
      "summary": "Rearchitect your Oracle database with Azure SQL Managed Instance.",
      "azureCategories": [
        "databases",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/rearchitect.png",
      "title": "Oracle database migration: Rearchitect",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-sql-managed-instance",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/example-scenario/oracle-migrate/oracle-migration-rearchitect"
    },
    {
      "summary": "An approach for rehosting mainframe legacy applications in Azure using the LzLabs Software Defined Mainframe platform.",
      "azureCategories": [
        "compute",
        "hybrid",
        "migration",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/lzlabs-software-defined-mainframe-in-azure-architecture-card.png",
      "title": "Use LzLabs Software Defined Mainframe (SDM) in an Azure VM deployment",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-database-postgresql",
        "azure-virtual-network",
        "azure-resource-manager"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/lzlabs-software-defined-mainframe-in-azure"
    },
    {
      "summary": "Use Azure Traffic Manager to automatically add resources to an existing app to accommodate an increase in load.",
      "azureCategories": [
        "compute",
        "hybrid",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-cross-cloud-scaling.png",
      "title": "Cross-cloud scaling with Traffic Manager",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-traffic-manager",
        "azure-container-instances",
        "azure-vm-scalesets",
        "azure-stack-hub",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/example-scenario/hybrid/hybrid-cross-cloud-scaling"
    },
    {
      "summary": "Learn how an IoT measure and control loop keeps an IoT device within the tolerable range of setpoint configuration, through a real-time, closed-loop control process.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/measure-and-control.png",
      "title": "IoT measure and control loops",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot"
      ],
      "url": "/azure/architecture/example-scenario/iot/measure-control-loop"
    },
    {
      "summary": "See how to refactor general mainframe applications to run more cost-effectively and efficiently on Azure.",
      "azureCategories": [
        "hybrid",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/general-mainframe-refactor.png",
      "title": "General mainframe refactor to Azure",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-files",
        "azure-load-balancer",
        "azure-sql-database",
        "azure-storage",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/general-mainframe-refactor"
    },
    {
      "summary": "Optimize, modernize, and streamline IBM z/OS mainframe applications by using Micro Focus Enterprise Server 6.0 on Azure VMs.",
      "azureCategories": [
        "compute",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/micro-focus-enterprise-server.png",
      "title": "Micro Focus Enterprise Server on Azure VMs",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-site-recovery",
        "azure-sqlserver-vm",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/micro-focus-server"
    },
    {
      "summary": "Use Infinite i to easily migrate your IBM System i (AS/400) workloads to Azure. You can lower costs, improve performance, improve availability, and modernize.",
      "azureCategories": [
        "compute",
        "developer-tools",
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ibm-system-i-azure-infinite-i.png",
      "title": "IBM System i to Azure using Infinite i",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/ibm-system-i-azure-infinite-i"
    },
    {
      "summary": "Learn how to refactor IBM z/TPF mainframe systems to functionally equivalent systems on Microsoft Azure.",
      "azureCategories": [
        "migration",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mainframe-migration-after.png",
      "title": "Refactor IBM z/TPF mainframe systems to Azure",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-cosmos-db",
        "azure-virtual-network",
        "azure-cache-redis"
      ],
      "url": "/azure/architecture/example-scenario/mainframe/refactor-ibm-ztpf-mainframe"
    },
    {
      "summary": "Let each service decide when and how a business operation is processed, instead of depending on a central orchestrator.",
      "azureCategories": [
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/choreography-example.png",
      "title": "Choreography pattern",
      "lastModified": "2022-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-grid"
      ],
      "url": "/azure/architecture/patterns/choreography"
    },
    {
      "summary": "Learn how GitHub tools like Codespaces and Actions make security practices an integral part of DevOps while maintaining efficiency in an Azure framework.",
      "azureCategories": [
        "devops",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/devsecops-in-github-data-flow.png",
      "title": "DevSecOps with GitHub Security",
      "lastModified": "2022-06-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-monitor",
        "azure-policy",
        "microsoft-defender",
        "defender-for-cloud",
        "github"
      ],
      "url": "/azure/architecture/solution-ideas/articles/devsecops-in-github"
    },
    {
      "summary": "Learn how to use network-level segmentation to secure virtual networks by using the wide and diverse set of segmentation options available in Microsoft Azure.",
      "azureCategories": [
        "hybrid",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/resource-flowchart.png",
      "title": "Secure and govern workloads with network-level segmentation",
      "lastModified": "2022-06-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-firewall",
        "azure-monitor",
        "azure-sql-database",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/reference-architectures/hybrid-networking/network-level-segmentation"
    },
    {
      "summary": "Build a serverless microservices solution on Azure Container Apps using Distributed Application Runtime (Dapr) and Kubernetes Event-Driven Autoscaling (KEDA).",
      "azureCategories": [
        "containers",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/microservices-with-container-apps-dapr.png",
      "title": "Deploy microservices with Azure Container Apps and Dapr",
      "lastModified": "2022-06-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-apps",
        "dotnet",
        "azure-sql-database",
        "azure-cosmos-db",
        "azure-cache-redis"
      ],
      "url": "/azure/architecture/example-scenario/serverless/microservices-with-container-apps-dapr"
    },
    {
      "summary": "This is a solution idea that builds on the multiple forests scenario by using Azure Virtual Desktop and Microsoft Entra Domain Services.",
      "azureCategories": [
        "windows-virtual-desktop"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/wvd-multi-forest-aadds-thumb.png",
      "title": "Multiple forests with AD DS, Microsoft Entra ID, and Microsoft Entra Domain Services",
      "lastModified": "2022-06-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-files",
        "azure-virtual-desktop"
      ],
      "url": "/azure/architecture/example-scenario/wvd/multi-forest-azure-managed"
    },
    {
      "summary": "Learn how a railway company implemented an intelligent IoT Edge train safety solution for fast, reliable defect identification and predictive maintenance.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/iot-predictive-maintenance-thumb.png",
      "title": "IoT Edge railroad maintenance and safety system",
      "lastModified": "2022-06-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-cosmos-db",
        "azure-iot",
        "azure-iot-edge",
        "azure-iot-hub"
      ],
      "url": "/azure/architecture/example-scenario/predictive-maintenance/iot-predictive-maintenance"
    },
    {
      "summary": "See an example and understand the components of a simple core startup stack architecture for an initial MVP or prototype.",
      "azureCategories": [
        "developer-tools",
        "devops",
        "management-and-governance",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/startup-stack-architecture.png",
      "title": "Core startup stack architecture",
      "lastModified": "2022-06-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-blob-storage",
        "azure-cdn",
        "azure-database-postgresql",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/startups/core-startup-stack"
    },
    {
      "summary": "Store security logs in Azure Data Explorer on a long-term basis. This solution minimizes costs and provides easy access when you need to query the data.",
      "azureCategories": [
        "analytics",
        "security",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/security-log-retention-azure-data-explorer.png",
      "title": "Long-term security log retention with Azure Data Explorer",
      "lastModified": "2022-06-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-explorer",
        "azure-data-lake-storage",
        "azure-event-hubs",
        "azure-log-analytics",
        "microsoft-sentinel"
      ],
      "url": "/azure/architecture/example-scenario/security/security-log-retention-azure-data-explorer"
    },
    {
      "summary": "Application Gateway is commonly used to load balance traffic to Container Instances container groups. This article explores automation for rotated IP addresses.",
      "azureCategories": [
        "containers",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-automation.png",
      "title": "Automate infrastructure reconfiguration by using Azure",
      "lastModified": "2022-06-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-instances",
        "azure-application-gateway",
        "azure-functions",
        "azure-monitor"
      ],
      "url": "/azure/architecture/web-apps/guides/networking/automation-application-gateway"
    },
    {
      "summary": "Learn how to deploy network virtual appliances for high availability architectures for ingress, egress, and both in Azure.",
      "azureCategories": [
        "networking",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/nvaha-load-balancer-internet-thumb.png",
      "title": "Deploy highly available NVAs",
      "lastModified": "2022-06-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-firewall",
        "azure-functions",
        "azure-traffic-manager",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/reference-architectures/dmz/nva-ha"
    },
    {
      "summary": "Use Azure sentiment analysis and face recognition services with open-source solutions like Apache NiFi and Azure HDInsight to gauge public opinion in tweets.",
      "azureCategories": [
        "analytics",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/nifi-sentiment-analysis-face-recognition-thumbnail.png",
      "title": "Face recognition and sentiment analysis",
      "lastModified": "2022-06-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "ai-services",
        "azure-cosmos-db",
        "azure-hdinsight",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/example-scenario/ai/nifi-sentiment-analysis-face-recognition"
    },
    {
      "summary": "Learn how to secure a data lakehouse that's implemented by using Azure Synapse Analytics.",
      "azureCategories": [
        "analytics",
        "databases",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/secure-data-lakehouse-architecture.png",
      "title": "Secure a data lakehouse with Azure Synapse Analytics",
      "lastModified": "2022-06-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-synapse-analytics",
        "azure-data-lake-storage",
        "azure-virtual-network",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/example-scenario/analytics/secure-data-lakehouse-synapse"
    },
    {
      "summary": "Choose a natural language processing service for sentiment analysis, topic and language detection, key phrase extraction, and document categorization.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/natural-language-processing-thumbnail.png",
      "title": "Natural language processing technology",
      "lastModified": "2022-06-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "ai-services",
        "azure-databricks",
        "azure-hdinsight",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/data-guide/technology-choices/natural-language-processing"
    },
    {
      "summary": "Learn about extract-transform-load (ETL) and extract-load-transform (ELT) data transformation pipelines, and how to use control flows and data flows.",
      "azureCategories": [
        "databases",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/etl.png",
      "title": "Extract, transform, and load (ETL)",
      "lastModified": "2022-06-21T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-synapse-analytics",
        "azure-data-factory"
      ],
      "url": "/azure/architecture/data-guide/relational-data/etl"
    },
    {
      "summary": "Best practices for setting up networking as per Requirement 1 and 2 of PCI-DSS 3.2.1.",
      "azureCategories": [
        "containers",
        "networking",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-pci-network.png",
      "title": "Configure networking of an AKS regulated cluster for PCI-DSS 3.2.1 (Part 3 of 9)",
      "lastModified": "2022-06-20T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-firewall",
        "azure-application-gateway",
        "entra",
        "entra-id",
        "microsoft-defender",
        "defender-for-cloud"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks-pci/aks-pci-network"
    },
    {
      "summary": "Learn about analyze and optimize loops, an IoT pattern for generating and applying optimization insights based on the entire business context.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/analyze-and-optimize.png",
      "title": "IoT analyze and optimize loops",
      "lastModified": "2022-06-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-lake",
        "azure-iot-edge",
        "azure-iot-hub"
      ],
      "url": "/azure/architecture/example-scenario/iot/analyze-optimize-loop"
    },
    {
      "summary": "This example demonstrates a highly scalable, resilient architecture for e-commerce order processing, using managed Azure services such as Azure Cosmos DB and HDInsight.",
      "azureCategories": [
        "web",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-ecommerce-order-processing-thumbnail.png",
      "title": "Scalable order processing",
      "lastModified": "2022-06-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-hdinsight"
      ],
      "url": "/azure/architecture/example-scenario/data/ecommerce-order-processing"
    },
    {
      "summary": "Understand how devices attest their identity, authenticate, and get provisioned within Azure IoT Hubs.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/late-binding-with-dps.png",
      "title": "Attestation, authentication, and provisioning",
      "lastModified": "2022-06-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot-hub"
      ],
      "url": "/azure/architecture/example-scenario/iot/attestation-provisioning"
    },
    {
      "summary": "Learn how to design a single network that has varying security and performance requirements.",
      "azureCategories": [
        "networking",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/performance-security-optimized-vwan-architecture-main.png",
      "title": "Virtual WAN architecture optimized for department-specific requirements",
      "lastModified": "2022-06-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-wan",
        "azure-expressroute",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/infrastructure/performance-security-optimized-vwan"
    },
    {
      "summary": "Architecture for a high-availability solution that handles massive amounts of data. It uses Azure Table Storage and serves multiple regions.",
      "azureCategories": [
        "databases",
        "web",
        "migration",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/multi-region-web-app-multi-writes-azure-table.png",
      "title": "Multi-region web application with custom Storage Table replication",
      "lastModified": "2022-06-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-front-door",
        "azure-app-service",
        "azure-functions",
        "azure-table-storage",
        "azure-cache-redis"
      ],
      "url": "/azure/architecture/solution-ideas/articles/multi-region-web-app-multi-writes-azure-table"
    },
    {
      "summary": "Architecture for a high-availability solution with failover that handles massive amounts of data. It uses Azure Table Storage and has two regions.",
      "azureCategories": [
        "databases",
        "web",
        "migration",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/multi-region-web-app-azure-table-failover.png",
      "title": "Two-region web application with Table Storage failover",
      "lastModified": "2022-06-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-front-door",
        "azure-app-service",
        "azure-functions",
        "azure-table-storage",
        "azure-cache-redis"
      ],
      "url": "/azure/architecture/solution-ideas/articles/multi-region-web-app-azure-table-failover"
    },
    {
      "summary": "Charon-SSP cross-platform hypervisor emulates legacy Sun SPARC systems on industry standard x86-64 computer systems and VMs.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/solaris.png",
      "title": "Stromasys Charon-SSP Solaris emulator on Azure VMs",
      "lastModified": "2022-06-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-storage",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/solution-ideas/articles/solaris-azure"
    },
    {
      "summary": "This mobile app architecture simplifies authentication, stores and syncs data, sends notifications, automates builds and tests, and monitors and analyzes usage.",
      "azureCategories": [
        "mobile",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/task-based-consumer-mobile-app.png",
      "title": "Task-based consumer mobile app",
      "lastModified": "2022-06-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "dotnet",
        "xamarin"
      ],
      "url": "/azure/architecture/solution-ideas/articles/task-based-consumer-mobile-app"
    },
    {
      "summary": "Learn about running Computational Fluid Dynamics simulations using Azure. Create, manage, and optimize clusters using Azure CycleCloud.",
      "azureCategories": [
        "compute",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-hpc-cfd.png",
      "title": "Run CFD simulations",
      "lastModified": "2022-06-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-batch",
        "azure-cyclecloud",
        "azure-storage",
        "dynamics",
        "github"
      ],
      "url": "/azure/architecture/example-scenario/infrastructure/hpc-cfd"
    },
    {
      "summary": "Use Azure Data Explorer for near real-time IoT telemetry analytics on fast-flowing, high-volume streaming data from a wide variety of IoT devices.",
      "azureCategories": [
        "analytics",
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/iot-azure-data-explorer-thumbnail-new.png",
      "title": "IoT analytics with Azure Data Explorer",
      "lastModified": "2022-06-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-data-explorer",
        "azure-digital-twins"
      ],
      "url": "/azure/architecture/solution-ideas/articles/iot-azure-data-explorer"
    },
    {
      "summary": "Unlock legacy data with Azure Stack. Create cloud-based applications while integrating and preserving mainframe and core business process application data.",
      "azureCategories": [
        "migration",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/unlock-legacy-data.png",
      "title": "Unlock legacy data with Azure Stack",
      "lastModified": "2022-06-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-stack",
        "azure-app-service"
      ],
      "url": "/azure/architecture/solution-ideas/articles/unlock-legacy-data"
    },
    {
      "summary": "Recommended architecture for IoT applications on Azure using PaaS (platform-as-a-service) components.",
      "azureCategories": [
        "iot",
        "featured"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/iot-refarch.svg",
      "title": "Azure IoT reference architecture",
      "lastModified": "2022-06-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-functions",
        "azure-iot-hub",
        "azure-stream-analytics",
        "azure-digital-twins"
      ],
      "url": "/azure/architecture/reference-architectures/iot"
    },
    {
      "summary": "Use Azure Blockchain Workbench to build an application for a supply chain that can track assets and trigger remediating events downstream.",
      "azureCategories": [
        "iot",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/supply-chain-track-and-trace.png",
      "title": "Supply chain track and trace",
      "lastModified": "2022-06-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot-hub"
      ],
      "url": "/azure/architecture/solution-ideas/articles/supply-chain-track-and-trace"
    },
    {
      "summary": "Help employees learn processes and materials effectively by providing persistent holographic instructions mapped to precise locations in physical workspaces.",
      "azureCategories": [
        "mixed-reality"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/training-and-procedural-guidance-powered-by-mixed-reality.png",
      "title": "Training and procedural guidance powered by mixed reality",
      "lastModified": "2022-06-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-media-services",
        "azure-spatial-anchors",
        "azure-video-indexer"
      ],
      "url": "/azure/architecture/solution-ideas/articles/training-and-procedural-guidance-powered-by-mixed-reality"
    },
    {
      "summary": "As data flows into a storage account, use Azure Stack to analyze the data for anomalies or compliance and to display insights in apps.",
      "azureCategories": [
        "analytics",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/tiered-data-for-analytics.png",
      "title": "Tier applications and data for analytics",
      "lastModified": "2022-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-functions",
        "azure-stack",
        "azure-storage",
        "azure-app-service"
      ],
      "url": "/azure/architecture/solution-ideas/articles/tiered-data-for-analytics"
    },
    {
      "summary": "Build an SAP landscape on NetWeaver by using Azure Virtual Machines to host SAP applications and a SQL Server database.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sap-netweaver-on-sql-server.png",
      "title": "SAP NetWeaver on SQL Server",
      "lastModified": "2022-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-expressroute",
        "azure-storage",
        "azure-virtual-machines",
        "sql-server"
      ],
      "url": "/azure/architecture/solution-ideas/articles/sap-netweaver-on-sql-server"
    },
    {
      "summary": "Learn about the architecture that can help you deploy a solution based on Azure DevTest Labs in your enterprise.",
      "azureCategories": [
        "developer-tools",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/devtest-labs-reference-architecture-enterprise.png",
      "title": "Azure DevTest Labs architecture for enterprises",
      "lastModified": "2022-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-devtest-labs",
        "azure-artifacts",
        "azure-bastion"
      ],
      "url": "/azure/architecture/example-scenario/infrastructure/devtest-labs-reference-architecture"
    },
    {
      "summary": "Learn how to deploy workloads in active/standby mode to achieve high availability and disaster recovery in Azure public MEC.",
      "azureCategories": [
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/edge-zones-disaster-recovery-architecture.png",
      "title": "High availability in Azure public MEC",
      "lastModified": "2022-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-traffic-manager",
        "azure-load-balancer",
        "azure-vm-scalesets"
      ],
      "url": "/azure/architecture/example-scenario/hybrid/multi-access-edge-compute-ha"
    },
    {
      "summary": "Learn how to use Azure public multi-access edge compute to exploit the speed of 5G and unlock key low latency and high bandwidth scenarios.",
      "azureCategories": [
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/public-multi-access-edge-compute-deployment.png",
      "title": "Azure public multi-access edge compute deployment",
      "lastModified": "2022-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-network",
        "azure-kubernetes-service",
        "azure-machine-learning",
        "ai-services"
      ],
      "url": "/azure/architecture/example-scenario/hybrid/public-multi-access-edge-compute-deployment"
    },
    {
      "summary": "Use Azure Database for MySQL to rapidly build engaging, performant, and scalable cross-platform and native apps for iOS, Android, Windows, or Mac.",
      "azureCategories": [
        "mobile",
        "web",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/scalable-web-and-mobile-applications-using-azure-database-for-mysql.png",
      "title": "Scalable web and mobile applications using Azure Database for MySQL",
      "lastModified": "2022-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-database-mysql"
      ],
      "url": "/azure/architecture/solution-ideas/articles/scalable-web-and-mobile-applications-using-azure-database-for-mysql"
    },
    {
      "summary": "Learn how to use Azure Stack Hub and Azure to stage, analyze, process, sanitize, and store data across multiple on-premises and cloud locations.",
      "azureCategories": [
        "compute",
        "storage",
        "hybrid",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-tiered-data-analytics.png",
      "title": "Tiered data for analytics",
      "lastModified": "2022-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-stack-hub",
        "azure-storage"
      ],
      "url": "/azure/architecture/example-scenario/hybrid/hybrid-tiered-data-analytics"
    },
    {
      "summary": "See an elastic, flexible publish-subscribe model for data producers and consumers to create and consume validated curated content or data.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/transit-hub.png",
      "title": "Transit hub dynamic pub-sub messaging system",
      "lastModified": "2022-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cache-redis",
        "azure-cosmos-db",
        "azure-event-hubs",
        "azure-functions",
        "azure-service-bus"
      ],
      "url": "/azure/architecture/solution-ideas/articles/transit-hub"
    },
    {
      "summary": "With large SAP HANA instances, use Azure Virtual Machines, OS clustering, and NFS storage for scalability, performance, high reliability, and disaster recovery.",
      "azureCategories": [
        "web",
        "storage",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sap-s4-hana-on-hli-with-ha-and-dr.png",
      "title": "SAP S/4 HANA for Large Instances",
      "lastModified": "2022-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-expressroute",
        "azure-files",
        "azure-sap",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/solution-ideas/articles/sap-s4-hana-on-hli-with-ha-and-dr"
    },
    {
      "summary": "Use Azure IoT Edge, MLOps, and human-in-the-loop notifications to train and deploy an ML computer vision solution for manufacturing.",
      "azureCategories": [
        "ai-machine-learning",
        "devops",
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/end-to-end-smart-factory-01.png",
      "title": "End-to-end computer vision at the edge for manufacturing",
      "lastModified": "2022-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-machine-learning",
        "azure-pipelines"
      ],
      "url": "/azure/architecture/reference-architectures/ai/end-to-end-smart-factory"
    },
    {
      "summary": "Use Event Grid to connect data sources and event handlers. Trigger a serverless function to run image analysis when a new photo enters a blob storage container.",
      "azureCategories": [
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/serverless-application-architectures-using-event-grid.png",
      "title": "Serverless application architectures using Event Grid",
      "lastModified": "2022-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-grid",
        "azure-blob-storage"
      ],
      "url": "/azure/architecture/solution-ideas/articles/serverless-application-architectures-using-event-grid"
    },
    {
      "summary": "Use Azure Database for PostgreSQL to rapidly build engaging, performant, and scalable cross-platform and native apps for iOS, Android, Windows, or Mac.",
      "azureCategories": [
        "mobile",
        "web",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/scalable-web-and-mobile-applications-using-azure-database-for-postgresql.png",
      "title": "Scalable web and mobile applications using Azure Database for PostgreSQL",
      "lastModified": "2022-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-database-postgresql"
      ],
      "url": "/azure/architecture/solution-ideas/articles/scalable-web-and-mobile-applications-using-azure-database-for-postgresql"
    },
    {
      "summary": "Store, manage, and analyze HIPAA-compliant and HITRUST-compliant health data and medical records with the highest level of built-in security.",
      "azureCategories": [
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/security-compliance-blueprint-hipaa-hitrust-health-data-ai.png",
      "title": "HIPAA and HITRUST compliant health data AI",
      "lastModified": "2022-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-event-grid",
        "azure-functions",
        "azure-machine-learning",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/security-compliance-blueprint-hipaa-hitrust-health-data-ai"
    },
    {
      "summary": "Use a canonical architecture to achieve high availability for Oracle Database Enterprise Edition in Azure.",
      "azureCategories": [
        "databases",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/reference-architecture-for-oracle-database-on-azure.png",
      "title": "Run Oracle databases on Azure",
      "lastModified": "2022-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-load-balancer",
        "azure-application-gateway"
      ],
      "url": "/azure/architecture/solution-ideas/articles/reference-architecture-for-oracle-database-on-azure"
    },
    {
      "summary": "Learn how to provide secure Azure Files that are secured by on-premises Windows Server Active Directory domain services (AD DS), and accessed on-premises.",
      "azureCategories": [
        "hybrid",
        "storage",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-files-on-premises-authentication.png",
      "title": "Azure Files accessed on-premises and secured by AD DS",
      "lastModified": "2022-06-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-network",
        "azure-expressroute",
        "azure-storage-accounts",
        "azure-files",
        "azure-dns"
      ],
      "url": "/azure/architecture/example-scenario/hybrid/azure-files-on-premises-authentication"
    },
    {
      "summary": "Get deep-learning analytics and insights from live streaming data. Run advanced analytics on IoT device data and website clickstream logs in near real time.",
      "azureCategories": [
        "analytics",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/real-time-analytics.png",
      "title": "Real-time analytics on big data architecture",
      "lastModified": "2022-06-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-analysis-services",
        "azure-event-hubs",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/solution-ideas/articles/real-time-analytics"
    },
    {
      "summary": "In this scenario, an organization consolidates multiple APIs internally using Azure API Management deployed inside a Virtual Network.",
      "azureCategories": [
        "integration",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-publish-internal-apis-externally-thumbnail-new.png",
      "title": "Publish internal APIs to external users",
      "lastModified": "2022-06-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-api-management",
        "azure-application-gateway",
        "azure-devops",
        "azure-monitor",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/apps/publish-internal-apis-externally"
    },
    {
      "summary": "Use Azure IoT Central to capture real-time asset data, and tools like Azure Functions, Azure Notification Hubs, and Power BI to create alerts and reports.",
      "azureCategories": [
        "management-and-governance",
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/real-time-asset-tracking-mgmt-iot-central-content.png",
      "title": "Real-time asset tracking and management",
      "lastModified": "2022-06-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot-central",
        "azure-stream-analytics",
        "azure-sql-database",
        "azure-functions",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/solution-ideas/articles/real-time-asset-tracking-mgmt-iot-central"
    },
    {
      "summary": "Implement a high-bandwidth, low-latency solution for SQL Server workloads. Use Azure NetApp Files for enterprise-scale performance and reduced costs.",
      "azureCategories": [
        "databases",
        "hybrid",
        "migration",
        "networking",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sql-server-azure-netapp-files-architecture-thumbnail.png",
      "title": "SQL Server on Azure Virtual Machines with Azure NetApp Files",
      "lastModified": "2022-06-09T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-netapp-files",
        "azure-sqlserver-vm",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/file-storage/sql-server-azure-netapp-files"
    },
    {
      "summary": "Use Azure Stream Analytics to ingest data to an Azure Synapse dedicated SQL pool in high throughput scenarios.",
      "azureCategories": [
        "analytics",
        "iot",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/stream-synapse.png",
      "title": "High throughput stream ingestion to Azure Synapse",
      "lastModified": "2022-06-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-synapse-analytics",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/example-scenario/data/stream-ingestion-synapse"
    },
    {
      "summary": "Deploy Astra Control Service with Azure NetApp Files for data protection, disaster recovery, and mobility for Azure Kubernetes Service (AKS) applications.",
      "azureCategories": [
        "containers",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/data-protection-kubernetes-astra-azure-netapp-files-thumbnail.png",
      "title": "Application data protection for AKS workloads on Azure NetApp Files",
      "lastModified": "2022-06-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-netapp-files",
        "azure-kubernetes-service",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/file-storage/data-protection-kubernetes-astra-azure-netapp-files"
    },
    {
      "summary": "Implement a high-bandwidth, low-latency solution for Oracle Database workloads. Use Azure NetApp Files for enterprise-scale performance and reduced costs.",
      "azureCategories": [
        "databases",
        "hybrid",
        "migration",
        "networking",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/oracle-azure-netapp-files-architecture-thumbnail.png",
      "title": "Oracle Database with Azure NetApp Files",
      "lastModified": "2022-06-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-netapp-files",
        "azure-virtual-machines",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/file-storage/oracle-azure-netapp-files"
    },
    {
      "summary": "Synapse serverless is a fast way to do EDA over a data lake or lakehouse. Self-service analytics is easy for business analysts who understand some SQL.",
      "azureCategories": [
        "analytics",
        "databases",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/exploratory-data-analytics.png",
      "title": "Query a data lake or lakehouse by using Azure Synapse serverless",
      "lastModified": "2022-06-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-lake",
        "azure-data-lake-storage",
        "azure-synapse-analytics",
        "azure-blob-storage"
      ],
      "url": "/azure/architecture/example-scenario/data/synapse-exploratory-data-analytics"
    },
    {
      "summary": "Automate your Microsoft Sentinel integration and deployment with Azure DevOps for your Security Operation Center.",
      "azureCategories": [
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/mitre-attack-in-sentinel-artifacts-dev.png",
      "title": "Automate Sentinel integration with Azure DevOps",
      "lastModified": "2022-06-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-devops",
        "azure-key-vault",
        "microsoft-defender",
        "defender-for-cloud",
        "microsoft-sentinel"
      ],
      "url": "/azure/architecture/example-scenario/devops/automate-sentinel-integration"
    },
    {
      "summary": "Create an image classifier with a solution architecture that includes Microsoft AirSim Drone simulator and Azure Custom Vision Cognitive Service.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/drone-rescue.png",
      "title": "Vision classifier model with Azure Custom Vision Cognitive Service",
      "lastModified": "2022-06-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "github"
      ],
      "url": "/azure/architecture/ai-ml/idea/vision-classifier-model-with-custom-vision"
    },
    {
      "summary": "Reduce tedious work by automating the process of setting up pipelines in Azure. Use the Azure DevOps REST API and Azure Logic Apps for the automation.",
      "azureCategories": [
        "devops",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/automate-azure-pipelines-thumbnail.png",
      "title": "Automate multistage pipeline setup in Azure",
      "lastModified": "2022-06-08T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-monitor",
        "azure-devops",
        "azure-logic-apps",
        "azure-pipelines"
      ],
      "url": "/azure/architecture/example-scenario/devops/automate-azure-pipelines"
    },
    {
      "summary": "Stream events by using fully managed Azure data services. Use open-source technologies like Kafka, Kubernetes, Cassandra, PostgreSQL, and Redis components.",
      "azureCategories": [
        "analytics",
        "containers",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/open-source-data-engine-stream-processing-architecture-thumbnail.png",
      "title": "Stream processing with fully managed open-source data engines",
      "lastModified": "2022-06-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-kubernetes-service",
        "azure-cosmos-db",
        "azure-database-postgresql",
        "azure-cache-redis"
      ],
      "url": "/azure/architecture/example-scenario/data/open-source-data-engine-stream-processing"
    },
    {
      "summary": "Tank level forecasting harnesses the power of real-time and historical data from sensors, meters, and records to prevent problems and reduce expenses.",
      "azureCategories": [
        "analytics",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/oil-and-gas-tank-level-forecasting.png",
      "title": "Oil and gas tank level forecasting",
      "lastModified": "2022-06-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-event-hubs",
        "azure-machine-learning",
        "azure-stream-analytics",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/solution-ideas/articles/oil-and-gas-tank-level-forecasting"
    },
    {
      "summary": "Car dealerships, manufacturers, and insurance companies can use Microsoft Azure to gain predictive insights on vehicle health and driving habits.",
      "azureCategories": [
        "ai-machine-learning",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/predictive-insights-with-vehicle-telematics.png",
      "title": "Predictive insights with vehicle telematics",
      "lastModified": "2022-06-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-event-hubs",
        "azure-hdinsight",
        "azure-machine-learning",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/solution-ideas/articles/predictive-insights-with-vehicle-telematics"
    },
    {
      "summary": "Modernize .NET applications with Azure SQL DB and Azure App Service. Make the apps secure, flexible, available, and scalable by migrating them to Azure.",
      "azureCategories": [
        "integration",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/net-app-modernization.png",
      "title": "Modernize .NET applications",
      "lastModified": "2022-06-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-database-migration",
        "azure-devops",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/solution-ideas/articles/net-app-modernization"
    },
    {
      "summary": "Build and run customer onboarding applications without managing or maintaining infrastructure. Improve developer productivity with this serverless architecture.",
      "azureCategories": [
        "migration",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/onboarding-customers-with-a-cloud-native-serverless-architecture.png",
      "title": "Serverless computing solution for LOB apps",
      "lastModified": "2022-06-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "ai-services",
        "azure-cosmos-db",
        "dynamics-365",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/onboarding-customers-with-a-cloud-native-serverless-architecture"
    },
    {
      "summary": "Use population health management to improve clinical and health outcomes and reduce costs. Track, monitor, and benchmark data with this tool.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/population-health-management-for-healthcare.png",
      "title": "Population health management for healthcare",
      "lastModified": "2022-06-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-databricks",
        "power-platform",
        "power-bi",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/solution-ideas/articles/population-health-management-for-healthcare"
    },
    {
      "summary": "Learn now to automate diagnostic or routine notebooks with an Azure serverless architecture.",
      "azureCategories": [
        "developer-tools",
        "devops",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/automate-diagnostic-jupyter-notebook-thumb.png",
      "title": "Automate Jupyter Notebooks for diagnostics",
      "lastModified": "2022-06-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-logic-apps",
        "azure-automation",
        "azure-devops",
        "azure-functions"
      ],
      "url": "/azure/architecture/example-scenario/data/automating-diagnostic-jupyter-notebook"
    },
    {
      "summary": "Implement a continuous integration (CI), continuous delivery (CD), and retraining pipeline for an AI application using Azure DevOps and Azure Machine Learning.",
      "azureCategories": [
        "ai-machine-learning",
        "devops",
        "featured"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ml-ops-python.png",
      "title": "MLOps for Python models using Azure Machine Learning",
      "lastModified": "2022-06-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-container-registry",
        "azure-devops",
        "azure-machine-learning",
        "azure-pipelines"
      ],
      "url": "/azure/architecture/ai-ml/guide/mlops-python"
    },
    {
      "summary": "Use a scalable HTAP architecture with Azure SQL Database to combine transaction processing with analytics. Reduce latencies and costs with in-memory databases.",
      "azureCategories": [
        "analytics",
        "databases",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-sql-htap-thumbnail.png",
      "title": "Cost savings through HTAP with Azure SQL",
      "lastModified": "2022-06-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-databricks",
        "azure-event-hubs",
        "azure-functions",
        "azure-sql-database",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/databases/guide/azure-sql-htap"
    },
    {
      "summary": "See how the open-source Blockchain Automation Framework (BAF) and Azure Arc-enabled Kubernetes work with multiparty DLTs to build a cross-cloud blockchain solution.",
      "azureCategories": [
        "hybrid",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/multi-cloud-blockchain-network.png",
      "title": "Multi-cloud blockchain DLT",
      "lastModified": "2022-06-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-arc",
        "azure-devops",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/example-scenario/blockchain/multi-cloud-blockchain"
    },
    {
      "summary": "Use confidential computing and containers to support a provider-hosted application. Securely collaborate with hospitals and diagnostic providers.",
      "azureCategories": [
        "ai-machine-learning",
        "containers",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/healthcare-demo-architecture-thumbnail.png",
      "title": "Confidential computing on a healthcare platform",
      "lastModified": "2022-06-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/example-scenario/confidential/healthcare-inference"
    },
    {
      "summary": "Use Microsoft Purview to build a foundation for data governance and management that can produce and deliver high-quality, trusted data.",
      "azureCategories": [
        "analytics",
        "databases",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/data-lake-purview.png",
      "title": "Data management across Azure Data Lake with Microsoft Purview",
      "lastModified": "2022-06-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "microsoft-purview",
        "azure-data-lake-storage",
        "azure-synapse-analytics",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/azure-purview-data-lake-estate-architecture"
    },
    {
      "summary": "This example scenario demonstrates delivery of a software-as-a-service (SaaS) platform built on the high-performance computing (HPC) capabilities of Azure.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-hpc-saas.png",
      "title": "Computer-aided engineering",
      "lastModified": "2022-06-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-application-gateway",
        "azure-blob-storage",
        "azure-kubernetes-service",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/example-scenario/apps/hpc-saas"
    },
    {
      "summary": "Improve your organization's governance process by using Microsoft Purview in your Azure SQL estate.",
      "azureCategories": [
        "analytics",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-purview-sql-estate-architecture-thumbnail.png",
      "title": "Manage data across Azure SQL estate with Microsoft Purview",
      "lastModified": "2022-06-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-sql-database",
        "microsoft-purview",
        "azure-sql-managed-instance",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/azure-purview-sql-estate-architecture"
    },
    {
      "summary": "See how Azure Cosmos DB uses features like change feed and time-to-live (TTL) to support diverse IoT workloads without sacrificing performance.",
      "azureCategories": [
        "iot",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/iot-using-cosmos-db-thumbnail-new.png",
      "title": "Azure Cosmos DB in IoT workloads",
      "lastModified": "2022-05-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-databricks",
        "azure-functions",
        "azure-iot-hub",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/iot-using-cosmos-db"
    },
    {
      "summary": "Protect Azure VMs by implementing two types of just-in-time access. Align with security principles like defense in depth, least privilege, and zero trust.",
      "azureCategories": [
        "security",
        "identity",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/multilayered-protection-azure-vm-architecture-diagram-thumbnail.png",
      "title": "Multilayered protection for Azure virtual machine access",
      "lastModified": "2022-05-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-bastion",
        "azure-rbac",
        "microsoft-defender",
        "defender-for-cloud"
      ],
      "url": "/azure/architecture/solution-ideas/articles/multilayered-protection-azure-vm"
    },
    {
      "summary": "Architecture for a high-availability solution that uses two Azure storage services, one for quick access to high-demand data, the other for low-demand data.",
      "azureCategories": [
        "databases",
        "web",
        "migration",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/minimal-storage-change-feed-replicate-data.png",
      "title": "Minimal storage – change feed to replicate data",
      "lastModified": "2022-05-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-front-door",
        "azure-app-service",
        "azure-functions",
        "azure-cosmos-db",
        "azure-table-storage"
      ],
      "url": "/azure/architecture/databases/idea/minimal-storage-change-feed-replicate-data"
    },
    {
      "summary": "Migrate IBM zSeries mainframe applications to Azure. Use a no-code approach that TmaxSoft OpenFrame offers for this lift and shift operation.",
      "azureCategories": [
        "migration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/migrate-mainframe-application-to-azure.png",
      "title": "Migrate IBM mainframe applications to Azure with TmaxSoft OpenFrame",
      "lastModified": "2022-05-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-bastion",
        "azure-expressroute",
        "azure-files",
        "azure-sql-database",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/solution-ideas/articles/migrate-mainframe-apps-with-tmaxsoft-openframe"
    },
    {
      "summary": "Move Azure resources across regions efficiently, securely, and seamlessly. See key steps, considerations, and strategies for planning and carrying out a move.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/move-azure-resources-architecture-diagram.png",
      "title": "Move Azure resources across regions",
      "lastModified": "2022-05-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "azure",
        "azure-expressroute",
        "azure-load-balancer",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/solution-ideas/articles/move-azure-resources-across-regions"
    },
    {
      "summary": "Send equipment data to the Azure cloud to monitor its condition and performance. Process the data with Azure Databricks and alert operators and administrators as needed.",
      "azureCategories": [
        "analytics",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/monitor-mining-equipment.png",
      "title": "Mining equipment monitoring",
      "lastModified": "2022-05-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-analysis-services",
        "azure-logic-apps",
        "azure-data-lake-storage",
        "azure-databricks",
        "azure-monitor"
      ],
      "url": "/azure/architecture/solution-ideas/articles/monitor-mining-equipment"
    },
    {
      "summary": "Modernize legacy systems by automating processes. Schedule calculations, connect to third party data sources or legacy systems, and process and share data.",
      "azureCategories": [
        "mobile"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/lob.png",
      "title": "Line of business extension",
      "lastModified": "2022-05-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-functions",
        "azure-sql-database",
        "power-platform",
        "power-apps",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/lob"
    },
    {
      "summary": "Create web apps in Azure App Service. Use the continuous integration and continuous deployment (CI/CD) pipeline to deliver value to customers faster.",
      "azureCategories": [
        "devops",
        "web",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/java-cicd-using-jenkins-and-azure-web-apps.png",
      "title": "Java CI/CD using Jenkins and Azure Web Apps",
      "lastModified": "2022-05-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-container-instances",
        "azure-database-mysql",
        "github"
      ],
      "url": "/azure/architecture/solution-ideas/articles/java-cicd-using-jenkins-and-azure-web-apps"
    },
    {
      "summary": "Use interactive analytics in Azure Data Explorer. Examine structured, semi-structured, and unstructured data with improvised, interactive, and fast queries.",
      "azureCategories": [
        "analytics",
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/interactive-azure-data-explorer.png",
      "title": "Azure Data Explorer interactive analytics",
      "lastModified": "2022-05-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-explorer",
        "azure-data-factory",
        "azure-event-hubs",
        "azure-iot-hub",
        "azure-storage"
      ],
      "url": "/azure/architecture/solution-ideas/articles/interactive-azure-data-explorer"
    },
    {
      "summary": "Connect devices that use the Open Platform Communication Unified Architecture standard to the cloud, and use predictive maintenance to optimize production.",
      "azureCategories": [
        "iot",
        "analytics",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/iot-predictive-maintenance.png",
      "title": "Predictive maintenance for industrial IoT",
      "lastModified": "2022-05-31T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-databricks",
        "azure-iot-edge",
        "azure-iot-hub",
        "azure-kubernetes-service",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/solution-ideas/articles/iot-predictive-maintenance"
    },
    {
      "summary": "Run multi-channel digital marketing websites on one platform. Start and stop campaigns on demand. Manage site and campaign performance with Episerver.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/digital-marketing-episerver.png",
      "title": "Scalable Episerver marketing website",
      "lastModified": "2022-05-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cache-redis",
        "azure-cdn",
        "azure-monitor",
        "azure-sql-database",
        "azure-traffic-manager"
      ],
      "url": "/azure/architecture/web-apps/hosting-applications/digital-marketing-episerver"
    },
    {
      "summary": "Azure Cache for Redis routes real-time messages in publish and subscribe systems. It also scales up web communication frameworks like Azure SignalR Service.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/messaging.png",
      "title": "Messaging",
      "lastModified": "2022-05-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-cache-redis"
      ],
      "url": "/azure/architecture/databases/idea/messaging"
    },
    {
      "summary": "The Sitecore Experience Platform (xP) provides the data, integrated tools, and automation needed to engage customers throughout an iterative life cycle.",
      "azureCategories": [
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/digital-marketing-sitecore.png",
      "title": "Scalable Sitecore marketing website",
      "lastModified": "2022-05-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cache-redis",
        "azure-cognitive-search",
        "azure-monitor",
        "azure-sql-database",
        "azure-app-service"
      ],
      "url": "/azure/architecture/web-apps/hosting-applications/digital-marketing-sitecore"
    },
    {
      "summary": "Run an Umbraco content management system on the Web Apps feature of Azure App Service. Use Azure managed services for a high-availability environment.",
      "azureCategories": [
        "web",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/medium-umbraco-web-app.png",
      "title": "Scalable Umbraco CMS web app",
      "lastModified": "2022-05-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-monitor",
        "azure-cache-redis",
        "azure-sql-database",
        "azure-storage-accounts",
        "azure-app-service"
      ],
      "url": "/azure/architecture/web-apps/hosting-applications/medium-umbraco-web-app"
    },
    {
      "summary": "Store healthcare data effectively and affordably with cloud-based solutions from Azure. Manage medical records with the highest level of built-in security.",
      "azureCategories": [
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/medical-data-storage.png",
      "title": "Medical data storage solutions",
      "lastModified": "2022-05-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-data-factory",
        "azure-data-lake",
        "azure-machine-learning",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/medical-data-storage"
    },
    {
      "summary": "Extend your on-premises big data investments to the cloud. Transform your business by using the advanced analytics capabilities of Azure HDInsight.",
      "azureCategories": [
        "analytics",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/extend-your-on-premises-big-data-investments-with-hdinsight.png",
      "title": "Extend your on-premises big data investments with HDInsight",
      "lastModified": "2022-05-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-hdinsight"
      ],
      "url": "/azure/architecture/solution-ideas/articles/extend-your-on-premises-big-data-investments-with-hdinsight"
    },
    {
      "summary": "Elastically scale your database to accommodate unpredictable bursts of traffic and deliver low-latency, multi-player experiences on a global scale.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/gaming-using-cosmos-db.png",
      "title": "Gaming using Azure Cosmos DB",
      "lastModified": "2022-05-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-cosmos-db"
      ],
      "url": "/azure/architecture/solution-ideas/articles/gaming-using-cosmos-db"
    },
    {
      "summary": "Deploy a highly available SharePoint farm for intranet capabilities that uses Microsoft Entra ID, a SQL always on instance, and SharePoint resources.",
      "azureCategories": [
        "web",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/highly-available-sharepoint-farm.png",
      "title": "Highly available SharePoint farm",
      "lastModified": "2022-05-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-load-balancer",
        "sql-server"
      ],
      "url": "/azure/architecture/solution-ideas/articles/highly-available-sharepoint-farm"
    },
    {
      "summary": "Extract, transform, and load (ETL) big data clusters on demand by using Azure HDInsight, Hadoop MapReduce, and Apache Spark.",
      "azureCategories": [
        "analytics",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/extract-transform-and-load-using-hdinsight.png",
      "title": "Extract, transform, and load (ETL) using HDInsight",
      "lastModified": "2022-05-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-data-lake-storage",
        "azure-hdinsight"
      ],
      "url": "/azure/architecture/solution-ideas/articles/extract-transform-and-load-using-hdinsight"
    },
    {
      "summary": "Use a hybrid connection for Azure Stack Hub solutions with apps that communicate with the Azure public cloud and on-premises Azure Stack Hub components.",
      "azureCategories": [
        "hybrid",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-connectivity.png",
      "title": "Hybrid connections",
      "lastModified": "2022-05-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-stack-hub",
        "azure-virtual-machines",
        "azure-virtual-network",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/solution-ideas/articles/hybrid-connectivity"
    },
    {
      "summary": "Get a hybrid high performance computing solution built with Windows Server technology. Use Azure HPC Pack to create a hybrid HPC environment.",
      "azureCategories": [
        "compute",
        "hybrid",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-hpc-in-azure-with-hpc-pack.png",
      "title": "Hybrid HPC in Azure with HPC Pack",
      "lastModified": "2022-05-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-machines",
        "windows",
        "windows-server"
      ],
      "url": "/azure/architecture/solution-ideas/articles/hybrid-hpc-in-azure-with-hpc-pack"
    },
    {
      "summary": "When you develop apps, use a continuous integration and continuous deployment (CI/CD) pipeline to automatically push changes to Azure virtual machines.",
      "azureCategories": [
        "devops",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/immutable-infrastructure-cicd-using-jenkins-and-terraform-on-azure-virtual-architecture-overview.png",
      "title": "Immutable infrastructure CI/CD using Jenkins and Terraform on Azure",
      "lastModified": "2022-05-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-log-analytics",
        "azure-managed-disks",
        "azure-vm-scalesets",
        "github"
      ],
      "url": "/azure/architecture/solution-ideas/articles/immutable-infrastructure-cicd-using-jenkins-and-terraform-on-azure-virtual-architecture-overview"
    },
    {
      "summary": "Use Azure SQL and Azure Cosmos DB to deliver highly scalable customer service and ERP applications that work with structured and unstructured data.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/erp-customer-service.png",
      "title": "Deliver highly scalable customer service and ERP applications",
      "lastModified": "2022-05-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db",
        "azure-data-lake-storage",
        "azure-sql-database",
        "azure-synapse-analytics",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/erp-customer-service"
    },
    {
      "summary": "Use Azure Database for MySQL to securely store critical data and provide users with high-value analytics and insights over aggregated data.",
      "azureCategories": [
        "databases",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/finance-management-apps-using-azure-database-for-mysql.png",
      "title": "Finance management apps using Azure Database for MySQL",
      "lastModified": "2022-05-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-database-mysql",
        "azure-app-service",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/finance-management-apps-using-azure-database-for-mysql"
    },
    {
      "summary": "Use Azure Database for PostgreSQL to develop sophisticated machine learning and visualization apps that provide analytics and information that you can act on.",
      "azureCategories": [
        "databases",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/intelligent-apps-using-azure-database-for-postgresql.png",
      "title": "Intelligent apps using Azure Database for PostgreSQL",
      "lastModified": "2022-05-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "ai-services",
        "azure-database-postgresql",
        "azure-machine-learning",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/databases/idea/intelligent-apps-using-azure-database-for-postgresql"
    },
    {
      "summary": "Build an HPC risk analysis solution architecture with a step-by-step flowchart from Microsoft Azure that combines CycleCloud, Avere vFXT and TIBCO GridServer.",
      "azureCategories": [
        "compute",
        "storage",
        "hybrid",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hpc-risk-analysis.png",
      "title": "HPC risk analysis template",
      "lastModified": "2022-05-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-cyclecloud",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/solution-ideas/articles/hpc-risk-analysis"
    },
    {
      "summary": "Manage applications and user identity across clouds with Azure Stack Hub. Use the hybrid identity blueprint for on-premises apps that interact with the cloud.",
      "azureCategories": [
        "identity",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-identity.png",
      "title": "Hybrid identity",
      "lastModified": "2022-05-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-stack-hub",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/solution-ideas/articles/hybrid-identity"
    },
    {
      "summary": "This large-enterprise architecture for SharePoint, Dynamics CRM, and Linux web servers runs on an on-premises datacenter and fails over to Azure infrastructure.",
      "azureCategories": [
        "management-and-governance",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/disaster-recovery-enterprise-scale-dr.png",
      "title": "Enterprise-scale disaster recovery",
      "lastModified": "2022-05-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-site-recovery",
        "azure-traffic-manager",
        "azure-virtual-network",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/solution-ideas/articles/disaster-recovery-enterprise-scale-dr"
    },
    {
      "summary": "Configure a DevTest and DevOps infrastructure for developing, testing, and deploying microservice-based software.",
      "azureCategories": [
        "devops",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/dev-test-microservice.png",
      "title": "DevTest and DevOps for microservice solutions",
      "lastModified": "2022-05-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-boards",
        "azure-container-registry",
        "azure-kubernetes-service",
        "azure-pipelines",
        "github"
      ],
      "url": "/azure/architecture/solution-ideas/articles/dev-test-microservice"
    },
    {
      "summary": "Combine Azure platform as a service (PaaS) resources with DevTest and DevOps practices to support rapid iteration cycles and reduced overhead.",
      "azureCategories": [
        "devops",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/dev-test-paas.png",
      "title": "DevTest and DevOps for PaaS solutions",
      "lastModified": "2022-05-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-boards",
        "azure-devops",
        "azure-pipelines",
        "github"
      ],
      "url": "/azure/architecture/solution-ideas/articles/dev-test-paas"
    },
    {
      "summary": "Small-sized and medium-sized businesses can inexpensively implement cloud-based disaster recovery solutions by using Azure Site Recovery or Double-Take DR.",
      "azureCategories": [
        "management-and-governance",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/disaster-recovery-smb-azure-site-recovery.png",
      "title": "SMB disaster recovery with Azure Site Recovery",
      "lastModified": "2022-05-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-site-recovery",
        "azure-traffic-manager",
        "azure-virtual-network",
        "sql-server"
      ],
      "url": "/azure/architecture/solution-ideas/articles/disaster-recovery-smb-azure-site-recovery"
    },
    {
      "summary": "Small-sized and medium-sized businesses can inexpensively implement cloud-based disaster recovery solutions by using a partner solution like Double-Take DR.",
      "azureCategories": [
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/disaster-recovery-smb-double-take-dr.png",
      "title": "SMB disaster recovery with Double-Take DR",
      "lastModified": "2022-05-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-traffic-manager",
        "azure-virtual-network",
        "azure-vpn-gateway",
        "sql-server"
      ],
      "url": "/azure/architecture/solution-ideas/articles/disaster-recovery-smb-double-take-dr"
    },
    {
      "summary": "Architecture on Azure that shows a regulated environment for researchers to access sensitive data while providing a higher level of control and data protection.",
      "azureCategories": [
        "ai-machine-learning",
        "compute",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/secure-research-env.png",
      "title": "Secure research environment for regulated data",
      "lastModified": "2022-05-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-science-vm",
        "azure-machine-learning",
        "azure-data-factory"
      ],
      "url": "/azure/architecture/ai-ml/architecture/secure-compute-for-research"
    },
    {
      "summary": "This livestreaming solution captures video of interviews, conferences, sporting events, and other events in real time and broadcasts to consumers in real time.",
      "azureCategories": [
        "media"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/digital-media-live-stream.png",
      "title": "Live stream digital media",
      "lastModified": "2022-05-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-cdn",
        "azure-media-player",
        "azure-media-services"
      ],
      "url": "/azure/architecture/solution-ideas/articles/digital-media-live-stream"
    },
    {
      "summary": "Bring all of your data together, via Azure Data Lake (ADLS) Gen-2,  with an Azure Synapse data warehouse that scales easily. Orchestrate and ingest data via Azure Data Factory (ADF) pipelines, optionally enhanced with Azure Databricks, for advanced scalable curation. Build operational reports and analytical dashboards to derive insights from the data.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/enterprise-data-warehouse.png",
      "title": "Enterprise data warehouse",
      "lastModified": "2022-05-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-data-lake",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/solution-ideas/articles/enterprise-data-warehouse"
    },
    {
      "summary": "Improve developer velocity and security with DevSecOps and GitHub during the entire development process using a shift-left strategy.",
      "azureCategories": [
        "devops",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/devsecops-with-rolling-branch-thumbnail.png",
      "title": "DevSecOps with a rolling main branching strategy",
      "lastModified": "2022-05-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-boards",
        "azure-container-registry",
        "github"
      ],
      "url": "/azure/architecture/solution-ideas/articles/devsecops-rolling-branch"
    },
    {
      "summary": "Use AKS to easily ingest and process a real-time data stream, with millions of data points collected via sensors.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/data-streaming-scenario.png",
      "title": "Data streaming with AKS",
      "lastModified": "2022-05-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-service",
        "azure-api-management",
        "azure-container-registry",
        "azure-cache-redis",
        "azure-cosmos-db"
      ],
      "url": "/azure/architecture/solution-ideas/articles/data-streaming-scenario"
    },
    {
      "summary": "This solution focuses on-demand forecasting within the energy sector, which can give a company a competitive advantage.",
      "azureCategories": [
        "analytics",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/demand-forecasting.png",
      "title": "Demand forecasting",
      "lastModified": "2022-05-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-factory",
        "azure-event-hubs",
        "azure-machine-learning",
        "azure-sql-database",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/solution-ideas/articles/demand-forecasting"
    },
    {
      "summary": "Use historical demand data to train a demand forecasting model for shipping and distribution solutions on Microsoft AI Platform.",
      "azureCategories": [
        "analytics",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/demand-forecasting-for-shipping-and-distribution.png",
      "title": "Demand forecasting for shipping and distribution",
      "lastModified": "2022-05-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-data-factory",
        "power-platform",
        "power-bi",
        "azure-stream-analytics",
        "azure-event-hubs"
      ],
      "url": "/azure/architecture/solution-ideas/articles/demand-forecasting-for-shipping-and-distribution"
    },
    {
      "summary": "Predict future customer demand and optimize pricing to maximize profitability using big-data and advanced-analytics services from Microsoft Azure.",
      "azureCategories": [
        "analytics",
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/demand-forecasting-price-optimization-marketing.png",
      "title": "Use a demand forecasting model for price optimization",
      "lastModified": "2022-05-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-data-factory",
        "azure-hdinsight",
        "azure-app-service",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/solution-ideas/articles/demand-forecasting-price-optimization-marketing"
    },
    {
      "summary": "Learn how the custom mobile workforce management app architecture is built and implemented.  The step-by-step diagram illustrates the integration of Active Directory, SAP, and Azure App Service.",
      "azureCategories": [
        "mobile",
        "identity",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/custom-mobile-workforce-app.png",
      "title": "Custom mobile workforce app",
      "lastModified": "2022-05-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-api-management",
        "azure-app-service"
      ],
      "url": "/azure/architecture/solution-ideas/articles/custom-mobile-workforce-app"
    },
    {
      "summary": "Store and share database query results, session states, static contents, and more, by using a common cache-aside pattern.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/data-cache-with-redis-cache.png",
      "title": "Data cache",
      "lastModified": "2022-05-24T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cache-redis",
        "azure-cosmos-db",
        "azure-database-mysql",
        "azure-sql-database",
        "azure-app-service"
      ],
      "url": "/azure/architecture/databases/idea/data-cache-with-redis-cache"
    },
    {
      "summary": "Recommended architecture for implementing a simple enterprise integration pattern using Azure Logic Apps and Azure API Management.",
      "azureCategories": [
        "integration",
        "developer-tools",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/simple-enterprise-integration.png",
      "title": "Basic enterprise integration on Azure",
      "lastModified": "2022-05-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-api-management",
        "azure-dns",
        "azure-logic-apps",
        "azure-monitor"
      ],
      "url": "/azure/architecture/reference-architectures/enterprise-integration/basic-enterprise-integration"
    },
    {
      "summary": "This architecture pattern demonstrates low-latency high throughput ingestion for large volumes of Content Delivery Network (CDN) logs for building near real-time analytics dashboards.",
      "azureCategories": [],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/content-delivery-network-azure-data-explorer.png",
      "title": "Content Delivery Network analytics",
      "lastModified": "2022-05-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-explorer",
        "azure-logic-apps",
        "azure-storage"
      ],
      "url": "/azure/architecture/solution-ideas/articles/content-delivery-network-azure-data-explorer"
    },
    {
      "summary": "Azure Synapse Analytics brings together data integration, enterprise data warehousing, and big data analytics.",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/big-data-analytics-enterprise-grade-security.png",
      "title": "Big data analytics with enterprise-grade security using Azure Synapse",
      "lastModified": "2022-05-23T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-analysis-services",
        "azure-data-lake-storage",
        "azure-synapse-analytics"
      ],
      "url": "/azure/architecture/solution-ideas/articles/big-data-analytics-enterprise-grade-security"
    },
    {
      "summary": "Learn how to build, deploy, and manage high-quality models with Azure Machine Learning, a service for the end-to-end ML lifecycle.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics",
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-machine-learning-solution-architecture.png",
      "title": "Azure Machine Learning architecture",
      "lastModified": "2022-05-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning",
        "azure-synapse-analytics",
        "azure-container-registry",
        "azure-monitor",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/ai-ml/idea/azure-machine-learning-solution-architecture"
    },
    {
      "summary": "Use Azure App Configuration and Azure Key Vault to create a centralized and secured configuration service for apps.",
      "azureCategories": [
        "security",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/appconfig-development.png",
      "title": "Centralized app configuration and security",
      "lastModified": "2022-05-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-app-configuration",
        "azure-key-vault"
      ],
      "url": "/azure/architecture/solution-ideas/articles/appconfig-key-vault"
    },
    {
      "summary": "Learn how to connect an on-premises standalone server to Microsoft Azure virtual networks by using the Azure Network Adapter that you deploy through Windows Admin Center (WAC).",
      "azureCategories": [
        "hybrid",
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-network-adapter.png",
      "title": "Connect standalone servers by using Azure Network Adapter",
      "lastModified": "2022-05-12T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-bastion",
        "azure-virtual-network",
        "azure-vpn-gateway",
        "windows",
        "windows-server",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/hybrid/azure-network-adapter"
    },
    {
      "summary": "Learn how to create a solution for predicting customer lifetime value and churn using Azure Machine Learning.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/architecture-customer-lifetime-churn.png",
      "title": "Determine customer lifetime and churn with Azure AI services",
      "lastModified": "2022-05-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-lake-storage",
        "azure-databricks",
        "azure-machine-learning",
        "azure-analysis-services"
      ],
      "url": "/azure/architecture/ai-ml/architecture/customer-lifecycle-churn"
    },
    {
      "summary": "Deploy an application on Azure virtual machines in multiple regions for high availability and resiliency.",
      "azureCategories": [
        "web",
        "databases",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/multi-region-sql-server.png",
      "title": "Multi-region N-tier application",
      "lastModified": "2022-05-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-monitor",
        "azure-traffic-manager",
        "azure-sql-database",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/reference-architectures/n-tier/multi-region-sql-server"
    },
    {
      "summary": "Learn about Azure services that can help you build a multiparty computing solution, which can include cloud-based and on-premises resources.",
      "azureCategories": [
        "storage",
        "containers"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/database-ledger.png",
      "title": "Multiparty computing architecture design",
      "lastModified": "2022-05-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-kubernetes-service",
        "azure-sql-database"
      ],
      "url": "/azure/architecture/guide/blockchain/multiparty-compute"
    },
    {
      "summary": "Best practices for detecting vulnerabilities as per Requirement 5 and 6 of PCI-DSS 3.2.1.",
      "azureCategories": [
        "containers",
        "security",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-pci.png",
      "title": "Detect vulnerabilities of an AKS regulated cluster for PCI-DSS 3.2.1 (Part 5 of 9)",
      "lastModified": "2022-05-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-application-gateway",
        "microsoft-defender",
        "defender-for-cloud"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks-pci/aks-pci-malware"
    },
    {
      "summary": "Get architectural guidance for industrial IoT (IIoT) analytics solutions that use Azure platform as a service (PaaS) components.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/iiot-architecture.png",
      "title": "Azure industrial IoT analytics guidance",
      "lastModified": "2022-05-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-iot-edge",
        "azure-iot-hub"
      ],
      "url": "/azure/architecture/guide/iiot-guidance/iiot-architecture"
    },
    {
      "summary": "Analyze and visualize data in an Azure Industrial IoT (IIoT) analytics solution. Use Azure Data Explorer, Power BI, custom web applications, and notebooks.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/warm-cold-data.png",
      "title": "Data analysis and visualization in an Azure industrial IoT analytics solution",
      "lastModified": "2022-05-03T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-data-explorer",
        "power-platform",
        "power-bi"
      ],
      "url": "/azure/architecture/guide/iiot-guidance/iiot-data"
    },
    {
      "summary": "Use Azure Arc to extend Azure Resource Manager capabilities to Windows and Linux machines on any infrastructure.",
      "azureCategories": [
        "hybrid",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-arc-hybrid-config.png",
      "title": "Manage configurations for Azure Arc-enabled servers",
      "lastModified": "2022-04-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-arc",
        "azure-monitor",
        "azure-policy",
        "azure-resource-manager",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/hybrid/azure-arc-hybrid-config"
    },
    {
      "summary": "Introduces technical aspects of implementing Azure Batch for risk grid computing in banking.",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/batch-run-process.png",
      "title": "Risk grid computing solution",
      "lastModified": "2022-04-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-batch",
        "entra",
        "entra-id",
        "azure-expressroute",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/industries/finance/risk-grid-banking-solution-guide"
    },
    {
      "summary": "Presents the business considerations of implementing risk grid computing in banking on Azure.",
      "azureCategories": [
        "databases",
        "networking",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-cloud.png",
      "title": "Risk grid computing in banking",
      "lastModified": "2022-04-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-batch",
        "azure-data-factory",
        "azure-expressroute",
        "azure-hdinsight"
      ],
      "url": "/azure/architecture/industries/finance/risk-grid-banking-overview"
    },
    {
      "summary": "Learn how to build an example workload that brings together data from various systems into a user-friendly dashboard.",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/relationship-mesh-saa-architecture.png",
      "title": "Build a relationship mesh dashboard on Azure",
      "lastModified": "2022-04-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-storage",
        "azure-sql-database",
        "azure-synapse-analytics",
        "power-platform",
        "power-bi",
        "office-365"
      ],
      "url": "/azure/architecture/example-scenario/analytics/relationship-mesh-solution-azure"
    },
    {
      "summary": "Build a precision medicine pipeline for genomic analysis and reporting. Use Microsoft Genomics for efficient secondary and tertiary analysis.",
      "azureCategories": [
        "analytics",
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/genomic-analysis-reporting-architecture-thumbnail.png",
      "title": "Precision medicine pipeline with genomics",
      "lastModified": "2022-04-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-blob-storage",
        "azure-data-factory",
        "azure-data-lake-storage",
        "azure-databricks",
        "azure-genomics"
      ],
      "url": "/azure/architecture/example-scenario/precision-medicine/genomic-analysis-reporting"
    },
    {
      "summary": "This reference architecture describes the services that must be deployed in Azure to provide automated management and configuration across on-premises or other cloud providers.",
      "azureCategories": [
        "hybrid",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-automation-hybrid.png",
      "title": "Azure Automation in a hybrid environment",
      "lastModified": "2022-04-06T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-automation",
        "azure-portal",
        "azure-monitor",
        "azure-virtual-machines",
        "azure-arc"
      ],
      "url": "/azure/architecture/hybrid/azure-automation-hybrid"
    },
    {
      "summary": "Learn best practices for a robust CI/CD pipeline for your serverless frontend on Azure.",
      "azureCategories": [
        "devops",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ci-cd-serverless-frontend.png",
      "title": "CI/CD for a serverless application frontend on Azure",
      "lastModified": "2022-02-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-pipelines"
      ],
      "url": "/azure/architecture/serverless/guide/serverless-app-cicd-best-practices"
    },
    {
      "summary": "Learn about machine learning data and models in Azure IoT Edge vision AI solutions.",
      "azureCategories": [
        "iot"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/vision-edge-flow.png",
      "title": "Machine learning in Azure IoT Edge vision AI",
      "lastModified": "2022-02-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/guide/iot-edge-vision/machine-learning"
    },
    {
      "summary": "Learn about hub-spoke topology in Azure, where the spoke virtual networks connect to the hub virtual network and can connect to each other.",
      "azureCategories": [
        "networking",
        "management-and-governance",
        "hybrid"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hub-spoke.png",
      "title": "Hub-spoke network topology in Azure",
      "lastModified": "2022-02-02T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-bastion",
        "azure-firewall",
        "azure-network-watcher",
        "azure-virtual-network",
        "azure-vpn-gateway"
      ],
      "url": "/azure/architecture/reference-architectures/hybrid-networking/hub-spoke"
    },
    {
      "summary": "Learn about asynchronous messaging options in Azure, including the different types of messages and the entities that participate in a messaging infrastructure.",
      "azureCategories": [
        "integration",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/messagetrans.png",
      "title": "Asynchronous messaging options",
      "lastModified": "2022-01-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-event-grid",
        "azure-service-bus",
        "azure-stream-analytics"
      ],
      "url": "/azure/architecture/guide/technology-choices/messaging"
    },
    {
      "summary": "Follow these recommendations to improve scalability by minimizing coordination between application services.",
      "azureCategories": [
        "databases",
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/database-lock.svg",
      "title": "Minimize coordination",
      "lastModified": "2022-01-30T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-storage",
        "azure-sql-database",
        "azure-cosmos-db"
      ],
      "url": "/azure/architecture/guide/design-principles/minimize-coordination"
    },
    {
      "summary": "Summary of a well-architected design of a PCI-DSS 3.2.1 infrastructure as a tenant on the AKS environment.",
      "azureCategories": [
        "containers",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-pci.png",
      "title": "Architecture summary of an AKS regulated cluster (Part 9 of 9)",
      "lastModified": "2021-12-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-firewall",
        "azure-application-gateway",
        "microsoft-defender",
        "defender-for-cloud",
        "azure-monitor"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks-pci/aks-pci-summary"
    },
    {
      "summary": "Get started on your architecture design by addressing the applicable PCI-DSS 3.2.1 requirements as a tenant on the AKS environment. The guidance covers the compliance aspects of the environment including infrastructure, interactions with the workload, operations, management, and interactions between services. Azure Kubernetes Service (AKS).",
      "azureCategories": [
        "containers",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-pci-intro.png",
      "title": "Introduction of an AKS regulated cluster for PCI-DSS 3.2.1 (Part 1 of 9)",
      "lastModified": "2021-11-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-monitor"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks-pci/aks-pci-intro"
    },
    {
      "summary": "Best practices for securing data as per Requirement 3 and 4 of PCI-DSS 3.2.1.",
      "azureCategories": [
        "containers",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-pci.png",
      "title": "Secure data in an AKS regulated cluster for PCI-DSS 3.2.1 (Part 4 of 9)",
      "lastModified": "2021-11-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-application-gateway",
        "entra",
        "entra-id",
        "microsoft-defender",
        "defender-for-cloud",
        "azure-monitor"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks-pci/aks-pci-data"
    },
    {
      "summary": "Monitor deployments of Apache NiFi on Azure by using MonitoFi. This tool sends alerts and displays health and performance information in dashboards.",
      "azureCategories": [
        "containers",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/monitor-apache-nifi-monitofi-architecture-thumbnail.png",
      "title": "Apache NiFi monitoring with MonitoFi",
      "lastModified": "2021-10-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-container-instances",
        "azure-container-registry",
        "azure-monitor"
      ],
      "url": "/azure/architecture/guide/data/monitor-apache-nifi-monitofi"
    },
    {
      "summary": "Learn how to plan for and deploy more efficient and scalable code that runs on Azure Functions and responds to Event Hubs events.",
      "azureCategories": [
        "compute",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/event-hubs-functions-consumer-groups.png",
      "title": "Performance and scale guidance for Event Hubs and Azure Functions",
      "lastModified": "2021-10-04T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-functions"
      ],
      "url": "/azure/architecture/serverless/event-hubs-functions/performance-scale"
    },
    {
      "summary": "Learn how to architect, develop, and deploy efficient and scalable code that runs on Azure Functions and responds to Event Hubs events.",
      "azureCategories": [
        "compute",
        "integration"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/event-hubs-architecture.png",
      "title": "Integrate Event Hubs with serverless functions on Azure",
      "lastModified": "2021-10-04T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-functions",
        "azure-monitor"
      ],
      "url": "/azure/architecture/serverless/event-hubs-functions/event-hubs-functions"
    },
    {
      "summary": "This article is presents guidance for implementing cloud automation using serverless technologies.",
      "azureCategories": [
        "developer-tools",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/cloud-automation.png",
      "title": "Event-based cloud automation",
      "lastModified": "2021-09-29T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-grid",
        "azure-functions",
        "azure-logic-apps",
        "azure-monitor",
        "azure-cosmos-db"
      ],
      "url": "/azure/architecture/reference-architectures/serverless/cloud-automation"
    },
    {
      "summary": "Examine the External Configuration Store pattern, which moves configuration information out of the application deployment package to a centralized location.",
      "azureCategories": [
        "developer-tools",
        "devops"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/external-configuration-store-overview.png",
      "title": "External Configuration Store pattern",
      "lastModified": "2021-09-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-app-configuration",
        "azure-blob-storage"
      ],
      "url": "/azure/architecture/patterns/external-configuration-store"
    },
    {
      "summary": "Use a Citrix-based solution to create a Virtual Desktop Infrastructure for Linux desktops on Azure.",
      "azureCategories": [
        "compute"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-citrix-sample-diagram.png",
      "title": "Linux virtual desktops with Citrix",
      "lastModified": "2021-06-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-load-balancer",
        "azure-sql-database",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/example-scenario/infrastructure/linux-vdi-citrix"
    },
    {
      "summary": "Deploy backend services into a set of geographical nodes, each of which can service any client request in any region.",
      "azureCategories": [
        "databases"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/geode-distribution.png",
      "title": "Geode pattern",
      "lastModified": "2021-06-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-cosmos-db"
      ],
      "url": "/azure/architecture/patterns/geodes"
    },
    {
      "summary": "When using CI/CD pipelines to automate deployments, apply RBAC not just on the ARM side, but also earlier in the process as developers check in their code.",
      "azureCategories": [
        "devops",
        "management-and-governance",
        "security"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/e2e-governance-in-azure.png",
      "title": "End-to-end governance in Azure when using CI/CD",
      "lastModified": "2021-06-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-devops",
        "azure-pipelines",
        "azure-resource-manager"
      ],
      "url": "/azure/architecture/example-scenario/governance/end-to-end-governance-in-azure"
    },
    {
      "summary": "Learn how container orchestration makes it easy to manage complex multi-container microservice deployments, scaling, and cluster health.",
      "azureCategories": [
        "web",
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/multi-container-cluster-with-orchestrator.png",
      "title": "Container orchestration for microservices",
      "lastModified": "2021-05-27T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "azure-service-fabric",
        "azure-container-instances"
      ],
      "url": "/azure/architecture/microservices/design/orchestration"
    },
    {
      "summary": "Learn how healthcare and life science organizations can centralize data and use data science and machine learning to stay relevant to their customers.",
      "azureCategories": [
        "ai-machine-learning",
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/predict-hospital-readmissions-machine-learning.png",
      "title": "Predict hospital readmissions with traditional and automated machine learning techniques",
      "lastModified": "2021-05-26T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning",
        "azure-synapse-analytics",
        "azure-data-factory"
      ],
      "url": "/azure/architecture/example-scenario/ai/predict-hospital-readmissions-machine-learning"
    },
    {
      "summary": "Guidance for documenting security policies as per Requirement 12 of PCI-DSS 3.2.1.",
      "azureCategories": [
        "containers",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-pci.png",
      "title": "Governance, risk, and compliance of for a PCI-DSS 3.2.1 infrastructure  (Part 8 of 9)",
      "lastModified": "2021-05-11T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "entra",
        "entra-id",
        "microsoft-defender",
        "defender-for-cloud"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks-pci/aks-pci-policy"
    },
    {
      "summary": "Best practices for monitoring a PCI-DSS 3.2.1 infrastructure as per Requirement 10, and 11.",
      "azureCategories": [
        "containers",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/aks-pci.png",
      "title": "Monitor operations of an AKS baseline cluster for a PCI-DSS 3.2.1 (Part 7 of 9)",
      "lastModified": "2021-04-28T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-kubernetes-service",
        "microsoft-defender",
        "defender-for-cloud",
        "azure-monitor"
      ],
      "url": "/azure/architecture/reference-architectures/containers/aks-pci/aks-pci-monitor"
    },
    {
      "summary": "Learn how to design a hybrid Windows Admin Center solution to manage workloads that are hosted on-premises and in Microsoft Azure.",
      "azureCategories": [
        "hybrid",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/hybrid-server-os-mgmt-wac-azure.png",
      "title": "Manage hybrid Azure workloads using Windows Admin Center",
      "lastModified": "2021-04-16T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "entra",
        "entra-id",
        "azure",
        "azure-key-vault",
        "azure-portal",
        "azure-virtual-machines"
      ],
      "url": "/azure/architecture/hybrid/hybrid-server-os-mgmt"
    },
    {
      "summary": "This reference architecture illustrates Azure Functions being utilized from on-premises virtual machines.",
      "azureCategories": [
        "hybrid",
        "web"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/azure-functions-hybrid.png",
      "title": "Azure Functions in a hybrid environment",
      "lastModified": "2021-02-22T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-functions",
        "azure-monitor",
        "azure-pipelines",
        "azure-storage",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/hybrid/azure-functions-hybrid"
    },
    {
      "summary": "Learn how to use observability patterns and metrics to improve the processing performance of a big data system using Azure Databricks.",
      "azureCategories": [
        "analytics"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/databricks-observability-architecture.png",
      "title": "Observability patterns and metrics for performance tuning",
      "lastModified": "2021-02-19T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-databricks",
        "azure-log-analytics",
        "azure-monitor"
      ],
      "url": "/azure/architecture/databricks-monitoring/databricks-observability"
    },
    {
      "summary": "Provides a data science methodology to deliver predictive analytics solutions and intelligent applications.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/tdsp-tasks-roles.png",
      "title": "What is the Team Data Science Process?",
      "lastModified": "2020-11-17T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-machine-learning"
      ],
      "url": "/azure/architecture/data-science-process/overview"
    },
    {
      "summary": "Learn about serverless technologies in Azure by walking through an example implementation of a serverless application with Azure Functions.",
      "azureCategories": [
        "developer-tools",
        "featured"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/serverless-functions-architecture.png",
      "title": "Code walkthrough: Serverless application with Functions",
      "lastModified": "2020-06-22T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-hubs",
        "azure-functions"
      ],
      "url": "/azure/architecture/web-apps/serverless/architectures/code"
    },
    {
      "summary": "Learn about automation of IP address space additions to peered vNets. You can add/remove address ranges and delete the peering, then re-create the peering.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/single-subscription.png",
      "title": "Add IP address spaces to peered virtual networks",
      "lastModified": "2020-02-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-virtual-network"
      ],
      "url": "/azure/architecture/networking/prefixes/add-ip-space-peered-vnet"
    },
    {
      "summary": "Create a continuous integration and continuous delivery pipeline for Artificial Intelligence (AI) applications using Docker and Kubernetes.",
      "azureCategories": [
        "ai-machine-learning"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ci-cd-flask-architecture.png",
      "title": "Create CI/CD pipelines for AI apps using Azure Pipelines, Docker, and Kubernetes",
      "lastModified": "2020-01-10T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-pipelines",
        "azure-machine-learning",
        "azure-kubernetes-service"
      ],
      "url": "/azure/architecture/data-science-process/ci-cd-flask"
    },
    {
      "summary": "The sequential convoy cloud design pattern allows for first-in-first-out processing of data in a serverless environment.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/sequential-convoy-overall.png",
      "title": "Sequential Convoy pattern",
      "lastModified": "2019-12-14T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-functions",
        "azure-service-bus"
      ],
      "url": "/azure/architecture/patterns/sequential-convoy"
    },
    {
      "summary": "Examine the Claim-Check pattern, which splits a large message into a claim check and a payload to avoid overwhelming a message bus.",
      "azureCategories": [
        "storage"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/claim-check.png",
      "title": "Claim-Check pattern",
      "lastModified": "2019-07-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-grid",
        "azure-blob-storage"
      ],
      "url": "/azure/architecture/patterns/claim-check"
    },
    {
      "summary": "Learn how to deploy microservices to Azure Service Fabric, which can be used as a starting point for most cluster configuration deployments.",
      "azureCategories": [
        "developer-tools"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/ra-sf-arch.png",
      "title": "Microservices architecture on Azure Service Fabric",
      "lastModified": "2019-06-13T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-api-management",
        "azure-key-vault",
        "azure-monitor",
        "azure-pipelines",
        "azure-service-fabric"
      ],
      "url": "/azure/architecture/reference-architectures/microservices/service-fabric"
    },
    {
      "summary": "Best practices about moving Windows applications to an Azure compute platform without rewriting. This migration uses container support in Azure Service Fabric.",
      "azureCategories": [
        "migration",
        "management-and-governance"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/container-service-fabric.png",
      "title": "Modernize enterprise applications with Azure Service Fabric",
      "lastModified": "2019-05-01T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-load-balancer",
        "azure-service-fabric"
      ],
      "url": "/azure/architecture/service-fabric/modernize-app-azure-service-fabric"
    },
    {
      "summary": "Learn about the Publisher-Subscriber pattern, which enables an application to announce events to many interested consumers asynchronously.",
      "azureCategories": [
        "networking"
      ],
      "thumbnailUrl": "/azure/architecture/browse/thumbs/enterprise-integration-queues.png",
      "title": "Publisher-Subscriber pattern",
      "lastModified": "2018-12-07T00:00:00+00:00",
      "locale": "en-us",
      "products": [
        "azure",
        "azure-event-grid",
        "azure-event-hubs",
        "azure-service-bus"
      ],
      "url": "/azure/architecture/patterns/publisher-subscriber"
    }
  ];

let unique = [...new Map(templates.map(item => [item['url'], item])).values()];
export default unique;