import { AzureClient, AzureClientProps, AzureMember, AzureRemoteConnectionConfig, AzureUser } from "@fluidframework/azure-client";
import { SharedMap } from "@fluidframework/map";
import { Resource, TemplateEngine } from "arm-visualizer-engine";
import * as dot from 'dot-object';
import azureIcons from "./AzureIcons";
import typeToIcon from "./mappings";
import { assign } from "min-dash";
import DataService from "./DataService";
import { AzureFunctionTokenProvider } from "@fluidframework/azure-client";
import { tenantId } from "../auth/authConfig.b2c";

class DiagramService {

    templateEngine = new TemplateEngine();

    createBlank = async (details: any, onCreated: (id: string) => void) => {

        let { containerId } = await this.createFluidContainer();

        DataService.createDiagram({
            diagramId: containerId,
            title: details.title,
            description: details.description,
            tags: details.tags,
            templateId: "",
            templateName: ""
        }).then((diagram: any) => {
            onCreated(containerId);
        });

    }

    createFromTemplate = async  (details: any, template: any, onCreated: (id: string) => void) => {
        this.templateEngine = new TemplateEngine();
        fetch(template.TemplateUrl)
            .then(response => response.text())
            .then(data => {
                this.templateEngine.loadTemplate(data);
                let resources: Resource[] = this.templateEngine.getAllResources();
                console.log(resources);
                return this.createDiagram(details, template, resources, onCreated);
            });
    }

    createFromArchitectureCentreTemplate = async  (template: any, onCreated: (id: string) => void) => {

        let { containerId, container } = await this.createFluidContainer();

        container.on("saved", () => {
            console.log("Saved");
            DataService.createDiagram({
                diagramId: containerId,
                title: template.title,
                description: template.summary,
                templateId: "",
                templateName: template.url,
                tags: template.products.join(", ").concat(", ", template.azureCategories.join(", "))
            }).then((diagram: any) => {
                console.log("Diagram created");
                onCreated(containerId);
            }).catch((error: any) => {
                console.log("Diagram error");
                console.log(error);
            });
        });

        let saveAndLoad = (shapes: any[]) => {
            console.log("saveAndLoad");

            if (container.connectionState === 2) {
                console.log("Connected");
                shapes.forEach(shape => {
                    this.setContainerObject(container, shape.id, shape);
                });
            } else {
                container.on("connected", () => {
                    console.log("Connected");
                    shapes.forEach(shape => {
                        this.setContainerObject(container, shape.id, shape);
                    });
                });
            }
        }

        // Create diagram name and summary labels
        let attrs = { width: 800, height: 200, fontSize: 12, textAlign: "left-top", shapeType: "label", shapeStyle: "Simple", strokeStyle: "Solid", strokeWidth: 1.5, fontWeight: 400, textPadding: 7, stroke: "#c8c6c4", fill: "#faf9f8", radius: 0 };
        let details = template ?
            `### **${template.title}**\r\n ${template.summary} [Details](https://learn.microsoft.com/en-us${template.url})\r\n\r\n`
            : `### **[Title here]**\r\n [Summary here]\r\n\r\n`;

        // Get image from thumbnail & put in diagram
        const getImage = function (url: string, callback: (result: any) => void) {
            console.log("getImage");

            fetch('https://cloudstudio-functions.azurewebsites.net/api/GetImageBase64?url=' + encodeURIComponent(url))
                .then(response => response.json())
                .then(data => {
                    console.log("gotImage");
                    callback(data);
                }).catch((error: any) => {
                    console.log("Error getting image");
                    console.log(error);
                });
        }

        let imageUrl = `https://learn.microsoft.com/en-us${template.thumbnailUrl}`;
        getImage(imageUrl, (result: any) => {
            console.log("getting image");
            let image = {
                "shapeType": "shape",
                "isFrame": true,
                "shapeStyle": "Image",
                "strokeStyle": "solid",
                "x": 0,
                "y": 210,
                "width": result.width,
                "height": result.height,
                "z": 1,
                text: imageUrl,
                "icon": {
                    "data": result.data,
                    "key": template.thumbnail,
                    "title": template.title,
                    "group": "custom"
                }
            };

            const label = assign({}, attrs, {
                id: "diagramName",
                type: "Label",
                x: 0,
                y: 0,
                width: 800,
                height: 80,
                text: details
            });
            let shapes: any[] = [label, image];

            // Create shapes
            saveAndLoad(shapes);

        });
    }

    createFromCode = async  (title: string, code: string, onCreated: (id: string) => void) => {
        this.templateEngine = new TemplateEngine();
        this.templateEngine.loadTemplate(code);
        let resources: Resource[] = this.templateEngine.getAllResources();
        return this.createDiagram(title, null, resources, onCreated);
    }

    createFromExisting = async (diagram: any, code: string, onCreated: (id: string) => void) => {

        let { containerId, container } = await this.createFluidContainer();
        console.log("Container created", containerId, diagram);

        let shapes = JSON.parse(code);

        let saveAndLoad = (shapes: any[]) => {

            container.on("saved", () => {
                DataService.createDiagram({
                    diagramId: containerId,
                    title: diagram.title,
                    description: diagram.description,
                    tags: diagram.tags
                }).then((diagram: any) => {
                    console.log("Diagram created");
                    onCreated(containerId);
                }).catch((error: any) => {
                    console.log(error);
                });
            });

            container.on("connected", () => {
                shapes.forEach(shape => {
                    this.setContainerObject(container, shape.id, shape);
                });
            });

        }

        saveAndLoad(shapes);
    }

    async createFluidContainer(): Promise<{ containerId: string, container: any }> {

        // Get user account
        const account = await DataService.getAccount();
        const user: AzureMember = { userId: account.localAccountId, userName: account.username, connections: [] };
        const connection: AzureRemoteConnectionConfig = {
            tenantId: "a644b047-7d6d-438c-8da4-d196893d7376",
            tokenProvider: new AzureFunctionTokenProvider("https://auth.cloudstudio.app/api/GetToken?code=v0yQpFZXeGOBhDAlmkW6vhjG9B32RJ0Q1Q5L9MlKeyW5AzFuOYvO1A%3D%3D", user),
            endpoint: "https://eu.fluidrelay.azure.com",
            type: "remote",
        };
        const clientProperties: AzureClientProps = {
            connection: connection
        };
        const client = new AzureClient(clientProperties);
        const schema = {
            initialObjects: {
                diagramShapes: SharedMap,
                diagramStyles: SharedMap,
                connectedUsers: SharedMap
            },
            dynamicObjectTypes: [
            ],
        };

        const { container } = await client.createContainer(schema);
        let containerId = await container.attach();
        return { containerId, container };

    }

    createDiagram = async  (details: any, template: any, resources: Resource[], onCreated: (id: string) => void) => {

        let { containerId, container } = await this.createFluidContainer();

        // Create shapes
        let shapes: any[] = [];
        let notAdded: any[] = [];
        let x = 0, y = 120, z = 0, width = 250, height = 200;
        let resourceShapes: any[] = [];
        let connections: any[] = [];
        resources.forEach(resource => {
            let shape: any | null = {};
            if (resource.type === "Microsoft.Resources/deployments") {
                resource.resources?.forEach(subResource => {
                    shape = this.createShapeForResource(subResource, x, y, z++, width, height);
                    if (shape) {
                        resourceShapes.push({ resource: subResource, shape: shape, parent: resource });
                    } else {
                        notAdded.push(subResource);
                    }
                });
            } else {
                shape = this.createShapeForResource(resource, x, y, z++, width, height);
                if (shape) {
                    resourceShapes.push({ resource: resource, shape: shape });
                } else {
                    notAdded.push(resource);
                }
            }
        });

        // Create child shapes
        // TODO: containers
        resourceShapes.filter(resource => resource.parent).forEach(resource => {
            let source = resourceShapes.find(r => r.resource === resource);
            let target = resourceShapes.find(r => r.resource === resource.parent);
            if (source && target) {
                if (!connections.find(c => c.source.shape.id === source.shape.id && c.target.shape.id === target.shape.id)) {
                    this.createAndAddUniqueConnection(connections, source.shape, target.shape, z++, "Solid");
                }
            }
        });

        // Do connections
        resourceShapes.map(x => x.resource).forEach(resource => {
            let depedencies = this.templateEngine.getDependencies(resource);
            if (resource.dependsOn) {
                resource.dependsOn.forEach((dependency: any) => {
                    let dependencyParts = dependency.split(", ");
                    let dependencyName = dependencyParts.length > 1 ? dependencyParts[1].replace(")]", "") : dependency;
                    let source = resourceShapes.find(r => r.resource.name.includes(dependencyName));
                    let target = resourceShapes.find(r => r.resource === resource);
                    if (source && target) {
                        this.createAndAddUniqueConnection(connections, source.shape, target.shape, z++, "Solid");
                    }
                });
            }

            depedencies.forEach(dependency => {
                let source = resourceShapes.find(r => r.resource === resource);
                let target = resourceShapes.find(r => r.resource === dependency);
                if (source && target) {
                    if (!connections.find(c => c.sourceId === source.shape.id && c.targetId === target.shape.id)) {
                        this.createAndAddUniqueConnection(connections, source.shape, target.shape, z++);
                    }
                }
            });

        });

        // Create diagram name and summary labels
        let attrs = { width: 800, height: 120, fontSize: 12, textAlign: "left-top", shapeStyle: "Simple", strokeStyle: "Solid", strokeWidth: 1.5, fontWeight: 400, textPadding: 7, stroke: "#c8c6c4", fill: "#faf9f8", radius: 10 };
        let detailsContent = template ?
            `### **${template.Title}**\r\n ${template.Summary} [Details](https://learn.microsoft.com/en-us${template.Url}) [ARM](${template.TemplateUrl}) [ARMVIZ](http://armviz.io/#/?load=${template.TemplateUrl})\r\n\r\n`
            : `### **[Title here]**\r\n [Summary here]\r\n\r\n`;

        // Parameters & Variables
        let parameters = this.templateEngine.template.parameters
        let variables = this.templateEngine.template.variables;
        detailsContent += `\r\n---\r\n### Variables\r\n| Name | Type | Description | Default Value |\r\n| --- | --- | --- | --- |\r\n`;
        let rowCount = 0
        for (let key in parameters) {
            let parameter = parameters[key];
            detailsContent += `| ${key}&nbsp;&nbsp;| ${parameter.type}&nbsp;&nbsp; | ${parameter.metadata?.description || '-'}&nbsp;&nbsp; | ${parameter.defaultValue || '-'} |\r\n`;
            rowCount++;
        }
        detailsContent += `\r\n---\r\n### Parameters\r\n| Name | Value |\r\n| --- | --- |\r\n`;
        for (let key in variables) {
            let variable = variables[key];
            detailsContent += `| ${key}&nbsp;&nbsp; | ${variable} |\r\n`;
            rowCount++;
        }
        detailsContent += `\r\n---\r\n### Not Added\r\n| Name | Type |\r\n| --- | --- |\r\n`;
        notAdded.forEach(resource => {
            detailsContent += `| ${resource.name}&nbsp;&nbsp; | ${resource.type} |\r\n`;
            rowCount++;
        });

        attrs = assign(attrs, { height: (80 + (rowCount * 27)) });
        let detailsLabel = assign(this.createLabel(-820, -120, z++, detailsContent), attrs);
        shapes.push(detailsLabel);

        let saveAndLoad = (shapes: any[]) => {

            container.on("saved", () => {
                console.log("Saved", details);
                DataService.createDiagram({
                    diagramId: containerId,
                    title: details.title || template.Title,
                    description: details.description || template.Summary,
                    tags: details.tags || template.Tags,
                    templateId: template ? template.TemplateUrl : "",
                    templateName: template ? template.Title : ""
                }).then((diagram: any) => {
                    console.log("Diagram created");
                    onCreated(containerId);
                }).catch((error: any) => {
                    console.log(error);
                });
            });

            container.on("connected", () => {
                console.log("Connected");
                shapes.forEach(shape => {
                    this.setContainerObject(container, shape.id, shape);
                });
            });

        }

        // Layout, save then redirect to load
        let layoutAndSave = async (options: any, shapePositions: any[], connectionPositions: any[]) => {
            shapePositions.forEach(position => {
                let shape = shapes.find(s => s.id === position.id);
                if (shape) {
                    shape.x = position.x;
                    shape.y = position.y;
                }
            });
            connectionPositions.forEach((position: any) => {
                if (position.waypoints) {
                    let connection = shapes.find(s => s.id === position.id);
                    connection.waypoints = position.waypoints;
                }
            });
            saveAndLoad(shapes);
        }

        // Layout shapes
        shapes = shapes.concat(...resourceShapes.map(r => r.shape));
        shapes = shapes.concat(connections);
        this.layoutDiagram(shapes, { direction: 'RIGHT' }, layoutAndSave);
    }

    createLabel =  (x: number, y: number, z: number, text: string): any => {
        return {
            id: this.generateId(z + 1),
            x: x,
            y: y,
            z: z,
            width: 300,
            height: 50,
            text: text,
            shapeType: "label",
            shapeStyle: "Simple",
            strokeStyle: "Solid",
            stroke: "transparent",
            fill: "transparent",
        };
    }

    createShape =  (x: number, y: number, z: number, width: number, height: number, text: string, icon: any) => {
        return {
            id: this.generateId(z + 1),
            x: x,
            y: y,
            z: z,
            width: width,
            height: height,
            text: text,
            shapeType: "shape",
            shapeStyle: icon ? "ImageLabel" : "Rounded",
            strokeStyle: "solid",
            isFrame: icon ? true : false,
            icon: icon
        };
    }

    createAndAddUniqueConnection =  (connections: any[], source: any, target: any, z: number, shapeStyle: string = "Dotted") => {
        if (!connections.find(c => c.sourceId === source.id && c.targetId === target.id)) {
            let connection = this.createConnection(source, target, z, shapeStyle);
            connections.push(connection);
        }
    }

    createConnection =  (source: any, target: any, z: number, shapeStyle: string = "Dotted") => {
        let start = { x: source.x + source.width / 2, y: source.y + source.height / 2 };
        let end = { x: target.x + target.width / 2, y: target.y + target.height / 2 };
        return {
            id: this.generateId(z + 1),
            // text: `${source.text} -> ${target.text}`,
            shapeType: "connection",
            shapeStyle: shapeStyle,
            strokeStyle: "dotted",
            sourceId: source.id,
            targetId: target.id,
            waypoints: [start, end]
        };
    }

    generateId = function (number: number) {
        return `shape_${number}`;
    }

    createShapeForResource =  (resource: Resource, x: number, y: number, z: number, width: number, height: number) => {
        const mapping = typeToIcon.find(m => m.type === resource.type);
        let shapeIcon = null;
        let icon = mapping ? azureIcons.find(i => i.file === mapping.icon) : undefined;
        if (icon) {
            shapeIcon = {
                group: icon.group,
                title: icon.title,
                key: icon.file,
                // data: `data:image/svg+xml;base64,${icon.data}`
            }
            let resourceText = `${this.templateEngine.resolveExpression(resource.name)}\r\n\r\n__${icon.title}__` as string;
            let shape = this.createShape(x, y, z, width, height, resourceText, shapeIcon);
            return shape;
        }
        return null;
    }

    /*
    * Set the Fluid container properties for a shape
    */
    setContainerObject = function (fluidContainer: any, id: string, value: any) {
        const dotted = dot.dot(value);
        let diagramShapes = fluidContainer.initialObjects.diagramShapes;
        Object.keys(dotted).forEach((key: string) => {
            let keyWithId = `${id}.${key}`;
            let currentValue = diagramShapes.get(keyWithId);
            if (currentValue !== dotted[key] && !['children', 'labels'].includes(key)) {
                diagramShapes.set(keyWithId, dotted[key]);
            }
        });
        // Remove properties that no longer exist
        diagramShapes.forEach((value: any, key: string) => {
            let keyParts = key.split('.');
            let keyWithoutId = keyParts.slice(1).join('.');
            if (key.startsWith(`${id}.`) && !dotted[keyWithoutId]) {
                diagramShapes.delete(key);
            }
        });
    }

    layoutDiagram = function (shapes: any, options: any, callback: Function) {

        let spacing = options.spacing || 100;
        let halfSpacing = spacing / 2;

        let graph = {
            id: "root",
            layoutOptions: {
                'elk.edgeRouting': 'ORTHOGONAL',
                "elk.direction": options.direction,
                "elk.algorithm": options.algorithm, /* [ 'layered', 'stress', 'mrtree', 'radial', 'force', 'disco' ] */
                "elk.padding": "[left=50, top=50, right=50, bottom=50]",
                "separateConnectedComponents": "true",
                "spacing.nodeNode": `${spacing}`,
                "spacing.nodeNodeBetweenLayers": `${spacing}`,
                "spacing.edgeNode": `${halfSpacing}`,
                "spacing.edgeNodeBetweenLayers": `${halfSpacing}`,
                "spacing.edgeEdge": `${halfSpacing}`,
                "spacing.edgeEdgeBetweenLayers": `${halfSpacing}`,
                "elk.stress.desiredEdgeLength": `${4 * spacing}`,
                "spacing.manhattan": "true"
            },
            children: [
                { id: "n1", width: 30, height: 30 },
                { id: "n2", width: 30, height: 30 },
                { id: "n3", width: 30, height: 30 }
            ],
            edges: [
                { id: "e1", sources: ["n1"], targets: ["n2"] },
                { id: "e2", sources: ["n1"], targets: ["n3"] }
            ]
        };

        let nodes = shapes.filter((s: any) => s.shapeType !== 'connection' && !s.parentId)
            .sort((a: any, b: any) => a.z - b.z)
            .map((s: any) => { return { id: s.id, width: s.width, height: s.height } });

        let nodeIds = nodes.map((n: any) => n.id);

        // TODO: connections -> sources & targets merge
        let edges = shapes.filter((s: any) => s.shapeType === 'connection')
            .filter((s: any) => nodeIds.includes(s.sourceId) || nodeIds.includes(s.targetId))
            .map((s: any) => { return { id: s.id, sources: [s.sourceId], targets: [s.targetId] } });

        graph.children = nodes;
        graph.edges = edges;

        return true;

    }

    layoutConnection = function (shapes: any, source: any, target: any, callback: Function) {

        let spacing = 100;
        let halfSpacing = spacing / 2;

        let graph = {
            id: "root",
            layoutOptions: {
                "elk.direction": 'right',
                "elk.algorithm": 'stress', /* [ 'layered', 'stress', 'mrtree', 'radial', 'force', 'disco' ] */
                'elk.edgeRouting': 'ORTHOGONAL',
                "elk.padding": "[left=50, top=50, right=50, bottom=50]",
                "separateConnectedComponents": "true",
                "spacing.nodeNode": `${spacing}`,
                "spacing.nodeNodeBetweenLayers": `${spacing}`,
                "spacing.edgeNode": `${halfSpacing}`,
                "spacing.edgeNodeBetweenLayers": `${halfSpacing}`,
                "spacing.edgeEdge": `${halfSpacing}`,
                "spacing.edgeEdgeBetweenLayers": `${halfSpacing}`,
                "elk.stress.desiredEdgeLength": `${4 * spacing}`,
                "spacing.manhattan": "true"
            },
            children: [],
            edges: []
        };

        let nodes = shapes.filter((s: any) => s.shapeType !== 'connection')
            .sort((a: any, b: any) => a.z - b.z)
            .map((s: any) => { return { id: s.id, x: s.x, y: s.y, width: s.width, height: s.height } });

        // TODO: connections -> sources & targets merge
        graph.children = nodes;
        graph.edges = [{ id: 'connection', sources: [source.id], targets: [target.id] }] as never[];

        return true;
    }

    getBoundingBox(selectedShapes: any[]) {

        let minX = 100000;
        let minY = 100000;
        let maxX = 0;
        let maxY = 0;

        selectedShapes.forEach((shape: any) => {
            if (shape.x && shape.y) {
                minX = Math.min(minX, shape.x);
                minY = Math.min(minY, shape.y);
            }
            if (shape.width && shape.height) {
                maxX = Math.max(maxX, shape.x + shape.width);
                maxY = Math.max(maxY, shape.y + shape.height);
            }
        });

        console.log(minX, minY, maxX, maxY);
        return {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY
        };
    }

};

export default DiagramService;