import React, { lazy, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
const Diagram = lazy(() => import('./Diagram'));

const DiagramWrapper = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [diagramId, setDiagramId] = React.useState<string | null>(null);

    // Pass the necessary values as props to the class component
    
    return <Suspense fallback={<div>Loading...</div>}>
            <Diagram location={location} navigate={navigate} key={diagramId} diagramId={diagramId} onDiagramChanged={(id: string) => {
                setDiagramId(id);
                window.location.hash = id;
            }} />
            </Suspense>};

export default DiagramWrapper;
