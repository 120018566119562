import React from "react";
import * as dot from 'dot-object';
import { ShapeStyles } from '../services/ShapeStyles';
import * as MarkdownIt from 'markdown-it';
import * as MarkdownItMark from 'markdown-it-mark';

let replaceTokens = (str, tokens) => {
  tokens.forEach(token => {
    str = str.replaceAll(`{${token.key}}`, `${token.value}`);
  });
  return str;
}

let borders = (str, dotted, value) => {

  value = value || 'top-bottom-right-left';
  let tokens = Object.keys(dotted).map(key => { return { key, value: dotted[key] } });

  let styles = new ShapeStyles();
  let style = styles.styles["default"];
  Object.keys(style).forEach(key => {
    let value = style[key];
    if (!tokens.some(token => token.key === key)) {
      tokens.push({ key, value });
    }
  });

  let noBorderStyleLeft = replaceTokens('inset {strokeWidth}px 0 0 0 transparent', tokens);
  let noBorderStyleRight = replaceTokens('inset -{strokeWidth}px 0 0 0 transparent', tokens);
  let noBorderStyleTop = replaceTokens('inset 0 {strokeWidth}px 0 0 transparent', tokens);
  let noBorderStyleBottom = replaceTokens('inset 0 -{strokeWidth}px 0 0 transparent', tokens);

  let borderStyleLeft = replaceTokens('inset {strokeWidth}px 0 0 0 {stroke}', tokens);
  let borderStyleRight = replaceTokens('inset -{strokeWidth}px 0 0 0 {stroke}', tokens);
  let borderStyleTop = replaceTokens('inset 0 {strokeWidth}px 0 0 {stroke}', tokens);
  let borderStyleBottom = replaceTokens('inset 0 -{strokeWidth}px 0 0 {stroke}', tokens);

  if (dotted["strokeStyle"] !== "solid") {
    noBorderStyleLeft = replaceTokens('borderLeft: {strokeWidth}px {strokeStyle} transparent;', tokens);
    noBorderStyleRight = replaceTokens('borderRight: {strokeWidth}px {strokeStyle} transparent;', tokens);
    noBorderStyleTop = replaceTokens('borderTop: {strokeWidth}px {strokeStyle} transparent;', tokens);
    noBorderStyleBottom = replaceTokens('borderBottom: {strokeWidth}px {strokeStyle} transparent;', tokens);
    borderStyleLeft = replaceTokens('borderLeft: {strokeWidth}px {strokeStyle} {stroke};', tokens);
    borderStyleRight = replaceTokens('borderRight: {strokeWidth}px {strokeStyle} {stroke};', tokens);
    borderStyleTop = replaceTokens('borderTop: {strokeWidth}px {strokeStyle} {stroke};', tokens);
    borderStyleBottom = replaceTokens('borderBottom: {strokeWidth}px {strokeStyle} {stroke};', tokens);
    const borderLeft = value.includes('left') ? borderStyleLeft : noBorderStyleLeft;
    const borderRight = value.includes('right') ? borderStyleRight : noBorderStyleRight;
    const borderTop = value.includes('top') ? borderStyleTop : noBorderStyleTop;
    const borderBottom = value.includes('bottom') ? borderStyleBottom : noBorderStyleBottom;
    return ` ${borderLeft} ${borderRight} ${borderTop} ${borderBottom} `;
  } else {
    const borderLeft = value.includes('left') ? borderStyleLeft : noBorderStyleLeft;
    const borderRight = value.includes('right') ? borderStyleRight : noBorderStyleRight;
    const borderTop = value.includes('top') ? borderStyleTop : noBorderStyleTop;
    const borderBottom = value.includes('bottom') ? borderStyleBottom : noBorderStyleBottom;
    const borders = ` boxShadow: ${borderLeft}, ${borderRight}, ${borderTop}, ${borderBottom}; `;
    return borders;
  }
}

let borderRadius = (str, dotted, value) => {
  const borders = dotted['borders'] || 'left-right-top-bottom';
  const borderRadius = dotted['radius'] || 0;
  const borderTopLeftRadius = borders.includes('top') && borders.includes('left') ? `${borderRadius}px` : 0;
  const borderTopRightRadius = borders.includes('top') && borders.includes('right') ? `${borderRadius}px` : 0;
  const borderBottomRightRadius = borders.includes('bottom') && borders.includes('right') ? `${borderRadius}px` : 0;
  const borderBottomLeftRadius = borders.includes('bottom') && borders.includes('left') ? `${borderRadius}px` : 0;
  return `borderRadius: ${borderTopLeftRadius} ${borderTopRightRadius} ${borderBottomRightRadius} ${borderBottomLeftRadius}; `;
}

let borderRadiusTop = (str, dotted, value) => {
  const borderRadius = dotted['radius'] || 0;
  return `borderRadius: ${borderRadius}px ${borderRadius}px 0 0; `;
}

let getNodes = (str, shape, pathString) => {
  const dotted = dot.dot(shape);

  if (!str) {
    return [];
  }

  let tokens = Object.keys(dotted).map(key => { return { key, value: dotted[key] } });

  // Then apply the shape values
  Object.keys(dotted).forEach(key => {
    let value = dotted[key];

    if (key === 'textAlign') {
      if (!value) {
        str = str.replaceAll(`{justifyContent}`, 'center');
        str = str.replaceAll(`{alignItems}`, 'center');
      } else {
        const parts = value.split('-');
        const horizontal = parts[0];
        const vertical = parts[1];
        const justifyContent = horizontal === 'left' ? 'flex-start' : horizontal === 'right' ? 'flex-end' : 'center';
        const textAlign = horizontal === 'left' ? 'left' : horizontal === 'right' ? 'right' : 'center';
        const alignItems = vertical === 'top' ? 'flex-start' : vertical === 'bottom' ? 'flex-end' : 'center';
        str = str.replaceAll(`{justifyContent}`, justifyContent);
        str = str.replaceAll(`{alignItems}`, alignItems);
        str = str.replaceAll(`{textAlign}`, textAlign);
      }
    } else if (['text', 'content'].includes(key) && value) {
      let html = convertMarkdownToHtml(value);
      str = str.replaceAll(`{${key}}`, html);
    } else if (key === 'expanded') {
      const expanded = (value === false) ? true : false;
      str = str.replaceAll(`{visibleExpanded}`, !expanded ? 'display: block;' : 'display: none;');
      str = str.replaceAll(`{visibleCollapsed}`, expanded ? 'display: block;' : 'display: none;');
    } else if (key === 'fontStyle' && value) {
      let textDecoration = (value.includes('underline') ? 'underline ' : '') + (value.includes('strikethrough') ? 'line-through ' : '');
      let fontStyle = value ? (value.includes('italic') ? 'italic' : 'normal') : 'normal';
      str = str.replaceAll(`{textDecoration}`, textDecoration);
      str = str.replaceAll(`{fontStyle}`, fontStyle);
    } else if (key === 'strokeStyle' && value) {
      const strokeWidth = dotted['strokeWidth'] ? +dotted['strokeWidth'] : 2;
      const strokeDasharray = value === "dashed" ? `${strokeWidth*2}, ${strokeWidth*2}` : value === "dotted" ? `${strokeWidth/8}, ${strokeWidth*2}` : "";
      str = str.replaceAll(`{${key}}`, value);
      str = str.replaceAll(`{strokeDasharray}`, `${strokeDasharray}`);
    } else if (key === 'borders') {
      str = str.replaceAll(`{borders}`, borders(str, dotted, value));
    } else if (key === 'radius') {
      str = str.replaceAll(`{borderRadiusTop}`, borderRadiusTop(str, dotted, value));
      str = str.replaceAll(`{borderRadius}`, borderRadius(str, dotted, value));
      str = str.replaceAll(`{radius}`, `{value}px`);
    } if (key === 'svg' && value) {
      value = replaceTokens(value, tokens);
      str = str.replaceAll(`{svg}`, `${value}`);
    } if (key === 'pathString' && value) {
      console.log('pathString', value);
    } else if (str.includes(`{${key}}`) && value !== undefined && value !== null) {
      str = str.replaceAll(`{${key}}`, value);
    }

  });

  // Apply default values
  let styles = new ShapeStyles();
  let style = styles.styles["default"];
  Object.keys(style).forEach(key => {
    let value = style[key];
    if (key === 'textAlign') {
        const parts = value.split('-');
        const horizontal = parts[0];
        const vertical = parts[1];
        const justifyContent = horizontal === 'left' ? 'flex-start' : horizontal === 'right' ? 'flex-end' : 'center';
        const textAlign = horizontal === 'left' ? 'left' : horizontal === 'right' ? 'right' : 'center';
        const alignItems = vertical === 'top' ? 'flex-start' : vertical === 'bottom' ? 'flex-end' : 'center';
        str = str.replaceAll(`{justifyContent}`, justifyContent);
        str = str.replaceAll(`{alignItems}`, alignItems);
        str = str.replaceAll(`{textAlign}`, textAlign);
      } else if (key === 'borders') {
        str = str.replaceAll(`{borders}`, borders(str, dotted, value));
      } else if (key === 'radius') {
        str = str.replaceAll(`{borderRadius}`, borderRadius(str, dotted, value));
        str = str.replaceAll(`{radius}`, `{value}px`);
      } else if (str.includes(`{${key}}`) && value) {
        str = str.replaceAll(`{${key}}`, value);
      }
  });

  // Half values
  str = str.replaceAll(`{halfWidth}`, dotted["width"] / 2);
  str = str.replaceAll(`{halfHeight}`, dotted["height"] / 2);
  str = str.replaceAll(`{halfStrokeWidth}`, dotted["strokeWidth"] / 2);
  str = str.replaceAll(`{widthPlusStroke}`, dotted["width"] + dotted["strokeWidth"]);
  str = str.replaceAll(`{heightPlusStroke}`, dotted["height"] + dotted["strokeWidth"]);

  if (str.includes('{pathString}') && pathString) {
    let tokens = Object.keys(dotted).map(key => { return { key, value: dotted[key] } });
    const radius = shape.radius || 0;
    const strokeWidth = shape.strokeWidth || 2;
    const width = shape.width;
    const height = shape.height;

    let pathCoords = JSON.parse(pathString);
    pathCoords.forEach(coord => {
      if (!isNaN(coord.x)) {
        if (coord.x === 0) {
          coord.x = strokeWidth / 2;
        } else if (coord.x === 100) {
          coord.x = width - (strokeWidth / 2);
        } else {
        coord.x = coord.x * (width / 100);
        }
      }
      if (!isNaN(coord.y)) {
        if (coord.y === 0) {
          coord.y = strokeWidth / 2;
        } else if (coord.y === 100) {
          coord.y = height - (strokeWidth / 2);
        } else {
        coord.y = coord.y * (height / 100);
        }
      }
    });
    pathString = JSON.stringify(pathCoords);
    let coordJson = replaceTokens(pathString, tokens);
    str = replaceTokens(str, tokens);
    let coords = JSON.parse(coordJson);
    if (coords && coords.length > 0) {
      let resolved = createPathString(coords);
      str = str.replaceAll(`{pathString}`, resolved);
      console.log('resolved', str);
    }
  }

  str = str.replaceAll(`{content}`, '');
  str = str.replaceAll(`{text}`, '');

  var body = new DOMParser().parseFromString(str, "text/html").body;
  var nodes = body.childNodes;
  return nodes;
}

let createJSX = nodeArray => {
  return nodeArray.map(node => {
    let attributeObj = {};
    const {
      attributes,
      localName,
      childNodes,
      nodeValue
    } = node;
    if (attributes) {
      Array.from(attributes).forEach(attribute => {
        if (attribute.name === "style") {
          let styleAttributes = attribute.nodeValue.split(";");
          let styleObj = {};
          styleAttributes.forEach(attribute => {
            let [key, value] = attribute.split(":");
            if (key && value) {
              styleObj[key.trim()] = value.trim();
            }
          });
          attributeObj[attribute.name] = styleObj;
        } else {
          attributeObj[attribute.name] = attribute.nodeValue;
        }
      });
    }
    attributeObj["key"] = Math.random().toString(36).substring(7);
    return !nodeValue
      ? React.createElement(
        localName,
        attributeObj,
        childNodes && Array.isArray(Array.from(childNodes)) && Array.from(childNodes).length > 0 ? createJSX(Array.from(childNodes)) : null
      )
      : nodeValue;
  });
};

const convertMarkdownToHtml = (text) => {
  var md = new MarkdownIt({ html: true, breaks: true }).use(MarkdownItMark);
  var html = md.render(text);
  if (html.substring(0, 3) === '<p>') {
    html = html.substring(3, html.length);
  }
  if (html.substring(html.length - 5, html.length) === '</p>\n') {
    html = html.substring(0, html.length - 5);
  }
  if (html.includes('<p></p>')) {
    html = html.replace(/<p><\/p>/g, '<br />');
  }
  var hash = window.location.hash.split('&');
  var fluidId = hash[0];
  html = html.replace(/<a href="h/g, '<a className="embedded-link" target="_blank" href="h');
  html = html.replace(/<a href="#/g, '<a className="embedded-link" href="' + fluidId + '&id=');
  return html;
}

export const StringToJSX = props => {
  const shapeDefinition = props.definition;
  if (props.shape.pathString) {
    const pathString = props.shape.pathString;
    delete(props.shape.pathString);
    const nodes = getNodes(shapeDefinition.definition, props.shape, pathString);
    const jsx = createJSX(Array.from(nodes));
    return jsx;
  } else {
    const nodes = getNodes(shapeDefinition.definition, props.shape, shapeDefinition.pathString);
    const jsx = createJSX(Array.from(nodes));
    return jsx;
  }
};

function createPathString(pathCoords) {
  const path = [];
  for (let i = 0; i < pathCoords.length; i++) {
    const coord = pathCoords[i];
    if (coord.x !== undefined && coord.y !== undefined) {
      const token = (i === 0) ? 'M' : 'L';
      path.push(`${token}${coord.x}`);
      path.push(coord.y);
    }
  }
    path.push('Z');
    return path.join(' ');
}

/**
 * Creates a coordinate path for the Path SVG element with rounded corners
 * @param pathCoords - An array of coordinates in the form [{x: Number, y: Number}, ...]
 */
function createRoundedPathString(pathCoords, curveRadius) {
  const path = [];

  // Reset indexes, so there are no gaps
  pathCoords = pathCoords.slice();

  for (let i = 0; i < pathCoords.length; i++) {

    // 1. Get current coord and the next two (startpoint, cornerpoint, endpoint) to calculate rounded curve
    const c2Index = ((i + 1) > pathCoords.length - 1) ? (i + 1) % pathCoords.length : i + 1;
    const c3Index = ((i + 2) > pathCoords.length - 1) ? (i + 2) % pathCoords.length : i + 2;

    const c1 = pathCoords[i];
    const c2 = pathCoords[c2Index];
    const c3 = pathCoords[c3Index];

    // 2. For each 3 coords, enter two new path commands: Line to start of curve, bezier curve around corner.

    // Calculate curvePoint c1 -> c2
    const c1c2Distance = Math.sqrt(Math.pow(c1.x - c2.x, 2) + Math.pow(c1.y - c2.y, 2));
    const c1c2DistanceRatio = (c1c2Distance - curveRadius) / c1c2Distance;
    const c1c2CurvePoint = [
      ((1 - c1c2DistanceRatio) * c1.x + c1c2DistanceRatio * c2.x).toFixed(1),
      ((1 - c1c2DistanceRatio) * c1.y + c1c2DistanceRatio * c2.y).toFixed(1)
    ];

    // Calculate curvePoint c2 -> c3
    const c2c3Distance = Math.sqrt(Math.pow(c2.x - c3.x, 2) + Math.pow(c2.y - c3.y, 2));
    const c2c3DistanceRatio = curveRadius / c2c3Distance;
    const c2c3CurvePoint = [
      ((1 - c2c3DistanceRatio) * c2.x + c2c3DistanceRatio * c3.x).toFixed(1),
      ((1 - c2c3DistanceRatio) * c2.y + c2c3DistanceRatio * c3.y).toFixed(1)
    ];

    // If at last coord of polygon, also save that as starting point
    if (i === pathCoords.length - 1) {
      path.unshift('M' + c2c3CurvePoint.join(','));
    }

    // Line to start of curve (L endcoord)
    path.push('L' + c1c2CurvePoint.join(','));
    // Bezier line around curve (Q controlcoord endcoord)
    path.push('Q' + c2.x + ',' + c2.y + ',' + c2c3CurvePoint.join(','));
  }
  // Logically connect path to starting point again (shouldn't be necessary as path ends there anyway, but seems cleaner)
  path.push('Z');

  return path.join(' ');
}