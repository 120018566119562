import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Text, Stack, Separator } from '@fluentui/react';
  import React from 'react';
import { SignInButton } from '../auth/SignInButton';
  
  export interface DemoProps {
    // Fill in props here
  }
  
  export const Demo = (props: DemoProps) => {
    return (
      <React.Fragment>

          <Stack tokens={{ childrenGap: 20 }} styles={{ root: { margin: 20, outerHeight: '100%', width: 'calc(100vw - 260px)' } }}>
            <Text variant="xLarge">
              You'll need to have an account with us to create and edit shapes
            </Text>
            <Stack tokens={{ childrenGap: 8 }} className="home-panel">

              <ul style={{ lineHeight: '2.5em', fontSize: '1.1em', marginBottom: '10px' }}>
                <li>Whilst we're in alpha, cloud/studio is <strong>free</strong></li>
                <li>We'll be introducing a paid tier soon</li>
                <li>Sign up now to get going</li>
              </ul>

              <Separator />

              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <SignInButton></SignInButton>
              </Stack>

            </Stack>
          </Stack>

      </React.Fragment>
    );
  }