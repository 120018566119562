import React from 'react';
import { Stack, ThemeProvider } from '@fluentui/react';
import './App.css';
import 'csshake';
import './diagram.css';
import { Route, Routes } from 'react-router-dom';
import FormsWithRouter, { IDiagram } from './pages/Diagrams';
import Home from './pages/Home';
import { Banner } from './page-components/Banner';
import LeftMenu from './page-components/LeftMenu';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./auth/authConfig.b2c";
import Templates from './pages/Templates';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import {Demo} from './pages/Demo';
import Shapes from './pages/Shapes';
import { defaultTheme } from './Theme';
import Styles from './pages/Styles';
import { Cookies } from './pages/Cookies';
import { Privacy } from './pages/Privacy';
import { Terms } from './pages/Terms';
import { About } from './pages/About';
import DiagramWrapper from './pages/DiagramWrapper';

// const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString: "InstrumentationKey=6af521fb-a372-4810-bd64-c0b1207811cc;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/",
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
        }
    }
});
appInsights.loadAppInsights();

const pca = new PublicClientApplication(msalConfig);

const App: React.FunctionComponent = () => {

  const [state] = React.useState<{ panelOpen: boolean, selectedForm: IDiagram | null, items: Array<IDiagram> }>({ panelOpen: false, selectedForm: null, items: [] });

  return (
    <MsalProvider instance={pca}>
    <ThemeProvider applyTo='body' theme={defaultTheme}>
        <Stack verticalFill={true} styles={{ root: { backgroundColor: 'rgb(250, 252, 247)' } }}>
          <Banner></Banner>

          <Stack horizontal verticalFill={true}>
            <LeftMenu></LeftMenu>
            <Stack verticalFill={true}>
              <Routes>
                <Route path="/home" element={<Home></Home>}></Route>
                <Route path="/diagrams" element={<FormsWithRouter items={state.items} selectedForm={state.selectedForm} navigation={undefined} />}></Route>
                <Route path="/templates" element={<Templates></Templates>}></Route>
                <Route path="/shapes" element={<Shapes></Shapes>}></Route>
                <Route path="/styles" element={<Styles></Styles>}></Route>
                <Route path="/diagram" element={<DiagramWrapper></DiagramWrapper>}></Route>
                <Route path="/demo" element={<Demo></Demo>}></Route>
                <Route path="/" element={<Home></Home>}></Route>

                <Route path="/about" element={<About></About>}></Route>
                <Route path="/cookies" element={<Cookies></Cookies>}></Route>
                <Route path="/privacy" element={<Privacy></Privacy>}></Route>
                <Route path="/terms" element={<Terms></Terms>}></Route>
              </Routes>
            </Stack>
          </Stack>
        </Stack>

    </ThemeProvider>
    </MsalProvider>
  );
};

export default withAITracking(reactPlugin, App);
