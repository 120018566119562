import * as React from 'react';
import { mergeStyleSets, TextField, DefaultButton, PrimaryButton, Panel, PanelType, Label, IconButton } from '@fluentui/react';
import DataService from '../services/DataService';
import Editor from '../editor/Editor';
import { registerIcons } from '@fluentui/react/lib/Styling';
import { Graph } from '@antv/x6';
import { Snapline } from '@antv/x6-plugin-snapline';
import { Selection } from '@antv/x6-plugin-selection'
import { Transform } from '@antv/x6-plugin-transform'
import { register } from '@antv/x6-react-shape';
import { Export } from "@antv/x6-plugin-export";
import { CustomShape } from '../pages/CustomShape';

registerIcons({
  fontFace: {
    fontFamily: "FabricMDL2Icons"
  },
  icons: {
    FlashAuto: "\uF50C",
  }
});

register({
  shape: 'custom-node',
  width: 200,
  height: 200,
  effect: ['custom-shape', 'definition'],
  component: CustomShape
})

const classNames = mergeStyleSets({
  container: {
    overflow: 'hide',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: '#fefefe',
    boxShadow: 'inset 0px -1px 5px 1px rgba(100,100,100,0.1)',
  }
});

export interface ShapePanelProps {
  shape: any | null;
  isOpen: boolean;
  handleShapeCreated: Function;
  handleShapeChanged: Function;
  handleShapePanelDismissed: Function;
}

const buttonStyles = { root: { marginRight: 8 } };

export class ShapePanel extends React.Component<ShapePanelProps, any> {

  editor: any;
  private container: HTMLDivElement | undefined
  private graph: Graph | undefined

  state = {
    isOpen: false,
    isBusy: false,
    name: this.props.shape?.title || '',
    definition: this.props.shape?.definition || '',
    preview: this.props.shape?.preview || ''
  };

  refContainer = (container: HTMLDivElement) => {
    this.container = container
  }

  saveChanges() {
    this.setState({ isBusy: true });
    if (this.props.shape != null && this.props.shape.shapeId) {
      DataService.updateShape(this.props.shape.shapeId, this.state.name, this.state.definition || [], this.state.preview)
        .then((shape) => {
          this.props.handleShapeChanged(this.state.name, this.state.definition);
          this.dismissPanel();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({ isBusy: false });
        });
    } else {
      DataService.createShape({ title: this.state.name, definition: this.state.definition, preview: this.state.preview })
        .then(response => response.json())
        .then((shape) => {
          this.props.handleShapeCreated(shape);
          this.dismissPanel();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({ isBusy: false });
        });
    }
  }

  dismissPanel() {
    this.setState({ isOpen: false });
    this.props.handleShapePanelDismissed();
  }

  onHandleChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) {
    this.setState({ name: event.currentTarget.value });
  }

  onFormatMarkup() {
    this.editor.getAction('editor.action.formatDocument').run();
  }

  editorDidMount = (editor: any, monaco: any) => {
    this.editor = editor;
  }

  setupPreview(): void {

    //#region Graph setup
    let graph = new Graph({
      container: this.container,
      panning: true,
      mousewheel: true,
      grid: {
        visible: true,
        type: 'dot',
        args: [
          {
            color: '#c8c8c8', // main grid line color  
            thickness: 1, // main grid line width  
          },
          {
            color: '#ddd', // secondary grid line color  
            thickness: 1, // Secondary grid line width  
            factor: 5, // Interval between major and minor grid lines 
          },
        ],
      },
      connecting: {
        router: "manhattan",
        connector: "normal",
      },
      background: {
      },
    });

    graph.use(
      new Snapline({
        enabled: true,
      }))
      .use(new Selection({
        enabled: true,
        rubberband: true,
        showNodeSelectionBox: true,
        modifiers: ['shift'],
      }))
      .use(new Transform({
        resizing: true,
        rotating: {
          enabled: true,
          grid: 1,
        }
      }))
      .use(new Snapline({
        enabled: true,
      }))
      .use(new Export());



    this.graph = graph;

    //#endregion

    this.updatePreview(this.state.definition, true);

  }

  updatePreview(definition: string | undefined, zoomToFit: boolean = false): void {
    if (this.graph) {

      const shape = {
        id: 'preview',
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        text: this.state.name,
      };
      this.graph.clearCells();
      const node = this.graph.addNode({
        id: shape.id,
        "custom-shape": JSON.stringify(shape),
        definition: JSON.stringify({ definition }),
        shape: "custom-node",
        x: shape.x,
        y: shape.y,
        width: shape.width,
        height: shape.height,
        label: shape.text,
      });

      // if (zoomToFit) {
      //   this.graph.centerContent();
      // }

      setTimeout(() => {
        this.graph?.toPNG((data: string) => {
          this.setState({ preview: data });
        }, { width: 100, height: 100, backgroundColor: 'transparent', padding: 10, viewBox: { x: 0, y: 0, width: 100, height: 100 }, quality: 100 });
      }, 500);

    }
  }

  public componentDidUpdate(previousProps: ShapePanelProps, previousState: any) {

    if (this.props.shape && previousProps.shape !== this.props.shape) {
      let name = this.props.shape ? this.props.shape.title : '';
      this.setState({ name: name, definition: this.props.shape.definition });
    }

    if (this.props.isOpen !== previousProps.isOpen) {
      this.setState({ isOpen: this.props.isOpen });
      if (this.props.isOpen) {
        setTimeout(() => {
          this.setupPreview();
        }, 1000);
      }
    }

  }

  onRenderFooterContent =
    () => (
      <div>
        <PrimaryButton onClick={this.saveChanges.bind(this)} styles={buttonStyles} disabled={this.state.isBusy}>
          Save
        </PrimaryButton>
        <DefaultButton onClick={this.dismissPanel.bind(this)}>Cancel</DefaultButton>
      </div>
    );


  render() {
    return (
      <Panel
        layerProps={{ eventBubblingEnabled: true }}
        type={PanelType.medium}
        isOpen={this.state.isOpen}
        onDismiss={this.dismissPanel.bind(this)}
        headerText="Shape properties"
        closeButtonAriaLabel="Close"
        onRenderFooterContent={this.onRenderFooterContent}
        isFooterAtBottom={true}>

        <TextField label="Name" value={this.state.name} onChange={(event) => this.setState({ name: event.currentTarget.value })} />

        <Label htmlFor="definition">
          Definition
          <IconButton styles={{ flexContainer: { padding: 0 } }} style={{ marginLeft: 10, padding: 2 }} iconProps={{ iconName: 'AutoEnhanceOn' }} title="Format" ariaLabel="Format" onClick={this.onFormatMarkup.bind(this)} />
        </Label>
        <div style={{ height: '30vh', border: '1px solid rgb(131, 136, 140)', borderRadius: 2 }}>
          <Editor
            mode="html"
            language="html"
            defaultLanguage='html'
            value={this.state.definition}
            options={{
              minimap: { enabled: true },
              automaticLayout: true,
              wordWrap: 'on',
              folding: true,
              lineNumbers: 'off',
              scrollBeyondLastLine: false,
              readOnly: false,
              formatOnType: true,
              formatOnPaste: true,
              suggest: {
                showKeywords: true,
                showSnippets: true,
                showColors: true,
                showClasses: true,
                showMethods: true,
                showFunctions: true,
                showVariables: true,
                showFields: true,
                showFiles: true,
                showReferences: true,
                showFolders: true,
                showModules: true,
                showProperties: true,
                showEvents: true,
                showOperators: true,
                showUnits: true,
                showValues: true,
                showConstants: true,
                showEnums: true,
                showEnumMembers: true,
                showStructs: true,
                showTypeParameters: true,
                showWords: true,
                showUsers: true,
                showIssues: true,
                showDeprecated: true,
                showInlineDetails: true,
                filterGraceful: true,
                localityBonus: true,
                shareSuggestSelections: true,
                snippetsPreventQuickSuggestions: true,
              }
            }}
            onChange={(event: string) => {
              console.log(event);
              this.setState({ definition: event?.valueOf() });
              this.updatePreview(event?.valueOf(), false);
            }
            }
            onMount={this.editorDidMount}
          />
        </div>

        <Label htmlFor="definition">
          Preview
          {/* <IconButton styles={{ flexContainer: { padding: 0 } }} style={{ marginLeft: 10, padding: 2 }} iconProps={{ iconName: 'AutoEnhanceOn' }} title="Format" ariaLabel="Format" onClick={this.onFormatMarkup.bind(this)} /> */}
        </Label>
        <div style={{ height: '30vh', border: '1px solid rgb(131, 136, 140)', borderRadius: 2 }}>
          <div className={classNames.container} ref={this.refContainer} style={{ height: '30vh' }} />
        </div>

      </Panel>
    );
  }

};
