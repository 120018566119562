import * as React from 'react';

const separatorStyles = {
  height: '5vh',
  width: 2,
  // border: '1px solid #E1E1E1',
  background: '#E1E1E1',
};

export interface ISeparator {
  height?: string | number;
  backgroundColor?: string;
}

export const Separator = ({height, backgroundColor}: ISeparator): JSX.Element => {
  return <div style={{...separatorStyles, height: height ?? '5vh', backgroundColor: backgroundColor ?? '#E1E1E1'}} />;
};
