import postal from 'postal';
let subscriptions: { subscription: ISubscriptionDefinition<unknown>, context: any}[] = [];

const eventBus = {
    on(event: any, callback: (arg0: any) => any, context: any = null) {
      let subscription = postal.subscribe({
        channel: 'global',
        topic: event,
        callback: (data: any) => callback(data),
      });
      subscriptions.push({subscription: subscription, context: context});
    },
    dispatch(event: string, data: any) {
      postal.publish({
        channel: 'global',
        topic: event,
        data: data,
      });
    },
    remove(event: any, context: any = null) {
      subscriptions
      .filter((item) => item.subscription.topic === event && item.context === context)
      .forEach((item) => {
        item.subscription.unsubscribe();
      });
      subscriptions = subscriptions.filter((item) => item.subscription.topic !== event);
    },
  };
  
  export default eventBus;