import * as React from 'react';
import { Stack, Text, DetailsListLayoutMode, Selection, SelectionMode, IColumn, ContextualMenu, DirectionalHint, IconButton, IContextualMenuItem, IIconProps, Link, Spinner, ShimmeredDetailsList, IListProps, PrimaryButton, DefaultButton, Separator, ITag, SearchBox, IDetailsListStyles, ConstrainMode, DetailsList, Label, TextField, Announced, ICommandBarStyles, CommandBar, TagPicker, ICommandBarItemProps, IBasePickerSuggestionsProps } from '@fluentui/react';
import shapeEventBus from "../EventBus";
import withRouter from '../withRouter';
import DataService from '../services/DataService';
import moment from 'moment';
import { ShapePanel } from '../page-components/ShapePanel';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { SignInButton } from '../auth/SignInButton';
import { StatusBarDetailsList } from '../page-components/StatusBarDetailsList';
import { defaultTheme } from '../Theme';

// #region Definitions

export interface IShape {
  shapeId: string;
  timestamp: Date;
  title: string;
  ownerEmail: string;
  ownerId: string;
  ownerName: string;
  basedOnId: string;
  basedOnName: string;
  definition: string;
}

export interface IShapesProps {
  selectedShape: IShape | null;
  handleShapeSelected: Function;
  shapes: IShape[];
  navigate: any;
}

export interface IShapesState {
  columns: IColumn[];
  shapes: IShape[];
  allShapes: IShape[];
  selectionDetails: string;
  selectedShape: IShape | null;
  selectedPanelOpen: boolean;
  announcedMessage?: string;
  showContextualMenu: boolean;
  buttonRef: Element | null;
  showWizard: boolean;
  isLoading: boolean;
  searchTerm: string;
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
// #endregion

// #region Styles

const commandBarStyles: Partial<ICommandBarStyles> = {
  root: {
    boxSizing: 'border-box',
    'border-bottom': '1px solid #eee',
    padding: 0,
  },
};

const gridStyles: Partial<IDetailsListStyles> = {
  root: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    width: '100%',
    height: 'calc(100vh - 160px)',
  },
  headerWrapper: {
    flex: '0 0 auto',
  },
  contentWrapper: {
    flex: '1 1 auto',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
};

// #endregion

class Shapes extends React.Component<IShapesProps, IShapesState> {
  private _selection: Selection;
  filterRef = React.createRef();

  // #region Context menu items
  private _menuIcon: IIconProps = { iconName: 'MoreVertical' };
  private _menuItems: IContextualMenuItem[] = [
    {
      key: 'edit', text: 'Edit properties...', onClick: (item: any) => {
        console.log('Edit properties', item, this.state.selectedShape);
        if (this.state.selectedShape != null) {
          this.handleShapeEditProperties(this.state.selectedShape);
        }
      }, iconProps: { iconName: 'Edit' }
    },
    {
      key: 'delete', text: 'Delete', onClick: (event: any) => {
        this.onDeleteShape();
      }, iconProps: { iconName: 'Delete' }
    },
  ];
  // #endregion

  private onSearch = (text: string | undefined): void => {
    console.log('onSearch', text);
    let results: IShape[] = this.state.allShapes
      .filter(
        x => (!text || x.title?.toLowerCase()?.includes(text?.toLowerCase() || ''))
      );

    this.setState({
      searchTerm: text || '',
      shapes: results,
    });

  };

  constructor(props: IShapesProps) {
    super(props);

    this.onShowContextualMenu = this.onShowContextualMenu.bind(this);
    this.onHideContextualMenu = this.onHideContextualMenu.bind(this);
    this._onItemInvoked = this._onItemInvoked.bind(this);
    this.handleShapeEditProperties = this.handleShapeEditProperties.bind(this);

    if (DataService.getAccount() != null) {
      this.loadShapes();
    }

    // #region Column definitions
    const columns: IColumn[] = [
      {
        className: 'shapes-cells',
        key: 'titleColumn',
        name: 'Title',
        fieldName: 'title',
        targetWidthProportion: 4,
        minWidth: 210,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IShape) => (<div><Link onClick={
          (event?: React.MouseEvent<HTMLElement>) => {
            if (item) {
              this.setState({ selectedShape: item, selectedPanelOpen: true });
            }
          }
        }>{item.title}</Link>
        </div>),
      },
      {
        className: 'shapes-cells',
        key: 'contextMenu',
        name: '',
        minWidth: 44,
        maxWidth: 44,
        targetWidthProportion: 0,
        isResizable: false,
        isSorted: false,
        isPadded: true,
        onRender: (item: IShape) => {
          return (
            <IconButton iconProps={this._menuIcon} onClick={(event) => this.onShowContextualMenu(event)} />
          );
        },
      },
      {
        className: 'shapes-cells',
        key: 'dateModifiedColumn',
        name: 'Date Modified',
        fieldName: 'timestamp',
        minWidth: 120,
        targetWidthProportion: 1,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        sortAscendingAriaLabel: 'Sorted Newest to Oldest',
        sortDescendingAriaLabel: 'Sorted Oldest to Newest',
        onColumnClick: this._onColumnClick,
        data: 'number',
        onRender: (item: IShape) => {
          return <span title={moment.utc(item.timestamp).format('LLL')}>{moment.utc(item.timestamp).fromNow()}</span>;
        },
        isPadded: true,
      },
      {
        className: 'shapes-cells',
        key: 'modifiedByColumn',
        name: 'Modified By',
        fieldName: 'ownerName',
        minWidth: 220,
        targetWidthProportion: 2,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: this._onColumnClick,
        onRender: (item: IShape) => {
          return <span>{item.ownerName} ({item.ownerEmail})</span>;
        },
        isPadded: true,
      }
    ];
    // #endregion

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
          selectedShape: this._getFirstSelected()
        });
      },
    });

    this.state = {
      shapes: [],
      allShapes: [],
      columns: columns,
      selectionDetails: this._getSelectionDetails(),
      selectedShape: this._getFirstSelected(),
      selectedPanelOpen: false,
      announcedMessage: undefined,
      showContextualMenu: false,
      buttonRef: null,
      showWizard: false,
      isLoading: true,
      searchTerm: '',
    };

  }

  loadShapes() {
    DataService.getShapes().then((shapes) => {
      const sorted = shapes.sort((a: IShape, b: IShape) => (a.timestamp > b.timestamp ? -1 : 1));
      this.setState({ shapes: sorted, allShapes: shapes.slice(), isLoading: false });
    });
  }

  componentWillUnmount() {
    shapeEventBus.remove("OnShowNewShapeWizard", this);
    shapeEventBus.remove("OnShapeSearch", this);
  }

  // #region Shape Panel

  handleShapeEditProperties = (selectedShape: IShape) => {
    this.setState({ selectedShape: selectedShape, selectedPanelOpen: true })
  };

  handleShapePanelDismissed = () => {
    this.setState({ selectedPanelOpen: false });
  }

  handleLeftNavClick = (isExpanded: boolean) => {
    console.log("handleLeftNavClick", isExpanded);
  }

  handleShapeChanged = (name: string, definition: string) => {
    let newForm = this.state.selectedShape;
    if (newForm) {
      newForm.title = name;
      newForm.definition= definition;
    }
    let shapes = this.state.shapes;
    if (this.state.selectedShape && newForm) {
      shapes.splice(shapes.indexOf(this.state.selectedShape), 1, newForm);
    }
    this.setState({ shapes: shapes, selectedShape: null, selectedPanelOpen: false });
  };

  handleShapeCreated = (shape: any) => {
    // Prepend the new shape to the list
    console.log('handleShapeCreated', shape);
    let shapes = this.state.shapes.slice();
    shapes.unshift(shape);
    this.setState({ shapes: shapes, selectedShape: null, selectedPanelOpen: false });
  };

  // #endregion


  // #region Context menu events

  onHideContextualMenu() {
    this.setState({ buttonRef: null, showContextualMenu: false });
  }

  onShowContextualMenu(event: any) {
    console.log('onShowContextualMenu', event, this.state.selectedShape);
    this.setState({ buttonRef: event.target, showContextualMenu: true });
  }

  // #endregion

  updateItem(item: IShape) {
    let { shapes } = this.state;
    for (let element of shapes) {
      if (element.shapeId === item.shapeId) {
        element.title = `${element.title} [updated at: ${new Date().toLocaleTimeString()}]`;
        break;
      }
    }
    this.setState({
      shapes: [...shapes]
    });
  }

  private _onItemInvoked(item: any): void {
    this.setState({ selectedShape: item });
    this.props.handleShapeSelected(item);
  }

  private _getFirstSelected(): IShape | null {
    const selectionCount = this._selection.getSelectedCount();
    switch (selectionCount) {
      case 0:
        return null;
      default:
        return (this._selection.getSelection()[0] as IShape);
    }
  }

  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();
    switch (selectionCount) {
      case 0:
        return 'No items selected';
      case 1:
        return '1 item selected: ' + (this._selection.getSelection()[0] as IShape).title;
      default:
        return `${selectionCount} items selected`;
    }
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    if (ev.target instanceof HTMLInputElement) {
      return;
    }
    const { columns, shapes } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? 'descending' : 'ascending'
            }`,
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const sorted = _copyAndSort(shapes, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      shapes: sorted,
    });
  };

  private async onDeleteShape() {
    let item = this.state.selectedShape;
    console.log(item?.title);
    if (item?.shapeId) {
      await DataService.deleteShape(item.shapeId);
      this.loadShapes();
      this._selection.setAllSelected(false);
    }
  }

  private async onCreateShape(details: any) {
    console.log('onCreateShape', details);
    this.setState({ selectedPanelOpen: false, isLoading: true });
    await DataService.createShape(details);
  }

  toolbarItems: ICommandBarItemProps[] = [
    {
      key: 'newItem',
      text: 'New',
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Add' },
      buttonStyles: { root: { backgroundColor: defaultTheme.palette.themePrimary, color: 'rgb(253, 255, 254)', minWidth: 80 }, icon: { color: 'rgb(253, 255, 254)' }, label: { paddingLeft: 0 } },
      onClick: () => { this.setState({ selectedShape: {} as IShape, selectedPanelOpen: true }) },
    },
    {
      key: 'search',
      text: 'Search',
      iconProps: { iconName: 'Search' },
      onRender: (item: any) => {
        return <SearchBox
          id='search'
          styles={{ root: { marginTop: '6px', marginBottom: 0, height: '37px', width: '230px' }, field: { width: '230px' } }}
          placeholder="Search"
          underlined={true}
          defaultValue={this.state.searchTerm}
          onChange={(e, value) => this.onSearch(value)}
        />
      }
    }
  ];


  public render() {

    const { columns, shapes } = this.state;
    const shimmeredDetailsListProps: IListProps = {
      renderedWindowsAhead: 0,
      renderedWindowsBehind: 0,
    };

    return (
      <React.Fragment>
        <AuthenticatedTemplate>
          <Stack style={{ width: "calc(100vw - 220px)", height: "calc(100vh - 74px)" }}>
            <CommandBar
              items={this.toolbarItems}
              styles={commandBarStyles}
            />

            {this.state.isLoading && (
              <Stack horizontalAlign='center' verticalAlign='center' style={{ backgroundColor: 'rgba(240,240,240,0.5)', position: 'fixed', left: 220, top: 74, width: 'calc(100vw - 220px)', height: 'calc(100vh - 74px)', zIndex: 2000 }}>
                <Spinner label="Loading shapes" ariaLive="assertive" labelPosition="bottom" />
              </Stack>
            )}
            <DetailsList
              styles={gridStyles}
              compact={true}
              items={shapes}
              columns={columns}
              selectionMode={SelectionMode.single}
              getKey={(item: any, index?: number) => { return item?.key; }}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onItemInvoked={this._onItemInvoked}
              selection={this._selection}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              listProps={shimmeredDetailsListProps}
            />
            <ContextualMenu
              items={this._menuItems}
              hidden={!this.state.showContextualMenu}
              target={this.state.buttonRef}
              onItemClick={this.onHideContextualMenu}
              onDismiss={this.onHideContextualMenu}
              isBeakVisible={false}
              directionalHint={DirectionalHint.bottomLeftEdge}
            />
            <StatusBarDetailsList statusMessage={`Showing ${this.state.shapes.length} shape${this.state.shapes.length !== 1 ? 's' : ''}`}></StatusBarDetailsList>
          </Stack>

        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Stack tokens={{ childrenGap: 20 }} styles={{ root: { margin: 20, outerHeight: '100%' } }}>
            <Text variant="xLarge">
              You'll need to have an account with us to create and edit shapes
            </Text>
            <Stack tokens={{ childrenGap: 8 }} className="home-panel">

              <ul style={{ lineHeight: '2.5em', fontSize: '1.1em', marginBottom: '10px' }}>
                <li>Whilst we're in alpha, cloud/studio is <strong>free</strong></li>
                <li>We'll be introducing a paid tier soon</li>
                <li>Sign up now to get going</li>
              </ul>

              <Separator />

              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <SignInButton></SignInButton>
              </Stack>

            </Stack>
          </Stack>
        </UnauthenticatedTemplate>

        <ShapePanel
          isOpen={this.state.selectedPanelOpen}
          shape={this.state.selectedShape}
          handleShapeCreated={this.handleShapeCreated}
          handleShapeChanged={this.handleShapeChanged}
          handleShapePanelDismissed={this.handleShapePanelDismissed}
        />

        {/* <ShapeCreate
          visible={this.state.showWizard}
          onDismiss={() => this.setState({ showWizard: false })}
          onCreateShape={(details: any) => this.onCreateShape(details)}
        /> */}

      </React.Fragment>
    );
  }
}

export default withRouter(Shapes);