import { Stack, Text } from '@fluentui/react';
  import React, { useEffect, useState } from 'react';

  export const About = (props: any) => {

    return (
        <Stack styles={{root: { margin: 20, outerHeight: '100%' }}} style={{ width: "calc(100vw - 260px)" }}>
        <Text variant="xLarge">
          About us
        </Text>
        <Stack tokens={{ childrenGap: 8 }} className="home-panel">
            
        </Stack>
      </Stack>
    );

  }