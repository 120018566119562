import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { mergeStyles } from '@fluentui/react';
import reportWebVitals from './reportWebVitals';

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh'
  },
});

// Find the root element
const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

// Render the app within the BrowserRouter
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// Hot Module Replacement (HMR) - Check if HMR is enabled
if (module['hot']) {
  module['hot'].accept('./App', () => {
    // If the App module or its dependencies change, re-import the App module and re-render
    const NextApp = require('./App').default;
    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <NextApp />
        </BrowserRouter>
      </React.StrictMode>
    );
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
