import * as React from 'react';
import { Text, Stack, ITooltipHostStyles, IBasePickerSuggestionsProps, Separator } from '@fluentui/react';
import withRouter from '../withRouter';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { SignInButton } from '../auth/SignInButton';


class Styles extends React.Component<any, any> {

    public render() {

        return (
            <React.Fragment>
                <AuthenticatedTemplate>
                    <Stack tokens={{ childrenGap: 20 }} styles={{ root: { margin: 20, outerHeight: '100%' } }}>
                    <Text variant="xLarge">
                        Not done yet, sorry.
                    </Text>
                    </Stack>
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate>

                    <Stack tokens={{ childrenGap: 20 }} styles={{ root: { margin: 20, outerHeight: '100%' } }}>
                        <Text variant="xLarge">
                            You'll need to have an account with us to view and use styles
                        </Text>
                        <Stack tokens={{ childrenGap: 8 }} className="home-panel">

                            <ul style={{ lineHeight: '2.5em', fontSize: '1.1em', marginBottom: '10px' }}>
                                <li>Whilst we're in alpha, cloud/studio is <strong>free</strong></li>
                                <li>We'll be introducing a paid tier soon</li>
                                <li>Sign up now to get going</li>
                            </ul>

                            <Separator />

                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                                <SignInButton></SignInButton>
                            </Stack>

                        </Stack>
                    </Stack>

                </UnauthenticatedTemplate>

            </React.Fragment>
        );
    }
}

export default withRouter(Styles);