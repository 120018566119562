import * as React from 'react';
import { Stack, IStackStyles, Label, ILabelStyles } from '@fluentui/react';

const containerStyles: Partial<IStackStyles> = {
    root: {
        height: 40,
        borderTop: '1px solid rgb(238, 238, 238)'
    }
};

const labelStyles: Partial<ILabelStyles> = {
    root: {
        height: 40,
        padding: 12,
        fontWeight: 400,
    }
};

export class StatusBarDetailsList extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      statusMessage: props.statusMessage,
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.statusMessage !== this.props.statusMessage) {
      this.setState({ statusMessage: this.props.statusMessage });
    }
  }
  
  public render() {
    return (
      <Stack horizontal verticalFill={true} styles={containerStyles}>
        <Stack.Item align="center" grow={1}>
          <Label styles={labelStyles}>
            {this.state.statusMessage}
          </Label>
        </Stack.Item>
      </Stack>
    )
  }
};
