import * as React from 'react';
import {Step} from './vertical-stepper/components/Step';
import {Separator} from './vertical-stepper/components/Separator';

export interface IStepDescription {
  stepContent: () => JSX.Element;
  stepStateColor?: string;
  stepStatusCircleSize?: number;
  onClickHandler?: () => void | undefined;
}

export interface IStepperNavProps {
  steps: IStepDescription[];
  currentStep: IStepDescription;
}

export const StepperNav = (props: IStepperNavProps): JSX.Element => {
  return (
    <nav>
      {props.steps.map(
        ( {stepContent, stepStateColor, onClickHandler, stepStatusCircleSize}, index ) => (
          <div key={index}>
            <Step
              stepContent={stepContent}
              statusColor={index <= props.steps.indexOf(props.currentStep) ? props.steps[index].stepStateColor : undefined}
              onClickHandler={onClickHandler}
              statusCircleSize={stepStatusCircleSize}
            />
            {index !== props.steps.length - 1 && (
              <div
                style={{
                  paddingLeft: `${(stepStatusCircleSize ?? 16) / 2 + 3}px`,
                  paddingBottom: 3,
                }}>
                <Separator height={44} backgroundColor={index < props.steps.indexOf(props.currentStep) ? props.steps[index].stepStateColor : undefined} />
              </div>
            )}
          </div>
        ),
      )}
    </nav>
  );
};
