export const getContainerStyleBasedOnResolution = () => {
  const screenWidth = window.screen.width;
  if (screenWidth < 1600) {
    return {
      panelWidth: "85%",
      navWidth: "240px",
      contentWidth: "60vw",
    };
  } else {
    return {
      panelWidth: "62%",
      navWidth: "240px",
      contentWidth: "70vw",
    };
  }
};
