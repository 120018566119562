import { assign } from 'min-dash';

export class ShapeStyles {

    constructor() {
        this.styles = {
            'default': {
                stroke: 'rgba(50, 50, 50, 0.9)',
                strokeStyle: 'solid',
                strokeWidth: 2,
                strokeDasharray: '',
                borders: 'left-right-top-bottom',
                fill: 'rgba(235, 235, 235, 0.5)',
                color: '#000000',
                opacity: 1,
                fontFamily: 'Segoe UI',
                fontSize: 14,
                fontWeight: '400',
                fontStyle: '',
                textAlign: 'center-middle',
                textPadding: 10,
                radius: 10,
            },
            'frame': {
                stroke: 'rgba(224, 224, 224, 0.9)',
                strokeWidth: 2,
                strokeDasharray: '',
                fill: 'rgba(238, 238, 238, 0.2)',
                color: '#000000',
                opacity: 1,
                fontFamily: 'Segoe UI',
                fontSize: 14,
                fontWeight: '400',
                fontStyle: '',
                textAlign: 'left-top',
                textPadding: 5,
                radius: 0,
            },
            'frame/ImageLabel': {
                stroke: 'rgba(224, 224, 224, 0.9)',
                fill: 'rgba(238, 238, 238, 0.2)',
                textAlign: 'center-middle',
            },
            'frame/Image': {
                stroke: 'rgba(224, 224, 224, 0)',
                fill: 'rgba(238, 238, 238, 0)',
            },
            'shape': {
                stroke: 'rgba(224, 224, 224, 0.9)',
                strokeWidth: 2,
                strokeDasharray: '',
                fill: 'rgba(238, 238, 238, 0.2)',
                color: '#000000',
                opacity: 1,
                fontFamily: 'Segoe UI',
                fontSize: 14,
                fontWeight: '400',
                fontStyle: '',
                textAlign: 'center-middle',
                textPadding: 7,
                radius: 0,
            },
            'shape/Circle': {
            },
            'shape/Rounded': {
                radius: 10,
            },
            'shape/Triangle': {
                radius: 0,
            },
            'connection': {
                fill: 'transparent',
                stroke: '#ccc',
                strokeWidth: 2,
                strokeDasharray: '',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                opacity: 1,
                color: '#000000',
                fontFamily: 'Segoe UI',
                fontSize: 14,
                fontWeight: '400',
                fontStyle: '',
                textAlign: 'center-middle',
                textPadding: 7,
            },
            'connection/Solid': {
                strokeDasharray: '',
            },
            'connection/Dotted': {
                strokeDasharray: '1,4',
            },
            'connection/Dashed': {
                strokeDasharray: '5,5',
            },
            'label': {
                textAlign: 'center-middle',
                textPadding: 7,
                textBaseline: 'middle',
                fill: 'transparent',
                stroke: 'transparent',
                strokeWidth: 2,
                strokeDasharray: '',
                opacity: 1,
                color: '#000000',
                fontFamily: 'Segoe UI',
                fontSize: 14,
                fontWeight: '400',
                fontStyle: '',
                radius: 0,
            },
            'label/Bordered': {
                stroke: 'rgba(224, 224, 224, 0.9)',
            },
            'label/Shaded': {
                stroke: 'rgba(224, 224, 224, 0.9)',
                fill: 'rgba(238, 238, 238, 0.2)',
            },
            'label/Inverse': {
                stroke: 'black',
                fill: 'black',
                color: '#ffffff',
            },
            'label/Highlighted': {
                stroke: 'rgba(224, 224, 224, 0.9)',
                fill: 'yellow',
            },
        };
        this.defaultStyles = JSON.parse(JSON.stringify(this.styles));
    }

    resetStyles = function () {
        this.styles = JSON.parse(JSON.stringify(this.defaultStyles));
        return this.styles;
    }

    getStyles = function () {
        return this.styles;
    }

    setStyles = function (diagramStyles) {
        this.styles = assign(this.styles, diagramStyles);
    }

    getStyle = function (shape) {
        let shapeType = shape.shapeType || 'shape';
        if (shape.isFrame === true) {
            shapeType = 'frame';
        }
        const shapeStyle = `/${shape.shapeStyle}` || '';
        const result = assign({}, this.styles[`${shapeType}`], this.styles[`${shapeType}${shapeStyle}`], shape);
        return result;
    }

    getStyleDefault = function (shape) {
        let shapeType = shape.shapeType || 'shape';
        if (shape.isFrame === true) {
            shapeType = 'frame';
        }
        const shapeStyle = `/${shape.shapeStyle}` || '';
        const result = assign({}, this.styles[`${shapeType}`], this.styles[`${shapeType}${shapeStyle}`])
        return result;
    }
}