var typeToIcon = [
    { type: "Microsoft.AlertsManagement/actionRules", icon: "00002-icon-service-Alerts"},
    { type: "Microsoft.SecurityInsights/alertRules", icon: "00002-icon-service-Alerts"},
    { type: "Microsoft.Blueprint/blueprints", icon: "00006-icon-service-Blueprints"},
    { type: "Microsoft.Blueprint/blueprints/artifacts", icon: "00006-icon-service-Blueprints"},
    { type: "Microsoft.Network/frontdoors/providers/diagnosticsettings", icon: "00008-icon-service-Diagnostics-Settings"},
    { type: "Microsoft.DataProtection/backupVaults", icon: "00017-icon-service-Recovery-Services-Vaults"},
    { type: "Microsoft.RecoveryServices/vaults", icon: "00017-icon-service-Recovery-Services-Vaults"},
    { type: "Microsoft.Solutions/applicationDefinitions", icon: "00021-icon-service-Solutions"},
    { type: "Microsoft.Solutions/applications", icon: "00021-icon-service-Solutions"},
    { type: "Microsoft.Automation/automationAccounts", icon: "00022-icon-service-Automation-Accounts"},
    { type: "Microsoft.OperationalInsights/workspaces", icon: "00024-icon-service-Operation-Log-(Classic)"},
    { type: "Microsoft.OperationsManagement/solutions", icon: "00024-icon-service-Operation-Log-(Classic)"},
    { type: "Microsoft.CustomProviders/resourceProviders", icon: "00025-icon-service-Service-Providers"},
    { type: "Microsoft.CustomProviders/resourceProviders/customResources", icon: "00025-icon-service-Service-Providers"},
    { type: "Microsoft.EventHub/namespaces", icon: "00039-icon-service-Event-Hubs"},
    { type: "Microsoft.EventHub/Namespaces", icon: "00039-icon-service-Event-Hubs"},
    { type: "Microsoft.EventHub/namespaces/AuthorizationRules", icon: "00039-icon-service-Event-Hubs"},
    { type: "Microsoft.EventHub/namespaces/eventhubs", icon: "00039-icon-service-Event-Hubs"},
    { type: "Microsoft.EventHub/namespaces/eventhubs/authorizationRules", icon: "00039-icon-service-Event-Hubs"},
    { type: "Microsoft.EventHub/namespaces/eventhubs/consumergroups", icon: "00039-icon-service-Event-Hubs"},
    { type: "Microsoft.EventHub/namespaces/IPFilterRules", icon: "00039-icon-service-Event-Hubs"},
    { type: "Microsoft.StreamAnalytics/streamingjobs", icon: "00042-icon-service-Stream-Analytics-Jobs"},
    { type: "Microsoft.CertificateRegistration/certificateOrders", icon: "00049-icon-service-App-Service-Certificates"},
    { type: "Microsoft.CertificateRegistration/certificateOrders/certificates", icon: "00049-icon-service-App-Service-Certificates"},
    { type: "Microsoft.Web/certificates", icon: "00049-icon-service-App-Service-Certificates"},
    { type: "Microsoft.DomainRegistration/domains/domainOwnershipIdentifiers", icon: "00050-icon-service-App-Service-Domains"},
    { type: "Microsoft.Cdn/CdnWebApplicationFirewallPolicies", icon: "00056-icon-service-CDN-Profiles"},
    { type: "Microsoft.Cdn/profiles", icon: "00056-icon-service-CDN-Profiles"},
    { type: "Microsoft.HealthcareApis/workspaces", icon: "00400-icon-service-Workspaces"},
    { type: "Microsoft.HealthcareApis/workspaces/fhirservices", icon: "00400-icon-service-Workspaces"},
    { type: "Microsoft.HealthcareApis/workspaces/iotconnectors", icon: "00400-icon-service-Workspaces"},
    { type: "Microsoft.HealthcareApis/workspaces/iotconnectors/fhirdestinations", icon: "00400-icon-service-Workspaces"},
    { type: "Microsoft.Network/privateEndpoints", icon: "00427-icon-service-Private-Link"},
    { type: "Microsoft.Synapse/workspaces", icon: "00606-icon-service-Azure-Synapse-Analytics"},
    { type: "Microsoft.Network/ipGroups", icon: "00701-icon-service-IP-Groups"},
    { type: "Microsoft.Sql/servers/keys", icon: "00787-icon-service-Keys"},
    { type: "Microsoft.Network/privateLinkServices", icon: "01105-icon-service-Private-Link-Service"},
    { type: "Microsoft.Sql/servers", icon: "02390-icon-service-Azure-SQL"},
    { type: "Microsoft.Network/bastionHosts", icon: "02422-icon-service-Bastions"},
    { type: "Microsoft.DeviceUpdate/accounts", icon: "02475-icon-service-Device-Update-IoT-Hub"},
    { type: "Microsoft.DeviceUpdate/accounts/instances", icon: "02475-icon-service-Device-Update-IoT-Hub"},
    { type: "Microsoft.Purview/accounts", icon: "02517-icon-service-Azure-Purview-Accounts"},
    { type: "Microsoft.StoragePool/diskPools", icon: "02560-icon-service-Disk-Pool"},
    { type: "Microsoft.VirtualMachineImages/imageTemplates", icon: "02634-icon-service-Image-Templates"},
    { type: "Microsoft.ConfidentialLedger/ledgers", icon: "02668-icon-service-Confidential-Ledger"},
    { type: "Microsoft.MobileNetwork/mobileNetworks", icon: "02794-icon-service-Private-Mobile-Network"},
    { type: "Microsoft.MobileNetwork/mobileNetworks/dataNetworks", icon: "02794-icon-service-Private-Mobile-Network"},
    { type: "Microsoft.MobileNetwork/mobileNetworks/services", icon: "02794-icon-service-Private-Mobile-Network"},
    { type: "Microsoft.MobileNetwork/mobileNetworks/simPolicies", icon: "02794-icon-service-Private-Mobile-Network"},
    { type: "Microsoft.MobileNetwork/mobileNetworks/sites", icon: "02794-icon-service-Private-Mobile-Network"},
    { type: "Microsoft.MobileNetwork/mobileNetworks/slices", icon: "02794-icon-service-Private-Mobile-Network"},
    { type: "Microsoft.MobileNetwork/packetCoreControlPlanes", icon: "02794-icon-service-Private-Mobile-Network"},
    { type: "Microsoft.MobileNetwork/packetCoreControlPlanes/packetCoreDataPlanes", icon: "02794-icon-service-Private-Mobile-Network"},
    { type: "Microsoft.MobileNetwork/packetCoreControlPlanes/packetCoreDataPlanes/attachedDataNetworks", icon: "02794-icon-service-Private-Mobile-Network"},
    { type: "Microsoft.Compute/galleries", icon: "02864-icon-service-Azure-Compute-Galleries"},
    { type: "Microsoft.Network/dnsResolvers", icon: "02882-icon-service-DNS-Private-Resolver"},
    { type: "Microsoft.App/containerApps", icon: "02884-icon-service-Worker-Container-App"},
    { type: "Microsoft.App/managedEnvironments", icon: "02989-icon-service-Container-App-Environments"},
    { type: "Microsoft.HealthcareApis/services", icon: "10004-icon-service-Service-Health"},
    { type: "Microsoft.Portal/dashboards", icon: "10015-icon-service-Dashboard"},
    { type: "Microsoft.Compute/virtualMachines", icon: "10021-icon-service-Virtual-Machine"},
    { type: "Microsoft.Compute/virtualMachines/extensions", icon: "10021-icon-service-Virtual-Machine"},
    { type: "Microsoft.DevTestLab/labs/virtualmachines", icon: "10021-icon-service-Virtual-Machine"},
    { type: "Microsoft.CognitiveServices/accounts", icon: "10025-icon-service-Availability-Sets"},
    { type: "Microsoft.Compute/availabilitySets", icon: "10025-icon-service-Availability-Sets"},
    { type: "Microsoft.SqlVirtualMachine/SqlVirtualMachineGroups/availabilityGroupListeners", icon: "10025-icon-service-Availability-Sets"},
    { type: "Microsoft.Batch/batchAccounts", icon: "10031-icon-service-Batch-Accounts"},
    { type: "Microsoft.Batch/batchAccounts/pools", icon: "10031-icon-service-Batch-Accounts"},
    { type: "Microsoft.Compute/disks", icon: "10032-icon-service-Disks"},
    { type: "Microsoft.Compute/images", icon: "10033-icon-service-Images"},
    { type: "Microsoft.Compute/virtualMachineScaleSets", icon: "10034-icon-service-VM-Scale-Sets"},
    { type: "Microsoft.Compute/virtualMachineScaleSets/extensions", icon: "10034-icon-service-VM-Scale-Sets"},
    { type: "Microsoft.Web/sites", icon: "10035-icon-service-App-Services"},
    { type: "Microsoft.Web/Sites", icon: "10035-icon-service-App-Services"},
    { type: "Microsoft.ServiceFabric/clusters", icon: "10036-icon-service-Service-Fabric-Clusters"},
    { type: "Microsoft.Compute/galleries/images", icon: "10039-icon-service-Shared-Image-Galleries"},
    { type: "Microsoft.Compute/galleries/images/versions", icon: "10039-icon-service-Shared-Image-Galleries"},
    { type: "Microsoft.ApiManagement/service", icon: "10042-icon-service-API-Management-Services"},
    { type: "Microsoft.Search/searchServices", icon: "10044-icon-service-Search-Services"},
    { type: "Microsoft.NotificationHubs/namespaces", icon: "10045-icon-service-Notification-Hubs"},
    { type: "Microsoft.NotificationHubs/namespaces/notificationHubs", icon: "10045-icon-service-Notification-Hubs"},
    { type: "Microsoft.Web/hostingEnvironments", icon: "10047-icon-service-App-Service-Environments"},
    { type: "Microsoft.Web/connections", icon: "10048-icon-service-API-Connections"},
    { type: "MICROSOFT.WEB/CONNECTIONS", icon: "10048-icon-service-API-Connections"},
    { type: "Microsoft.SignalRService/signalR", icon: "10052-icon-service-SignalR"},
    { type: "Microsoft.SignalRService/webPubSub", icon: "10052-icon-service-SignalR"},
    { type: "Microsoft.DBforMariaDB/servers/virtualNetworkRules", icon: "10061-icon-service-Virtual-Networks"},
    { type: "Microsoft.DBforMySQL/servers/firewallRules", icon: "10061-icon-service-Virtual-Networks"},
    { type: "Microsoft.DBforPostgreSQL/servers/virtualNetworkRules", icon: "10061-icon-service-Virtual-Networks"},
    { type: "Microsoft.DevTestLab/labs/virtualnetworks", icon: "10061-icon-service-Virtual-Networks"},
    { type: "Microsoft.Network/virtualNetworks", icon: "10061-icon-service-Virtual-Networks"},
    { type: "Microsoft.Network/VirtualNetworks", icon: "10061-icon-service-Virtual-Networks"},
    { type: "Microsoft.Network/loadBalancers", icon: "10062-icon-service-Load-Balancers"},
    { type: "Microsoft.Network/virtualNetworkGateways", icon: "10063-icon-service-Virtual-Network-Gateways"},
    { type: "Microsoft.Network/dnsForwardingRulesets", icon: "10064-icon-service-DNS-Zones"},
    { type: "Microsoft.Network/dnszones", icon: "10064-icon-service-DNS-Zones"},
    { type: "Microsoft.Network/dnsZones", icon: "10064-icon-service-DNS-Zones"},
    { type: "Microsoft.Network/dnsZones/A", icon: "10064-icon-service-DNS-Zones"},
    { type: "Microsoft.Network/dnsZones/CNAME", icon: "10064-icon-service-DNS-Zones"},
    { type: "Microsoft.Network/dnsZones/TXT", icon: "10064-icon-service-DNS-Zones"},
    { type: "Microsoft.Network/privateDnsZones", icon: "10064-icon-service-DNS-Zones"},
    { type: "Microsoft.Network/networkProfiles", icon: "10065-icon-service-Traffic-Manager-Profiles"},
    { type: "Microsoft.Network/trafficmanagerprofiles", icon: "10065-icon-service-Traffic-Manager-Profiles"},
    { type: "Microsoft.Network/trafficManagerProfiles", icon: "10065-icon-service-Traffic-Manager-Profiles"},
    { type: "Microsoft.Network/networkWatchers", icon: "10066-icon-service-Network-Watcher"},
    { type: "Microsoft.Network/networkSecurityGroups", icon: "10067-icon-service-Network-Security-Groups"},
    { type: "Microsoft.Network/publicIpAddresses", icon: "10069-icon-service-Public-IP-Addresses"},
    { type: "Microsoft.Network/publicIPAddresses", icon: "10069-icon-service-Public-IP-Addresses"},
    { type: "Microsoft.Network/ddosProtectionPlans", icon: "10072-icon-service-DDoS-Protection-Plans"},
    { type: "Microsoft.Network/frontdoors", icon: "10073-icon-service-Front-Doors"},
    { type: "Microsoft.Network/frontDoors", icon: "10073-icon-service-Front-Doors"},
    { type: "Microsoft.Network/applicationGateways", icon: "10076-icon-service-Application-Gateways"},
    { type: "Microsoft.Network/ApplicationGatewayWebApplicationFirewallPolicies", icon: "10076-icon-service-Application-Gateways"},
    { type: "Microsoft.Network/localNetworkGateways", icon: "10077-icon-service-Local-Network-Gateways"},
    { type: "Microsoft.Network/vpnGateways", icon: "10077-icon-service-Local-Network-Gateways"},
    { type: "Microsoft.Network/expressRouteCircuits", icon: "10079-icon-service-ExpressRoute-Circuits"},
    { type: "Microsoft.Network/networkInterfaces", icon: "10080-icon-service-Network-Interfaces"},
    { type: "Microsoft.Network/connections", icon: "10081-icon-service-Connections"},
    { type: "Microsoft.Network/routeTables", icon: "10082-icon-service-Route-Tables"},
    { type: "Microsoft.DBforMariaDB/servers/firewallRules", icon: "10084-icon-service-Firewalls"},
    { type: "Microsoft.DBforMySQL/servers/databases", icon: "10084-icon-service-Firewalls"},
    { type: "Microsoft.DBforPostgreSQL/servers/firewallRules", icon: "10084-icon-service-Firewalls"},
    { type: "Microsoft.Network/azureFirewalls", icon: "10084-icon-service-Firewalls"},
    { type: "Microsoft.Sql/servers/firewallRules", icon: "10084-icon-service-Firewalls"},
    { type: "Microsoft.Storage/storageAccounts", icon: "10086-icon-service-Storage-Accounts"},
    { type: "Microsoft.Storage/StorageAccounts", icon: "10086-icon-service-Storage-Accounts"},
    { type: "Microsoft.Storage/storageAccounts/blobServices", icon: "10086-icon-service-Storage-Accounts"},
    { type: "Microsoft.Storage/storageAccounts/blobServices/containers", icon: "10086-icon-service-Storage-Accounts"},
    { type: "Microsoft.Storage/storageAccounts/fileServices", icon: "10086-icon-service-Storage-Accounts"},
    { type: "Microsoft.Storage/storageAccounts/fileServices/shares", icon: "10086-icon-service-Storage-Accounts"},
    { type: "Microsoft.Storage/storageAccounts/localUsers", icon: "10086-icon-service-Storage-Accounts"},
    { type: "Microsoft.NetApp/netAppAccounts", icon: "10096-icon-service-Azure-NetApp-Files"},
    { type: "Microsoft.NetApp/netAppAccounts/capacityPools", icon: "10096-icon-service-Azure-NetApp-Files"},
    { type: "Microsoft.NetApp/netAppAccounts/capacityPools/volumes", icon: "10096-icon-service-Azure-NetApp-Files"},
    { type: "Microsoft.NetApp/netAppAccounts/volumeGroups", icon: "10096-icon-service-Azure-NetApp-Files"},
    { type: "Microsoft.DataShare/accounts", icon: "10098-icon-service-Data-Shares"},
    { type: "Microsoft.ContainerInstance/containerGroups", icon: "10105-icon-service-Container-Registries"},
    { type: "Microsoft.ContainerRegistry/registries", icon: "10105-icon-service-Container-Registries"},
    { type: "Microsoft.ContainerRegistry/registries/replications", icon: "10105-icon-service-Container-Registries"},
    { type: "Microsoft.DBforMySQL/flexibleServers", icon: "10122-icon-service-Azure-Database-MySQL-Server"},
    { type: "Microsoft.DBforMySQL/servers", icon: "10122-icon-service-Azure-Database-MySQL-Server"},
    { type: "Microsoft.DBforMySQL/servers/virtualNetworkRules", icon: "10122-icon-service-Azure-Database-MySQL-Server"},
    { type: "Microsoft.DBforMariaDB/servers", icon: "10123-icon-service-Azure-Database-MariaDB-Server"},
    { type: "Microsoft.SqlVirtualMachine/SqlVirtualMachineGroups", icon: "10124-icon-service-Azure-SQL-VM"},
    { type: "Microsoft.SqlVirtualMachine/sqlVirtualMachines", icon: "10124-icon-service-Azure-SQL-VM"},
    { type: "Microsoft.SqlVirtualMachine/SqlVirtualMachines", icon: "10124-icon-service-Azure-SQL-VM"},
    { type: "Microsoft.DataFactory/datafactories", icon: "10126-icon-service-Data-Factory"},
    { type: "Microsoft.DataFactory/factories", icon: "10126-icon-service-Data-Factory"},
    { type: "Microsoft.ContainerService/managedClusters", icon: "10127-icon-service-Virtual-Clusters"},
    { type: "Microsoft.ContainerService/managedClusters/agentPools", icon: "10127-icon-service-Virtual-Clusters"},
    { type: "Microsoft.Sql/servers/databases", icon: "10130-icon-service-SQL-Database"},
    { type: "Microsoft.DBforPostgreSQL/servers", icon: "10131-icon-service-Azure-Database-PostgreSQL-Server"},
    { type: "Microsoft.DataMigration/services", icon: "10133-icon-service-Azure-Database-Migration-Services"},
    { type: "Microsoft.Sql/managedInstances", icon: "10136-icon-service-SQL-Managed-Instance"},
    { type: "Microsoft.Cache/redis", icon: "10137-icon-service-Cache-Redis"},
    { type: "Microsoft.Cache/Redis", icon: "10137-icon-service-Cache-Redis"},
    { type: "Microsoft.Cache/Redis/linkedServers", icon: "10137-icon-service-Cache-Redis"},
    { type: "Microsoft.HDInsight/clusters", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "Microsoft.HDInsight/clusters/applications", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "microsoft.insights/actionGroups", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "Microsoft.Insights/actionGroups", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "microsoft.insights/activityLogAlerts", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "Microsoft.Insights/activityLogAlerts", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "microsoft.insights/alertrules", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "microsoft.insights/autoscalesettings", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "Microsoft.Insights/autoscalesettings", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "Microsoft.Insights/autoscaleSettings", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "microsoft.insights/components", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "Microsoft.Insights/components", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "Microsoft.Insights/dataCollectionRules", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "Microsoft.Insights/diagnosticSettings", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "microsoft.insights/metricAlerts", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "Microsoft.Insights/metricAlerts", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "Microsoft.Insights/webtests", icon: "10142-icon-service-HD-Insight-Clusters"},
    { type: "Microsoft.DataLakeAnalytics/accounts", icon: "10143-icon-service-Data-Lake-Analytics"},
    { type: "Microsoft.AnalysisServices/servers", icon: "10148-icon-service-Analysis-Services"},
    { type: "Microsoft.EventHub/clusters", icon: "10149-icon-service-Event-Hub-Clusters"},
    { type: "Microsoft.DataLakeStore/accounts", icon: "10150-icon-service-Data-Lake-Store-Gen1"},
    { type: "Microsoft.MachineLearningServices/workspaces", icon: "10167-icon-service-Machine-Learning-Studio-Workspaces"},
    { type: "Microsoft.MachineLearningServices/workspaces/computes", icon: "10167-icon-service-Machine-Learning-Studio-Workspaces"},
    { type: "Microsoft.MachineLearningServices/workspaces/data", icon: "10167-icon-service-Machine-Learning-Studio-Workspaces"},
    { type: "Microsoft.MachineLearningServices/workspaces/data/versions", icon: "10167-icon-service-Machine-Learning-Studio-Workspaces"},
    { type: "Microsoft.MachineLearningServices/workspaces/datasets", icon: "10167-icon-service-Machine-Learning-Studio-Workspaces"},
    { type: "Microsoft.MachineLearningServices/workspaces/datastores", icon: "10167-icon-service-Machine-Learning-Studio-Workspaces"},
    { type: "Microsoft.MachineLearningServices/workspaces/jobs", icon: "10167-icon-service-Machine-Learning-Studio-Workspaces"},
    { type: "Microsoft.MachineLearningServices/workspaces/linkedServices", icon: "10167-icon-service-Machine-Learning-Studio-Workspaces"},
    { type: "Microsoft.MachineLearningServices/workspaces/services", icon: "10167-icon-service-Machine-Learning-Studio-Workspaces"},
    { type: "Microsoft.TimeSeriesInsights/environments", icon: "10181-icon-service-Time-Series-Insights-Environments"},
    { type: "Microsoft.Devices/IotHubs", icon: "10182-icon-service-IoT-Hub"},
    { type: "Microsoft.Devices/iotHubs/eventhubEndpoints/ConsumerGroups", icon: "10182-icon-service-IoT-Hub"},
    { type: "Microsoft.Devices/IotHubs/eventHubEndpoints/ConsumerGroups", icon: "10182-icon-service-IoT-Hub"},
    { type: "Microsoft.Maps/accounts", icon: "10185-icon-service-Azure-Maps-Accounts"},
    { type: "Microsoft.Maps/accounts/creators", icon: "10185-icon-service-Azure-Maps-Accounts"},
    { type: "Microsoft.Maps/accounts/providers/roleAssignments", icon: "10185-icon-service-Azure-Maps-Accounts"},
    { type: "Microsoft.Logic/integrationAccounts", icon: "10201-icon-service-Logic-Apps"},
    { type: "Microsoft.Logic/integrationAccounts/agreements", icon: "10201-icon-service-Logic-Apps"},
    { type: "Microsoft.Logic/integrationAccounts/maps", icon: "10201-icon-service-Logic-Apps"},
    { type: "Microsoft.Logic/integrationAccounts/partners", icon: "10201-icon-service-Logic-Apps"},
    { type: "Microsoft.Logic/integrationAccounts/schemas", icon: "10201-icon-service-Logic-Apps"},
    { type: "Microsoft.Logic/integrationServiceEnvironments", icon: "10201-icon-service-Logic-Apps"},
    { type: "Microsoft.Logic/integrationServiceEnvironments/ManagedApis", icon: "10201-icon-service-Logic-Apps"},
    { type: "Microsoft.EventGrid/systemTopics", icon: "10206-icon-service-Event-Grid-Topics"},
    { type: "Microsoft.EventGrid/systemTopics/eventSubscriptions", icon: "10206-icon-service-Event-Grid-Topics"},
    { type: "Microsoft.EventGrid/topics", icon: "10206-icon-service-Event-Grid-Topics"},
    { type: "Microsoft.Relay/namespaces", icon: "10209-icon-service-Relays"},
    { type: "Microsoft.Relay/Namespaces", icon: "10209-icon-service-Relays"},
    { type: "Microsoft.Blockchain/blockchainMembers", icon: "10210-icon-service-Blockchain-Applications"},
    { type: "Microsoft.AppConfiguration/configurationStores", icon: "10219-icon-service-App-Configuration"},
    { type: "Microsoft.AppConfiguration/configurationStores/keyValues", icon: "10219-icon-service-App-Configuration"},
    { type: "Microsoft.EventGrid/eventSubscriptions", icon: "10221-icon-service-Event-Grid-Subscriptions"},
    { type: "Microsoft.AAD/domainServices", icon: "10222-icon-service-Azure-AD-Domain-Services"},
    { type: "Microsoft.ManagedIdentity/userAssignedIdentities", icon: "10227-icon-service-Managed-Identities"},
    { type: "Microsoft.Security/advancedThreatProtectionSettings", icon: "10241-icon-service-Security-Center"},
    { type: "Microsoft.Security/automations", icon: "10241-icon-service-Security-Center"},
    { type: "Microsoft.KeyVault/managedHSMs", icon: "10245-icon-service-Key-Vaults"},
    { type: "Microsoft.KeyVault/vaults", icon: "10245-icon-service-Key-Vaults"},
    { type: "Microsoft.KeyVault/vaults/keys", icon: "10245-icon-service-Key-Vaults"},
    { type: "Microsoft.KeyVault/vaults/secrets", icon: "10245-icon-service-Key-Vaults"},
    { type: "Microsoft.DevTestLab/labs", icon: "10264-icon-service-DevTest-Labs"},
    { type: "Microsoft.LabServices/labPlans", icon: "10265-icon-service-Lab-Services"},
    { type: "Microsoft.LabServices/labs", icon: "10265-icon-service-Lab-Services"},
    { type: "Microsoft.Migrate/MigrateProjects", icon: "10281-icon-service-Azure-Migrate"},
    { type: "Microsoft.Migrate/MigrateProjects/Solutions", icon: "10281-icon-service-Azure-Migrate"},
    { type: "Microsoft.Network/natGateways", icon: "10310-icon-service-NAT"},
    { type: "Microsoft.Compute/HostGroups", icon: "10346-icon-service-Host-Groups"},
    { type: "Microsoft.Compute/Hostgroups/hosts", icon: "10347-icon-service-Hosts"},
    { type: "Microsoft.Network/p2sVpnGateways", icon: "10353-icon-service-Virtual-WANs"},
    { type: "Microsoft.Network/virtualWans", icon: "10353-icon-service-Virtual-WANs"},
    { type: "Microsoft.Network/firewallPolicies", icon: "10362-icon-service-Web-Application-Firewall-Policies(WAF)"},
    { type: "Microsoft.Network/FrontDoorWebApplicationFirewallPolicies", icon: "10362-icon-service-Web-Application-Firewall-Policies(WAF)"},
    { type: "Microsoft.Compute/proximityPlacementGroups", icon: "10365-icon-service-Proximity-Placement-Groups"},
    { type: "Microsoft.Devices/provisioningServices", icon: "10369-icon-service-Device-Provisioning-Services"},
    { type: "Microsoft.AppPlatform/Spring", icon: "10370-icon-service-Azure-Spring-Cloud"},
    { type: "Microsoft.Network/publicIPPrefixes", icon: "10372-icon-service-Public-IP-Prefixes"},
    { type: "Microsoft.Attestation/attestationProviders", icon: "10422-icon-service-AzureAttestation"},
    { type: "Microsoft.Databricks/workspaces", icon: "10787-icon-service-Azure-Databricks"},
    { type: "Microsoft.Consumption/budgets", icon: "10793-icon-service-Cost-Budgets"},
    { type: "Microsoft.MobileNetwork/simGroups", icon: "10822-icon-service-Mobile"},
    { type: "Microsoft.MobileNetwork/simGroups/sims", icon: "10822-icon-service-Mobile"},
    { type: "Microsoft.PowerBI/workspaceCollections", icon: "10824-icon-service-Power"},
    { type: "Microsoft.PowerBIDedicated/capacities", icon: "10824-icon-service-Power"},
    { type: "Microsoft.DocumentDb/databaseAccounts", icon: "10829-icon-service-Production-Ready-Database"},
    { type: "Microsoft.DocumentDB/databaseAccounts", icon: "10829-icon-service-Production-Ready-Database"},
    { type: "Microsoft.DocumentDB/databaseAccounts/cassandraKeyspaces", icon: "10829-icon-service-Production-Ready-Database"},
    { type: "Microsoft.DocumentDB/databaseAccounts/cassandraKeyspaces/tables", icon: "10829-icon-service-Production-Ready-Database"},
    { type: "Microsoft.DocumentDB/databaseAccounts/gremlinDatabases", icon: "10829-icon-service-Production-Ready-Database"},
    { type: "Microsoft.DocumentDB/databaseAccounts/gremlinDatabases/graphs", icon: "10829-icon-service-Production-Ready-Database"},
    { type: "Microsoft.DocumentDB/databaseAccounts/mongodbDatabases", icon: "10829-icon-service-Production-Ready-Database"},
    { type: "Microsoft.DocumentDB/databaseAccounts/mongodbDatabases/collections", icon: "10829-icon-service-Production-Ready-Database"},
    { type: "Microsoft.DocumentDB/databaseAccounts/sqlDatabases", icon: "10829-icon-service-Production-Ready-Database"},
    { type: "Microsoft.DocumentDB/databaseAccounts/sqlDatabases/containers", icon: "10829-icon-service-Production-Ready-Database"},
    { type: "Microsoft.DocumentDB/databaseAccounts/sqlDatabases/containers/storedProcedures", icon: "10829-icon-service-Production-Ready-Database"},
    { type: "Microsoft.DocumentDB/databaseAccounts/sqlDatabases/containers/triggers", icon: "10829-icon-service-Production-Ready-Database"},
    { type: "Microsoft.DocumentDB/databaseAccounts/sqlDatabases/containers/userDefinedFunctions", icon: "10829-icon-service-Production-Ready-Database"},
    { type: "Microsoft.DocumentDB/databaseAccounts/sqlRoleAssignments", icon: "10829-icon-service-Production-Ready-Database"},
    { type: "Microsoft.DocumentDB/databaseAccounts/sqlRoleDefinitions", icon: "10829-icon-service-Production-Ready-Database"},
    { type: "Microsoft.DevTestLab/schedules", icon: "10833-icon-service-Scheduler"},
    { type: "Microsoft.web/serverfarms", icon: "10835-icon-service-Server-Farm"},
    { type: "Microsoft.Web/serverfarms", icon: "10835-icon-service-Server-Farm"},
    { type: "Microsoft.ServiceBus/namespaces", icon: "10836-icon-service-Service-Bus"},
    { type: "Microsoft.ServiceBus/Namespaces", icon: "10836-icon-service-Service-Bus"},
    { type: "Microsoft.DocumentDB/databaseAccounts/tables", icon: "10841-icon-service-Table"},
    { type: "Microsoft.Web/sites/slots", icon: "10849-icon-service-Web-Slots"},
    { type: "Microsoft.Logic/workflows", icon: "10852-icon-service-Workflow"},
    { type: "Microsoft.Media/mediaservices", icon: "10854-icon-service-Media"},
    { type: "Microsoft.Media/mediaServices", icon: "10854-icon-service-Media"},
]

export default typeToIcon;
