import { useEffect, useRef, useState } from "react";
import AceEditor from "react-ace";
import range from "ace-builds";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { Stack, IStackStyles, mergeStyleSets, ICommandBarStyles, IButtonStyles, ICommandBarItemProps, CommandBar } from "@fluentui/react";

// Mutating styles definition
const containerStyle = (): IStackStyles => {
  return {
    root: {
      height: "100%" /* was 80vh */,
    },
  };
};

const editorStackStyle: IStackStyles = {
  root: {
    height: "100%",
  },
};

const styles: Partial<ICommandBarStyles> = {
  root: {
      boxSizing: 'border-box',
      borderBottom: '1px solid #eee',
      backgroundColor: '#fafcf7',
      padding: 0,
      height: 32
  },
};

const buttonStyles: Partial<IButtonStyles> = {
  root: {
      padding: 0,
      borderRight: '1px solid #eee',
      width: 32
  },
  icon: {
      color: '#555',
      margin: 0,
  },
  iconHovered: {
      color: '#333',
  }
}


export const getEditorClassNames = ({
  isFullWidth,
}: {
  isFullWidth: boolean;
}): IStackStyles =>
  mergeStyleSets({
    root: [
      {
        width: "50%",
      },
      isFullWidth && {
        width: "100%",
        height: "100%",
      },
    ],
  });

const Editor = ({
  value,
  mode,
  onChanged,
  selectedShape
}: any): JSX.Element => {
  const editorRef = useRef<AceEditor | null>(null);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.editor.focus();
    }
  }, []);

  useEffect(() => {
    setEditorValue(value);
  }, [value]);

  useEffect(() => {
    if (selectedShape) {
      // Set the current selected line to the "id": selectedShape.id
      const editor = editorRef.current?.editor;
      if (editor) {
        const code = editor.getValue();
        const lines = code.split("\n");
        const line = lines.findIndex((l) =>
          l.includes(`"id": "${selectedShape.id}"`)
        );
        if (line !== -1) {
          editor.gotoLine(line + 1, 0, true);
        }
      }
    }
  }, [selectedShape]);

  const surroundSelection = ((selectedSurroundWith: string) => {
    if (selectedSurroundWith) {
      const editor = editorRef.current?.editor;
      if (!editor) {
        return;
      }

      const selection = editor.getSelection().getRange();
      let startColumn = selection.start.column;
      let endColumn = selection.end.column;
      if (startColumn > endColumn) {
        const temp = startColumn;
        startColumn = endColumn;
        endColumn = temp;
      }
      const selectedText = editor.getSelectedText();

      if (!selection || !selectedText) {
        return;
      }

      if (selectedText.length === 0) {
        return;
      }

      if (startColumn !== 0) {
        // If text is already surrounded with selectedSurroundWith, remove it
        const surroundWithLength = selectedSurroundWith.length;
        // Get the range of characters before and after the selected text
        const code = editor.getValue();
        const previousChars = code.substring(startColumn - surroundWithLength - 1, startColumn);
        const nextChars = code.substring(startColumn + selectedText.length, startColumn + selectedText.length + surroundWithLength);
        if (previousChars === selectedSurroundWith && nextChars === selectedSurroundWith) {
          // Remove the selectedSurroundWith from the previous and next characters
          const newRange = {} as any;
          newRange.start = { row: selection.start.row, column: startColumn - surroundWithLength};
          newRange.end = { row: selection.end.row, column: endColumn + surroundWithLength};
          editor.selection.setRange(newRange);
          const newCode = `${selectedText}`;
          console.log(`newCode: ${newCode}, newRange:`, newRange);
          editor.session.replace(newRange, newCode);
          return;
        }
      }

      // Get the selected text and surround it with selectedSurroundWith
      const newCode = `${selectedSurroundWith}${selectedText}${selectedSurroundWith}`;
      editor.session.replace(selection, newCode);
    }
  });


  const removeHeadings = () => {
    const editor = editorRef.current?.editor;
    if (!editor) {
      return;
    }

    const selection = editor.getSelection();
    const value = editor.getSelectedText();
    const lines = value.split('\n');
    const newLines = lines.map((line: string) => {
        return line.replace(/^(#+)\s+/, '');
    });
    const newCode = newLines.join('\n');
    editor.session.replace(selection.getRange(), newCode);

}

const surroundLines = (styleLeft: string, styleRight: string = '') => {

  const editor = editorRef.current?.editor;
  if (!editor) {
    return;
  }
  const selection = editor.getSelection();
  const value = editor.getSelectedText();
  const lines = value.split('\n');
  const newLines = lines.map((line: string) => {
      return `${styleLeft}${line}${styleRight}`;
  });
  const newCode = newLines.join('\n');
  editor.session.replace(selection.getRange(), newCode);
  editor.focus();
  onChanged(newCode);
}

  const [editorValue, setEditorValue] = useState(value);

  const onEditorChange = (newValue: string) => {
    setEditorValue(newValue);
    onChanged(newValue);
  };

  const items: ICommandBarItemProps[] = [
    {
        key: 'fontSizes',
        buttonStyles: buttonStyles,
        iconProps: { iconName: 'FontSize' },
        disabled: false,
        subMenuProps: {
            items: [
                {
                    key: 'fontSizeH1',
                    text: 'Heading 1',
                    onClick: () => { surroundSelection('# '); },
                },
                {
                    key: 'fontSizeH2',
                    text: 'Heading 2',
                    onClick: () => { surroundSelection('## '); },
                },
                {
                    key: 'fontSizeH3',
                    text: 'Heading 3',
                    onClick: () => { surroundSelection('### '); },
                },
                {
                    key: 'fontSizeH4',
                    text: 'Heading 4',
                    onClick: () => { surroundSelection('#### '); },
                },
                {
                    key: 'fontSizeNormal',
                    text: 'Normal',
                    onClick: () => { removeHeadings(); },
                },
            ]
        }
    },
    {
        key: 'bold',
        buttonStyles: buttonStyles,
        iconProps: { iconName: 'Bold' },
        onClick: () => { surroundSelection('**'); },
        disabled: false
    },
    {
        key: 'italic',
        buttonStyles: buttonStyles,
        iconProps: { iconName: 'Italic' },
        onClick: () => { surroundSelection('_'); },
        disabled: false
    },
    {
        key: 'strike',
        buttonStyles: buttonStyles,
        iconProps: { iconName: 'StrikeThrough' },
        onClick: () => { surroundSelection('~~'); },
        disabled: false
    },
    {
        key: 'highlight',
        buttonStyles: buttonStyles,
        iconProps: { iconName: 'Highlight' },
        onClick: () => { surroundSelection('=='); },
        disabled: false
    },
    {
        key: 'code',
        buttonStyles: buttonStyles,
        iconProps: { iconName: 'Code' },
        onClick: () => { surroundSelection('`'); },
        disabled: false
    },
    {
        key: 'link',
        buttonStyles: buttonStyles,
        iconProps: { iconName: 'Link' },
        onClick: () => {
            // let selection = editor.getSelection();
            // let value = editor.getModel().getValueInRange(selection);
            // if (value === '') {
            //     autoCompleteWord = '[](';
            //     editor.trigger('suggest', 'editor.action.triggerSuggest');
            // } else {
            //     autoCompleteWord = '[](';
            //     editor.trigger('suggest', 'editor.action.triggerSuggest');
            // }
        },
        disabled: false
    },
    {
        key: 'list',
        buttonStyles: buttonStyles,
        iconProps: { iconName: 'BulletedList' },
        onClick: () => { surroundLines(' - ', ''); },
        disabled: false
    },
    {
        key: 'numbers',
        buttonStyles: buttonStyles,
        iconProps: { iconName: 'NumberedList' },
        onClick: () => { surroundLines(' 1. ', ''); },
        disabled: false
    },

];


  return (
    <Stack styles={containerStyle}>
      { mode === 'markdown' && <CommandBar
          items={items}
          ariaLabel="Use left and right arrow keys to navigate between commands"
          styles={styles}
      />
      }
      <Stack wrap horizontal grow styles={editorStackStyle}>
        <AceEditor
          ref={(ref) => (editorRef.current = ref)}
          value={editorValue}
          mode={mode}
          theme="github"
          height="100%"
          width="100%"
          setOptions={{
            enableLiveAutocompletion: true,
            showLineNumbers: true,
            tabSize: 2,
            showGutter: true,
            enableBasicAutocompletion: true,
            enableSnippets: false,
            highlightActiveLine: true,
            highlightSelectedWord: true,
            wrap: true,
          }}
          onChange={onEditorChange}
        />
      </Stack>
    </Stack>
  );
};

export default Editor;
