import { register } from "@antv/x6-react-shape";
import azureIcons from "../services/AzureIcons";
import { StringToJSX } from "../services/StringToJSX";
import { Node } from '@antv/x6'

const getImageData = function (element: any) {
    let icon = azureIcons.find(i => i.file === element["icon.file"]);
    if (!icon) {
        // remove .svg from the file name and try again
        icon = azureIcons.find(i => i.file === element["icon.file"].replace(".svg", ""));
    }
    return `data:image/svg+xml;base64,${icon?.data}`;
}

export const CustomShape = ({ node }: { node: Node }) => {
    const shape = JSON.parse(node.prop('custom-shape'));
    const definition = JSON.parse(node.prop('definition'));
    console.log("shape", shape);
    if (shape["icon.file"] && !shape["icon.data"]) {
        shape["icon.data"] = getImageData(shape);
    }
    return (
        <StringToJSX definition={definition} shape={shape} />
    )
}
