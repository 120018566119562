import * as React from 'react';
import { Nav, INavLink, INavStyles, INavLinkGroup, Stack, ActionButton, IStackStyles, SearchBox, ISearchBoxStyles, Label, IconButton } from '@fluentui/react';
import diagramEventBus from "../EventBus";
import withRouter from '../withRouter';

const navStyles: Partial<INavStyles> = {
  root: {
    height: 'calc(100vh - 116px)',
    overflowY: 'auto',
    backgroundColor: 'rgb(250, 252, 247)',
  },
};

const fillerStyles: Partial<IStackStyles> = {
  root: {
    height: 'calc(100vh - 80px)',
    width: 41,
    overflowY: 'auto',
    backgroundColor: 'rgb(250, 252, 247)',
  },
};

const propertyStackStyles: IStackStyles = {
  root: {
    boxSizing: 'border-box',
    borderTop: '1px solid #eee',
  },
};

const searchBoxStyles: Partial<ISearchBoxStyles> = {
  root: {
    height: 44,
    borderBottom: '1px solid #eee',
  }
};

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: 'Home',
        url: '/home',
        key: 'home',
      },
      {
        name: 'Diagrams',
        url: '/diagrams',
        key: 'diagrams',
      },
      {
        name: 'Templates',
        url: '/templates',
        key: 'templates',
      },
      {
        name: 'Shapes',
        url: '/shapes',
        key: 'shapes',
      },
      {
        name: 'Styles',
        url: '/styles',
        key: 'styles',
      },
      {
        name: 'Demo',
        url: '/demo',
        key: 'demo',
      },
      {
        name: 'Notes',
        url: '/notes',
        key: 'notes',
        disabled: true,
      },
      {
        name: 'Policies & terms',
        url: '#',
        key: 'site',
        links: [
          { name: 'Cookie policy', url: '/cookies', key: 'cookies' },
          { name: 'Privacy policy', url: '/privacy', key: 'privacy' },
          { name: 'Terms & conditions', url: '/terms', key: 'terms' },
          // { name: 'About', url: '/about', key: 'about' },
        ],
      },
    ],
  },
];

class LeftMenu extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      selectedKey: 'home',
      isExpanded: true,
      width: 220,
      items: navLinkGroups,
    };

  }

  toggleExpanded() {
    const width = this.state.isExpanded ? 40 : 220;
    this.setState({ isExpanded: !this.state.isExpanded, width: width });
    diagramEventBus.dispatch("onLeftNavSizeChanged", { isExpanded: !this.state.isExpanded, width: width });
  }

  setSelectedMenuItem() {
    let key = this.props.location.pathname.replace('/', '');
    if (key === '') key = 'home';
    if (key === 'diagram') key = 'diagrams';
    this.setState({ selectedKey: key });
  }

  componentDidMount() {
    this.setSelectedMenuItem();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      this.setSelectedMenuItem();
    }
  }

  componentWillUnmount() {
    console.log("LeftMenu unmount");
    diagramEventBus.remove("OnShowNewDiagramWizard", this);
    diagramEventBus.remove("onShowShapeList", this);
    diagramEventBus.remove("onShapesChanged", this);
    diagramEventBus.remove("onSelectionChanged", this);
  }


  public render() {
    let self = this;
    return (
      <Stack verticalFill={true} styles={{ root: { height: 'calc(100vh - 75px)', boxSizing: 'border-box', borderRight: '1px solid #eee' } }} style={{ width: this.state.width, minWidth: this.state.width }}>
        <Stack.Item grow={0} hidden={!(this.state.showShapes && this.state.isExpanded)}>
          <SearchBox placeholder="Search for shapes" underlined={true} styles={searchBoxStyles} value={this.state.filter} onChange={(event, newValue) => { this.setState({ filter: newValue ?? "" }); }} />
        </Stack.Item>
        <Stack.Item grow={1} hidden={!this.state.isExpanded}>
            <Nav
              onLinkClick={(event?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                if (event) {
                  event.preventDefault();
                }
                if (item) {
                  this.props.navigate(item.url);
                  this.setState({ selectedKey: item.key });
                }
              }}
              selectedKey={this.state.selectedKey}
              initialSelectedKey={this.state.selectedKey}
              ariaLabel="Left menu navigation"
              styles={navStyles}
              groups={this.state.items}
              isOnTop={false}
            />
        </Stack.Item>
        <Stack.Item grow={1} hidden={this.state.isExpanded} styles={fillerStyles}>
          <div className='navigation-label-left' onClick={() => this.toggleExpanded()}>Navigation</div>
        </Stack.Item>
        <Stack.Item grow={0} >
          <Stack horizontal styles={propertyStackStyles}>
            <Stack.Item className='stack-hover' align="start" grow={1} hidden={!this.state.isExpanded}>
              <ActionButton iconProps={{ iconName: 'Settings' }} allowDisabledFocus onClick={() => diagramEventBus.dispatch('onShowDiagramProperties', {})}>
                Settings
              </ActionButton>
            </Stack.Item>
            <Stack.Item className='stack-hover' style={{ paddingLeft: 4, paddingRight: 2 }}>
              <ActionButton iconProps={{ iconName: this.state.isExpanded ? 'DoubleChevronLeft12' : 'DoubleChevronRight12' }} allowDisabledFocus onClick={() => this.toggleExpanded()}>
              </ActionButton>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    )
  }
};

export default withRouter(LeftMenu);